/**
 * Component that populates the item for each row in Payments page.
 */

import { Button, Checkbox, Col, Row, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import {
    compact,
    concat,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
} from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    paymentMethodTypes,
    paymentResults,
    paymentTypes,
} from '../../constants/paymentsSortAndFilters';
import '../../pages/sales/sales.less';
import { ApplicationState } from '../../store';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../store/companies/sagas';
import { getPaymentSelectedId } from '../../store/payments/sagas';
import { Payment } from '../../store/payments/types';
import { getCompanyName, getIfIsLg, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { generateCommentItemWithType } from '../common/SharedExportComponents';

interface IProps {
    readonly item: Payment;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly daysFromToday?: (date: any, dateFormat?: string) => number | null;
}
const PaymentItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    formatDateLocal,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const selectedId = useSelector(getPaymentSelectedId);
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);
    const SupportCashAllocation = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
    );

    const {
        Id,
        LocalPaidDate,
        // SettledDate,
        PaidAmount,
        // ReceivedAmount,
        AllocatedAmount,
        Result,
        Customer,
        Allocations,
        ConversationLine,
        // CustomFields,
        Attachments,
        Source,
        PaymentMethod,
        LocalSettlementDate,
        VerificationState,
        BankInstitution,
        InvoicesAllocated,
    } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function called when clicking on the `latest comment` icon.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    /**
     * Function called when clicking on the `invoices paid` icon.
     * @param e
     */
    const viewPaidInvoicesSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'invoices');
    };

    // const name = `${get(item, 'User.GivenName', 'Jonathan')} ${get(
    //     item,
    //     'User.FamilyName',
    //     'Smith'
    // )}`;

    // const handleFormatDate = (date: any) => {
    //     return formatDateUTCToLocal ? formatDateUTCToLocal(date) : null;
    // };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    let itemColor = 'green';

    if (Result === paymentResults.UNCONFIRMED) {
        itemColor = 'orange';
        // itemIcon = ['far', 'circle'];
    } else if (Result === paymentResults.FAILED) {
        itemColor = 'red';
    }
    else if (Result === paymentResults.DISREGARDED) {
        itemColor = '#b3b3b3';
    }

    let UnallocatedAmount: number | undefined = undefined;
    let remittanceAdviceColor: string | undefined = undefined;

    if (SupportCashAllocation) {
        UnallocatedAmount = PaidAmount - (AllocatedAmount || 0);
        remittanceAdviceColor = UnallocatedAmount > 0 ? 'red' : 'green';
    }

    const isLg = getIfIsLg();

    // let secondRowContainerHeight: any = 'auto';
    let firstRowContainerHeight: any = 'auto';

    /**
     * Generate item icon based on payment type - default for now
     */
    const generateItemIcon = () => {
        let iconUsed = (
            <FontAwesome
                icon={['fas', 'external-link-alt']}
                className={itemColor}
                size="2x"
                transform="shrink-9 left-3"
            />
        );
        const cardIcon = (
            <FontAwesome
                icon={['fas', 'credit-card']}
                className={itemColor}
                size="2x"
                transform="shrink-9 left-4"
            />
        );
        const chequeIcon = (
            <FontAwesome
                icon={['fas', 'money-check-alt']}
                className={itemColor}
                size="2x"
                transform="shrink-9 left-5"
            />
        );
        if (
            Source === paymentTypes.WESTERN_UNION ||
            Source === paymentTypes.CORPAY ||
            Source === paymentTypes.EZIDEBIT ||
            Source === paymentTypes.INTEGRAPAY ||
            Source === paymentTypes.ADVAM
        ) {
            if (PaymentMethod === paymentMethodTypes.CARD) {
                iconUsed = cardIcon;
            } else {
                iconUsed = chequeIcon;
            }
        } else if (Source === paymentTypes.CHEQUE) {
            iconUsed = chequeIcon;
        }

        return (
            <div className="fa-layers fa-fw fa-2x">
                <FontAwesome
                    icon={['far', 'circle']}
                    className={itemColor}
                    size="2x"
                    transform="left-3 shrink-2"
                />
                {iconUsed}
            </div>
        );
    };

    if (isLg) {
        // secondRowContainerHeight = get(
        //     secondRowContainerRef,
        //     'current.clientHeight'
        // )
        //     ? get(secondRowContainerRef, 'current.clientHeight') - 2
        //     : 40;

        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    /**
     * Function for populating the icon group from row item.
     * Defined to easily use in different screen size layouts.
     */
    const iconGroupComponent = (
        <ButtonGroup className="button-link-icons">
            {!isEmpty(Attachments) && (
                <Tooltip
                    title={`${Attachments.length} attachment${Attachments.length > 1 ? 's' : ''} available`}
                    placement="topRight"
                >
                    <Button type="link" className="row-icons-btn pr-5">
                        <div className="fs-20">
                            <FontAwesome
                                icon={['fas', 'file-alt']}
                                className="blue"
                            />
                        </div>
                    </Button>
                </Tooltip>
            )}
            {ConversationLine &&
                generateCommentItemWithType(
                    ConversationLine,
                    'payment',
                    numberOfDaysBeforeRecentCommentTurnedOff,
                    viewCommentsSectionDrawer                    
                )}
            {InvoicesAllocated > 0 && (
                <Tooltip
                    title={`Pays ${InvoicesAllocated} invoice${
                        InvoicesAllocated > 1 ? 's' : ''
                    }`}
                    placement="topRight"
                >
                    <Button
                        type="link"
                        className="row-icons-btn pa-0"
                        onClick={viewPaidInvoicesSectionDrawer}
                    >
                        <div className="fa-layers fa-fw fa fs-20">
                            <FontAwesome
                                className="blue"
                                icon={['fa', 'file']}
                            />
                            <FontAwesome
                                className="white"
                                icon={['fa', 'plus']}
                                transform="shrink-8 down-2"
                            />
                        </div>
                    </Button>
                </Tooltip>
            )}
        </ButtonGroup>
    );

    /**
     * Function to populate `Paid on` and `Settled on` components.
     * Defined to easily be used in different screen size layouts.
     */
    const paidAndSettledDatesComponent = (
        <Row type="flex" gutter={60}>
            <Col>Paid on {LocalPaidDate && formatDateLocal(LocalPaidDate)}</Col>
            <Col>
                {LocalSettlementDate
                    ? `Settled on ${formatDateLocal(LocalSettlementDate)}`
                    : ''}
            </Col>
        </Row>
    );

    const AllCustomFields = concat(compact(get(Customer, 'CustomFields')));
    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            AllCustomFields,
            ({ Name, Value }: { Name: String; Value: String }) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    {/* Commented xl sizes are when icon is added */}
                    {/* <Col
                        xxl={2}
                        xl={2}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="h-100"
                    >
                        <Row className="fx-center-xl h-100">
                            <Col
                                // xl={12}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="h-100"
                            >
                                <div className="ta-center icon-checkbox-cont">
                                    <div
                                        style={{
                                            height: firstRowContainerHeight,
                                        }}
                                    >
                                        <Checkbox
                                            checked={includes(
                                                selectedRowKeys,
                                                Id
                                            )}
                                            onClick={handleCheckboxClick}
                                        />
                                    </div>
                                    {generateItemIcon()}
                                </div>
                            </Col>
                        </Row>
                    </Col> */}
                    <Col
                        className="h-100"
                        xxl={2}
                        xl={3}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                    >
                        <Row
                            className="h-100"
                            type="flex"
                            align={isLg ? 'top' : 'middle'}
                        >
                            <Col
                                xl={12}
                                // xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div
                                    className="ta-center center-flex-all"
                                    style={{
                                        height: firstRowContainerHeight,
                                    }}
                                >
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={12}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-center"
                            >
                                <div className="ta-center center-flex-all">
                                    {generateItemIcon()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xxl={22}
                        xl={21}
                        lg={22}
                        md={22}
                        sm={22}
                        xs={22}
                        className="h-100"
                    >
                        <Row className="h-100" type="flex">
                            <Col span={24} className="h-100">
                                <div ref={firstRowContainerRef}>
                                    <Row className="h-100">
                                        <Col span={14} className="h-100">
                                            <Row type="flex" gutter={60}>
                                                {!isUndefined(
                                                    get(
                                                        Customer,
                                                        'CustomerCode'
                                                    )
                                                ) && (
                                                    <Col
                                                        // xxl={4}
                                                        className={`flx-3 mxw-100`}
                                                    >
                                                        <b className="ws-nw va-top">
                                                            {get(
                                                                Customer,
                                                                'CustomerCode'
                                                            )}
                                                        </b>
                                                    </Col>
                                                )}

                                                <Col
                                                    className={`flx-7 mxw-100`}
                                                >
                                                    <b className="ws-nw va-top">
                                                        {getCompanyName(
                                                            Customer
                                                        )}
                                                    </b>
                                                </Col>
                                                {BankInstitution && (
                                                    <Col
                                                        className={`flx-7 mxw-100`}
                                                    >
                                                        <b className="ws-nw va-top">
                                                            {BankInstitution}
                                                        </b>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={10} className="ta-right">
                                            {/* <Row>
                                                <Col>
                                                    <b className={itemColor}>
                                                        Amount received{' '}
                                                        {handleFormatCurrency(
                                                            222
                                                        )}
                                                    </b>
                                                </Col>
                                            </Row> */}
                                            {/* <Row>
                                                <Col>
                                                    <b className={itemColor}>
                                                        Amount paid{' '}
                                                        {handleFormatCurrency(
                                                            PaidAmount
                                                        )}
                                                    </b>
                                                </Col>
                                            </Row> */}
                                            <Row
                                                gutter={30}
                                                type="flex"
                                                justify="end"
                                            >
                                                <Col
                                                    className={`contact-icons-container ta-right`}
                                                >
                                                    {iconGroupComponent}
                                                </Col>
                                                <Col
                                                    className={
                                                        isLg
                                                            ? 'flx-a-200'
                                                            : 'flx-a-250'
                                                    }
                                                >
                                                    <div>
                                                        <b className={itemColor}>
                                                            {getTranslatedText("Amount paid")}{' '}
                                                            {handleFormatCurrency(
                                                                PaidAmount
                                                            )}
                                                        </b>
                                                    </div>
                                                    {
                                                        SupportCashAllocation &&
                                                        VerificationState != 'Confirmed' &&
                                                        <div>
                                                            <b className={remittanceAdviceColor}>
                                                                    {getTranslatedText("Amount unallocated")}{' '}
                                                                {handleFormatCurrency(
                                                                    UnallocatedAmount || 0
                                                                )}
                                                            </b>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={14}>
                                            <Row>
                                                <Col xxl={8} xl={8} lg={24}>
                                                    {getTranslatedText("Result:")} {Result}
                                                </Col>
                                                {!isLg && (
                                                    <Col>
                                                        {
                                                            paidAndSettledDatesComponent
                                                        }
                                                    </Col>
                                                )}
                                            </Row>
                                            {isLg && (
                                                <Row>
                                                    <Col>
                                                        {
                                                            paidAndSettledDatesComponent
                                                        }
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                    {populateCustomFields()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* End of payment item */}
                </Row>
            </Col>
        </Row>
    );
};

// export default withDateFormatHandler(
//     withNumberFormatHandler(PaymentItemComponent)
// );

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(PaymentItemComponent)),
    arePropsEqual
);

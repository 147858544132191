import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map, filter as lFilter, isUndefined, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { DETAILS_TAB, PAYMENTS_PAGE } from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import {
    checkIfEmailIsValid,
    checkShouldRequestRefetch,
    customPostData,
    formatDateToDateObjectUTC,
    getRegionallyApiResponse,
    getRegionallyGraphqlResponse,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    addPaymentForADVAMRequestAction,
    addPaymentForEziDebitRequestAction,
    addPaymentForIntegraPayRequestAction,
    addPaymentForUrlRequestAction,
    addPaymentForWesternUnionRequestAction,
    getPaymentChangesErrorAction,
    getPaymentChangesSuccessAction,
    getPaymentConversationErrorAction,
    getPaymentConversationSuccessAction,
    getPaymentDataErrorAction,
    getPaymentDataSuccessAction,
    getPaymentInvoicesErrorAction,
    getPaymentInvoicesSuccessAction,
    getPaymentsErrorAction,
    getPaymentsRequestAction,
    getPaymentsSuccessAction,
    setPaymentSelectedIdSuccessAction,
    deallocatePaymentRequestAction,
    getBankFileFormatsSuccessAction,
    getBankFileFormatsErrorAction
} from './actions';
import { Payment, PaymentsActionTypes } from './types';
import { Invoice } from '../invoices/types';
import { appliedFilterIndicator } from '../../components/common/FilterBar';
import { populateSortFieldsParamSortParams } from '../tasks/sagas';
import { getOrganisationActionUrl } from '../organisations/sagas';
import { Auth } from 'aws-amplify';

export const getPaymentData = (state: ApplicationState) =>
    state.payments.activeData;

export const getPaymentSelectedId = (state: ApplicationState) =>
    state.payments.activeData.selectedId;

export const getPaymentsPageData = (state: ApplicationState) =>
    state.payments.pageData;

let refetchCount = 0;

const preparePayloadForTaskFilter = ({ payload, canExcludeTasks, parseCustomFields }: { 
    payload: any, 
    canExcludeTasks: boolean,
    parseCustomFields: boolean
 }) => {
    const {
        filter,
        filters,
        sortBy,
        sortAscending,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
        taskIds,
        excludeTasks,
        recordLimit
    } = payload;

    const finalPayload: any = {
        Ascending: sortAscending,
        ...(
            canExcludeTasks ? {
                TaskIds: taskIds,
                ExcludeTasks: excludeTasks,
                RecordLimit: recordLimit
            } : {}
        )
    };
    const finalFilter = filter || filters; // Handle different filter property names in models

    if (finalFilter) {
        const typeValue = isPaymentPlanEnabled
            ? finalFilter.Type
            : lFilter(finalFilter.Type, (ft: any) => ft !== 8);
        const usedFilters = {
            ...finalFilter,
            Type: typeValue,
            [`Type${appliedFilterIndicator}`]: typeValue,
        };
        const cleanFilters = removeAppliedFiltersForApiRequest(
            usedFilters,
            true,
            'task'
        );
        const assignedUserValue = cleanFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete cleanFilters.AssignedUserId;
                cleanFilters.AssignedEmailAddress = assignedUserValue;
            }
        }
        if (parseCustomFields) {
            if (cleanFilters.CustomFieldFilters) {
                cleanFilters.CustomFieldFilters = JSON.parse(
                    cleanFilters.CustomFieldFilters
                );
            }
        }
        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;
        Object.assign(finalPayload, cleanFilters);
    }

    if (sortBy) {
        const sortFields = populateSortFieldsParamSortParams(sortBy);
        if (parseCustomFields) {
            if (sortFields.CustomFieldSort) {
                sortFields.CustomFieldSort = JSON.parse(
                    sortFields.CustomFieldSort
                );
            }
        }
        Object.assign(finalPayload, sortFields);
    }

    return finalPayload;
}

/**
 * Function that calls the API for fetching the payment list.
 * @param param0
 */
function* handleGetPaymentsRequest({ payload }: any) {
    const errorMessage =
        'Error fetching payments list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            ActiveState,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENTS_FOR_COMPANY, {
                ...cleanFilters,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * PAYMENTS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                ActiveState
            })
        );

        const { Payments } = get(res.data, 'GetPaymentsForCompany');
        const responsePayload = {
            data: Payments,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Payments.length < pageSize) &&
                    !(pageSize < PAYMENTS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getPaymentsSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getPaymentsRequestAction(payload));
        } else {
            yield put(getPaymentsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function calling the API for fetching the payment data based on the given id.
 * @param param0
 */
function* handleGetPaymentDataRequest({
    payload: { 
        paymentId, 
        isUsingCloudImportType, 
        companyId 
    },
}: any) {
    const errorMessage = 'Error fetching payment details. Please try again later.';
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_PAYMENT_DETAILS_FOR_COMPANY, 
            {
                PaymentId: paymentId,
                CompanyId: companyId,
                IsCloudImportType: isUsingCloudImportType,
            }
        );

        const Payment = get(res.data, 'GetPaymentDetailsForCompany');

        if (Payment) {
            const responsePayload = {
                record: Payment,
            };

            yield put(getPaymentDataSuccessAction(responsePayload));
        } else {
            yield put(getPaymentDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentDataErrorAction([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the company bank file format based on the company id.
 * @param param0
 */
function* handleGetBankFileFormatsRequest() {
    const errorMessage =
        'Error fetching company bank file format. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_BANK_FILE_FORMATS_FOR_COMPANY)
        );

        const bankFileFormats = get(res.data, 'GetBankFileFormatsForCompany');

        if (bankFileFormats) {
            const responsePayload = {
                bankFileFormats: bankFileFormats
            };

            yield put(getBankFileFormatsSuccessAction(responsePayload));
        } else {
            yield put(getBankFileFormatsErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getBankFileFormatsErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected paymentId id for reference.
 * @param param0
 */
function* handleSetPaymentSelectedIdRequest({ payload }: any) {
    const { paymentId, callback } = payload;
    yield put(setPaymentSelectedIdSuccessAction(paymentId));
    if (callback) callback();
}

/**
 * Function that gets the invoices list for a certain payment.
 * @param param0
 */
function* handleGetPaymentInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment's invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_ALLOCATED_INVOICES_FOR_PAYMENT,
            {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            }
        );

        const { PaymentInvoices } = get(res.data, 'GetAllocatedInvoicesForPayment');
        if (PaymentInvoices) {
            const Invoices: Invoice[] = PaymentInvoices.map((inv: any) => inv.Invoice);
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getPaymentInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given payment.
 * @param param0
 */
function* handleGetOrganisationPaymentConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching organisation payment's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CONVERSATION_LINES_FOR_ORGANISATION, 
            {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            }
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForOrganisation'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given payment.
 * @param param0
 */
function* handleGetPaymentConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching payment's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CONVERSATION_LINES_FOR_COMPANY, 
            {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            }
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentConversationErrorAction([errorMessage]));
    }
}

function* handleOrganisationPaymentChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CHANGE_LINES_FOR_ORGANISATION,
            {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize,
            }
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForOrganisation');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the paymentChanges list of a given payment.
 * @param param0
 */
function* handleGetPaymentChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(
                queries.GET_CHANGE_LINES_FOR_COMPANY,
                {
                    ...cleanFilters,
                    PageSize: pageSize,
                    Skip:
                        currentPage * DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize,
                }
            )
        );
        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.PAYMENT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the payment's conversation list.
 * @param param0
 */
function* handlePaymentAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, paymentIds, excludePayments, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        PaymentManagementFilter: {
            ...cleanFilters,
            PaymentIds: paymentIds,
            ExcludePayments: excludePayments,
        },
        Comment: comment,
    };

    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        yield call(
            getRegionallyApiResponse,
            apiUrl,
            '/conversation/save/paymentcomment',
            'POST',
            payload
        );
        
        if (callback) {
            let RefetchList = true;
            if (paymentIds.length === 1 && excludePayments === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const paymentsUpdated: DynamicObject[] = map(
                    paymentIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getPaymentsPageData);
                yield put(
                    getPaymentsSuccessAction({
                        data: paymentsUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called when connecting add payment for payment url to the API.
 * @param param0
 */
function* handleAddPaymentForUrlRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/fromurl',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForUrlRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for western union to the API.
 * @param param0
 */
function* handleAddPaymentForWesternUnionRequest({
    payload: sagaPayload,
}: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/westernunion',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                addPaymentForWesternUnionRequestAction(payload, callback)
            );
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for EziDebit to the API.
 * @param param0
 */
function* handleAddPaymentForEziDebitRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/ezidebit',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForEziDebitRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for IntegraPay to the API.
 * @param param0
 */
function* handleAddPaymentForIntegraPayRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/integrapay',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForIntegraPayRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for ADVAM to the API.
 * @param param0
 */
function* handleAddPaymentForADVAMRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/advam',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(addPaymentForADVAMRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when connecting add payment for western union to the API.
 * @param param0
 */
function* handleAddPaymentForCorpayRequest({
    payload: sagaPayload,
}: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/instructdeal',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        // if (
        //     refetchCount <= maxAPIRefetchCount &&
        //     checkShouldRequestRefetch(err)
        // ) {
        //     refetchCount++;
        //     yield delay(refetchAPIDelay);
        //     yield put(
        //         addPaymentForWesternUnionRequestAction(payload, callback)
        //     );
        // } else {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
        // }
    }
}

/**
 * Function called when getting the spot rate for corpay from the API .
 * @param param0
 */
function* handleGetCorpayPaymentSpotRate({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/spotrate',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        // if (
        //     refetchCount <= maxAPIRefetchCount &&
        //     checkShouldRequestRefetch(err)
        // ) {
        //     refetchCount++;
        //     yield delay(refetchAPIDelay);
        //     yield put(addPaymentForADVAMRequestAction(payload, callback));
        // } else {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }

}

/**
 * Function called when connecting book deal for Corpay to the API.
 * @param param0
 */
function* handleCorpayBookDealRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/corpay/bookdeal',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }

}

/**
 * Function called when connecting book deal for Corpay to the API.
 * @param param0
 */
 function* handleSendPaymentInstructionForCorpayRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/payment/sendreceipt',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        // callback(err)
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }

}

/**
 * Function called when connecting deallocate payment to the API.
 * @param param0
 */
function* handleDeallocatePaymentRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    const { PaymentId, CompanyId } = payload;
    let variables: any = { PaymentId };
    if (!isUndefined(CompanyId) && !isEmpty(CompanyId)) {
        variables.CompanyId = CompanyId
    }
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyApiResponse,
            apiUrl,
            '/change/payment/reverse',
            'POST',
            variables
        );

        refetchCount = 0;
        if (callback) {
            const response = {
                ...res,
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(deallocatePaymentRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function for check last payment change line that can be deallocated
 * @param param0
 */
function* handleCheckLastPaymentChangeLineRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    const { filters } = payload;

    try {
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'changelines');
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.CHECK_LAST_PAYMENT_CHANGELINES,
            {
                ...cleanFilters,
            }
        );

        const { IsLastPaymentChangeline } = get(res.data, 'CheckLastPaymentChangeLines');
        const response = {
            IsSuccess: true,
            IsLastPaymentChangeline
        };
        callback(response);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the payments custom fields for a company.
 * @param param0
 */
function* handleGetPaymentsCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetPaymentsCustomFieldsForCompany');
        
        if (callback && CustomFieldValues) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when creating credit as overpayment
 * @param param0
 */
function* handleCreateCreditAsOverpaymentRequest({ payload: sagaPayload }: any) {
    const {
        CompanyId,
        callback,
    } = sagaPayload;

    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter, CompanyId },
    };

    try {
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/over-payment-credit',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleCreateOrganisationCreditAsOverpaymentRequest({ payload: sagaPayload }: any) {
    const {
        CompanyId,
        callback,
    } = sagaPayload;

    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter, CompanyId },
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );
    
    try {
        let response : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            response = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/over-payment-credit`,
                payload, { Authorization: accessToken }
            );
        } else {
            response = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/over-payment-credit',
                {
                    body: payload,
                }
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when confirming a remittance advice disregard tasks.
 * @param param0
 */
function* handlePaymentDisregardTasksRequest({ payload: sagaPayload }: any) {
    const {
        CompanyId,
        callback,
    } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter, CompanyId },
    };

    try {
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/disregard/payment',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleOrganisationPaymentDisregardTasksRequest({ payload: sagaPayload }: any) {
    const {
        CompanyId,
        callback,
    } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter, CompanyId },
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/disregard/payment`,
                payload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/disregard/payment',
                {
                    body: payload,
                }
            );
        }
        
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetPaymentsRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENTS_REQUEST,
        handleGetPaymentsRequest
    );
}

function* watchGetPaymentDataRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_DATA_REQUEST,
        handleGetPaymentDataRequest
    );
}

function* watchGetBankFileFormatsRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_BANK_FILE_FORMATS_REQUEST,
        handleGetBankFileFormatsRequest
    );
}

function* watchSetPaymentSelectedIdRequest() {
    yield takeLatest(
        PaymentsActionTypes.SET_PAYMENT_SELECTED_ID_REQUEST,
        handleSetPaymentSelectedIdRequest
    );
}

function* watchGetPaymentInvoicesRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_INVOICES_REQUEST,
        handleGetPaymentInvoicesRequest
    );
}

function* watchGetOrganisationPaymentConversationRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CONVERSATION_REQUEST,
        handleGetOrganisationPaymentConversationRequest
    );
}

function* watchGetPaymentConversationRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_CONVERSATION_REQUEST,
        handleGetPaymentConversationRequest
    );
}

function* watchGetPaymentChangesRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_PAYMENT_CHANGES_REQUEST,
        handleGetPaymentChangesRequest
    );
}

function* watchGetOrganisationPaymentChangesRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_ORGANISATION_PAYMENT_CHANGES_REQUEST,
        handleOrganisationPaymentChangesRequest
    );
}

function* watchPaymentAddCommentRequest() {
    yield takeLatest(
        PaymentsActionTypes.PAYMENT_ADD_COMMENT_REQUEST,
        handlePaymentAddCommentRequest
    );
}

function* watchAddPaymentForUrlRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_URL_REQUEST,
        handleAddPaymentForUrlRequest
    );
}

function* watchAddPaymentForWesternUnionRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_WESTERN_UNION_REQUEST,
        handleAddPaymentForWesternUnionRequest
    );
}

function* watchAddPaymentForEziDebitRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_EZIDEBIT_REQUEST,
        handleAddPaymentForEziDebitRequest
    );
}

function* watchAddPaymentForIntegraPayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_INTEGRAPAY_REQUEST,
        handleAddPaymentForIntegraPayRequest
    );
}

function* watchAddPaymentForADVAMRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_ADVAM_REQUEST,
        handleAddPaymentForADVAMRequest
    );
}
function* watchAddPaymentForCorpayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.ADD_PAYMENT_FOR_CORPAY_REQUEST,
        handleAddPaymentForCorpayRequest
    );
}
function* watchGetCorpayPaymentSpotRate() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENT_SPOT_RATE,
        handleGetCorpayPaymentSpotRate
    );
}
function* watchCorpayBookDealRequest() {
    yield takeLatest(
        PaymentsActionTypes.CORPAY_BOOK_DEAL_REQUEST,
        handleCorpayBookDealRequest
    );
}
function* watchSendPaymentInstructionForCorpayRequestRequest() {
    yield takeLatest(
        PaymentsActionTypes.SEND_PAYMENT_INSTRUCTION_FOR_CORPAY_REQUEST,
        handleSendPaymentInstructionForCorpayRequest
    );
}
function* watchDeallocatePaymentRequest() {
    yield takeLatest(
        PaymentsActionTypes.DEALLOCATE_PAYMENT_REQUEST,
        handleDeallocatePaymentRequest
    );
}


function* watchCheckLastPaymentChangeLineRequest() {
    yield takeLatest(
        PaymentsActionTypes.CHECK_LAST_PAYMENT_CHANGELINES_REQUEST,
        handleCheckLastPaymentChangeLineRequest
    );
}

function* watchGetPaymentsCustomFieldsForCompanyRequest() {
    yield takeLatest(
        PaymentsActionTypes.GET_PAYMENTS_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetPaymentsCustomFieldsForCompanyRequest
    );
}

function* watchCreateCreditAsOverpaymentRequest() {
    yield takeLatest(
        PaymentsActionTypes.CREATE_CREDIT_AS_OVERPAYMENT_REQUEST,
        handleCreateCreditAsOverpaymentRequest
    );
}

function* watchCreateOrganisationCreditAsOverpaymentRequest() {
    yield takeLatest(
        PaymentsActionTypes.CREATE_ORGANISATION_CREDIT_AS_OVERPAYMENT_REQUEST,
        handleCreateOrganisationCreditAsOverpaymentRequest
    );
}

function* watchPaymentDisregardTasksRequest() {
    yield takeLatest(
        PaymentsActionTypes.DISREGARD_PAYMENT_REQUEST, 
        handlePaymentDisregardTasksRequest
    );
}

function* watchOrganisationPaymentDisregardTasksRequest() {
    yield takeLatest(
        PaymentsActionTypes.ORGANISATION_DISREGARD_PAYMENT_REQUEST, 
        handleOrganisationPaymentDisregardTasksRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* paymentsSaga() {
    yield all([
        fork(watchGetPaymentsRequest),
        fork(watchGetPaymentDataRequest),
        fork(watchGetBankFileFormatsRequest),
        fork(watchSetPaymentSelectedIdRequest),
        fork(watchGetPaymentInvoicesRequest),
        fork(watchGetPaymentConversationRequest),
        fork(watchGetPaymentChangesRequest),
        fork(watchPaymentAddCommentRequest),
        fork(watchAddPaymentForUrlRequestRequest),
        fork(watchAddPaymentForWesternUnionRequestRequest),
        fork(watchAddPaymentForEziDebitRequestRequest),
        fork(watchAddPaymentForIntegraPayRequestRequest),
        fork(watchAddPaymentForADVAMRequestRequest),
        fork(watchGetCorpayPaymentSpotRate),
        fork(watchCorpayBookDealRequest),
        fork(watchAddPaymentForCorpayRequestRequest),
        fork(watchSendPaymentInstructionForCorpayRequestRequest),
        fork(watchDeallocatePaymentRequest),
        fork(watchCheckLastPaymentChangeLineRequest),
        fork(watchGetPaymentsCustomFieldsForCompanyRequest),
        fork(watchCreateCreditAsOverpaymentRequest),
        fork(watchCreateOrganisationCreditAsOverpaymentRequest),
        fork(watchPaymentDisregardTasksRequest),
        fork(watchOrganisationPaymentDisregardTasksRequest),
        fork(watchGetOrganisationPaymentConversationRequest),
        fork(watchGetOrganisationPaymentChangesRequest)
    ]);
}

export default paymentsSaga;

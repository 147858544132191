/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Col, Drawer, Form, Modal, Row, Table } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { PaymentPlan } from '../../store/paymentPlans/types';
import moment from 'moment-timezone';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (isPaymentPlanCreatedSuccessfully?: boolean) => void;
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly form?: any;
    readonly paymentPlan: PaymentPlan;
    readonly isRequireDeposit: boolean;
    readonly dispatchAction?: (payload: any, callback: any) => void;
}

const ReviewCreatedNewPaymentPlanPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    formatCurrency,
    form,
    paymentPlan,
    isRequireDeposit,
    dispatchAction,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { validateFields, resetFields } = form;

    const instalmentsTableColums = [
        {
            title: 'Instalment',
            dataIndex: 'InstalmentNumber',
            width: '25%'
        },
        {
            title: 'Instalment Amount',
            dataIndex: 'InstalmentAmount',
            width: '36%'
        },
        {
            title: 'Instalment Due Date',
            dataIndex: 'InstalmentDueDate',
            width: '39%'
        }
    ];

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction(
                           paymentPlan,
                           createPaymentPlanResponseModal
                        )
                    );
                } else {
                    if (closePanel) closePanel(true);
                }
            }
        });
    };

    /**
     * Function responsible for showing the response modal after creating the payment plan.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createPaymentPlanResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Payment plan is created successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText('Failed to create payment plan!');
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {

        const paymentPlanInstalmentList = paymentPlan.PaymentPlanSchedules.map((p, index) => {
            return {
                Id: p.Id,
                InstalmentNumber: index + 1,
                InstalmentAmount: handleFormatCurrency(p.AmountRemaining),
                InstalmentDueDate: moment(p.LocalDueDateTime).format(dateFormatDDMMMYYYYSpace)
            }
        });

        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <div>
                            <h4>{getTranslatedText('Total amount to pay is')}: {paymentPlan && handleFormatCurrency(paymentPlan.TotalAmount)}</h4>
                        </div>
                        {isRequireDeposit && paymentPlan && paymentPlan.PaymentPlanSchedules && paymentPlan.PaymentPlanSchedules.length > 0 && (<div>
                            <h4>{getTranslatedText('The require deposit will be')}: {handleFormatCurrency(paymentPlan.PaymentPlanSchedules[0].AmountRemaining)}</h4>
                        </div>)}
                    </Col>
                </Row>
                <Row>
                    <h4>{getTranslatedText('The payment plan will be created the following instalments')}</h4>
                    <Col span={24}>
                        <Table
                            rowKey="Id"
                            className="app-pl-instalments-table"
                            columns={instalmentsTableColums.map(column => ({
                                ...column,
                                title: getTranslatedText(column.title)
                            }))}
                            dataSource={paymentPlanInstalmentList}
                            pagination={false}
                            bordered={true}
                            locale={{
                                emptyText: getTranslatedText('No Data'),
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Drawer
            title={getTranslatedText("Review Created New Payment Plan")}
            width={1000}
            onClose={handleClosePanel}
            visible={visible}
            className="review-created-new-payment-plan-panel"
            closable={false}
            maskClosable={false}
        >
            {paymentPlan && (<Row>
                <Col>
                    <div>{populatePanelContent()}</div>
                    <br />
                    <Row>
                        <Col className="ta-right" span={24}>
                            <Button
                                className="mr-8"
                                type="primary"
                                onClick={handleSubmitForm}
                            >
                                {getTranslatedText("Create")}
                            </Button>
                            <Button className="buttonGrey mr-8" onClick={handleClosePanel}>{getTranslatedText("Back")}</Button>
                        </Col>
                    </Row>
                </Col>
                {submitLoading && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={getTranslatedText("Creating new payment plan")}
                            modalVisible={submitLoading}
                            displayMessage={getTranslatedText("Please wait while creating new payment plan . . .")}
                            containerRef={containerRef}
                        />
                    </Suspense>
                )}
            </Row>)}
        </Drawer>
    );
};

const ReviewCreatedNewPaymentPlanPanelForm = Form.create({
    name: 'review-created-new-payment-plan-panel-form',
})(ReviewCreatedNewPaymentPlanPanel);

export default withRouter(ReviewCreatedNewPaymentPlanPanelForm);
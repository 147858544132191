/**
 * Component that populates the item for each row in Invoices page.
 */

import { Button, Checkbox, Col, Popover, Row } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import {
    capitalize,
    compact,
    concat,
    get,
    includes,
    isEmpty,
    isEqual,
    isNull,
    isUndefined,
    map,
} from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatDMMMYYYYSpace } from '../../constants/dateFormats';
import {
    taskHistoryBatchTypes,
    taskItemColorsAndLabel,
} from '../../constants/tasksSortAndFilters';
import '../../pages/sales/sales.less';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../store/companies/sagas';
import { getSelectedInvoiceId } from '../../store/invoices/sagas';
import { Invoice, DeliveryDetails } from '../../store/invoices/types';
import {
    checkIfDateHasPassed,
    checkIfTaskReady,
    getCompanyName,
    getIfIsLg,
    populateDaysOverdue,
    getContactTypeValue,
    getTranslatedText,
    replaceTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAutomationIconsHandler } from '../common/AutomationIconsHandler';
import ContactMethodIcons from '../common/ContactMethodIcons';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    generateAttachmentsIcon,
    generateCommentItemWithType,
    generateCustomerIcon,
    generateEscalatedIcon,
    generatePauseIcon,
    generateSettledIcon,
} from '../common/SharedExportComponents';


interface IProps {
    readonly item: Invoice;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly generateAutomationIcon?: (
        type: string,
        automation: string | null,
        iconColor: string,
        iconCircleColor?: string | null,
        fontSizeProp?: number | string | null,
        isCompactView?: boolean,
        includePaymentPlan?: boolean
    ) => JSX.Element | undefined;
    readonly daysFromToday?: (date: any, dateFormat?: string) => number | null;
    readonly isCompactView?: boolean;
    readonly usingCustomerWorkflow?: boolean;
    readonly customerLabel?: string;
}
const InvoiceItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    formatDateLocal,
    formatDateUTCToLocal,
    generateAutomationIcon,
    daysFromToday,
    isCompactView,
    usingCustomerWorkflow,
    customerLabel,
}: IProps) => {
    
    const selectedId = useSelector(getSelectedInvoiceId);
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);

    const {
        Id,
        Number: InvoiceNumber,
        LocalCreatedDate,
        DueDate,
        LocalDueDate,
        OriginalAmount,
        AmountOwing,
        State,
        LocalSettledDate,
        Customer,
        ConversationLine,
        CustomFields,
        Attachments,
        LastCommunication,
        CurrentTask,
        IsBlocked,
        Company
    } = item;

    const usedCurrentTask = usingCustomerWorkflow
        ? get(Customer, 'CurrentTask')
        : CurrentTask;

    const usedLastCommunication = LastCommunication;

    const isInvoiceSettled = State === 'Settled';
    const companyName = get(Company, 'Name');

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function called when clicking on the `latest comment` icon.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    // const name = `${get(item, 'User.GivenName', 'Jonathan')} ${get(
    //     item,
    //     'User.FamilyName',
    //     'Smith'
    // )}`;

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    /**
     * Function for getting the days overdue of a certain date.
     * @param date - date to base the overdue number of days
     */
    const getDaysOverdue = (date: any) => {
        if (daysFromToday) {
            return daysFromToday(date);
        } else {
            return null;
        }
    };

    /**
     * Function that populates the days overdue component (including extra text).
     * @param days - number of days
     */
    const populateDaysOverdueSection = (days: number | null) => {
        let overdueString = '';

        if (isInvoiceSettled) {
            overdueString = getTranslatedText('Closed');
        } else {
            overdueString = populateDaysOverdue(DueDate, days);
        }

        return overdueString;
    };

    const populateDeliveryFeedbackInfo = (deliveryDetails: DeliveryDetails[]) => {
        const contactMethods = getContactTypeValue(deliveryDetails);
        return (
            <ContactMethodIcons
                contactMethods={contactMethods}
                deliveryDetails={deliveryDetails}
                showOnlyComment={true}
            />
        );
    };
    
    const daysOverdue = getDaysOverdue(DueDate);
    let itemColor = 'green';
    // let itemIcon = ['far', 'play-circle'];

    if (isInvoiceSettled) {
        itemColor = 'settled';
        // itemIcon = ['far', 'circle'];
    } else {
        if (!isNull(daysOverdue) && daysOverdue > 0) {
            itemColor = 'red';
        }
    }

    const isLg = getIfIsLg();

    const customerComment = get(Customer, 'ConversationLine');
    const deliveryDetails = get(LastCommunication, 'DeliveryDetails');

    /**
     * Function for populating the icon group from row item.
     * Defined to easily use in different screen size layouts.
     */
    const iconGroupComponent = (
        <ButtonGroup className="button-link-icons">
            {!isEmpty(deliveryDetails) && populateDeliveryFeedbackInfo(deliveryDetails)}
            {generateAttachmentsIcon(Attachments)}
            {ConversationLine &&
                generateCommentItemWithType(
                    ConversationLine,
                    'invoice',
                    numberOfDaysBeforeRecentCommentTurnedOff,
                    viewCommentsSectionDrawer
                )}
            {customerComment &&
                generateCommentItemWithType(
                    customerComment,
                    'customer',
                    numberOfDaysBeforeRecentCommentTurnedOff,
                    viewCommentsSectionDrawer
                )}
        </ButtonGroup>
    );

    /**
     * Function to populate `Created on` and `Due on` components.
     * Defined to easily be used in different screen size layouts.
     */
    const createdAndDueDatesComponent = (
        <Row type="flex" gutter={60}>
            <Col>{getTranslatedText('Created on')} {formatDateLocal(LocalCreatedDate)}</Col>
            <Col>{getTranslatedText('Due on')} {formatDateLocal(LocalDueDate)}</Col>
        </Row>
    );

    /**
     * Function that calls an HOC function to generate the automation icon appropriate for this item.
     */
    const generateInvoiceItemIcon = () => {
        const lastCommunicationState = get(usedLastCommunication, 'StateName');

        if (isInvoiceSettled) {
            return generateSettledIcon();
        }
        if (lastCommunicationState === 'Escalate To Collections') {
            return generateEscalatedIcon();
        } else if (usedCurrentTask || CurrentTask) {
            if (generateAutomationIcon) {
                const taskType = get(CurrentTask, 'Type');

                let taskAutomation = get(CurrentTask, 'Automation');
                let actionDate = get(CurrentTask, 'ActionDate');
                let stateName = get(CurrentTask, 'Workflow.StateName');
                let state = get(CurrentTask, 'State');
                let usedTaskType = taskType;
                const ticketNonBlocking = get(
                    CurrentTask,
                    'Ticket.TicketOption.NonBlocking'
                );
                const showNonBlockingTicket =
                    ticketNonBlocking === true &&
                    (IsBlocked ||
                        Customer.IsVip ||
                        get(usedCurrentTask, 'Type') ===
                            taskHistoryBatchTypes.Collection ||
                        !usedCurrentTask);

                if (
                    !(
                        get(CurrentTask, 'Type') ===
                            taskHistoryBatchTypes.Ticket &&
                        (ticketNonBlocking !== true || showNonBlockingTicket)
                    )
                ) {
                    if (Customer.IsVip) {
                        return customerVIPIconGenerate();
                    } else if (IsBlocked) {
                        return <div>{generatePauseIcon()}</div>;
                    } else if (usingCustomerWorkflow) {
                        if (
                            get(usedCurrentTask, 'Type') !==
                            taskHistoryBatchTypes.Ticket
                        ) {
                            taskAutomation = get(usedCurrentTask, 'Automation');
                            actionDate = get(usedCurrentTask, 'ActionDate');
                            stateName = get(
                                usedCurrentTask,
                                'Workflow.StateName'
                            );
                            state = get(usedCurrentTask, 'State');
                            usedTaskType = get(usedCurrentTask, 'Type');
                        } else {
                            usedTaskType = '';
                        }
                    }
                }

                const isTaskReady = checkIfTaskReady(state, actionDate);

                const { iconCircleColor, iconContentColor } =
                    taskItemColorsAndLabel(
                        usedTaskType,
                        stateName,
                        isTaskReady,
                        actionDate
                    );

                // const notPaddedTaskTypes = [
                //     taskHistoryBatchTypes.Communication,
                //     taskHistoryBatchTypes.Collection,
                // ];

                return (
                    <div
                        // className={`coupled-icon-cont ${
                        //     includes(notPaddedTaskTypes, taskType) ? '' : 'pr-8'
                        // }`}
                        className="coupled-icon-cont ta-center center-flex-all w-100"
                    >
                        {generateAutomationIcon(
                            usedTaskType,
                            taskAutomation,
                            iconContentColor,
                            iconCircleColor,
                            undefined,
                            undefined,
                            true
                        )}
                    </div>
                );
            }
        } else {
            if (Customer.IsVip) {
                return customerVIPIconGenerate();
            } else if (IsBlocked) {
                return <div>{generatePauseIcon()}</div>;
            }
        }

        return null;
    };

    const customerVIPIconGenerate = () => {
        const TotalOverdue = get(Customer, 'AgedTrialBalance.TotalOverdue');
        const itemColor = TotalOverdue > 0 ? 'red' : 'green';
        return <div>{generateCustomerIcon(true, itemColor, 30.17)}</div>;
    };

    /**
     * Function for populating the current task section.
     */
    const populateCurrentTaskSection = () => {
        let currentTaskText: string | null = null;
        if (isInvoiceSettled) {
            currentTaskText = `${getTranslatedText('Closed on')} ${formatDateLocal(LocalSettledDate)}`;
        } else {
            if (usedCurrentTask || CurrentTask) {
                let taskType = get(usedCurrentTask, 'Type');
                let stateName = get(usedCurrentTask, 'Workflow.StateName');

                const ticketNonBlocking = get(
                    CurrentTask,
                    'Ticket.TicketOption.NonBlocking'
                );
                const showNonBlockingTicket =
                    (ticketNonBlocking === true &&
                        (IsBlocked ||
                            Customer.IsVip ||
                            get(usedCurrentTask, 'Type') ===
                                taskHistoryBatchTypes.Collection)) ||
                    !usedCurrentTask;

                if (
                    get(CurrentTask, 'Type') === taskHistoryBatchTypes.Ticket &&
                    (ticketNonBlocking !== true || showNonBlockingTicket)
                ) {
                    let ticketText = '';
                    const ticketReason = get(
                        CurrentTask,
                        'Ticket.TicketOption.Reason'
                    );

                    if (ticketReason) {
                        if (ticketReason === 'Other') {
                            const captureMessage = get(
                                CurrentTask,
                                'Ticket.TicketOption.CaptureMessage'
                            );
                            ticketText = `Other${
                                captureMessage ? ` - ${captureMessage}` : ''
                            }`;
                        } else {
                            ticketText = ticketReason;
                        }
                        currentTaskText = `${getTranslatedText('Ticket raised')}: ${ticketText}`;
                    } else {
                        currentTaskText = `Action assistance/ticket #${get(
                            CurrentTask,
                            'Ticket.TicketNumber',
                            ''
                        )}`;
                    }
                } else if (
                    taskType === taskHistoryBatchTypes.Communication ||
                    taskType === taskHistoryBatchTypes.Collection ||
                    taskType === taskHistoryBatchTypes.PaymentSchedule
                ) {
                    if (!IsBlocked) {
                        const actionDate = get(usedCurrentTask, 'ActionDate');
                        const hasDatePassed = checkIfDateHasPassed(actionDate);
                        const actionPhrase = hasDatePassed
                            ? 'ready since'
                            : 'pending until';
                        const workflowName = populateWorkflowName();
                        const workflowText = workflowName
                            ? ` on ${workflowName}`
                            : '';
                        if (
                            taskType === taskHistoryBatchTypes.PaymentSchedule
                        ) {
                            currentTaskText = `${getTranslatedText('Current Task')}: ${stateName}${workflowText} for schedule #${get(
                                CurrentTask,
                                'PaymentPlanSchedule.Number',
                                '--'
                            )}, ${actionPhrase} ${formatDateUTCToLocal(
                                actionDate,
                                null,
                                dateFormatDMMMYYYYSpace
                            )}`;
                        } else if (usingCustomerWorkflow) {
                            currentTaskText = `${getTranslatedText(`Current ${capitalize(
                                customerLabel
                            )} Task`)}: ${stateName}${workflowText}, ${actionPhrase} ${formatDateUTCToLocal(
                                actionDate,
                                null,
                                dateFormatDMMMYYYYSpace
                            )}`;
                        } else {
                            currentTaskText = `${getTranslatedText('Current Task')}: ${stateName}${workflowText} for invoice ${InvoiceNumber}, ${actionPhrase} ${formatDateUTCToLocal(
                                actionDate,
                                null,
                                dateFormatDMMMYYYYSpace
                            )}`;
                        }
                    }
                }
            }
        }

        if (currentTaskText)
            return <Col className="fx-1" style={{ whiteSpace: 'pre' }}>{currentTaskText}</Col>;

        return null;
    };

    /**
     * Function for populating the Workflow name in certain conditions.
     */
    const populateWorkflowName = () => {
        let workflowNameDisplay = '';
        if (usedCurrentTask) {
            const taskType = get(usedCurrentTask, 'Type');
            if (
                !(
                    taskType === taskHistoryBatchTypes.Ticket ||
                    IsBlocked ||
                    Customer.IsVip
                )
            ) {
                workflowNameDisplay = get(usedCurrentTask, 'Workflow.WorkflowName');
            }
        }
        return workflowNameDisplay;
    };

    /**
     * Function to populate `Closed on section` if `Settled date` is defined.
     * Defined to easily be used in different screen size layouts.
     */
    const populateTaskAndReminderComponent = () => {
        const lastCommunicationState = get(usedLastCommunication, 'StateName');
        const lastCommunicationSentDateTime = get(
            usedLastCommunication,
            'SentDateTime'
        );
        const lastCommunicationWorkflowName = get(
            usedLastCommunication,
            'WorkflowName'
        );

        return (
            <Row>
                <Col>
                    <Row type="flex" gutter={10}>
                        {populateCurrentTaskSection()}
                    </Row>
                </Col>
                {lastCommunicationState && (
                    <Col span={24}>
                        {getTranslatedText('Last Reminder')}: {lastCommunicationState}
                        {lastCommunicationWorkflowName
                            ? ` on ${lastCommunicationWorkflowName}`
                            : ''}
                        {lastCommunicationSentDateTime
                            ? ` sent on ${formatDateUTCToLocal(
                                  lastCommunicationSentDateTime,
                                  null,
                                  dateFormatDMMMYYYYSpace
                              )}`
                            : null}
                    </Col>
                )}
            </Row>
        );
    };

    /**
     * Function to populate original total.
     */
    const originalTotalComponent = (
        <div>{replaceTranslatedText(getTranslatedText('Original total [OriginalAmount]'), { 'OriginalAmount': handleFormatCurrency(OriginalAmount) })}</div>
    );

    const AllCustomFields = concat(
        compact(CustomFields),
        compact(get(Customer, 'CustomFields'))
    );

    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            AllCustomFields,
            ({ Name, Value }: { Name: String; Value: String }) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    const iconLgStyle = { minHeight: isLg ? 48 : 'auto' };

    const renderInvoiceItem = () => {
        return (
            <Row
                className="fx-center-xl center-flex-i pr-8 h-100"
                gutter={{ xxl: 10, xl: 10, lg: 20 }}
            >
                {/* Commented xl sizes are when icon is added */}
                <Col
                    className="h-100"
                    xxl={2}
                    xl={3}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                >
                    <Row className="h-100" type="flex" align="middle">
                        <Col
                            xxl={8}
                            xl={8}
                            // xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ paddingBottom: isLg ? 3 : 0 }}
                        >
                            <div className={`ta-center center-flex-all`}>
                                <Checkbox
                                    checked={includes(selectedRowKeys, Id)}
                                    onClick={handleCheckboxClick}
                                />
                            </div>
                        </Col>
                        <Col
                            xxl={16}
                            xl={16}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="ta-center"
                        >
                            <div
                                className="ta-center center-flex-all"
                                style={iconLgStyle}
                            >
                                {generateInvoiceItemIcon()}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={22} xl={21} lg={22} md={22} sm={22} xs={22}>
                    <Row className="first-row-container" type="flex">
                        <Col span={24}>
                            <div>
                                <Row gutter={20}>
                                    <Col
                                        xxl={17}
                                        xl={13}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex" gutter={60}>
                                            <Col
                                                // xxl={4}
                                                className={`${
                                                    isLg ? 'flx-5' : 'flx-2'
                                                } mxw-100`}
                                            >
                                                <b className="ws-nw va-top" style={{ whiteSpace: 'pre' }}>
                                                    {InvoiceNumber}
                                                </b>
                                            </Col>
                                            {!isUndefined(
                                                get(Customer, 'CustomerCode')
                                            ) && (
                                                <Col
                                                    // xxl={4}
                                                    className={`${
                                                        isLg ? 'flx-5' : 'flx-2'
                                                    } mxw-100`}
                                                >
                                                    <b className="ws-nw va-top">
                                                        {get(
                                                            Customer,
                                                            'CustomerCode'
                                                        )}
                                                    </b>
                                                </Col>
                                            )}

                                            <Col
                                                className="flx-6 mxw-100"
                                                span={isLg ? 24 : undefined}
                                            >
                                                <b className="ws-nw va-top">
                                                    {getCompanyName(Customer)}
                                                </b>
                                            </Col>
                                            {companyName && (<Col
                                                className="flx-6 mxw-100"
                                                span={isLg ? 24 : undefined}
                                            >
                                                <b className="ws-nw">
                                                    {companyName}
                                                </b>
                                            </Col>)}
                                            <Col span={24}>
                                                {createdAndDueDatesComponent}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* Fixed Total overdue and contact icons container */}
                                    <Col
                                        xxl={7}
                                        xl={11}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex">
                                            <Col
                                                className={`icons-container ${
                                                    isLg ? 'w-42' : 'w-43'
                                                }`}
                                            >
                                                <Row>
                                                    <Col
                                                        span={24}
                                                        className="ta-right"
                                                    >
                                                        <b
                                                            className={
                                                                itemColor
                                                            }
                                                        >
                                                            {populateDaysOverdueSection(
                                                                daysOverdue
                                                            )}
                                                        </b>
                                                    </Col>
                                                    <Col
                                                        xl={24}
                                                        lg={24}
                                                        md={24}
                                                        sm={24}
                                                        xs={24}
                                                        className="ta-right"
                                                    >
                                                        {iconGroupComponent}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="ta-right totals-container fx-1">
                                                <Row>
                                                    <Col>
                                                        <b
                                                            className={
                                                                itemColor
                                                            }
                                                        >
                                                            {getTranslatedText('Amount owed')}{' '}
                                                            {handleFormatCurrency(
                                                                AmountOwing
                                                            )}
                                                        </b>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xxl={24}
                                                        xl={24}
                                                        lg={24}
                                                        md={24}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {originalTotalComponent}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="second-row-container">
                                <Row>
                                    <Col
                                        xl={24}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                    >
                                        {populateTaskAndReminderComponent()}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {populateCustomFields()}
                </Col>
                {/* End of invoice item */}
            </Row>
        );
    };

    const renderCompactInvoiceItem = () => {
        return (
            <Row
                className="fx-center-xl center-flex-i pr-8 h-100"
                gutter={{ xxl: 10, xl: 10, lg: 20 }}
            >
                {/* Commented xl sizes are when icon is added */}
                <Col
                    className="h-100"
                    xxl={2}
                    xl={3}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                >
                    <Row className="h-100" type="flex" align="middle">
                        <Col
                            xxl={8}
                            xl={8}
                            // xl={24}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            style={{ paddingBottom: isLg ? 3 : 0 }}
                        >
                            <div className={`ta-center center-flex-all`}>
                                <Checkbox
                                    checked={includes(selectedRowKeys, Id)}
                                    onClick={handleCheckboxClick}
                                />
                            </div>
                        </Col>
                        <Col
                            xxl={16}
                            xl={16}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="ta-center"
                        >
                            <div
                                className="ta-center center-flex-all"
                                style={iconLgStyle}
                            >
                                {generateInvoiceItemIcon()}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={21} xl={20} lg={21} md={21} sm={21} xs={21}>
                    <Row className="first-row-container" type="flex">
                        <Col span={24}>
                            <div>
                                <Row gutter={20}>
                                    <Col
                                        xxl={17}
                                        xl={13}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex" gutter={60}>
                                            <Col
                                                // xxl={4}
                                                className={`${
                                                    isLg ? 'flx-5' : 'flx-2'
                                                } mxw-100`}
                                            >
                                                <b className="ws-nw" style={{ whiteSpace: 'pre' }}>
                                                    {InvoiceNumber}
                                                </b>
                                            </Col>
                                            {!isUndefined(
                                                get(Customer, 'CustomerCode')
                                            ) && (
                                                <Col
                                                    // xxl={4}
                                                    className={`${
                                                        isLg ? 'flx-5' : 'flx-2'
                                                    } mxw-100`}
                                                >
                                                    <b className="ws-nw">
                                                        {get(
                                                            Customer,
                                                            'CustomerCode'
                                                        )}
                                                    </b>
                                                </Col>
                                            )}

                                            <Col
                                                className="flx-6 mxw-100"
                                                span={isLg ? 24 : undefined}
                                            >
                                                <b className="ws-nw">
                                                    {getCompanyName(Customer)}
                                                </b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* Fixed Total overdue and contact icons container */}
                                    <Col
                                        xxl={7}
                                        xl={11}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex">
                                            <Col
                                                className={`icons-container ${
                                                    isLg ? 'w-42' : 'w-43'
                                                }`}
                                            >
                                                <Row>
                                                    <Col
                                                        span={24}
                                                        className="ta-right"
                                                    >
                                                        <b
                                                            className={
                                                                itemColor
                                                            }
                                                        >
                                                            {populateDaysOverdueSection(
                                                                daysOverdue
                                                            )}
                                                        </b>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="ta-right totals-container fx-1">
                                                <Row>
                                                    <Col>
                                                        <b
                                                            className={
                                                                itemColor
                                                            }
                                                        >
                                                            {getTranslatedText('Amount owed')}{' '}
                                                            {handleFormatCurrency(
                                                                AmountOwing
                                                            )}
                                                        </b>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Button type="link">
                        <FontAwesome icon={['fas', 'angle-down']} />
                    </Button>
                </Col>
                {/* End of invoice item */}
            </Row>
        );
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                {isCompactView
                    ? renderCompactInvoiceItem()
                    : renderInvoiceItem()}
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withAutomationIconsHandler(
        withDateFormatHandler(withNumberFormatHandler(InvoiceItemComponent))
    ),
    arePropsEqual
);

import { reportSortByOptions } from "../../constants/reportSortAndFilters";
import { PageData } from "../common/types";
import { Company } from "../companies/types";
import { ReportHistory } from "../report/types";
import { User } from "../users/types";

export enum ScheduledReportActionTypes {
    CREATE_SCHEDULED_REPORTS = '@@report/CREATE_SCHEDULED_REPORTS',
    GET_SCHEDULED_REPORTS_REQUEST = '@@report/GET_SCHEDULED_REPORTS_REQUEST',
    GET_SCHEDULED_REPORTS_SUCCESS = '@@report/GET_SCHEDULED_REPORTS_SUCCESS',
    GET_SCHEDULED_REPORTS_ERROR = '@@report/GET_SCHEDULED_REPORTS_ERROR',
    GET_SCHEDULED_REPORTS_FOR_ORGANISATION_REQUEST = '@@report/GET_SCHEDULED_REPORTS_FOR_ORGANISATION_REQUEST',

    UPDATE_SCHEDULED_REPORTS_TABLE_FILTER_STATE = '@@report/UPDATE_SCHEDULED_REPORTS_TABLE_FILTER_STATE',

    UPDATE_SCHEDULED_REPORTS_SORT_BY_AND_STATE = '@@report/UPDATE_SCHEDULED_REPORTS_SORT_BY_AND_STATE',

    UPDATE_SCHEDULED_REPORTS_FILTERS = '@@report/UPDATE_SCHEDULED_REPORTS_FILTERS',
    SET_SCHEDULED_REPORT_SELECTED_ID_REQUEST = '@@report/SET_SCHEDULED_REPORT_SELECTED_ID_REQUEST',
    SET_SCHEDULED_REPORT_SELECTED_ID = '@@report/SET_SCHEDULED_REPORT_SELECTED_ID',
    GET_SCHEDULED_REPORT_DATA_REQUEST = '@@report/GET_SCHEDULED_REPORT_DATA_REQUEST',
    GET_SCHEDULED_REPORT_DATA_FOR_ORGANISATION_REQUEST = '@@report/GET_SCHEDULED_REPORT_DATA_FOR_ORGANISATION_REQUEST',
    GET_SCHEDULED_REPORT_DATA_SUCCESS = '@@report/GET_SCHEDULED_REPORT_DATA_SUCCESS',
    GET_SCHEDULED_REPORT_DATA_ERROR = '@@report/GET_SCHEDULED_REPORT_DATA_ERROR',
    CLEAR_SCHEDULED_REPORT_DATA = '@report/CLEAR_SCHEDULED_REPORT_DATA',

    DELETE_SCHEDULED_REPORT = '@report/DELETE_SCHEDULED_REPORT'

}
export interface ReportScheduleFilters {
    readonly Name: string;
    readonly Value: string;
}

export interface ReportTypeFilter {
    Type: number;
    Descriptions: string[];
}

export interface ScheduledReportState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: ScheduledReport[];
    readonly pageData: PageData;
    readonly filters: GetScheduledReportsRequestPayload['filters'];
    readonly sortBy: GetScheduledReportsRequestPayload['sortBy'];
    readonly tableFilter: string | undefined;
    readonly sortAscending: boolean;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface ScheduledReport {
    readonly Id: string;
    readonly ScheduleId: string;
    readonly CompanyId?: string;
    readonly OrganisationId?: string;
    readonly Region?: string;
    readonly Company: Company;
    readonly User: User;
    readonly Title: string;
    readonly Description: string;
    readonly Type: number;
    readonly Status: string;
    readonly ScheduleType: number;
    readonly ScheduleTiming1: number;
    readonly ScheduleTiming2: number;
    readonly ApplyScheduleDate: boolean;
    readonly DateFilterField: string;
    readonly NextReportDateTime: string;
    readonly NextReportLocalDateTime: string;
    readonly LastReportDateTime: string;
    readonly LastReportLocalDateTime: string;
    readonly ReportScheduleFilters: ReportScheduleFilters[];
    readonly LastReportHistory: ReportHistory;
    readonly LastReportStatus: string;
}

export interface GetScheduledReportsRequestPayload {
    filters: {
        ScheduleId?: string
        UserId?: string;
        NextReportDateTimeMin?: string
        NextReportDateTimeMax?: string
        ReportTypeFilters?: ReportTypeFilter[];
        Status?: number;
    };
    CompanyId?: string;
    sortBy: typeof reportSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    Region?: string;
    CustomerLabel?: string;
    OrganisationId?: string;
}
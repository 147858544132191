/**
 * File for the `Daily Sales Out` widget.
 */

import { Col, Row, Spin } from 'antd';
import { clone, find, get, forEach, includes } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets, widgetDateRangeValues } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getDashboardDailySalesOutRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly widgetTitle?: string;
    readonly functionRefObj?: any;
}

export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');

const DailySalesOutWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    isOrgView,
    organisationCurrenciesAll,
    widgetTitle,
    functionRefObj
}: /*customFieldsFilterList,*/
    IProps) => {
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );
    const [dataState, setDataState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        openSum: number;
    }>({
        lastWidgetDetails: {},
        loading: false,
        openSum: 0,
    });

    let currencyValueUsed: any = undefined;
    let localeUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    if (isOrgView) {
        // const region = widgetDetails.Region;
        // const currencySelectedParsed = region
        //     ? find(organisationCurrenciesAll, ['Name', region])
        //     : undefined;

        const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
        
        const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        localeUsed = get(currencyValueUsed, 'Locale');
        currencyCodeUsed = get(currencyValueUsed, 'Value');
    }

    /**
     * Common function for updating the `dataState` state.
     * @param dataStateObject
     */
    const updateDataStateObject = (dataStateObject: {}) => {
        setDataState({
            ...dataState,
            ...dataStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];
        let customerFieldValue: any;
        
        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        let payload: DynamicObject = {
            DateMin: startDate,
            DateMax: endDate,
            Customer: customerFieldValue,
            filters: {    
                ...customFieldFilters
            },
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed
            });
        }

        dispatch(
            getDashboardDailySalesOutRequestAction(
                payload,
                isOrgView,
                (openSum: number) => {
                    if (unmountedRef.current) return;

                    updateDataStateObject({
                        loading: false,
                        openSum: openSum || 0,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            dataState.lastWidgetDetails,
            widgetDetails
        );

        if (bypassAPIFetching) return;

        updateDataStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const { loading: stateLoading, openSum: stateOpenSum } = dataState;

    return (
        <div className="box-number-widget">
            <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                <Row className="box-number-widget-row">
                    <Col span={24} className="box-number-widget-title">
                        {getTranslatedText(widgetTitle || widgetDetails.title.toString())}
                    </Col>
                    <Col span={24} className="box-number-widget-count">
                    {stateOpenSum &&
                        formatNumber(stateOpenSum, 0, localeUsed)}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default withNumberFormatHandler(DailySalesOutWidget);

import { LinkType, WorkflowTemplateOption, WorkflowStateTemplateOption, WorkflowAction, WorkflowTransitionTemplateOption } from "../store/workflow/types";

export enum EntityType {
    Invoice = 'Invoice',
    Customer = 'Customer',
    PaymentPlanInstallment = 'Payment Plan Installment'
}

export const entityTypeValues = [EntityType.Invoice, EntityType.Customer];

export enum WorkflowDefinitionType {
    Communication = 'Communication'
}

export enum WorkflowStateType {
    Communication = 'Communication',
    Collection = 'Collection',
    PhoneCall = 'PhoneCall',
    PaymentSchedule = 'PaymentSchedule',
}

export enum ReminderType {
    Overdue = 'Overdue',
    NotDue = 'Not due'
}

export const reminderTypeValues = [
    ReminderType.Overdue,
    ReminderType.NotDue
];

export enum TimingOrientation {
    DueDate = 'Due Date',
    CreateDate = 'Create Date',
    LastAction = 'Last Action'
}

export const timingOrientationValues = [
    TimingOrientation.CreateDate,
    TimingOrientation.DueDate,
    TimingOrientation.LastAction,
];

export const linkTypeValues = [LinkType.FirstNotDue, LinkType.FirstOverdue];

export enum WorkflowTransitionContent {
    TransitionForm = 'TransitionForm'
}

export enum AdditionalContent {
    LinkTypeSelection = 'LinkTypeSelection'
}

// [NOTE] can be customized by returning from back-end instead of hardcoding
export const workflowTransitionTemplateOptions: WorkflowTransitionTemplateOption[] = [
    {
        Name: 'New Not Due Invoices',
        Transition: {
            Timing: {
                Gap: -7,
                Orientation: TimingOrientation.DueDate
            },
            Trigger: 'New Not Due Invoices',
            _linkType: LinkType.FirstNotDue,
        },
        AdditionalContents: [AdditionalContent.LinkTypeSelection]
    },
    {
        Name: 'New Overdue Invoices',
        Transition: {
            Timing: {
                Gap: 1,
                Orientation: TimingOrientation.DueDate
            },
            Trigger: 'New Overdue Invoices',
            _linkType: LinkType.FirstOverdue,
        },
        AdditionalContents: [AdditionalContent.LinkTypeSelection]
    },
    {
        Name: 'New Customers',
        Transition: {
            NewState: 'Pending State Move',
            Timing: {
                Gap: 0,
                Orientation: TimingOrientation.LastAction
            },
            Trigger: 'New Customers'
        }
    },
    {
        Name: 'Move To Not Due',
        Transition: {
            Timing: {
                Gap: -7,
                Orientation: TimingOrientation.DueDate
            },
            Trigger: 'Move To Not Due',
            _linkType: LinkType.FirstNotDue,
        },
        AdditionalContents: [AdditionalContent.LinkTypeSelection]
    },
    {
        Name: 'Move To Overdue',
        Transition: {
            Timing: {
                Gap: 1,
                Orientation: TimingOrientation.DueDate
            },
            Trigger: 'Move To Overdue',
            _linkType: LinkType.FirstOverdue,
        },
        AdditionalContents: [AdditionalContent.LinkTypeSelection]
    },
    {
        Name: 'Escalate To Collections',
        Transition: {
            Trigger: 'Escalate To Collections',
            NewState: 'Pending Settlement'
        }
    },
    {
        Name: 'Send Automated Communication',
        Transition: {
            Trigger: 'Send Automated Communication',
            Timing: {
                Gap: 7,
                Orientation: TimingOrientation.LastAction
            },
            _linkType: LinkType.NextState
        }
    },
    {
        Name: 'Action Phone Call',
        Transition: {
            Trigger: 'Action Phone Call',
            Timing: {
                Gap: 7,
                Orientation: TimingOrientation.LastAction
            },
            _linkType: LinkType.NextState
        }
    },
    {
        Name: 'New Payment Schedule',
        Transition: {
            Trigger: 'New Payment Schedule',
            Timing: {
                Gap: 0,
                Orientation: TimingOrientation.DueDate
            },
            _linkType: LinkType.NextState
        }
    },
    {
        Name: 'Cancel Payment Plan',
        Transition: {
            Trigger: 'Cancel Payment Plan',
            NewState: 'Closed'
        }
    },
    {
        Name: 'Leave It Open',
        Transition: {
            Trigger: 'Leave It Open',
            NewState: 'Closed'
        }
    }
];

// [NOTE] can be customized by returning from back-end instead of hardcoding
// [TODO] change description
export const workflowStateTemplateOptions: WorkflowStateTemplateOption[] = [
    {
        Name: 'New Invoices',
        IsCustomerWorkflow: false,
        State: {
            Name: 'New',
            IsEntryOrExit: true,
            Transitions: [
                'New Not Due Invoices',
                'New Overdue Invoices'
            ],
            _fixed: true,
            _canMoveUp: false,
            _canMoveDown: false
        },
        DisplayName: 'New Invoices',
        Description: 'This is the state description',
    },
    {
        Name: 'New Customers',
        IsCustomerWorkflow: true,
        State: {
            Name: 'New',
            IsEntryOrExit: true,
            Transitions: [
                'New Customers'
            ],
            _fixed: true,
            _canMoveUp: false,
            _canMoveDown: false
        },
        DisplayName: 'New Customers',
        Description: 'This is the state description'
    },
    {
        Name: 'Pending State Move',
        State: {
            Name: 'Pending State Move',
            IsEntryOrExit: false,
            Transitions: [
                'Move To Not Due',
                'Move To Overdue'
            ],
            _fixed: true,
            _canMoveUp: false,
            _canMoveDown: false,
        },
        DisplayName: 'State transition',
        Description: 'This is the state description',
    },
    {
        Name: 'Escalate To Collections',
        State: {
            Name: 'Escalate To Collections',
            IsEntryOrExit: false,
            ReminderType: ReminderType.Overdue,
            Type: WorkflowStateType.Collection,
            Transitions: [
                'Escalate To Collections'
            ],
            _fixed: true,
            _canMoveUp: false,
            _canMoveDown: false
        },
        Description: 'This is the state description',
    },
    {
        Name: 'Pending Settlement',
        State: {
            Name: 'Pending Settlement',
            IsEntryOrExit: true,
            _fixed: true,
            _canMoveUp: false,
            _canMoveDown: false
        },
        Description: 'This is the state description',
    },
    {
        Name: 'Overdue Reminder',
        State: {
            Name: 'Overdue Reminder',
            ReminderType: ReminderType.Overdue,
            Type: WorkflowStateType.Communication,
            IsEntryOrExit: false,
            Transitions: [
                'Send Automated Communication'
            ],
            _canMoveDown: true,
            _canMoveUp: true,
            _fixed: false
        },
        Description: 'This is overdue reminder description',
        ReadOnlyFields: ['ReminderType'],
        FormFields: ['Name', 'ReminderType'],
        HasContent: true
    },
    {
        Name: 'Payment Reminder',
        State: {
            Name: 'Payment Reminder',
            ReminderType: ReminderType.NotDue,
            Type: WorkflowStateType.Communication,
            IsEntryOrExit: false,
            Transitions: [
                'Send Automated Communication'
            ],
            _canMoveDown: true,
            _canMoveUp: true,
            _fixed: false
        },
        Description: 'This is payment reminder description',
        ReadOnlyFields: ['ReminderType'],
        FormFields: ['Name', 'ReminderType', 'NotDueGap'],
        HasContent: true,
        MaxInstanceAllowed: 1
    },
    {
        Name: 'Phone Call',
        State: {
            Name: 'Phone Call',
            Type: WorkflowStateType.PhoneCall,
            IsEntryOrExit: false,
            Transitions: [
                'Action Phone Call'
            ],
            _canMoveDown: true,
            _canMoveUp: true,
            _fixed: false,
        },
        Description: 'This is phone call description',
        FormFields: ['Name', 'ReminderType', 'NotDueGap']
    },
    {
        Name: 'New Payment Schedule',
        State: {
            Name: 'New',
            Type: WorkflowStateType.PaymentSchedule,
            IsEntryOrExit: true,
            Transitions: [
                'New Payment Schedule'
            ],
            _canMoveDown: false,
            _canMoveUp: false,
            _fixed: true,
        },
        DisplayName: 'New Payment Schedule',
        Description: 'This is new payment schedule description'
    },
    {
        Name: 'Instalment',
        State: {
            Name: 'Instalment',
            Type: WorkflowStateType.PaymentSchedule,
            ReminderType: ReminderType.Overdue,
            IsEntryOrExit: false,
            Transitions: [
                'Send Automated Communication'
            ],
            _canMoveDown: true,
            _canMoveUp: true,
            _fixed: false,
        },
        Description: 'This is new payment schedule description',
        ReadOnlyFields: ['ReminderType'],
        FormFields: ['Name', 'ReminderType'],
        HasContent: true
    },
    {
        Name: 'Cancel Payment Plan',
        State: {
            Name: 'Cancel Payment Plan',
            Type: WorkflowStateType.PaymentSchedule,
            ReminderType: ReminderType.Overdue,
            IsEntryOrExit: false,
            Transitions: [
                'Cancel Payment Plan',
                'Leave It Open'
            ],
            _canMoveDown: false,
            _canMoveUp: false,
            _fixed: true,
        },
        Description: 'This is cancel payment plan description'
    }
];

// [NOTE] can be customized by returning from back-end instead of hardcoding
export const workflowActions: WorkflowAction[] = [
    { Name: 'Payment Reminder', NewStateOption: 'Payment Reminder' },
    { Name: 'Overdue Reminder', NewStateOption: 'Overdue Reminder' },
    { Name: 'Phone Call', NewStateOption: 'Phone Call' },
    { Name: 'Payment Instalment', NewStateOption: 'Instalment' }
];

// [NOTE] can be customized by returning from back-end instead of hardcoding
export const workflowTemplateOptions: WorkflowTemplateOption[] = [
    {
        Name: 'Default Invoice Reminder',
        IsCustomerWorkflow: false,
        RequiredMinReminders: 1,
        WorkflowDefinition: {
            EntityType: EntityType.Invoice,
            Type: WorkflowDefinitionType.Communication,
            States: [
                'New Invoices',
                'Pending State Move',
                'Escalate To Collections',
                'Pending Settlement'
            ],
        },
        AvailableActions: [
            'Payment Reminder',
            'Overdue Reminder',
            'Phone Call'
        ],
        InsertIdx: -3
    },

    {
        Name: 'Default Customer Reminder',
        IsCustomerWorkflow: true,
        RequiredMinReminders: 1,
        WorkflowDefinition: {
            EntityType: EntityType.Customer,
            Type: WorkflowDefinitionType.Communication,
            States: [
                'New Customers',
                'Pending State Move',
                'Escalate To Collections',
                'Pending Settlement'
            ],
        },
        AvailableActions: [
            'Payment Reminder',
            'Overdue Reminder',
            'Phone Call'
        ],
        InsertIdx: -3
    },
    //{
    //    Name: 'Default Payment Instalments',
    //    IsPaymentPlanRelated: true,
    //    RequiredMinReminders: 1,
    //    WorkflowDefinition: {
    //        EntityType: EntityType.PaymentPlanInstallment,
    //        Type: WorkflowDefinitionType.Communication,
    //        States: [
    //            'New Payment Schedule',
    //            'Instalment',
    //            'Cancel Payment Plan',
    //            'Pending Settlement'
    //        ],
    //    },
    //    AvailableActions: ['Payment Instalment'],
    //    InsertIdx: -3
    //}
];
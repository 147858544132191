/**
 * File responsible for all the virtualized list.
 * Virtualized - works by only rendering part of a large data set (just enough to fill the viewport).
 */

import { Spin } from 'antd';
import { debounce, get, includes, isEqual } from 'lodash';
import React, { PureComponent } from 'react';
import { useSelector } from 'react-redux';
import { ScrollParams } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import {
    CellMeasurer,
    CellMeasurerCache,
} from 'react-virtualized/dist/es/CellMeasurer';
import { List } from 'react-virtualized/dist/es/List';
import 'react-virtualized/styles.css';
import { pixelAllowanceScrollToFetchMore } from '../../config/config';
import { DynamicObject } from '../../utils/commonInterfaces';
import EmptyList from './EmptyList';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    readonly idKeyName?: string;
    readonly dataSource: unknown[];
    readonly loading: boolean;
    readonly loadingText?: string;
    readonly fetchMore?: any;
    readonly scroll?: any;
    readonly hasNextPage: boolean;
    readonly emptyText?: string;
    readonly resetTableScroll?: boolean;
    readonly selectedRowKeys?: any;
    readonly onRowClick?: ((record: any) => void) | undefined;
    readonly onCheckboxClick?: ((record: any) => void) | undefined;
    itemComponent: any;
    readonly itemHeight: number;
    readonly rerenderTrigger?: any;
    readonly overscanCount?: number;
    readonly hideCheckbox?: boolean;
    readonly selectedId?: any;
    readonly usingCustomerWorkflow?: boolean;
    readonly lockedDeliveryMethod?: boolean;
    readonly selectedUserCompany?: DynamicObject;
    readonly isCompactView?: boolean;
    readonly toggledIds?: string[];
    readonly customerLabel?: string;
    readonly extraData?: DynamicObject;
}

interface IState {
    scrollTop: undefined | number;
    cache: any;
}

let lastScrollTop = 0;
let initialCellMeasurerSet = false;
class VirtualizedList extends PureComponent<IProps, IState> {
    private emptyRef: any;
    private unmounted: boolean = false;
    private setCellMeasurerCacheWithDelay: any;
    private setCellMeasurerCacheNoDelay: any;

    loadedRowsMap: any = {};

    constructor(props: IProps) {
        super(props);

        this.state = {
            scrollTop: undefined,
            cache: new CellMeasurerCache({
                fixedWidth: true,
                minHeight: props.itemHeight,
            }),
        };

        /**
         * Debounce function to wrap the setCellMeasurerCacher
         * (setting of cache state from react-virtualized CellMeasurerCache).
         */
        this.setCellMeasurerCacheWithDelay = debounce(
            this.setCellMeasurerCache,
            100
        );
        this.setCellMeasurerCacheNoDelay = debounce(
            this.setCellMeasurerCache,
            0
        );
    }

    componentDidMount() {
        this.unmounted = false;
        // this.listElement = findDOMNode(this.listRef) as HTMLElement;
        // this.listElement.addEventListener('scroll', (event: any) => {
        //     // checking whether a selector is well defined
        //     let maxScroll =
        //         event.target.scrollHeight - event.target.clientHeight;
        //     let currentScroll = Math.ceil(event.target.scrollTop);
        //     const { hasNextPage, fetchMore, loading } = this.props;
        //     if (
        //         currentScroll >= maxScroll &&
        //         hasNextPage &&
        //         !loading &&
        //         lastScrollTop !== currentScroll
        //     ) {
        //         lastScrollTop = currentScroll - 1;
        //         // load more data
        //         fetchMore();
        //     }
        // });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            this.props.resetTableScroll !== prevProps.resetTableScroll &&
            this.props.resetTableScroll &&
            !this.unmounted
        ) {
            this.setState(
                {
                    scrollTop: 0,
                },
                () => {
                    if (this.unmounted) return;
                    setTimeout(() => {
                        if (!this.unmounted)
                            this.setState({ scrollTop: undefined });
                    }, 1000);
                }
            );
        }

        if (
            prevProps.itemHeight !== this.props.itemHeight ||
            (!this.props.loading && this.props.loading !== prevProps.loading)
        ) {
            this.setCellMeasurerCacheWithDelay();
        } else if (
            this.props.isCompactView !== prevProps.isCompactView ||
            !isEqual(prevProps.toggledIds, this.props.toggledIds)
        ) {
            this.setCellMeasurerCacheNoDelay();
        }
    }

    /**
     * Function that gets all the properties of loading component for table.
     */
    getListLoadingProp = () => {
        const { loadingText, loading } = this.props;
        if (loadingText) {
            return {
                spinning: loading,
                tip: getTranslatedText(loadingText),
            };
        } else {
            return {
                spinning: loading,
            };
        }
    };

    /**
     * Function that sets the cache state based on react-virtualized
     * CellMeasurerCache.
     */
    setCellMeasurerCache = () => {
        if (!this.unmounted) {
            this.setState({
                cache: new CellMeasurerCache({
                    fixedWidth: true,
                    minHeight: this.props.itemHeight,
                }),
            });
        }
    };

    /**
     * Function that renders each table row item.
     */
    renderItem = ({ index, key, parent, style }: any) => {
        const {
            dataSource,
            itemComponent: ItemComponent,
            onRowClick,
            onCheckboxClick,
            selectedRowKeys,
            hideCheckbox,
        } = this.props;

        const { cache } = this.state;
        const item = dataSource[index];
        const newStyle = { ...style };
        const idKeyNameUsed = this.props.idKeyName || 'Id';

        const selectedInd = this.props.selectedId
            ? this.props.selectedId !== get(item, idKeyNameUsed)
            : true;
        if (!(index % 2 === 0) && selectedInd)
            newStyle.backgroundColor = '#f8f8f8';

        const isExpanded =
            (this.props.isCompactView &&
                includes(this.props.toggledIds, get(item, 'Id'))) ||
            (!this.props.isCompactView &&
                !includes(this.props.toggledIds, get(item, 'Id')));

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}
            >
                {() => (
                    <ItemComponent
                        style={newStyle}
                        item={item}
                        onRowClick={onRowClick}
                        onCheckboxClick={onCheckboxClick || onRowClick}
                        selectedRowKeys={selectedRowKeys}
                        hideCheckbox={hideCheckbox}
                        selectedId={this.props.selectedId}
                        usingCustomerWorkflow={this.props.usingCustomerWorkflow}
                        lockedDeliveryMethod={this.props.lockedDeliveryMethod}
                        isExpanded={isExpanded}
                        customerLabel={this.props.customerLabel && getTranslatedText(this.props.customerLabel)}
                        {...this.props.extraData}
                    />
                )}
            </CellMeasurer>
        );
    };

    /**
     * Function called when the table is scrolled
     * Used as a basis for loading more table item data if there's more.
     */
    onScroll = (e: ScrollParams) => {
        const { clientHeight, scrollHeight, scrollTop } = e;
        const currentScroll = clientHeight + scrollTop;
        const { hasNextPage, loading, fetchMore } = this.props;
        const roundedCurrentScroll =
            Math.ceil(currentScroll) + pixelAllowanceScrollToFetchMore;

        const scrollDirectionDown = !(lastScrollTop > roundedCurrentScroll);
        lastScrollTop = roundedCurrentScroll;

        if (
            roundedCurrentScroll >= scrollHeight &&
            hasNextPage &&
            !loading &&
            scrollDirectionDown
        ) {
            // load more data
            fetchMore();
        }
    };

    render() {
        const { scroll, dataSource, rerenderTrigger, emptyText, itemHeight } =
            this.props;
        const spinProps = this.getListLoadingProp();
        const { scrollTop } = this.state;

        const heightUsedScroll = scroll ? get(scroll, 'y') : '100%';

        const currentHeight =
            dataSource.length > 0
                ? heightUsedScroll
                : get(this.emptyRef, 'clientHeight', 150);

        const usedHeight =
            currentHeight < itemHeight ? itemHeight : currentHeight;

        return (
            <Spin
                {...spinProps}
                wrapperClassName="spin-vlist h-100"
                style={{
                    height: scroll ? usedHeight : currentHeight,
                }}
            >
                <div
                    className="virtualized-list-container"
                    style={{
                        overflowX: 'hidden',
                        height: scroll ? usedHeight : currentHeight,
                    }}
                >
                    <AutoSizer
                        // disableHeight
                        onResize={() => {
                            if (initialCellMeasurerSet === false) {
                                initialCellMeasurerSet = true;
                            } else {
                                this.setCellMeasurerCacheWithDelay();
                            }
                        }}
                    >
                        {({ height, width }) => {
                            const { cache } = this.state;
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    rowCount={dataSource.length}
                                    deferredMeasurementCache={cache}
                                    rowHeight={cache.rowHeight}
                                    rerenderTrigger={rerenderTrigger}
                                    rowRenderer={this.renderItem}
                                    noRowsRenderer={() => (
                                        <EmptyList
                                            componentRef={(ref: any) =>
                                                (this.emptyRef = ref)
                                            }
                                            emptyMessage={
                                                getTranslatedText(`${emptyText || 'No data found'}`)
                                            }
                                        />
                                    )}
                                    onScroll={this.onScroll}
                                    scrollToIndex={scrollTop}
                                    recomputeRowHeights={1}
                                    style={{ outline: 'none' }}
                                />
                            );
                        }}
                    </AutoSizer>
                </div>
            </Spin>
        );
    }
}

export default VirtualizedList;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdditionalContent, linkTypeValues, ReminderType, reminderTypeValues, timingOrientationValues, workflowActions, WorkflowDefinitionType, workflowStateTemplateOptions, WorkflowTransitionContent, workflowTransitionTemplateOptions } from '../../constants/workflowsSortAndFilters';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Tooltip, Typography } from 'antd';
import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { ContentStateMapping, LinkType, WorkflowAction, WorkflowDefinitionUpdateModel, WorkflowStateTemplateOption, WorkflowStateUpdateModel, WorkflowTemplateOption, WorkflowTransitionTemplateOption, WorkflowTransitionUpdateModel } from '../../store/workflow/types';
import { cloneDeep, filter, find, flatMap, flatten, get, includes, isBoolean, isEmpty, isNumber, map, some, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from 'antd/lib/form/FormItem';
import { getTranslatedText, limitOnlyNumber, populatePopoverContainer } from '../../utils/commonFunctions';
import SelectReadonly from '../FormComponents/SelectReadonly';
import FontAwesome from '../common/FontAwesome';
import { DANGER_COLOR } from '../../constants/common';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import { CompaniesState, CompanyUserRole } from '../../store/companies/types';
import { ApplicationState } from '../../store';
import { getFullWorkflowOptions } from '../../store/companies/sagas';
import { getWorkflowStateConfigs } from '../../store/contents/sagas';
import { ContentsState } from '../../store/contents/types';
import { getWorkflowContentConfigurationAction } from '../../store/contents/actions';
import { TasksState } from '../../store/tasks/types';
import { getTaskWorkflows } from '../../store/tasks/sagas';

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly inputWorkflow: WorkflowDefinitionUpdateModel;
    readonly workflowTemplateOption: WorkflowTemplateOption;
    readonly isCreateNew: boolean;
    readonly submitCallback: (payload: {
        workflowModel?: WorkflowDefinitionUpdateModel,
        contentStateMappings?: ContentStateMapping[],
        errorMessage?: string
    }) => void;
    readonly setOnSubmit: (handler: () => void) => void;
    readonly setDataHasChanges: (changed: boolean) => void;
    readonly setNextButtonLoading: (value: boolean) => void;
}

interface ContentStateMappingVM extends ContentStateMapping {
    _stateRef: WorkflowStateUpdateModelVM
}

interface WorkflowDefinitionUpdateModelVM extends WorkflowDefinitionUpdateModel {
    Type: WorkflowDefinitionType;
    States: WorkflowStateUpdateModelVM[];
    _templateOption: WorkflowTemplateOption;
    _version: string;
}

interface WorkflowStateUpdateModelVM extends WorkflowStateUpdateModel {
    Transitions?: WorkflowTransitionUpdateModelVM[];
    _templateOption: WorkflowStateTemplateOption;
    _originalName: string;
    _fixed: boolean;
    _canMoveUp: boolean;
    _canMoveDown: boolean;
    _getFieldsError?: (names?: Array<string>) => Record<string, string[] | undefined>;
    _validateFields?: () => void;
    _id: string;
}

interface WorkflowTransitionUpdateModelVM extends WorkflowTransitionUpdateModel {
    _templateOption: WorkflowTransitionTemplateOption;
    _newStateRef?: WorkflowStateUpdateModelVM,
    _linkType?: LinkType,
    _errorMessage?: JSX.Element | string,
    _populateContent?: TransitionFormPopulateContentFunc
    _getFieldsError?: (names?: Array<string>) => Record<string, string[] | undefined>;
    _validateFields?: () => void;
}

const WorkflowDefinitionForm: React.FC<IProps> = ({
    inputWorkflow, workflowTemplateOption, isCreateNew, setOnSubmit, submitCallback, setDataHasChanges, setNextButtonLoading
}) => {
    const dispatch = useDispatch();
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const usingCustomerWorkflow = get(
        selectedUserCompany,
        'Company.UsingCustomerWorkflow'
    );
    const contentConfigs: ContentsState['workflowContents']['contentConfigs'] = useSelector(getWorkflowStateConfigs);
    const containerRef = useRef(null);
    const [actionLoading, setActionLoading] = useState<{ [key: string]: boolean }>({});
    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [workflowModel, setWorkflowModel] = useState<WorkflowDefinitionUpdateModelVM | undefined>();
    const [originalWorkflowModel, setOriginalWorkflowModel] = useState<WorkflowDefinitionUpdateModelVM | undefined>();
    const validStateTemplateOptions = useMemo(() => {
        return filter(workflowStateTemplateOptions, o => !isBoolean(o.IsCustomerWorkflow) || o.IsCustomerWorkflow === usingCustomerWorkflow);
    }, [usingCustomerWorkflow]);

    const detectStateTemplate = (filter: WorkflowStateUpdateModelVM | string) => {
        if (typeof filter === 'string') {
            return find(validStateTemplateOptions, opt => opt.Name === filter);
        } else {
            let templateOption = find(validStateTemplateOptions, opt => opt.Name === filter.TemplateOption);
            if (!templateOption) templateOption = find(validStateTemplateOptions, opt => opt.State._fixed && opt.State.Name === filter.Name);
            if (!templateOption) templateOption = find(validStateTemplateOptions, opt => opt.State.Type === filter.Type && opt.State.ReminderType === filter.ReminderType);
            if (!templateOption) templateOption = find(validStateTemplateOptions, opt => opt.State.Type === filter.Type);
            return templateOption;
        }
    }

    const updateActionLoading = (action: string, loading: boolean) => setActionLoading(obj => ({
        ...obj,
        [action]: loading
    }))

    const onActionClick = (action: WorkflowAction) => () => {
        if (action.NewStateOption) onAddNewState(action);
    }

    const scrollToState = (id: string) => {
        const element = document.getElementById(`wfs-form-${id}`);
        element && element.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const onAddNewState = (action: WorkflowAction) => {
        const stateTemplateOption = action.NewStateOption && detectStateTemplate(action.NewStateOption);
        if (!stateTemplateOption) return;
        updateActionLoading(action.Name, true);
        const newState = convertToStateViewModel(stateTemplateOption, initPopulateTransitionContent);
        updateWorkflowModel(workflowModel => {
            const insertIdx = workflowModel._templateOption.InsertIdx > 0
                ? workflowModel._templateOption.InsertIdx
                : workflowModel.States.length + 1 + workflowModel._templateOption.InsertIdx;
            workflowModel.States.splice(insertIdx, 0, newState);
            recalculateStates(workflowModel);
            return { ...workflowModel };
        });
        setTimeout(() => updateActionLoading(action.Name, false), 1000);
        setTimeout(() => scrollToState(newState._id), 300);
    }

    setOnSubmit(() => {
        triggerValidateFields();
        setTimeout(() => {
            validateWorkflow((valid, errorMessage) => {
                if (valid) {
                    Modal.confirm({
                        className: 'modal-swapped-buttons',
                        title: getTranslatedText('Are you sure you want to submit'),
                        onOk: submitData,
                        okText: getTranslatedText(confirmModalOkText),
                        cancelText: getTranslatedText(confirmModalCancelText),
                        getContainer: populatePopoverContainer(containerRef),
                    });
                } else {
                    submitCallback({ errorMessage });
                }
            });
            setNextButtonLoading(false);
        }, 500);
    });

    const submitData = () => {
        if (!workflowModel) return;
        const payload = convertToPayload(workflowModel);
        const contentStateMappings = map(filter(objectRef.contentStateMappings, map => !!map.FromWorkflowId && !!map.FromStateName), (map) => ({
            FromStateName: map.FromStateName,
            FromWorkflowId: map.FromWorkflowId,
            NewState: map._stateRef.Name
        }));
        submitCallback({
            workflowModel: payload,
            contentStateMappings
        });
    };

    const onResetButtonClick = () => {
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Are you sure you want to reset the data'),
            onOk: resetData,
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            getContainer: populatePopoverContainer(containerRef),
        });
    }

    const resetData = () => {
        if (!originalWorkflowModel) return;
        const workflowModel = cloneDeep(originalWorkflowModel);
        workflowModel._version = uniqueId();
        setWorkflowModel(workflowModel);
        setFormHasChanges(false);
        setIsSubmitted(false);
        submitCallback({});
    }

    const convertToPayload = (workflowModel: WorkflowDefinitionUpdateModelVM) => {
        const workflowUpdatePayload: WorkflowDefinitionUpdateModel = {
            EntityType: get(workflowModel, 'EntityType'),
            States: map(get(workflowModel, 'States'), s => {
                const state: WorkflowStateUpdateModel = {
                    Name: get(s, 'Name'),
                    NotDueGap: get(s, 'NotDueGap'),
                    ReminderType: get(s, 'ReminderType'),
                    Transitions: map(get(s, 'Transitions'), t => {
                        const transition: WorkflowTransitionUpdateModel = {
                            NewState: get(t, 'NewState'),
                            Timing: get(t, 'Timing'),
                            DisplayName: get(t, 'DisplayName'),
                            Trigger: get(t, 'Trigger')
                        };
                        return transition;
                    }),
                    Type: get(s, 'Type'),
                    IsEntryOrExit: get(s, 'IsEntryOrExit'),
                    Index: get(s, 'Index'),
                    TemplateOption: get(s, 'TemplateOption')
                };
                return state;
            }),
            Name: get(inputWorkflow, 'Name'),
            WorkflowId: get(workflowModel, 'WorkflowId'),
        };
        return workflowUpdatePayload;
    };

    const updateWorkflowModel = (func: (model: WorkflowDefinitionUpdateModelVM) => WorkflowDefinitionUpdateModelVM) => {
        setFormHasChanges(true);
        setDataHasChanges(true);
        setIsSubmitted(false);
        submitCallback({});
        setWorkflowModel(model => model && func(model));
    };

    const reminderLinkAdditionalContent: TransitionFormAdditionContentFunc = (
        workflowModel,
        transition,
        form
    ) => {
        const { getFieldDecorator } = form;
        return <FormItem label={getTranslatedText('Link to')}>
            {getFieldDecorator(transitionFormFieldNames.LinkType, {
                rules: [],
                initialValue: transition._linkType
            })(
                <SelectReadonly
                    style={{ width: 200 }}
                    onChange={onLinkTypeChange(transition)}
                    getPopupContainer={populatePopoverContainer(containerRef)}
                >
                    {map(
                        linkTypeValues,
                        (value) => (
                            <Option key={value} value={value}>
                                {value}
                            </Option>
                        )
                    )}
                </SelectReadonly>
            )}
        </FormItem>
    };

    const onLinkTypeChange = (transition: WorkflowTransitionUpdateModelVM) => (value: LinkType) => {
        transition._linkType = value;
        updateWorkflowModel(model => {
            recalculateStates(model);
            return { ...model };
        });
    }

    const onTransitionFormValuesChange = (transition: WorkflowTransitionUpdateModelVM) => (rawChangedValues: any, _: any) => {
        const { _linkType, ...changedValues } = rawChangedValues;
        if (transition.Timing) {
            Object.assign(transition.Timing, changedValues);
        }
        transition._linkType = _linkType;
        updateWorkflowModel(model => ({ ...model }));
    };

    const onWorkflowStateFormValuesChange = (state: WorkflowStateUpdateModelVM) => (rawChangedValues: any, allValues: any) => {
        const { FromWorkflowId: _1, FromWorkflowState: _2, ...changedValues } = rawChangedValues;
        const { FromWorkflowId, FromWorkflowState } = allValues;
        Object.assign(state, changedValues);
        let contentStateMapping = objectRef.contentStateMappings.find(map => map._stateRef === state);
        if (!contentStateMapping) {
            contentStateMapping = {
                _stateRef: state,
                NewState: '',
                FromStateName: '',
                FromWorkflowId: ''
            };
            objectRef.contentStateMappings.push(contentStateMapping);
        }
        contentStateMapping.FromWorkflowId = FromWorkflowId;
        contentStateMapping.FromStateName = FromWorkflowState;
        updateWorkflowModel(model => {
            recalculateStates(model);
            return { ...model };
        });
    };

    const onMoveUp = (state: WorkflowStateUpdateModelVM, stateIdx: number) => {
        updateWorkflowModel(model => {
            const states = model.States.splice(stateIdx, 1);
            model.States.splice(stateIdx - 1, 0, ...states);
            recalculateStates(model);
            return { ...model };
        });
    };

    const onMoveDown = (state: WorkflowStateUpdateModelVM, stateIdx: number) => {
        updateWorkflowModel(model => {
            const states = model.States.splice(stateIdx, 1);
            model.States.splice(stateIdx + 1, 0, ...states);
            recalculateStates(model);
            return { ...model };
        });
    };

    const onDeleteState = (state: WorkflowStateUpdateModelVM, stateIdx: number) => {
        const deletedMappingIdx = objectRef.contentStateMappings.findIndex(map => map._stateRef === state);
        objectRef.contentStateMappings.splice(deletedMappingIdx, 1);
        updateWorkflowModel(model => {
            model.States.splice(stateIdx, 1);
            recalculateStates(model);
            return { ...model };
        });
    };

    const triggerValidateFields = () => {
        const allStates = flatMap(get(workflowModel, 'States'));
        const allTransitions = flatten(map(allStates, s => get(s, 'Transitions') || []));
        allStates.forEach(state => state._validateFields && state._validateFields());
        allTransitions.forEach(transition => transition._validateFields && transition._validateFields());
        setNextButtonLoading(true);
    }

    const validateWorkflow = (callback: (valid: boolean, errorMessage?: string) => void) => {
        if (!workflowModel) return;
        let valid = true;
        const allStates = flatMap(get(workflowModel, 'States'));
        const allTransitions = flatten(map(allStates, s => get(s, 'Transitions') || []));
        let errorMessage = getTranslatedText('Please review the error messages in the form');

        for (let state of allStates) {
            if (state._getFieldsError && valid) {
                const errors = state._getFieldsError();
                const hasErrors = Object.values(errors).some(e => !!e);
                if (hasErrors) {
                    valid = false;
                }
            }
        }

        for (let transition of allTransitions) {
            transition._errorMessage = undefined;
            if (transition._getFieldsError && valid) {
                const errors = transition._getFieldsError();
                const hasErrors = Object.values(errors).some(e => !!e);
                if (hasErrors) {
                    valid = false;
                }
            }
            if (!transition.NewState) {
                transition._errorMessage = getTranslatedText('Next state is required');
                valid = false;
            }
        }

        if (workflowModel._templateOption.RequiredMinReminders) {
            const minReminders = workflowModel._templateOption.RequiredMinReminders;
            const reminderCount = filter(allStates, state => !state._fixed
                && (state.ReminderType === ReminderType.NotDue || state.ReminderType === ReminderType.Overdue)).length;
            if (reminderCount < minReminders) {
                valid = false;
                errorMessage = getTranslatedText('This workflow requires at least [workflow.MinReminders] reminder(s)')
                    .replace('[workflow.MinReminders]', minReminders.toString());
            }
        }

        setIsSubmitted(true);
        callback(valid, valid ? undefined : errorMessage);
    }

    const nameValidator = (isNew: boolean) => async (rule: any, value: any) => {
        const isDuplicated = filter(
            get(workflowModel, 'States'),
            s => s.Name.trim() === value.trim() || (s._templateOption.DisplayName && s._templateOption.DisplayName.trim() === value.trim())
        ).length > (isNew ? 0 : 1);
        if (isDuplicated) throw new Error(getTranslatedText('Name already exists'));
    };

    const stepNameRules = (isNew: boolean = false) => ([
        {
            required: true,
            message: getTranslatedText('Step Name required'),
        },
        {
            max: 256,
            message: getTranslatedText('Maximum [max.Limitation] characters')
                .replace('[max.Limitation]', '256'),
        },
        { validator: nameValidator(isNew) }
    ]);

    const initPopulateTransitionContent: TransitionFormInitPopulateContentFunc = (transition: WorkflowTransitionUpdateModelVM) => {
        if (transition._populateContent) return transition._populateContent;
        let populateContent: TransitionFormPopulateContentFunc;

        switch (transition._templateOption.PopulateContentType) {
            case WorkflowTransitionContent.TransitionForm:
            default: {
                const templateAdditionalContents = transition._templateOption.AdditionalContents;

                const additionalContent: TransitionFormAdditionContentFunc = (
                    workflowModel, transition, form
                ) => <>{map(templateAdditionalContents, contentType => {
                    switch (contentType) {
                        case AdditionalContent.LinkTypeSelection: return reminderLinkAdditionalContent(workflowModel, transition, form);
                    }
                })}</>;

                populateContent = ((state, transition, workflowModel, isSubmitted) => <TransitionFormWrapper
                    workflowModel={workflowModel}
                    transition={transition}
                    onValuesChange={onTransitionFormValuesChange(transition)}
                    isSubmitted={isSubmitted}
                    additionalContent={additionalContent}
                    containerRef={containerRef} />);
                break;
            }
        }

        return populateContent;
    }

    const objectRef = useMemo(() => ({
        initPopulateTransitionContent,
        contentStateMappings: [] as ContentStateMappingVM[]
    }), []);

    useEffect(() => {
        const initPopulateTransitionContent: TransitionFormInitPopulateContentFunc = (vm) => objectRef.initPopulateTransitionContent(vm);

        const constructWorkflowModel = () => {
            const workflowModel: WorkflowDefinitionUpdateModelVM = cloneDeep(inputWorkflow) as any;
            workflowModel._templateOption = workflowTemplateOption;
            workflowModel._version = uniqueId();
            workflowModel.Type = workflowTemplateOption.WorkflowDefinition.Type;
            if (!workflowModel.States) {
                workflowModel.States = [];
                workflowTemplateOption.WorkflowDefinition.States.forEach(stateName => {
                    const templateOption = detectStateTemplate(stateName);
                    if (templateOption) {
                        const stateTemplateVM = convertToStateViewModel(templateOption, initPopulateTransitionContent);
                        workflowModel.States.push(stateTemplateVM);
                    }
                })
            } else {
                workflowModel.States.forEach((state) => {
                    const templateOption = detectStateTemplate(state);
                    if (templateOption) {
                        const stateTemplateVM = convertToStateViewModel(templateOption, initPopulateTransitionContent);
                        assignStateValues(stateTemplateVM, state, workflowModel);
                    }
                });
            }
            if (!isCreateNew) {
                workflowModel.States.forEach((state, idx) => {
                    state.Index = idx;
                });
            }
            return workflowModel;
        }

        const workflowModel = constructWorkflowModel();
        recalculateStates(workflowModel);
        setWorkflowModel(workflowModel);
        setOriginalWorkflowModel(cloneDeep(workflowModel));
    }, [inputWorkflow, workflowTemplateOption, isCreateNew, objectRef]);


    const fetchWorkflowContentConfiguration = () => {
        if (!contentConfigs) {
            dispatch(getWorkflowContentConfigurationAction())
        }
    }

    useEffect(fetchWorkflowContentConfiguration, []);

    const populateActions = () => {
        return workflowTemplateOption.AvailableActions.map((actionName, index) => {
            const action = find(workflowActions, a => a.Name === actionName);
            if (action) {
                let disabled = false;
                if (action.NewStateOption) {
                    const stateTemplate = workflowStateTemplateOptions.find(opt => opt.Name === action.NewStateOption);
                    if (stateTemplate && isNumber(stateTemplate.MaxInstanceAllowed)) {
                        const stateCount = workflowModel && workflowModel.States.filter(s => s._templateOption.Name === stateTemplate.Name).length;
                        if (stateCount && stateCount >= stateTemplate.MaxInstanceAllowed) {
                            disabled = true;
                        }
                    }
                }

                return <Button
                    key={action + index.toString()}
                    className="mr-10"
                    type="default"
                    disabled={disabled}
                    onClick={onActionClick(action)}
                    loading={actionLoading[action.Name]}
                >
                    <FontAwesome
                        icon={action.Icon || ['fa', 'plus']}
                        className={`mr-8 ${actionLoading[action.Name] ? 'ml-8' : ''}`}
                    />
                    {action.Name}
                </Button>;
            }
            return null;
        });
    };

    return workflowModel && <div>
        <Title level={3}>{get(inputWorkflow, 'Name')}</Title>
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <Row className="actions-container" gutter={[10, 10]}>
                    <Col md={18}>{populateActions()}</Col>
                    <Col md={6} className="ta-right">
                        <Button
                            className="buttonGrey w-100px"
                            onClick={onResetButtonClick}
                            disabled={!formHasChanges}
                        >
                            <FontAwesome icon={['fa', 'undo']} className="mr-8" />
                            {getTranslatedText('Reset')}
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[10, 10]} style={{ height: '85vh', overflow: 'auto' }} ref={containerRef}>
                    {map(get(workflowModel, 'States'), (state, stateIdx) => <Col id={`wfs-form-${state._id}`} key={`${workflowModel._version}_${state._id}`} span={24}>
                        <WorkflowStateFormWrapper
                            workflowModel={workflowModel}
                            state={state} stateIdx={stateIdx}
                            onMoveUp={onMoveUp}
                            onMoveDown={onMoveDown}
                            onDeleteState={onDeleteState}
                            nameRules={stepNameRules()}
                            isSubmitted={isSubmitted}
                            onValuesChange={onWorkflowStateFormValuesChange(state)}
                            containerRef={containerRef}
                        />
                    </Col>)}
                </Row>
            </Col>
        </Row>
    </div> || null;
};

export default WorkflowDefinitionForm;

const convertToTransitionViewModel = (
    templateOption: WorkflowTransitionTemplateOption,
    initPopulateTransitionContent: TransitionFormInitPopulateContentFunc
) => {
    const transitionTemplate = templateOption.Transition;
    const vm: WorkflowTransitionUpdateModelVM = {
        ...transitionTemplate,
        NewState: transitionTemplate.NewState || '',
        _templateOption: templateOption
    }
    vm._populateContent = initPopulateTransitionContent(vm);
    return vm;
}

const convertToStateViewModel = (
    templateOption: WorkflowStateTemplateOption,
    initPopulateTransitionContent: TransitionFormInitPopulateContentFunc
) => {
    const stateTemplate = templateOption.State;
    const vm: WorkflowStateUpdateModelVM = {
        ...stateTemplate,
        _id: uniqueId(),
        _originalName: '',
        _templateOption: templateOption,
        Transitions: map(stateTemplate.Transitions, transitionName => {
            const templateOption = find(workflowTransitionTemplateOptions, opt => opt.Name === transitionName);
            return (templateOption && convertToTransitionViewModel(templateOption, initPopulateTransitionContent)) as WorkflowTransitionUpdateModelVM;
        })
    };
    return vm;
}

const assignStateValues = (from: WorkflowStateUpdateModelVM, to: WorkflowStateUpdateModelVM, workflowModel: WorkflowDefinitionUpdateModelVM) => {
    const tempTo = { ...to };
    Object.assign(to, from);
    to._originalName = tempTo.Name;
    to.Name = tempTo.Name;
    to.NotDueGap = tempTo.NotDueGap;
    to.Transitions = tempTo.Transitions;

    if (to.Transitions) {
        to.Transitions.forEach(toTransition => {
            const fromTransition = find(from.Transitions, t => t.Trigger === toTransition.Trigger);
            if (fromTransition) {
                const tempToTransition = { ...toTransition };
                Object.assign(toTransition, fromTransition);
                toTransition._newStateRef = find(workflowModel.States, s => s.Name === toTransition.NewState);
                toTransition.NewState = tempToTransition.NewState || fromTransition.NewState;
                toTransition.DisplayName = tempToTransition.DisplayName;
                toTransition.Timing = tempToTransition.Timing;
                toTransition.Trigger = tempToTransition.Trigger;
            }
        })
    } else {
        to.Transitions = from.Transitions;
    }
}

const recalculateStates = (workflowModel: WorkflowDefinitionUpdateModelVM) => {
    const states = get(workflowModel, 'States');
    let firstNotDueState: WorkflowStateUpdateModelVM | undefined;
    let firstOverdueState: WorkflowStateUpdateModelVM | undefined;
    const firstTargetLinkTransitions: WorkflowTransitionUpdateModelVM[] = [];

    for (let i = 0; i < states.length; i++) {
        const currentState = states[i];
        currentState.Transitions = currentState.Transitions || [];
        if (!firstNotDueState && currentState.ReminderType === ReminderType.NotDue) firstNotDueState = currentState;
        if (!firstOverdueState && currentState.ReminderType === ReminderType.Overdue) firstOverdueState = currentState;
        const prevState = states[i - 1];
        const nextState = states[i + 1];
        currentState.Transitions.forEach(transition => {
            switch (transition._linkType) {
                case LinkType.FirstNotDue:
                case LinkType.FirstOverdue: firstTargetLinkTransitions.push(transition); break;
                case LinkType.NextState: {
                    transition._newStateRef = nextState;
                    transition.NewState = get(nextState, 'Name') || '';
                    break;
                }
                default: {
                    if (transition.NewState) {
                        transition._newStateRef = find(states, s => s.Name === transition.NewState);
                    }
                }
            }
        });
        if (!currentState._fixed) {
            currentState._canMoveUp = prevState && !prevState._fixed;
            currentState._canMoveDown = nextState && !nextState._fixed;
        }
    }

    firstTargetLinkTransitions.forEach(transition => {
        let targetState: WorkflowStateUpdateModelVM | undefined;
        switch (transition._linkType) {
            case LinkType.FirstNotDue: targetState = firstNotDueState; break;
            case LinkType.FirstOverdue: targetState = firstOverdueState; break;
        }
        transition._newStateRef = targetState;
        transition.NewState = get(targetState, 'Name') || '';
    });
}

// ===== WORKFLOW STATE FORM =====
interface WorkflowStateFormProps {
    workflowModel: WorkflowDefinitionUpdateModelVM;
    state: WorkflowStateUpdateModelVM;
    stateIdx: number;
    onMoveUp: (state: WorkflowStateUpdateModelVM, stateIdx: number) => void;
    onMoveDown: (state: WorkflowStateUpdateModelVM, stateIdx: number) => void;
    onDeleteState: (state: WorkflowStateUpdateModelVM, stateIdx: number) => void;
    nameRules: ValidationRule[],
    onValuesChange: (changedValues: any, allValues: any) => void;
    isSubmitted: boolean;
    containerRef?: any;
}

const workflowStateFormFieldNames = {
    Name: 'Name',
    ReminderType: 'ReminderType',
    NotDueGap: 'NotDueGap',
    FromWorkflowId: 'FromWorkflowId',
    FromWorkflowState: 'FromWorkflowState'
};

const WorkflowStateForm: React.FC<WorkflowStateFormProps & { form: WrappedFormUtils }> = ({
    form, workflowModel, state, stateIdx, onMoveUp, onMoveDown, onDeleteState,
    nameRules, isSubmitted, containerRef
}) => {
    const availableWorkflowOptions: CompaniesState['fullWorkflowOptions'] = useSelector(getFullWorkflowOptions);
    const { errorMessages, loading: configsLoading }: ContentsState['workflowContents'] = useSelector((state: ApplicationState) => state.contents.workflowContents);
    const contentConfigs: ContentsState['workflowContents']['contentConfigs'] = useSelector(getWorkflowStateConfigs);
    const { data: taskWorkflows }: TasksState['taskWorkflows'] = useSelector(getTaskWorkflows);
    const workflowOptions = filter(
        availableWorkflowOptions,
        opt => some(contentConfigs,
            config => config.WorkflowId === opt.WorkflowId && config.States && config.States.length > 0));
    const [contentSelection, setContentSelection] = useState<{
        WorkflowId?: string;
        StateName?: string;
    }>({});
    const selectedWorkflowStateConfig = useMemo(() => {
        return contentSelection && contentSelection.WorkflowId
            ? find(contentConfigs, c => c.WorkflowId === contentSelection.WorkflowId)
            : undefined;
    }, [contentSelection, contentConfigs]);

    const { getFieldDecorator, getFieldsError, validateFields, setFieldsValue } = form;
    state._getFieldsError = getFieldsError;
    state._validateFields = validateFields;

    useEffect(() => {
        validateFields([workflowStateFormFieldNames.Name]);
    }, []);

    const populateFormFields = () => {
        if (!state._templateOption.FormFields) return;
        const fields: JSX.Element[] = [];
        state._templateOption.FormFields.forEach(field => {
            switch (field) {
                case workflowStateFormFieldNames.Name: {
                    fields.push(<FormItem label={getTranslatedText('Step Name')}>
                        {getFieldDecorator(workflowStateFormFieldNames.Name, {
                            rules: nameRules,
                            initialValue: stateName
                        })(<Input
                            placeholder={getTranslatedText('Input step name')}
                            style={{ width: 200 }}
                        />)}
                    </FormItem>)
                    break;
                }

                case workflowStateFormFieldNames.ReminderType: {
                    fields.push(<FormItem label={getTranslatedText('Reminder type')}>
                        {getFieldDecorator(workflowStateFormFieldNames.ReminderType, {
                            rules: [{
                                required: true,
                                message: getTranslatedText('Reminder type required'),
                            }],
                            initialValue: get(state, 'ReminderType')
                        })(<SelectReadonly
                            style={{ width: 200 }}
                            readOnly={isReadOnly(workflowStateFormFieldNames.ReminderType)}
                            getPopupContainer={populatePopoverContainer(containerRef)}
                        >
                            {map(
                                reminderTypeValues,
                                (value) => (
                                    <Option key={value} value={value}>
                                        {value}
                                    </Option>
                                )
                            )}
                        </SelectReadonly>)}
                    </FormItem>)
                    break;
                }

                case workflowStateFormFieldNames.NotDueGap: {
                    if (state.ReminderType === ReminderType.NotDue) {
                        fields.push(<FormItem label={getTranslatedText('Not Due Gap')}>
                            {getFieldDecorator(workflowStateFormFieldNames.NotDueGap, {
                                rules: [],
                                initialValue: get(state, 'NotDueGap')
                            })(<InputNumber
                                placeholder={getTranslatedText('Input Not due gap')}
                                onKeyDown={limitOnlyNumber()}
                                step={1}
                                style={{ width: 200 }}
                            />)}
                        </FormItem>);
                    }
                }
            }
        });
        return fields;
    }

    const isReadOnly = (fieldName: string) => includes(state._templateOption.ReadOnlyFields, fieldName);

    const { canDelete, deleteTooltip } = useMemo(() => {
        if (state._fixed) return { canDelete: false, deleteTooltip: undefined };
        if (includes(map(taskWorkflows, workflow => workflow.StateName), state._originalName)) {
            return {
                canDelete: false,
                deleteTooltip: getTranslatedText('You cannot delete this step since it is being referenced by some tasks')
            }
        }
        return { canDelete: true };
    }, [state, taskWorkflows]);

    const populateDeleteButton = () => (<div
        style={{ cursor: canDelete ? undefined : 'not-allowed' }}
        onClick={canDelete ? (() => onDeleteState(state, stateIdx)) : undefined}
        className="d-inline-block cursor-p-div ml-10"
    >
        <FontAwesome style={{ color: canDelete ? DANGER_COLOR : 'grey' }} icon={['fas', 'trash']} />
    </div>);

    const populateContentSection = () => {
        return <>
            {<Divider orientation="left" className="mb-3">{getTranslatedText('Template Content')}</Divider>}
            <Col span={24}>
                <div>{getTranslatedText('Please select the workflow content to clone for this new step')}</div>
            </Col>
            <Col span={24}>
                <FormItem label={getTranslatedText('Workflow')}>
                    {getFieldDecorator(workflowStateFormFieldNames.FromWorkflowId, {
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText('Workflow is required')
                            }
                        ]
                    })(<Select
                        style={{ width: 200 }}
                        loading={configsLoading}
                        disabled={configsLoading}
                        placeholder={getTranslatedText('Choose a workflow')}
                        getPopupContainer={populatePopoverContainer(containerRef)}
                        onChange={(value: string) => {
                            setFieldsValue({
                                [workflowStateFormFieldNames.FromWorkflowState]: undefined
                            })
                            setContentSelection({
                                WorkflowId: value
                            });
                        }}
                    >
                        {map(
                            workflowOptions,
                            ({ WorkflowId, WorkflowName }) => (
                                <Option key={WorkflowId} value={WorkflowId}>
                                    {WorkflowName}
                                </Option>
                            )
                        )}
                    </Select>)}
                </FormItem>
                <FormItem label={getTranslatedText('State Name')}>
                    {getFieldDecorator(workflowStateFormFieldNames.FromWorkflowState, {
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText('Workflow state is required')
                            }
                        ]
                    })(<Select
                        style={{ width: 200 }}
                        loading={configsLoading}
                        disabled={!selectedWorkflowStateConfig || configsLoading}
                        placeholder={getTranslatedText('Choose a state name')}
                        getPopupContainer={populatePopoverContainer(containerRef)}
                        onChange={(value: string) => setContentSelection(state => ({
                            ...state,
                            StateName: value
                        }))}
                    >
                        {selectedWorkflowStateConfig && map(
                            selectedWorkflowStateConfig.States,
                            ({ StateName }) => (
                                <Option key={StateName} value={StateName}>
                                    {StateName}
                                </Option>
                            )
                        )}
                    </Select>)}
                </FormItem>
            </Col>
        </>
    }

    const stateName = state._templateOption.DisplayName || get(state, 'Name');
    const editableFields = populateFormFields();
    const isNewState = !state.Index;
    const Transitions = get(state, 'Transitions');

    return <Form className="form-inline-mb-0 workflow-inline-form" layout="inline">
        <Card className="workflow-state-card"
            size="small"
            title={<Title level={4}>{stateName}</Title>}
            extra={<>
                {state._canMoveUp && <div onClick={() => onMoveUp(state, stateIdx)} className="d-inline-block cursor-p-div ml-10">
                    <FontAwesome icon={['fa', 'arrow-up']} />
                </div>}
                {state._canMoveDown && <div onClick={() => onMoveDown(state, stateIdx)} className="d-inline-block cursor-p-div ml-10">
                    <FontAwesome icon={['fa', 'arrow-down']} />
                </div>}
                {(deleteTooltip || canDelete) && <Tooltip title={deleteTooltip} placement="topRight">{populateDeleteButton()}</Tooltip>}
            </>}
            headStyle={{ background: '#bae7ff' }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div>{state._templateOption.Description}</div>
                </Col>
                {!isEmpty(editableFields) && <Col span={24}>
                    {editableFields}
                </Col>}
                {state._templateOption.HasContent === true && isNewState && populateContentSection()}
                {!isEmpty(Transitions) && <Divider orientation="left" className="mb-3">{getTranslatedText('Transitions')}</Divider>}
                {map(Transitions, (transition) => <Col key={`${workflowModel._version}_${transition.Trigger}`} xs={24} md={get(Transitions, 'length') === 3 ? 8 : 12}>
                    {transition._populateContent && transition._populateContent(state, transition, workflowModel, isSubmitted)}
                </Col>)}
            </Row>
        </Card>
    </Form>;
};
const WorkflowStateFormWrapper: React.FC<WorkflowStateFormProps> = Form.create({
    name: 'workflow-inline-form',
    onValuesChange(props, rawChangedValues, allValues) {
        const onValuesChange = get(props, 'onValuesChange');
        if (onValuesChange) onValuesChange(rawChangedValues, allValues);
    },
})(WorkflowStateForm) as any;


// ===== TRANSITION FORM =====
type TransitionFormAdditionContentFunc = (workflowModel: WorkflowDefinitionUpdateModelVM, transition: WorkflowTransitionUpdateModelVM, form: WrappedFormUtils) => JSX.Element;
type TransitionFormPopulateContentFunc = (
    state: WorkflowStateUpdateModelVM,
    transition: WorkflowTransitionUpdateModelVM,
    workflowModel: WorkflowDefinitionUpdateModelVM,
    isSubmitted: boolean) => JSX.Element | null;

type TransitionFormInitPopulateContentFunc = (vm: WorkflowTransitionUpdateModelVM) => TransitionFormPopulateContentFunc;

interface TransitionFormProps {
    workflowModel: WorkflowDefinitionUpdateModelVM;
    transition: WorkflowTransitionUpdateModelVM;
    onValuesChange: (changedValues: any, allValues: any) => void;
    additionalContent?: TransitionFormAdditionContentFunc;
    isSubmitted: boolean;
    containerRef?: any;
}

const transitionFormFieldNames = {
    Gap: 'Gap',
    Orientation: 'Orientation',
    LinkType: '_linkType'
};

const TransitionForm: React.FC<TransitionFormProps & { form: WrappedFormUtils }> = ({
    form, workflowModel, transition, additionalContent, isSubmitted, containerRef
}) => {
    const { getFieldDecorator, getFieldsError, validateFields } = form;
    transition._getFieldsError = getFieldsError;
    transition._validateFields = validateFields;

    return <Form className="workflow-inline-form form-inline-mb-0" layout="inline">
        {transition.Trigger && <div>{getTranslatedText('Trigger')}: <b>{transition.Trigger}</b></div>}
        <div>{getTranslatedText('Next state')}: <b>{get(transition._newStateRef, ['_templateOption', 'DisplayName']) || get(transition, 'NewState') || 'N/A'}</b></div>
        {isSubmitted && transition._errorMessage && <div style={{ color: DANGER_COLOR }}>{transition._errorMessage}</div>}
        <div className="mb-10"></div>
        <Row>
            {transition.Timing && <>
                <Col span={24}>
                    <FormItem label={getTranslatedText('Timing Gap')}>
                        {getFieldDecorator(transitionFormFieldNames.Gap, {
                            rules: [
                                {
                                    required: true,
                                    message: getTranslatedText('Timing Gap required'),
                                },
                            ],
                            initialValue: transition.Timing.Gap
                        })(<InputNumber
                            placeholder={getTranslatedText('Input timing gap')}
                            onKeyDown={limitOnlyNumber()}
                            step={1}
                            style={{ width: 200 }}
                        />)}
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem label={getTranslatedText('Orientation')}>
                        {getFieldDecorator(transitionFormFieldNames.Orientation, {
                            rules: [
                                {
                                    required: true,
                                    message: getTranslatedText('Orientation required'),
                                }
                            ],
                            initialValue: transition.Timing.Orientation
                        })(
                            <SelectReadonly
                                style={{ width: 200 }}
                                getPopupContainer={populatePopoverContainer(containerRef)}
                            >
                                {map(
                                    timingOrientationValues,
                                    (value) => (
                                        <Option key={value} value={value}>
                                            {getTranslatedText(value)}
                                        </Option>
                                    )
                                )}
                            </SelectReadonly>
                        )}
                    </FormItem>
                </Col>
            </>}
            <Col span={24}>
                {additionalContent && additionalContent(workflowModel, transition, form)}
            </Col>
        </Row>
    </Form>
};
const TransitionFormWrapper: React.FC<TransitionFormProps> = Form.create({
    name: 'transition-form',
    onValuesChange(props, rawChangedValues, allValues) {
        const onValuesChange = get(props, 'onValuesChange');
        if (onValuesChange) onValuesChange(rawChangedValues, allValues);
    },
})(TransitionForm) as any;
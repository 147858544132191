/**
 * Function for populating a button that triggers a drawer component.
 */

import { Button, Spin } from 'antd';
import { find, get, includes, isEmpty } from 'lodash';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { populatePopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { getCurrentUser } from '../../store/users/sagas';
import { IsOrganisationViewAttribute, OrganisationRoleIdAttribute } from '../../constants/authUserAttributes';

const Drawer = lazy(() => import('antd/lib/drawer'));

interface IProps {
    readonly containerRef?: any;
    readonly allowedRoles?: string[];
    readonly popoverClassName?: string;
    readonly popoverVisible?: boolean;
    readonly popoverTitle?: any;
    readonly popoverContent: any;
    readonly popoverComponent?: any;
    readonly buttonType:
        | 'link'
        | 'default'
        | 'ghost'
        | 'primary'
        | 'dashed'
        | 'danger'
        | undefined;
    readonly buttonClassName?: string;
    readonly buttonContent: any;
    readonly buttonRestProps?: any;
    readonly drawerCloseable: boolean;
    readonly drawerWidth?: any;
}
const ProtectedDrawerButton: React.FC<IProps> = ({
    containerRef,
    allowedRoles,
    popoverVisible,
    popoverTitle,
    popoverContent,
    popoverComponent,
    buttonType,
    buttonClassName,
    buttonContent,
    buttonRestProps,
    drawerCloseable,
    drawerWidth,
}: IProps) => {
    
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);

    let userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    if (isOrgView) {
        userRole = get(orgUserRole, 'Name');
    }

    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        return (
            <>
                <Button
                    type={buttonType}
                    className={buttonClassName}
                    {...buttonRestProps}
                >
                    {getTranslatedText(buttonContent)}
                </Button>
                {popoverComponent || <Suspense fallback={<Spin />}>
                    <Drawer
                        visible={popoverVisible}
                        getContainer={populatePopoverContainer(containerRef)}
                        closable={drawerCloseable}
                        title={popoverTitle}
                        width={drawerWidth || 420}
                        className="protected-drawer"
                    >
                        {popoverVisible && popoverContent}
                    </Drawer>
                </Suspense>}
            </>
        );
    } else {
        return null;
    }
};

export default ProtectedDrawerButton;

import React, { useMemo } from 'react';
import { workflowTemplateOptions } from '../../constants/workflowsSortAndFilters';
import { Checkbox, Form, Input, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { filter, find, get, isBoolean, map } from 'lodash';
import { CompanyUserRole } from '../../store/companies/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { UpdateWorkflowRequestPayload, WorkflowConfiguration, WorkflowDefinitionUpdateModel, WorkflowTemplateOption } from '../../store/workflow/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getTranslatedText } from '../../utils/commonFunctions';

const { Option } = Select;

interface IProps {
    readonly isCreateNew: boolean;
    readonly workflowConfiguration?: WorkflowConfiguration;
    readonly updatePayload?: UpdateWorkflowRequestPayload;
    readonly setOnSubmit: (handler: () => void) => void;
    readonly submitCallback: (updatePayload: UpdateWorkflowRequestPayload) => void;
    readonly setDataHasChanges: (changed: boolean) => void;
}

const workflowConfigurationFieldNames = {
    Name: 'Name',
    TemplateOption: 'TemplateOption',
    CustomFieldName: 'CustomFieldName',
    CustomFieldValue: 'CustomFieldValue',
    MakeDefault: 'MakeDefault'
}

const maxLenRule = {
    max: 256,
    message: getTranslatedText('Maximum [max.Limitation] characters')
        .replace('[max.Limitation]', '256'),
};

const WorkflowConfigurationForm: React.FC<IProps & { form: WrappedFormUtils }> = ({
    form, updatePayload, isCreateNew, workflowConfiguration, setOnSubmit, submitCallback
}) => {
    const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } = form;
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const usingCustomerWorkflow = get(
        selectedUserCompany,
        'Company.UsingCustomerWorkflow'
    );
    const isPaymentPlanEnabled = get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    );
    const workflowModel = get(updatePayload, 'WorkflowDefinition');
    const isDefault = get(workflowConfiguration, 'WorkflowType') === 'Default';
    const validWorkflows = useMemo(() => {
        return filter(workflowTemplateOptions, o =>
            (!isBoolean(o.IsCustomerWorkflow) || o.IsCustomerWorkflow === usingCustomerWorkflow)
            && (!isBoolean(o.IsPaymentPlanRelated) || o.IsPaymentPlanRelated === isPaymentPlanEnabled))
    }, [usingCustomerWorkflow, isPaymentPlanEnabled]);

    setOnSubmit(() => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const workflowModel: WorkflowDefinitionUpdateModel = get(updatePayload, 'WorkflowDefinition') || {} as any;
                workflowModel.TemplateOption = get(values, workflowConfigurationFieldNames.TemplateOption);
                const workflowTemplate: WorkflowTemplateOption | undefined = find(validWorkflows, w => w.Name === workflowModel.TemplateOption);
                if (!workflowTemplate) return;
                workflowModel.EntityType = get(workflowTemplate, ['WorkflowDefinition', 'EntityType']);
                workflowModel.Name = get(values, workflowConfigurationFieldNames.Name);
                const newUpdatePayload: UpdateWorkflowRequestPayload = {
                    WorkflowDefinition: workflowModel
                };
                if (!isDefault) {
                    newUpdatePayload.CustomField = {
                        Name: get(values, workflowConfigurationFieldNames.CustomFieldName),
                        Value: get(values, workflowConfigurationFieldNames.CustomFieldValue)
                    }
                }

                if (get(values, workflowConfigurationFieldNames.MakeDefault)) {
                    newUpdatePayload.MakeDefault = get(values, workflowConfigurationFieldNames.MakeDefault);
                }
                
                submitCallback(newUpdatePayload);
            }
        });
    });

    const detectTemplateOption = () => {
        if (!workflowModel) return;
        const templateOption = get(workflowModel, 'TemplateOption');
        if (templateOption) return templateOption;
        const detectedWorkflow = find(validWorkflows, w => w.WorkflowDefinition.EntityType === workflowModel.EntityType);
        return get(detectedWorkflow, 'Name');
    }

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {
        setFieldsValue({
            [workflowConfigurationFieldNames.MakeDefault]: e.target.checked
        });
    };

    return (
        <Form style={{ width: 500 }} layout="horizontal" labelCol={{ md: 8 }} wrapperCol={{ md: 16 }}>
            <FormItem label={getTranslatedText('Name')}>
                {getFieldDecorator(workflowConfigurationFieldNames.Name, {
                    rules: [
                        {
                            required: true,
                            message: getTranslatedText('Workflow Name required'),
                        },
                        maxLenRule
                    ],
                    initialValue: get(workflowModel, 'Name')
                })(<Input placeholder={getTranslatedText('Workflow Name')} />)}
            </FormItem>
            <FormItem label={getTranslatedText('Workflow template')}>
                {getFieldDecorator(workflowConfigurationFieldNames.TemplateOption, {
                    rules: [
                        {
                            required: true,
                            message: getTranslatedText('Workflow Template required'),
                        }
                    ],
                    initialValue: detectTemplateOption()
                })(<Select placeholder={getTranslatedText('Select a template')} disabled={!isCreateNew}>
                    {map(validWorkflows, template => <Option key={template.Name} value={template.Name}>{template.Name}</Option>)}
                </Select>)}
            </FormItem>
            {!isDefault && <>
                <FormItem label={getTranslatedText('Custom field name')}>
                    {getFieldDecorator(workflowConfigurationFieldNames.CustomFieldName, {
                        rules: [
                            maxLenRule,
                            {
                                async validator(rule, value, callback, source, options) {
                                    if (getFieldValue(workflowConfigurationFieldNames.CustomFieldValue) && !value) {
                                        throw new Error(getTranslatedText('Custom field name is required'));
                                    }
                                },
                            }
                        ],
                        initialValue: get(updatePayload, ['CustomField', 'Name'])
                    })(<Input placeholder={getTranslatedText('Input Custom field name')} />)}
                </FormItem>
                <FormItem label={getTranslatedText('Custom field value')}>
                    {getFieldDecorator(workflowConfigurationFieldNames.CustomFieldValue, {
                        rules: [maxLenRule],
                        initialValue: get(updatePayload, ['CustomField', 'Value'])
                    })(<Input placeholder={getTranslatedText('Input Custom field value')} />)}
                </FormItem>
            </>}
            <FormItem label={getTranslatedText('Default')}>
                {getFieldDecorator(workflowConfigurationFieldNames.MakeDefault, {
                    initialValue: isDefault,
                    valuePropName: "checked"
                })(
                    <Checkbox
                        disabled={isDefault}
                        onChange={handleCheckboxClick}
                    />
                )}
            </FormItem>
        </Form>
    );
};
const WorkflowConfigurationFormWrapper: React.FC<IProps> = Form.create({
    name: 'workflow-configuration-form',
    onValuesChange(props, changedValues, allValues) {
        const setDataHasChanges = get(props, 'setDataHasChanges');
        if (setDataHasChanges) setDataHasChanges(true);
    },
})(WorkflowConfigurationForm) as any;

export default WorkflowConfigurationFormWrapper;

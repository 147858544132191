/**
 * File responsible for all the UI and actions for Import page - `/app/import`.
 */

import React from 'react';
import ImportExcelComponent from '../../../../components/import/ImportExcelComponent';

interface IProps {}
const ImportExcelPage: React.FC<IProps> = () => {
    

    return (
        <ImportExcelComponent
            downloadTemplate={"IODM_Payment_Plan_Template.xlsx"}
            uploadFileType={"pp"}
            templateText={"new payment plans"}
        />
    );
};

export default ImportExcelPage;
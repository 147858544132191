import { I18n } from 'aws-amplify';

interface Vocabularies {
    [key: string]: string;
}

class I18nWrapper {
    static currentLanguage: string = 'en-AU';
    static vocabularies: { [language: string]: Vocabularies } = {};

    static setLanguage(language: string) {
        I18n.setLanguage(language);
        this.currentLanguage = language;
    }

    static getCurrentLanguage(): string {
        return localStorage.getItem('language') || 'en-AU';
    }

    static putVocabulariesForLanguage(language: string, vocabularies: Vocabularies) {
        I18n.putVocabulariesForLanguage(language, vocabularies);
        //this.vocabularies[language] = vocabularies;
    }

    static getVocabulary(key: string): string | undefined {
        if (this.currentLanguage && this.vocabularies[this.currentLanguage]) {
            return this.vocabularies[this.currentLanguage][key];
        }
        return undefined;
    }

    static hasVocabulary(key: string): boolean {
        if (this.currentLanguage && this.vocabularies[this.currentLanguage]) {
            return key in this.vocabularies[this.currentLanguage];
        }
        return false;
    }

    static getAllVocabularies(): Vocabularies | null {
        if (this.currentLanguage && this.vocabularies[this.currentLanguage]) {
            return this.vocabularies[this.currentLanguage];
        }
        return null;
    }
}

export default I18nWrapper;
/**
 * Reusable component for populating the schedule dates tab section in payment plan selfserve.
 */

import { Form,DatePicker,Tooltip } from 'antd';
import React from 'react';
import {
    FORM_FIELDS
} from '../../constants/paymentPlanConfigs';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { getTranslatedText } from '../../utils/commonFunctions';
import moment from 'moment-timezone';
import FontAwesome from '../common/FontAwesome';

const { Item: FormItem } = Form;

interface IProps {
    form?: any;
    updateFormState: any;
    paymentPlanOptionIndex: number;
    paymentPlanOptionKeyIndex: number;
    paymentPlanScheduleDate:any;
    paymentPlanScheduleDateKeys:any;
    removeDynamicScheduleDate:(paymentPlanOptionIndex: number,paymentPlanOptionKeyIndex: number,scheduleDateKey:string, scheduleDateIndex: number) => void;
    formatDateString: any;
    scheduleDatesWarningDynamic: { ScheduleIndex: number, warning: string }[];
}

const PaymentPlanScheduleDateTabContent: React.FC<IProps> = ({
    form,
    updateFormState,
    paymentPlanOptionIndex,
    paymentPlanOptionKeyIndex,
    paymentPlanScheduleDate,
    paymentPlanScheduleDateKeys,
    removeDynamicScheduleDate,
    formatDateString,
    scheduleDatesWarningDynamic
}: IProps) => {
    const { getFieldDecorator } = form;
    
    return (
        <>
            { paymentPlanScheduleDateKeys[paymentPlanOptionKeyIndex].map((key:any, index:number) => (
                <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <FormItem key={key}>
                        {getFieldDecorator(FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionKeyIndex + "Dynamic" + FORM_FIELDS.SCHEDULED_DATE + index, {
                            initialValue: paymentPlanScheduleDate[index] ? moment(paymentPlanScheduleDate[index], 'DD/MM/YYYY') : null,
                            rules: [
                                {
                                    required: true,
                                    message: getTranslatedText("Scheduled dates required!"),
                                }
                            ]
                        })(<DatePicker
                            format={dateFormatDDMMMYYYYSpace}
                            placeholder="Date"
                            onChange={updateFormState(
                                FORM_FIELDS.SCHEDULED_DATE + "Dynamic" + index,
                                paymentPlanOptionIndex,
                                index,
                                null,
                                paymentPlanOptionKeyIndex
                            )}
                            />
                        )}

                    </FormItem>
                    <a
                        style={{ marginLeft: '10px', cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                        onClick={() => removeDynamicScheduleDate(paymentPlanOptionIndex,paymentPlanOptionKeyIndex,key, index)}
                    >
                        <FontAwesome icon={['fas', 'trash']} style={{ color: 'gray' }} />
                    </a>
                    {(scheduleDatesWarningDynamic[index]) && (
                        <Tooltip title={scheduleDatesWarningDynamic[index].warning}>
                            <FontAwesome
                                icon={['fas', 'info-circle']}
                                style={{ color: '#ff5d00', marginLeft: '5px' }}
                                className="fs-18"
                            />
                        </Tooltip>)}
                </div>))}
        </>
        
    );
};

export default PaymentPlanScheduleDateTabContent;

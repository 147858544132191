/**
 * File responsible for mapping the redux state.
 */

import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
// import { History } from 'history';

// Types
import { AuthState } from './auth/types';
import { StoredRouteState } from './storedRoute/types';
import { RolesState } from './roles/types';
import { SmsCustomizationState } from './smsProviders/types';
import { UsersState } from './users/types';
import { InvitesState } from './invites/types';
import { CompaniesState } from './companies/types';
import { ImportState } from './import/types';
import { InvoicesState } from './invoices/types';
import { CustomersState } from './customers/types';
import { NotificationsState } from './notifications/types';
import { TasksState } from './tasks/types';
import { TicketsState } from './tickets/types';
import { AppCommonState } from './common/types';
import { AccessTokensState } from './accessTokens/types';
import { CreditsState } from './credits/types';
import { DashboardsState } from './dashboards/types';
import { PaymentsState } from './payments/types';
import { OrganisationsState } from './organisations/types';
import { PaymentPlansState } from './paymentPlans/types';
import { RemittanceAdvicesState } from './remittanceAdvices/types';
import { WorkflowsState } from './workflow/types';
import { ReportState } from './report/types';
import { ScheduledReportState } from './scheduledReports/types';
import { BankFileDescriptorsState } from './bankFileDescriptors/types';
import { BanksState } from './banks/types';

// Sagas
import authSaga from './auth/sagas';
import rolesSaga from './roles/sagas';
import smsProvidersSaga from './smsProviders/sagas';
import invitesSaga from './invites/sagas';
import usersSaga from './users/sagas';
import companiesSaga from './companies/sagas';
import importSaga from './import/sagas';
import invoicesSaga from './invoices/sagas';
import customersSaga from './customers/sagas';
import notificationsSaga from './notifications/sagas';
import tasksSaga from './tasks/sagas';
import ticketsSaga from './tickets/sagas';
import commonSaga from './common/sagas';
import accessTokensSaga from './accessTokens/sagas';
import creditsSaga from './credits/sagas';
import dashboardsSaga from './dashboards/sagas';
import paymentsSaga from './payments/sagas';
import organisationsSaga from './organisations/sagas';
import paymentPlansSaga from './paymentPlans/sagas';
import conversationLineSaga from './conversationLine/sagas';
import remittanceAdvicesSaga from './remittanceAdvices/sagas';
import changesSaga from './changes/sagas';
import workflowsSaga from './workflow/sagas';
import reportSaga from './report/sagas';
import contentsSaga from './contents/sagas';
import scheduledReportSaga from './scheduledReports/sagas';
import banksSaga from './banks/sagas';
import bankFileDescriptorsSaga from './bankFileDescriptors/sagas';

import supportTicketsSaga from './supportTickets/sagas';

// Reducers
import { authReducer } from './auth/reducer';
import { storedRouteReducer } from './storedRoute/reducer';
import { rolesReducer } from './roles/reducer';
import { smsProvidersReducer } from './smsProviders/reducer';
import { usersReducer } from './users/reducer';
import { invitesReducer } from './invites/reducer';
import { companiesReducer } from './companies/reducer';
import { importReducer } from './import/reducer';
import { invoicesReducer } from './invoices/reducer';
import { customersReducer } from './customers/reducer';
import { notificationsReducer } from './notifications/reducer';
import { tasksReducer } from './tasks/reducer';
import { ticketsReducer } from './tickets/reducer';
import { commonReducer } from './common/reducer';
import { accessTokensReducer } from './accessTokens/reducer';
import { creditsReducer } from './credits/reducer';
import { dashboardsReducer } from './dashboards/reducer';
import { paymentsReducer } from './payments/reducer';
import { organisationsReducer } from './organisations/reducer';
import { paymentPlansReducer } from './paymentPlans/reducer';
import { remittanceAdvicesReducer } from './remittanceAdvices/reducer';
import { supportTicketsReducer } from './supportTickets/reducer';
import { ContentsState } from './contents/types';
import { contentsReducer } from './contents/reducer';
import { changesReducer } from './changes/reducer';
import { workflowsReducer } from './workflow/reducer';
import { reportReducer } from './report/reducer';
import { scheduledReportReducer } from './scheduledReports/reducer';
import { banksReducer } from './banks/reducer';
import { bankFileDescriptorsReducer } from './bankFileDescriptors/reducer';

import { SupportTicketsState } from './supportTickets/types';

// The top-level state object
export interface ApplicationState {
    app: AppCommonState;
    auth: AuthState;
    storedRoute: StoredRouteState;
    roles: RolesState;
    smsProviders: SmsCustomizationState;
    users: UsersState;
    companies: CompaniesState;
    import: ImportState;
    invites: InvitesState;
    invoices: InvoicesState;
    customers: CustomersState;
    notifications: NotificationsState;
    tasks: TasksState;
    tickets: TicketsState;
    accessTokens: AccessTokensState;
    credits: CreditsState;
    dashboards: DashboardsState;
    payments: PaymentsState;
    organisations: OrganisationsState;
    paymentPlans: PaymentPlansState;
    remittanceAdvices: RemittanceAdvicesState;
    workflows: WorkflowsState;
    report: ReportState;
    scheduledReport: ScheduledReportState;
    contents: ContentsState;
    banks: BanksState;
    bankFileDescriptors: BankFileDescriptorsState;
    supportTickets: SupportTicketsState;

    router: RouterState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) =>
    combineReducers({
        app: commonReducer,
        auth: authReducer,
        storedRoute: storedRouteReducer,
        roles: rolesReducer,
        smsProviders: smsProvidersReducer,
        users: usersReducer,
        invites: invitesReducer,
        companies: companiesReducer,
        import: importReducer,
        invoices: invoicesReducer,
        customers: customersReducer,
        notifications: notificationsReducer,
        tasks: tasksReducer,
        tickets: ticketsReducer,
        accessTokens: accessTokensReducer,
        credits: creditsReducer,
        dashboards: dashboardsReducer,
        payments: paymentsReducer,
        organisations: organisationsReducer,
        paymentPlans: paymentPlansReducer,
        remittanceAdvices: remittanceAdvicesReducer,
        changes: changesReducer,
        workflows: workflowsReducer,
        report: reportReducer,
        scheduledReport: scheduledReportReducer,
        contents: contentsReducer,
        banks: banksReducer,
        bankFileDescriptors: bankFileDescriptorsReducer,
        supportTickets: supportTicketsReducer,
        router: connectRouter(history),
    });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
    // yield all([fork(heroesSaga), fork(teamsSaga)]);
    yield all([
        fork(authSaga),
        fork(rolesSaga),
        fork(smsProvidersSaga),
        fork(invitesSaga),
        fork(usersSaga),
        fork(companiesSaga),
        fork(importSaga),
        fork(invoicesSaga),
        fork(customersSaga),
        fork(notificationsSaga),
        fork(tasksSaga),
        fork(ticketsSaga),
        fork(commonSaga),
        fork(accessTokensSaga),
        fork(creditsSaga),
        fork(dashboardsSaga),
        fork(paymentsSaga),
        fork(organisationsSaga),
        fork(paymentPlansSaga),
        fork(conversationLineSaga),
        fork(remittanceAdvicesSaga),
        fork(changesSaga),
        fork(workflowsSaga),
        fork(reportSaga),
        fork(scheduledReportSaga),
        fork(contentsSaga),
        fork(supportTicketsSaga),
        fork(banksSaga),
        fork(bankFileDescriptorsSaga),
        fork(supportTicketsSaga)
    ]);
}

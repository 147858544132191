/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { isEmpty, map, toLower } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { ContactOptions } from '../../store/common/types';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly filterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
    readonly form?: any;
}

const ResendPaymentPlanApprovalPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    filterPayload,
    dispatchAction,
    form
}: IProps) => {
    const { getFieldDecorator, getFieldInstance, getFieldValue, setFieldsValue, resetFields } = form;
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const customerLabel = useSelector(getCustomerUILabel);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    /**
     * Function called when `Cancel` button is clicked inside resend payment plan approval panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        setSubmitLoading(true);

        if (dispatchAction) {
            dispatch(
                dispatchAction({
                    ...filterPayload,
                    selectedContact: selectedItems.length > 0 ? selectedItems.reduce((acc, curr) => acc + curr, 0) : undefined,
                    callback: resendPaymentPlanApprovalResponseModal,
                })
            );
        } else {
            if (closePanel) closePanel(true);
        }
    };

    const resendPaymentPlanApprovalResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Requested resending payment plan approval content successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText('Failed to resend payment plan approval content!');
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('ok')
            });
        }
    };

    const handleChange = (values: any) => {
        const numberValues = values.map(Number);
        let updatedValues: number[] = [];
        let unselected: boolean = false;

        // unselected the list item
        if (!numberValues.includes(8)) {
            if (selectedItems.some(item => !numberValues.includes(item))) {
                updatedValues = numberValues;
                unselected = true;
            }
        }

        if (!unselected) {
            numberValues.forEach((value: number) => {
                const intValue = value;

                if (intValue === 8) {
                    updatedValues = selectedItems.includes(8) ? [] : [8];
                } else {
                    updatedValues = selectedItems.includes(intValue)
                        ? selectedItems.filter((item) => item !== intValue)
                        : [...selectedItems.filter((item) => item !== 8), intValue];
                }
            });
        }

        setSelectedItems(updatedValues);
    };

    useEffect(() => {
        if (getFieldInstance('ContactMethod')) {
            const currentValue = getFieldValue('ContactMethod') || [];
            const newValue = selectedItems.map(String);
    
            if (currentValue.join(',') !== newValue.join(',')) {
                setFieldsValue({
                    ContactMethod: newValue
                });
            }
        }
    }, [form, selectedItems]);

    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24}>
                        <FormItem  labelAlign='left' label={getTranslatedText("Contact Method")} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                {getFieldDecorator('ContactMethod', {
                                    initialValue: selectedItems.map(String)
                                })(
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder={getTranslatedText('Select communication method')}
                                        onChange={handleChange}
                                    >
                                        {ContactOptions.filter(co => co.label !== 'Postal address').map(contact => (
                                            <Option key={contact.value} value={String(contact.value)}>{getTranslatedText(contact.label)}</Option>
                                        ))}
                                    </Select>
                                )}
                        </FormItem>
                        <div>
                            {getTranslatedText(`You are about to resend approval content to the ${toLower(customerLabel)}(s) associated with the selected payment plans.`)}
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Submit')}
                        </Button>
                        <Button onClick={handleClosePanel}>Cancel</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Processing")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while processing the request . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const ResendPaymentPlanApprovalPanelForm = Form.create({
    name: 'resend-payment-plan-approval-content-form',
})(ResendPaymentPlanApprovalPanel);

export default withRouter(ResendPaymentPlanApprovalPanelForm);

import API, { graphqlOperation } from '@aws-amplify/api';
import { Modal } from 'antd';
import { get, includes, isEmpty, map } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    takeEvery,
    takeLatest,
    select
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    IODM_COMPANY_STORAGE_NAME,
    IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { defaultGuidValue } from '../../constants/common';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch, getRegionallyGraphqlResponse, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { Company, CompanyNotificationCustomization, CompanyUserRole } from '../companies/types';
import {
    fetchNotificationDetailsPublicAction,
    getCategorizedNotificationsErrorAction,
    getCategorizedNotificationsSuccessAction,
    getNotificationsByCategoryErrorAction,
    getNotificationsByCategorySuccessAction,
    getProductAnnouncementsRequestAction,
    getUnreadNotificationsForUserRequestAction,
    getUnreadNotificationsForUserSuccessAction,
    payUsingCreditsRequestAction,
    triggerRefetchNotificationsBooleanAction,
} from './actions';
import { NotificationsActionTypes } from './types';
import { getOrganisationActionUrl } from '../organisations/sagas';

/**
 * Selector for getting the count of unread notifications.
 * @param state
 */
export const getUnreadNotificationsCount = (state: ApplicationState) => {
    return !isEmpty(state.notifications.unreadNotificationCategories)
        ? state.notifications.unreadNotificationCategories.length
        : 0;
};

/**
 * Function for getting the 1st drawer data of notifications panel from API.
 * @param param0
 */
function* handleGetCategorizedNotificationsRequest({
    payload: sagaPayload,
}: any) {
    const { refetchNewNotif, callback, noState } = sagaPayload;
    const errorMessage =
        'Error fetching categorized notifications list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CATEGORIZED_NOTIFICATIONS_FOR_USER)
        );

        const { Notifications } = get(
            res.data,
            'GetCategorizedNotificationsForUser'
        );

        const responsePayload = {
            data: Notifications,
            refetchNewNotif,
        };

        if (callback) {
            callback(Notifications);
        }

        if (!noState) {
            yield put(getUnreadNotificationsForUserRequestAction());
            yield put(
                getCategorizedNotificationsSuccessAction(responsePayload)
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCategorizedNotificationsErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the 2nd drawer data of notifications panel from API.
 * @param param0
 */
function* handleGetNotificationsByCategoryRequest({
    payload: categoryId,
}: any) {
    const errorMessage =
        'Error fetching notifications by category list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_NOTIFICATIONS_BY_CATEGORY_FOR_USER, {
                CategoryId: categoryId,
            })
        );

        const { Notifications } = get(
            res.data,
            'GetNotificationsByCategoryForUser'
        );
        const responsePayload = {
            data: Notifications,
        };

        yield put(getNotificationsByCategorySuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getNotificationsByCategoryErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the new notifications list from API.
 * @param param0
 */
function* handleFetchNewNotificationsRequest({ payload: sagaPayload }: any) {
    const { latestNotificationDateTime, callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_NEW_NOTIFICATIONS_FOR_USER, {
                CreatedDateTime: latestNotificationDateTime || null,
            })
        );

        const { Notifications } = get(res.data, 'GetNewNotificationsForUser');
        const responsePayload: DynamicObject = {
            data: Notifications,
            IsSuccess: true,
        };

        yield put(triggerRefetchNotificationsBooleanAction(false));
        if (callback) callback(responsePayload);
    } catch (err) {
        yield put(triggerRefetchNotificationsBooleanAction(false));
        if (callback)
            callback({
                data: [],
                IsSuccess: false,
            });
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

let refetchCount = 0;
/**
 * Function for getting the data needed to populate the public Notification details page from API.
 * @param param0
 */
function* handleFetchNotificationDetailsPublic({ payload: sagaPayload }: any) {
    const {
        notificationId,
        applyDelay,
        isPaymentPlanEnabled,
        invoicesAndCreditsOnly,
        callback,
    } = sagaPayload;
    try {
        if (applyDelay) yield delay(3000); // delay for 3 seconds
        // To call async functions, use redux-saga's `call()`.

        // preview notification page check
        let res: DynamicObject
        if (notificationId === defaultGuidValue) {
            if (invoicesAndCreditsOnly) {
                const jsonResponse: DynamicObject = yield call(
                    fetch,
                    `/notification-preview-invoices-credits-data.json`
                );
                res = yield jsonResponse.json();
            } else {
                const companyUserRole: CompanyUserRole = JSON.parse(localStorage.getItem(IODM_COMPANY_STORAGE_NAME) || '{}');
                const company: Company = get(companyUserRole, 'Company');
                const previewCustomization: CompanyNotificationCustomization = JSON.parse(localStorage.getItem(IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME) || '{}');

                Object.assign(company, {
                    NotificationCustomization: previewCustomization
                });

                res = {
                    "data": {
                        "GetNotificationCommunicationDetail": {
                            "Contents": [
                                { "TemplateS3Key": "EmailSubject.txt", "File": { "Id": defaultGuidValue } },
                                { "TemplateS3Key": "EmailBody.html", "File": { "Id": defaultGuidValue } },
                                { "TemplateS3Key": "SMS.txt", "File": { "Id": defaultGuidValue } },
                                { "TemplateS3Key": "Letter.html", "File": { "Id": defaultGuidValue } },
                            ],
                            "Customer": {
                                "Id": defaultGuidValue,
                                "CustomerCode": "SAMPLE CUSTOMER CODE",
                                "DisplayName": "A Sample Customer"
                            },
                            "Company": company
                        }
                    }
                };
            }
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_NOTIFICATION_COMMUNICATION_DETAIL, {
                    CommunicationId: notificationId || null,
                    AllInvoices: false,
                    PaymentPlanEnabled: isPaymentPlanEnabled,
                    InvoicesAndCreditsOnly: invoicesAndCreditsOnly,
                })
            );
        }

        res.IsSuccess = true;
        refetchCount = 0;
        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                fetchNotificationDetailsPublicAction(
                    notificationId,
                    applyDelay,
                    isPaymentPlanEnabled,
                    invoicesAndCreditsOnly,
                    callback
                )
            );
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function for getting the list of unread notifications for the user logged in from API.
 */
function* handleGetUnreadNotificationsForUserRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(
                queries.GET_UNREAD_NOTIFICATION_CATEGORIES_FOR_USER
            )
        );

        const unreadNotificationCategories = get(
            res.data,
            'GetUnreadNotificationCategoriesForUser'
        );

        yield put(
            getUnreadNotificationsForUserSuccessAction(
                unreadNotificationCategories
            )
        );
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the presigned url for the letter found in Notification details page from API.
 * @param param0
 */
function* handleGetNotificationLetterPresignedUrl({
    payload: sagaPayload,
}: any) {
    const { fileId, callback } = sagaPayload;
    try {
        let apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_FILE_LOCATION,
            {
                FileId: fileId || null,
            }
        );

        res.IsSuccess = true;
        if (res.data.GetFileLocation) {
            res.data.GetFileLocation = get(res, 'data.GetFileLocation');
        }
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the options for Step 1 in Inform Us panel from API.
 * @param param0
 */
function* handleGetTicketOptionsRequest({ payload: sagaPayload }: any) {
    const { companyId, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TICKET_OPTIONS_FOR_COMPANY, {
                CompanyId: companyId,
            })
        );

        const { TicketOptions } = get(res, 'data.GetTicketOptionsForCompany');
        if (callback) callback(TicketOptions);
    } catch (err) {
        const onCloseCallback = () => {
            if (callback) callback([]);
        };
        
        Modal.error({
            title: getTranslatedText("Error"),
            content: getTranslatedText("Failed to fetch ticket options!"),
            onOk: onCloseCallback,
            onCancel: onCloseCallback,
            okText: getTranslatedText("OK")
        });
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when submitting the query using Inform Us panel to the API.
 * @param param0
 */
function* handleRaiseQueryRequest({ payload: sagaPayload }: any) {
    const {
        InvoiceIds,
        CustomerId,
        CompanyId,
        TicketOptionId,
        WorkflowId,
        CommunicationId,
        Details,
        callback,
    } = sagaPayload;

    const payload: DynamicObject = {
        CompanyId,
        WorkflowId,
        TicketOption: {
            Id: TicketOptionId,
        },
        Invoices: map(InvoiceIds, (Id) => ({
            Id,
        })),
        Customer: {
            Id: CustomerId,
        },
        Details,
        Communication: {
            Id: CommunicationId,
        },
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/ticket',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(`Ticket has been sent successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for setting the notifications as read via an API.
 * @param param0
 */
function* handleMarkNotificationAsRead({ payload: sagaPayload }: any) {
    const { categoryId, notificationId, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.UPDATE_NOTIFICATION_IS_READ_FLAG, {
                CategoryId: categoryId,
                NotificationId: notificationId,
            })
        );
        const { UpdateNotificationIsReadFlag } = res.data;
        let successMessages: string[] = [];
        if (UpdateNotificationIsReadFlag) {
            successMessages.push(
                `Notification has been marked as read successfully!`
            );
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called when connecting `Pay using credits` to the API.
 * @param param0
 */
function* handlePayUsingCreditsRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/credit/paynow',
            {
                body: payload,
            }
        );

        refetchCount = 0;
        if (callback) {
            if (isEmpty(get(res, 'Messages'))) {
                const response = {
                    ...res,
                    IsSuccess: true,
                };
                callback(response);
            } else {
                throw new Error('Failed');
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(payUsingCreditsRequestAction(payload, callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                }

                const firstErrorMessage = get(returnData, 'Messages.0', '');
                if (
                    includes(firstErrorMessage, 'No credits to allocate') ||
                    includes(
                        firstErrorMessage,
                        'No invoices to allocate credits to'
                    )
                ) {
                    returnData.IsSuccess = true;
                } else {
                    returnData.IsSuccess = false;
                }
                callback(returnData);
            }
        }
    }
}

/**
 * Function for getting the payment details of company publicly.
 * @param param0
 */
function* handleGetPaymentDataPublicRequest({ payload: sagaPayload }: any) {
    const { paymentId, companyId, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_DETAILS_FOR_COMPANY_PUBLIC, {
                PaymentId: paymentId,
                CompanyId: companyId,
            })
        );

        const Payment = get(res.data, 'GetPublicPaymentDetails');

        if (callback) callback(Payment);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that connects to fetching the products announcement list between the given dates.
 */
function* handleGetProductAnnouncementsRequest({ payload: sagaPayload }: any) {
    const { minDate, maxDate, callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PRODUCT_ANNOUNCEMENTS, {
                PublishDateTimeMin: minDate,
                PublishDateTimeMax: maxDate,
                Skip: 0,
                PageSize: 1000,
            })
        );

        const { ProductAnnouncements } = get(
            res.data,
            'GetProductAnnouncements'
        );
        refetchCount = 0;
        const responsePayload = {
            data: ProductAnnouncements,
            IsSuccess: true,
        };
        callback(responsePayload);
    } catch (err) {
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getProductAnnouncementsRequestAction(minDate, maxDate, callback)
            );
        } else {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

/**
 * Function called for getting the workflow step options for task editing.
 * @param param0
 */
 function* handleGetCorpayCurrenciesForCompanyRequest({ payload: sagaPayload }: any) {
    try {
        const { companyId, callback } = sagaPayload;
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CORPAY_CURRENCIES_FOR_COMPANY, {
                CompanyId: companyId,
            })
        );

        const corpayCurrencies = get(res.data, 'GetCorpayCurrenciesForCompany');

        if (callback) callback(corpayCurrencies);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetCategorizedNotificationsRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_CATEGORIZED_NOTIFICATIONS_REQUEST,
        handleGetCategorizedNotificationsRequest
    );
}

function* watchGetNotificationsByCategoryRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_NOTIFICATIONS_BY_CATEGORY_REQUEST,
        handleGetNotificationsByCategoryRequest
    );
}

function* watchFetchNewNotificationsRequest() {
    yield takeLatest(
        NotificationsActionTypes.FETCH_NEW_NOTIFICATIONS,
        handleFetchNewNotificationsRequest
    );
}

function* watchFetchNotificationDetailsPublic() {
    yield takeEvery(
        NotificationsActionTypes.FETCH_NOTIFICATIONS_DETAILS_PUBLIC,
        handleFetchNotificationDetailsPublic
    );
}

function* watchGetUnreadNotificationsForUserRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_FOR_USER_REQUEST,
        handleGetUnreadNotificationsForUserRequest
    );
}

function* watchGetNotificationLetterPresignedUrl() {
    yield takeLatest(
        NotificationsActionTypes.GET_NOTIFICATION_LETTER_PRESIGNED_URL,
        handleGetNotificationLetterPresignedUrl
    );
}

function* watchGetTicketOptionsRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_TICKET_OPTIONS_REQUEST,
        handleGetTicketOptionsRequest
    );
}

function* watchRaiseQueryRequest() {
    yield takeLatest(
        NotificationsActionTypes.RAISE_QUERY_REQUEST,
        handleRaiseQueryRequest
    );
}

function* watchMarkNotificationAsRead() {
    yield takeLatest(
        NotificationsActionTypes.MARK_NOTIFICATION_AS_READ,
        handleMarkNotificationAsRead
    );
}

function* watchPayUsingCreditsRequest() {
    yield takeLatest(
        NotificationsActionTypes.PAY_USING_CREDITS,
        handlePayUsingCreditsRequest
    );
}

function* watchGetPaymentDataPublicRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_PAYMENT_DATA_PUBLIC_REQUEST,
        handleGetPaymentDataPublicRequest
    );
}

function* watchGetProductAnnouncementsRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_PRODUCT_ANNOUNCEMENTS_REQUEST,
        handleGetProductAnnouncementsRequest
    );
}

function* watchGetCorpayCurrenciesForCompanyRequest() {
    yield takeLatest(
        NotificationsActionTypes.GET_CORPAY_CURRENCIES_FOR_COMPANY_REQUEST,
        handleGetCorpayCurrenciesForCompanyRequest
    );
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* notificationsSaga() {
    yield all([
        fork(watchGetCategorizedNotificationsRequest),
        fork(watchGetNotificationsByCategoryRequest),
        fork(watchFetchNewNotificationsRequest),
        fork(watchFetchNotificationDetailsPublic),
        fork(watchGetUnreadNotificationsForUserRequest),
        fork(watchGetNotificationLetterPresignedUrl),
        fork(watchGetTicketOptionsRequest),
        fork(watchRaiseQueryRequest),
        fork(watchMarkNotificationAsRead),
        fork(watchPayUsingCreditsRequest),
        fork(watchGetPaymentDataPublicRequest),
        fork(watchGetProductAnnouncementsRequest),
        fork(watchGetCorpayCurrenciesForCompanyRequest)
    ]);
}

export default notificationsSaga;

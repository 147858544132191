import { action } from 'typesafe-actions';
import {
    DeleteWorkflowsRequestPayload,
    GetWorkflowConfigurationsRequestPayload,
    GetWorkflowDefinitionRequestPayload,
    UpdateWorkflowRequestPayload,
    WorkflowActionTypes,
    WorkflowDefinition,
} from './types';
import { DynamicObject, ResponseModalObject } from '../../utils/commonInterfaces';

export const getWorkflowConfigurationsRequestAction = (
    payload: GetWorkflowConfigurationsRequestPayload
) => action(WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_REQUEST, payload);
export const getWorkflowConfigurationsSuccessAction = (payload: {
    data: DynamicObject[];
}) => action(WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_SUCCESS, payload);
export const getWorkflowConfigurationsErrorAction = (payload: string[]) =>
    action(WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_ERROR, payload);

/**
 * Actions for fetching the workflow definition list.
 * @param payload
 */
export const getWorkflowDefinitionRequestAction = (payload: GetWorkflowDefinitionRequestPayload) =>
    action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST, payload);

export const getWorkflowDefinitionSuccessAction = (payload: WorkflowDefinition) =>
    action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_SUCCESS, payload);

export const getWorkflowDefinitionErrorAction = (payload: string[]) =>
    action(WorkflowActionTypes.GET_WORKFLOW_DEFINITION_ERROR, payload);

// Single Record
/**
 * Action sfor setting the selected workflow Id when clicking on a workflow table row item.
 * @param workflowId
 * @param callback
 */
export const setWorkflowSelectedIdRequestAction = (
    workflowId: string | null,
    callback: () => void
) =>
    action(WorkflowActionTypes.SET_WORKFLOW_SELECTED_ID_REQUEST, {
        workflowId,
        callback,
    });

export const setWorkflowSelectedIdSuccessAction = (workflowId: string | null) =>
    action(WorkflowActionTypes.SET_WORKFLOW_SELECTED_ID_SUCCESS, workflowId);

/**
 * Actions called for saving workflow definition data.
 * @param payload
 * @param callback - function called after saving - useful for refetching the workflow definition
 * and showing alert modals
 */
export const saveWorkflowDefinitionConfigurationRequestAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST, {
        payload,
        callback,
    });

export const clearWorkflowActiveDataAction = () =>
    action(WorkflowActionTypes.CLEAR_WORKFLOW_ACTIVE_DATA);

export const clearWorkflowsStateAction = () =>
    action(WorkflowActionTypes.CLEAR_WORKFLOW_STATES);

export const updateWorkflowRequestAction = (
    payload: UpdateWorkflowRequestPayload,
    isCreateNew: boolean,
    callback?: (response: ResponseModalObject) => void
) =>
    action(WorkflowActionTypes.UPDATE_WORKFLOW_REQUEST, {
        payload,
        isCreateNew,
        callback,
    });

/**
 * Actions for delete workflows.
 * @param payload
 */
export const deleteWorkflowsRequestAction = (payload: DeleteWorkflowsRequestPayload) =>
    action(WorkflowActionTypes.DELETE_WORKFLOWS_REQUEST, payload);

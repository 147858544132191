/**
 * Responsible for the content of create payment plan config .
 */

import { Button, Col, Form, Row, DatePicker, InputNumber, Select, Tabs, Card, Input, Tooltip, Icon, Checkbox } from 'antd';
import { get, map, unset, isEmpty, filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    paymentPlanConfigTypeOptions,
    paymentPlanConfigStartTimingOptions,
    INSTALMENT_TYPE,
    FORM_FIELDS,
    TAB_NAME,
    FORM_DISPLAY
} from '../../constants/paymentPlanConfigs'
import { dateFormatDDMMMYYYYSpace, dateFormatDDMMYYYYSlash } from '../../constants/dateFormats';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { limitOnlyNumber, getLoadingSkeletons, roundNumberToDecimalDigits, getTranslatedText } from '../../utils/commonFunctions';
import moment from 'moment-timezone';
import { Company } from '../../store/companies/types';
import { PaymentPlansState } from '../../store/paymentPlans/types';
import CustomFieldsTabContent from '../../components/common/CustomFieldsTabContent';
import { ApplicationState } from '../../store';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FontAwesome from '../common/FontAwesome';

const { Option } = Select;
const { TabPane } = Tabs;
const { Item: FormItem } = Form;

interface IProps {
    readonly form?: any;
    readonly setPaymentPlanConfigFormState: any;
    readonly paymentPlanConfigFormState: any;
    readonly setFormHasChanges: () => void;
    readonly paymentPlanOptionIndex: any;
    readonly selectedUserCompany: Company;
    readonly isTemplate?: boolean;
}

const CreatePaymentPlanConfigTabContent: React.FC<IProps> = ({
    form,
    setPaymentPlanConfigFormState,
    paymentPlanConfigFormState,
    setFormHasChanges,
    paymentPlanOptionIndex,
    selectedUserCompany,
    isTemplate
}: IProps) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = form;
    const { RangePicker } = DatePicker;
    const paymentPlansState: PaymentPlansState = useSelector(
        (state: ApplicationState) => state.paymentPlans
    );
    const { loading } = paymentPlansState;

    /**
      * Function that concatanate field names.
      */
    const generateFields = (fieldName: string, fieldIndex?: number | null) => {
        let field = `${fieldName}${paymentPlanOptionIndex}`;
        if (fieldIndex != null) {
            field = `${field}${fieldIndex}`;
        }

        return field;
    };

    //constants
    const customFieldsTabName: string = generateFields(TAB_NAME.CUSTOM_FIELDS);
    const selectedCustomFieldTabName: string = generateFields(TAB_NAME.SELECTED_CUSTOM_FIELD);
    const customFieldTabIdName: string = generateFields(TAB_NAME.CUSTOM_FIELD_ID);
    const customFieldTabKeysName: string = generateFields(TAB_NAME.CUSTOM_FIELD_KEYS);
    const firstDateOfCurrentMonth = () => {
        const current = moment(); // Get the current date
        current.date(1); // Set the date to the first of the month
        return current.format(dateFormatDDMMMYYYYSpace); // This will return a Date object representing the first day of the current month
    };
    const instalmentType: string = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.INSTALMENT_TYPE));

    //states
    const [instalmentTypeDescription, setInstalmentTypeDescription] = useState<string>();

    const updateFormStateObject = (name: string, val: any, hasChanges?: boolean | false) => {
        setPaymentPlanConfigFormState((prev: any) => ({
            ...prev,
            [name]: val
        }));

        if (hasChanges) {
            setFormHasChanges();
        }
    };

    /**
        * Function that updates the form state and which form field will be updated.
        * @param name - field name
        * @param valueIdentifier - string, either value or checked, or others, based on e.target.value
        */
    const updateFormState: any = (
        name: string
    ) => {
        return (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            let correctValue =
                get(e, `target.value`) ||
                get(e, `target.value`);

            if (correctValue == undefined) {
                correctValue = e;
            }

            updateFormStateObject(name, correctValue, true);
        };
    };

    /**
  * Function that populates the dropdown options for custom day option.
  */
    const populateCustomDayChildrenOptions = () =>
        map(
            Array.from({ length: 31 }, (_, i) => i + 1),
            i => (
                <Option key={i} value={i}>
                    {i}
                </Option>
            )
        );

    /**
      * Function that populates the accounting system dropdown options.
      */
    const populatePaymentPlanConfigTypeOptions = () => {
        return map(
            paymentPlanConfigTypeOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {getTranslatedText(label)}
                </Option>
            )
        );
    };

    /**
      * Function that populates the accounting system dropdown options.
      */
    const populatePaymentPlanConfigStartTimingOptions = () => {
        return map(
            paymentPlanConfigStartTimingOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {getTranslatedText(label)}
                </Option>
            )
        );
    };

    useEffect(() => {

        switch (instalmentType) {

            case INSTALMENT_TYPE.CUSTOM:
                setInstalmentTypeDescription(getTranslatedText("The custom type is for payment schedule dates that are specific."));
                break;
            case INSTALMENT_TYPE.DATE_RANGE:
                setInstalmentTypeDescription(getTranslatedText("The date range type allows you to generate payment schedule dates based on the number of instalments."));
                break;
            case INSTALMENT_TYPE.MONTHLY:
                setInstalmentTypeDescription(getTranslatedText("The monthly type is for creating payment schedule dates based on the invoice creation, overdue, or requested dates."));
                break;
            case INSTALMENT_TYPE.PAYMENT_AMOUNT:
                setInstalmentTypeDescription(getTranslatedText("The payment amount type is for monthly payment schedule dates that will calculate the number of installments based on the invoice amount and the instalment amount the customer will pay."));
                break;
        }

    }, [paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)]]);

    useEffect(() => {

        let displayDates: any[] = [];//ui display
        let formDates: any[] = [];//save format
        let assignMonthDate: any = (date: any, day: number) => {
            const endOfMonth = moment(date).endOf('month').date();

            if (day > endOfMonth) return endOfMonth;

            return monthDay;
        }

        let instalments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let splitInstalmentPeriod: RangePickerValue | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE));
        if (instalments && splitInstalmentPeriod && splitInstalmentPeriod[0] && splitInstalmentPeriod[1]) {
            const startDate = splitInstalmentPeriod[0].toDate();
            const endDate = splitInstalmentPeriod[1].toDate();
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalments;

            // Arrays to store the unique dates
            const displayDatesSet = new Set();
            const formDatesSet = new Set();

            for (let i = 1; i <= instalments; i++) {
                // Calculate the date
                const newDate = new Date(startDate.getTime() + i * interval);

                // Format the dates in the desired formats
                const displayDate = moment(newDate).format(dateFormatDDMMMYYYYSpace);
                const formDate = moment(newDate).format(dateFormatDDMMYYYYSlash);

                // Add to the set to ensure uniqueness
                displayDatesSet.add(displayDate);
                formDatesSet.add(formDate);
            }

            // Convert sets to arrays
            const numberOfDays = moment(splitInstalmentPeriod[1]).diff(moment(splitInstalmentPeriod[0]), 'days');
            if (instalments <= numberOfDays) {
                displayDates = Array.from(displayDatesSet);
                formDates = Array.from(formDatesSet);
            }

            updateFormStateObject(generateFields(FORM_DISPLAY.INSTALMENT_MAX), numberOfDays);
        }

        let monthDay: number | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY));
        let startDelay: number | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.START_DELAY));
        let frequencyGap: number | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.FREQUENCY_GAP));
        if (instalmentType == INSTALMENT_TYPE.MONTHLY && monthDay && startDelay && frequencyGap && instalments) {
            let currentDate = moment(firstDateOfCurrentMonth());
            let startMonth: number = startDelay;
            let saveDate: any;

            for (let i = 1; i <= instalments; i++) {
                if (!isEmpty(saveDate))
                    saveDate = saveDate.add(frequencyGap, 'months').date(assignMonthDate(saveDate, monthDay));
                else
                    saveDate = currentDate.add(startMonth, 'months').date(assignMonthDate(currentDate, monthDay));

                displayDates.push(saveDate.format(dateFormatDDMMMYYYYSpace));
                formDates.push(saveDate.format(dateFormatDDMMYYYYSlash));
            }


        }

        let installmentAmount: number | undefined = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.INSTALMENT_AMOUNT));
        if (instalmentType == INSTALMENT_TYPE.PAYMENT_AMOUNT && monthDay && startDelay && frequencyGap && installmentAmount) {
            let currentDate = moment(firstDateOfCurrentMonth());
            let startMonth: number = startDelay;
            let saveDate: any;

            let numberOfInstallment: number = Number(roundNumberToDecimalDigits(1000 / installmentAmount, 0));
            let finalInstallmentAmount: number = 1000 / numberOfInstallment;//apply it for oayment amount type

            for (let i = 1; i <= numberOfInstallment; i++) {
                if (!isEmpty(saveDate))
                    saveDate = saveDate.add(frequencyGap, 'months').date(monthDay);
                else
                    saveDate = currentDate.add(startMonth, 'months').date(monthDay);

                //instalment amount is not finalize
                //it can be finalInstallmentAmount result
                //or we can add another schedule date for the remainder
                displayDates.push(saveDate.format(dateFormatDDMMMYYYYSpace) + ` - $${numberOfInstallment}`);
                formDates.push(saveDate.format(dateFormatDDMMYYYYSlash));
            }
        }


        //set schedule dates
        if (displayDates.length > 0 && formDates.length > 0) {
            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATES), formDates);
            updateFormStateObject(generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY), displayDates);
        }

    }, [
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_AMOUNT)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_DELAY)],
        paymentPlanConfigFormState[generateFields(FORM_FIELDS.FREQUENCY_GAP)]
    ]);

    useEffect(() => {
        if (paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)] == false) {
            let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
            if (numberOfInstalment) {
                for (var i = 0; i < numberOfInstalment; i++)
                    unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAY_PERCENT, i));
            }
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW));
        }

    }, [paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)]]);

    const resetPayPercentValues = () => {
        let addPayPercent: boolean = get(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.ADD_PAY_PERCENT));
        if (addPayPercent) {
            let instalments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
            for (let i = 1; i <= instalments; i++) {
                setFieldsValue({ [generateFields(FORM_FIELDS.PAY_PERCENT, i - 1)]: null });
                updateFormStateObject(generateFields(FORM_FIELDS.PAY_PERCENT, i - 1), null);
            }

            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW), true);
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL), '');
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING), '');
        }
    }

    const resetPayPercent = (numberOfInstalment: number) => {
        //remove pay percent
        if (numberOfInstalment) {
            for (var i = 0; i < numberOfInstalment; i++)
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAY_PERCENT, i));

            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW));
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING));

            updateFormStateObject(generateFields(FORM_DISPLAY.ADD_PAY_PERCENT), false);
        }
    }

    const resetPayPercentMonthly = (latestNumberOfInstalment: number | null = null) => {
        let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        if (numberOfInstalment == 1 || latestNumberOfInstalment == 1)
            resetPayPercent(numberOfInstalment);
        else
            resetPayPercentValues();
    }

    function handleChangeInstalmentPeriod(dates: RangePickerValue) {

        //set state value
        updateFormStateObject(generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE), dates, true);

        let addPayPercent: boolean = get(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.ADD_PAY_PERCENT));
        if (addPayPercent) {
            let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
            for (var i = 0; i < numberOfInstalment; i++) {
                setFieldsValue({ [generateFields(FORM_FIELDS.PAY_PERCENT, i)]: null });
                updateFormStateObject(generateFields(FORM_FIELDS.PAY_PERCENT, i), null);
            }

            //reset pay percent preview
            unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY));
        }

        //hide pay percent preview and reset values
        if (addPayPercent) {
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW), true);
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL), '');
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING), '');
        }
    }

    function handleChangeInstalments(instalment: number | undefined) {
        if (instalment && instalment >= paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MIN)] && instalment <= 30) {
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), instalment, true);
            resetPayPercentMonthly(instalment);
        } else
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), null, true);

    }

    function handleSelectCustomPaymentDay(customPaymentDay: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY), customPaymentDay, true);
        resetPayPercentMonthly();
    }

    function handleFrequencyGap(value: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.FREQUENCY_GAP), value, true);
        resetPayPercentMonthly();
    }

    function handleStartDelay(value: any) {
        updateFormStateObject(generateFields(FORM_FIELDS.START_DELAY), value, true);
        resetPayPercentMonthly();
    }
    function handleSelectPaymentPlanConfigTypeSelect(paymentPlanConfigType: any) {

        //unset fields states
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATES));
        unset(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY));

        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE));

        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_AMOUNT));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.DEPOSIT_GAP));

        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.START_DELAY));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.START_TIMING));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.FREQUENCY_GAP));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUT_OFF_DATE));

        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.INSTALMENT_AMOUNT));


        let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));

        resetPayPercent(numberOfInstalment);

        //remove custom field state
        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        if (keys && keys.length > 0) {
            map(keys, (key) => {
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, key));
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, key));
            });
        }


        //remove  schedule dates state
        let scheduleDatesKey: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        if (scheduleDatesKey && scheduleDatesKey.length > 0) {
            map(scheduleDatesKey, (key) => {
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE, key));
            });

            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), []);
            unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        }

        //init custom field tabs
        updateFormStateObject(customFieldTabIdName, 0);
        updateFormStateObject(customFieldsTabName, []);
        updateFormStateObject(customFieldTabKeysName, []);
        updateFormStateObject(selectedCustomFieldTabName, '');

        //set minimum number of installment
        let minimumInstalment: number = paymentPlanConfigType == INSTALMENT_TYPE.DATE_RANGE ? 2 : 1;
        updateFormStateObject(generateFields(FORM_DISPLAY.INSTALMENT_MIN), minimumInstalment);

        //set value
        updateFormStateObject(generateFields(FORM_FIELDS.INSTALMENT_TYPE), paymentPlanConfigType);
        if (paymentPlanConfigType == INSTALMENT_TYPE.CUSTOM) {

            //set default number of installment
            updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), minimumInstalment);
            setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: minimumInstalment });

            //set default custom schedule date
            let keys: number[] = [];
            for (var i = 1; i < minimumInstalment + 1; i++) keys.push(i);
            updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), keys);
        } else {
            resetFields(
                generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)
            );
        }
    }

    function handlePayPercent(percent: any, index: number) {
        if (percent == 0) {
            unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAY_PERCENT, index));
        } else {
            //set field value
            updateFormStateObject(generateFields(FORM_FIELDS.PAY_PERCENT, index), percent, true);
        }

        let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let scheduleDatesDisplay: string[] = [];

        if (instalmentType == INSTALMENT_TYPE.CUSTOM) {
            for (var i = 0; i < numberOfInstalment; i++) {
                let key: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
                let scheduleDate = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE, key[i]));

                if (!isEmpty(scheduleDate)) {
                    scheduleDatesDisplay.push(scheduleDate.format(dateFormatDDMMYYYYSlash));
                }
            }
        } else
            scheduleDatesDisplay = get(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY));

        let newScheduleDatesDisplay: string[] = [];
        let total: number = 0;
        for (var i = 0; i < numberOfInstalment; i++) {

            //get latest value
            if (i == index && percent > 0) {
                total = total + percent;
                if (scheduleDatesDisplay) newScheduleDatesDisplay.push(`${scheduleDatesDisplay[index]} - ${percent}%`);
            }
            else {
                let value: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAY_PERCENT, i));
                if (value) {
                    total = total + value;
                    if (scheduleDatesDisplay) newScheduleDatesDisplay.push(`${scheduleDatesDisplay[i]} - ${value}%`);
                }
            }
        }

        //update display schedule dates
        if (scheduleDatesDisplay && newScheduleDatesDisplay.length > 0)
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY), newScheduleDatesDisplay);

        updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL), getTranslatedText("Total is") + ` ${total}%`);
        if (total != 100)
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING), getTranslatedText("The required total payment percentage has not been met."));
        else
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING), '');

        //allow you to display pay percent preview
        updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW), false);
    }

    function handleManualScheduleDate(date: any, index: number) {
        let scheduleDatesDisplay: string[] = [];
        let numberOfInstalment: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let key: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        let currentIndex: number = 0;

        for (var i = 0; i < numberOfInstalment; i++) {
            if (key[i] == index) {
                currentIndex = i;
                if (date)
                    scheduleDatesDisplay.push(date.format(dateFormatDDMMMYYYYSpace));
            }
            else {
                let scheduleDate = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE) + key[i]);
                if (scheduleDate)
                    scheduleDatesDisplay.push(scheduleDate.format(dateFormatDDMMMYYYYSpace));
            }

        }

        //update schedule date pay percent
        let addPayPercent: boolean = get(paymentPlanConfigFormState, generateFields(FORM_DISPLAY.ADD_PAY_PERCENT));
        if (addPayPercent) {
            setFieldsValue({ [generateFields(FORM_FIELDS.PAY_PERCENT, currentIndex)]: null });
            updateFormStateObject(generateFields(FORM_FIELDS.PAY_PERCENT, currentIndex), null);
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL), '');
            updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING), '');
        }

        //set field value
        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULED_DATE, index), date, true);

        //allow you to display pay percent preview
        updateFormStateObject(generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY), scheduleDatesDisplay);

        //hide pay percent display
        updateFormStateObject(generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW), true);
    }

    const populatePaymentPercent = () => {
        let listOfInstalment: number[] = [];
        let numberOfInstalment = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        for (var i = 0; i < numberOfInstalment; i++) {
            listOfInstalment.push(i);
        }

        const formItems = listOfInstalment.map((k: any) => (
            <div key={k} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                {k + 1}.&nbsp;<FormItem key={k}>
                    {getFieldDecorator(generateFields(FORM_FIELDS.PAY_PERCENT, k), {
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText("Payment percentage is required!"),
                            }
                        ],
                        initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.PAY_PERCENT, k)]
                    })(<InputNumber
                        min={1}
                        max={100}
                        onKeyDown={limitOnlyNumber(false)}
                        style={{ width: 100 }}
                        onChange={(val) => handlePayPercent(val, k)}
                        disabled={instalmentType == INSTALMENT_TYPE.DATE_RANGE && paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE)].length == 0 ? true : false}
                    />

                    )}
                </FormItem>&nbsp;%
            </div>
        ));

        return formItems;
    };

    const handlePayPercentCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {

        updateFormStateObject(generateFields(FORM_DISPLAY.ADD_PAY_PERCENT), e.target.checked, true);

        if (!e.target.checked) {
            let numberOfInstalment = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
            for (var i = 0; i < numberOfInstalment; i++)
                unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.PAY_PERCENT, i));
        }
    };

    const addPaymentPlanScheduleDate = () => {
        let numberOfInstallments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT));
        let newNumberOfInstallments: number = numberOfInstallments + 1;
        let keys: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        let newKey: number = keys[numberOfInstallments - 1] + 1//prevent duplicate key
        keys.push(newKey);

        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), keys);

        //update number of installments
        setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: newNumberOfInstallments });
        updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), newNumberOfInstallments);
    };

    const removeScheduleDate = (key: number) => {
        let keys: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        const newKeys = keys.filter((value) => value != key);

        //remove selected schedule date
        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULED_DATE, key), null);
        updateFormStateObject(generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS), newKeys);

        //update number of installments
        let numberOfInstallments: number = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)) - 1;//remove 1 instalment
        //remove pay percent if any
        if (numberOfInstallments == 1)
            resetPayPercent(numberOfInstallments);
        else
            resetPayPercentValues();

        //update number of instalment
        setFieldsValue({ [generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]: numberOfInstallments });
        updateFormStateObject(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), numberOfInstallments, true);
    };

    const populateAddedScheduleDates = () => {
        const scheduleDatesWarning: { ScheduleIndex: number, warning: string }[] = [];

        let scheduleDates: { date: string }[] = [];
        let keys: number[] = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS));
        for (var i = 0; i < keys.length; i++) {
            let scheduleDate = get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.SCHEDULED_DATE, keys[i]));
            if (!isEmpty(scheduleDate))
                scheduleDates[keys[i]] = { date: scheduleDate.format(dateFormatDDMMYYYYSlash) };
        }

        map(keys, (key: number) => {
            let dateKey = scheduleDates[key];

            if (dateKey) {
                const isDuplicateInDynamicScheduleDates = filter(scheduleDates, paymentPlanScheduleDate => paymentPlanScheduleDate && paymentPlanScheduleDate.date === dateKey.date).length > 1;

                if (isDuplicateInDynamicScheduleDates)
                    scheduleDatesWarning[key] = { ScheduleIndex: key, warning: getTranslatedText("This schedule date has been duplicated!") };
            }
        });

        const formItems = paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS)].map((k: number) => (
            <div key={k} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FormItem key={k}>
                    {getFieldDecorator(generateFields(FORM_FIELDS.SCHEDULED_DATE, k), {
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText("Scheduled dates required!"),
                            }
                        ],
                        initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULED_DATE, k)]
                    })(<DatePicker
                        format={dateFormatDDMMMYYYYSpace}
                        placeholder={getTranslatedText("Date")}
                        onChange={(val) => handleManualScheduleDate(val, k)}
                    />

                    )}
                </FormItem>
                {k > paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MIN)] ? (<Button type="link" style={{ marginLeft: 10 }} onClick={() => removeScheduleDate(k)}><FontAwesome icon={['fas', 'trash']} style={{ color: 'gray' }} /></Button>) : ''}
                {(scheduleDatesWarning[k]) && (
                    <Tooltip title={scheduleDatesWarning[k].warning}>
                        <FontAwesome
                            icon={['fas', 'info-circle']}
                            style={{ color: '#ff5d00', marginLeft: '5px' }}
                            className="fs-18"
                        />
                    </Tooltip>)}
            </div>
        ));

        return formItems;
    };

    const populateScheduleDates = () => {
        return (
            <>
                <Row className="mt-9">
                    <Col span={24}>
                        <Card>
                            <Col span={12}>
                                <h4>{getTranslatedText("Schedule dates:")}</h4>
                                <Button type="primary" onClick={() => addPaymentPlanScheduleDate()} disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)] >= 30}>{getTranslatedText("Add schedule dates")}</Button>
                            </Col>
                            <Col span={12}>
                                {
                                    paymentPlanConfigFormState[generateFields(FORM_FIELDS.SCHEDULE_DATE_KEYS)] ?
                                        populateAddedScheduleDates()
                                        : ''
                                }
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </>
        );
    }

    const addCustomFieldTab = () => {
        let customFieldsTabId: number = get(paymentPlanConfigFormState, customFieldTabIdName);
        let tabs: any[] = get(paymentPlanConfigFormState, customFieldsTabName);
        tabs.push({
            key: `custom-field-dynamic-${customFieldsTabId}`,
            title: getTranslatedText("Custom field") + " " + (customFieldsTabId + 1),
            component: CustomFieldsTabContent,
            closable: true
        });

        //add new tab
        updateFormStateObject(customFieldsTabName, tabs);


        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        keys.push(customFieldsTabId);

        //update details
        updateFormStateObject(customFieldTabKeysName, keys);
        updateFormStateObject(customFieldTabIdName, customFieldsTabId + 1);
        updateFormStateObject(selectedCustomFieldTabName, "custom-field-dynamic-" + customFieldsTabId);

        setFormHasChanges();
    };

    const removeCustomFieldTab = (targetKey: any) => {

        //remove fields
        const tabIndex = targetKey.split('-')[3];
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_NAME, tabIndex));
        unset(paymentPlanConfigFormState, generateFields(FORM_FIELDS.CUSTOM_FIELD_VALUE, tabIndex));

        //update state
        let keys: number[] = get(paymentPlanConfigFormState, customFieldTabKeysName);
        const newTabKeys = keys.filter((key) => key != tabIndex);
        updateFormStateObject(customFieldTabKeysName, newTabKeys);

        //remove selected custom field tab
        let tabs: any[] = get(paymentPlanConfigFormState, customFieldsTabName);
        const newTabs = tabs.filter((pane) => pane.key !== targetKey);
        updateFormStateObject(customFieldsTabName, newTabs);

        // If the active tab is being removed, switch to the next tab or the previous one
        if (get(paymentPlanConfigFormState, selectedCustomFieldTabName) === targetKey && newTabs.length > 0) {
            // If the tab to be removed is active, set the new active key 
            updateFormStateObject(selectedCustomFieldTabName, newTabs[0].key);
        }
    };

    const handleCustomFieldEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove") => {
        if (action === 'add') {
            addCustomFieldTab();  // Call the add function when action is 'add'
        } else if (action === 'remove') {
            removeCustomFieldTab(targetKey);
        }
    };

    /**
    * Function called when the active payment plan option tab (clicking on tab item - title)
    * @param value
    */
    const handleCustomFieldTabChange = (value: string) => {
        updateFormStateObject(selectedCustomFieldTabName, value);
    };

    const getLabelByValue = (value: string) => {
        const option = paymentPlanConfigStartTimingOptions.find(option => option.value === value);
        return option ? option.label : ''; // Returns null if no match is found
    }

    return (
        <>
            <Form className="form-inline-mb-0">
                <Row>
                    <Col span={8}>
                        <FormItem label={getTranslatedText("Name")}>
                            {getFieldDecorator(generateFields(FORM_FIELDS.NAME), {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.NAME)],
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Name is required!"),
                                    }
                                ]
                            })(<Input onChange={updateFormState(
                                generateFields(FORM_FIELDS.NAME), true
                            )} />)}
                        </FormItem>
                    </Col>

                </Row>

                <Row>
                    <Col span={8}>
                        <FormItem label={getTranslatedText("Instalment type")}
                            extra={instalmentType ?
                                (<i>{instalmentTypeDescription}</i>) : ''
                            }>
                            {getFieldDecorator(generateFields(FORM_FIELDS.INSTALMENT_TYPE), {
                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)],
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Instalment type is required!"),
                                    }
                                ]
                            })(<Select onChange={handleSelectPaymentPlanConfigTypeSelect}>
                                {populatePaymentPlanConfigTypeOptions()}
                            </Select>)}
                        </FormItem>
                    </Col>
                </Row>

                {instalmentType ?
                    (
                        <Row>
                            <Col span={12}>
                                <Card style={{ marginTop: 15 }}>

                                    {instalmentType == INSTALMENT_TYPE.DATE_RANGE ?
                                        (<Row><Col span={12}>
                                            <FormItem label={getTranslatedText("Instalment periods")}>
                                                {getFieldDecorator(generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE), {
                                                    initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE)],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Instalment periods required!"),
                                                        }
                                                    ]
                                                })(<RangePicker
                                                    format={dateFormatDDMMMYYYYSpace}
                                                    style={{ width: 325 }}
                                                    onChange={handleChangeInstalmentPeriod} />)}
                                            </FormItem>
                                        </Col></Row>) : ''
                                    }

                                    {instalmentType == INSTALMENT_TYPE.MONTHLY || instalmentType == INSTALMENT_TYPE.PAYMENT_AMOUNT ?
                                        (
                                            <>
                                                {instalmentType == INSTALMENT_TYPE.PAYMENT_AMOUNT ?
                                                    (
                                                        <>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <h3>{getTranslatedText("Sample invoice total amount: $1000")}</h3>
                                                                    <i style={{ color: 'red' }}>{getTranslatedText("Note: This amount may vary based on the actual total amount of the invoice")}</i>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col span={12}>
                                                                    <FormItem label={
                                                                        <span>
                                                                            {getTranslatedText("Installment amount")}&nbsp;
                                                                            <Tooltip title={getTranslatedText("It will be based on the invoice details, and for multiple invoices, it will be the earliest one.")}>
                                                                                <Icon type="question-circle-o" />
                                                                            </Tooltip>
                                                                        </span>
                                                                    }>
                                                                        {getFieldDecorator(generateFields(FORM_FIELDS.INSTALMENT_AMOUNT), {
                                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_AMOUNT)],
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Start timing is required!',
                                                                                }
                                                                            ]
                                                                        })(<InputNumber
                                                                            onKeyDown={limitOnlyNumber(false)}
                                                                            style={{ width: 325 }}
                                                                            onChange={updateFormState(
                                                                                generateFields(FORM_FIELDS.INSTALMENT_AMOUNT), true
                                                                            )}
                                                                            min={1}
                                                                        />)}
                                                                    </FormItem>
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    ) : ''
                                                }
                                                <Row>
                                                    <Col span={12}>
                                                        <FormItem label={
                                                            <span>
                                                                {getTranslatedText("Start timing")}&nbsp;
                                                                <Tooltip title={getTranslatedText("It will be based on the invoice details, and for multiple invoices, it will be the earliest one.")}>
                                                                    <Icon type="question-circle-o" />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                            extra={paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_TIMING)] ?
                                                                (<strong>{`${getTranslatedText("E.g. Earliest Invoice")} ${getTranslatedText(getLabelByValue(paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_TIMING)]))}: ${firstDateOfCurrentMonth()} `}</strong>) : ''
                                                            }>
                                                            {getFieldDecorator(generateFields(FORM_FIELDS.START_TIMING), {
                                                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_TIMING)],
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: getTranslatedText("Start timing is required!"),
                                                                    }
                                                                ]
                                                            })(<Select
                                                                onChange={updateFormState(
                                                                    generateFields(FORM_FIELDS.START_TIMING), true
                                                                )}
                                                                style={{ width: 325 }}
                                                            >
                                                                {populatePaymentPlanConfigStartTimingOptions()}
                                                            </Select>)}
                                                        </FormItem>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <FormItem label={
                                                            <span>
                                                                {getTranslatedText("Frequecy gap")}&nbsp;
                                                                <Tooltip title={getTranslatedText("Monthly gap between each payment date.")}>
                                                                    <Icon type="question-circle-o" />
                                                                </Tooltip>
                                                            </span>
                                                        }>
                                                            {getFieldDecorator(generateFields(FORM_FIELDS.FREQUENCY_GAP), {
                                                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.FREQUENCY_GAP)],
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: getTranslatedText("Frequency gap is required!"),
                                                                    }
                                                                ]
                                                            })(
                                                                <InputNumber
                                                                    min={1}
                                                                    max={100}
                                                                    onKeyDown={limitOnlyNumber(false)}
                                                                    style={{ width: 325 }}
                                                                    onChange={handleFrequencyGap}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <FormItem label={
                                                            <span>
                                                                {getTranslatedText("Month day")}&nbsp;
                                                                <Tooltip title={getTranslatedText("The current day of the month")}>
                                                                    <Icon type="question-circle-o" />
                                                                </Tooltip>
                                                            </span>
                                                        }>
                                                            {getFieldDecorator(generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY), {
                                                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.SPECIFIC_PAYMENT_DAY)],
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: getTranslatedText("Month Day required!"),
                                                                    }
                                                                ]
                                                            })(
                                                                <Select showSearch
                                                                    style={{ width: 325 }}
                                                                    onChange={handleSelectCustomPaymentDay} >
                                                                    {populateCustomDayChildrenOptions()}
                                                                </Select>)}
                                                        </FormItem>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <FormItem label={
                                                            <span>
                                                                {getTranslatedText("Start delay")}&nbsp;
                                                                <Tooltip title={getTranslatedText("Adding a delay to your start payment date")}>
                                                                    <Icon type="question-circle-o" />
                                                                </Tooltip>
                                                            </span>
                                                        }>
                                                            {getFieldDecorator(generateFields(FORM_FIELDS.START_DELAY), {
                                                                initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_DELAY)],
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: getTranslatedText("Start delay is required!"),
                                                                    }
                                                                ]
                                                            })(
                                                                <InputNumber
                                                                    min={1}
                                                                    max={12}//month
                                                                    onKeyDown={limitOnlyNumber(false)}
                                                                    style={{ width: 325 }}
                                                                    onChange={handleStartDelay}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </Col>
                                                </Row>

                                            </>
                                        ) : ''
                                    }

                                    {instalmentType == INSTALMENT_TYPE.CUSTOM ?
                                        populateScheduleDates()
                                        : ''
                                    }

                                    {instalmentType != INSTALMENT_TYPE.PAYMENT_AMOUNT ?
                                        (
                                            <Row>
                                                <Col span={12}>
                                                    {paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)] > paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MAX)] ?
                                                        (<span style={{ color: 'red' }}>{`${getTranslatedText("There are")} ${get(paymentPlanConfigFormState, generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT))} ${getTranslatedText("instalments but we only have")} ${paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MAX)]} ${getTranslatedText("days.")}`}</span>) : ''
                                                    }
                                                    <FormItem label={getTranslatedText("Number of instalments")}>
                                                        {getFieldDecorator(generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT), {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: getTranslatedText("Number of instalments required!")
                                                                },
                                                                {
                                                                    validator: (rule: any, value: any, callback: any) => {
                                                                        if (value && (value < paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MIN)] || value > 30)) {
                                                                            callback(`${getTranslatedText("The number of instalments should be between")} ${paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MIN)]} ${getTranslatedText("and 30.")}`);
                                                                        } else {
                                                                            callback();
                                                                        }
                                                                    }
                                                                }
                                                            ],
                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)]
                                                        })(
                                                            <InputNumber
                                                                min={paymentPlanConfigFormState[generateFields(FORM_DISPLAY.INSTALMENT_MIN)]}
                                                                max={30}
                                                                onChange={handleChangeInstalments}
                                                                onKeyDown={limitOnlyNumber(false)}
                                                                disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)] == INSTALMENT_TYPE.CUSTOM ? true : false}
                                                                style={{ width: 325 }}
                                                            />
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        ) : ''
                                    }

                                    {instalmentType != INSTALMENT_TYPE.MONTHLY ?
                                        (
                                            <Row>
                                                <Col span={12}>
                                                    <FormItem label={
                                                        <span>
                                                            {getTranslatedText("Cutoff date")}&nbsp;
                                                            <Tooltip title={getTranslatedText("Config that will not include if date have passed")}>
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }>
                                                        {getFieldDecorator(generateFields(FORM_FIELDS.CUT_OFF_DATE), {
                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.CUT_OFF_DATE)]
                                                        })(<DatePicker
                                                            format={dateFormatDDMMMYYYYSpace}
                                                            placeholder={getTranslatedText("Date")}
                                                            onChange={updateFormState(
                                                                generateFields(FORM_FIELDS.CUT_OFF_DATE), true
                                                            )}
                                                            style={{ width: 325 }}
                                                        />

                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        ) : ''
                                    }

                                    <Row className="mt-9">
                                        <Card>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={10}>
                                                        <h4>{getTranslatedText("Allocate Instalment Percentage:")}</h4>
                                                        <FormItem>
                                                            <Checkbox
                                                                onChange={handlePayPercentCheckboxClick}
                                                                checked={paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)]}
                                                                disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.NUMBER_OF_INSTALMENT)] == 1 ? true : false}
                                                            />
                                                        </FormItem>
                                                    </Col>
                                                    <Col span={8}>
                                                        {
                                                            paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)] && paymentPlanConfigFormState[generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY)] ?
                                                                populatePaymentPercent()
                                                                : ''
                                                        }
                                                    </Col>
                                                    <Col span={6}>
                                                        <strong>{paymentPlanConfigFormState[generateFields(FORM_DISPLAY.PAY_PERCENT_TOTAL)]}</strong>
                                                        <br />
                                                        <br />
                                                        <i style={{ color: 'red' }}>{paymentPlanConfigFormState[generateFields(FORM_DISPLAY.PAY_PERCENT_WARNING)]}</i>
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col span={24}>
                                                {paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)] ?
                                                    (<i style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{getTranslatedText("Note: Payment Percentage should have a total of 100%")}</i>) : ''
                                                }
                                            </Col>
                                        </Card>
                                    </Row>

                                    {
                                        /*
                                        //Request to remove by Graham
                                              <Row>
                                                <Col span={12}>
                                                    <FormItem label={
                                                        <span>
                                                            Deposit percentage&nbsp;
                                                            <Tooltip title="Deposit amount is calculated by percentage of invoice total amount">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }>
                                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE), {
                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE)]
                                                        })(
                                                            <InputNumber
                                                                min={1}
                                                                max={100}
                                                                onKeyDown={limitOnlyNumber(false)}
                                                                style={{ width: 325 }}
                                                                onChange={updateFormState(
                                                                    generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE), true
                                                                )}
                                                                disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_AMOUNT)] ? true : false}
                                                            />
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <FormItem label={
                                                        <span>
                                                            Deposit gap&nbsp;
                                                            <Tooltip title="Number of days that desposit will start to pay">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }>
                                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_GAP), {
                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_GAP)]
                                                        })(
                                                            <InputNumber
                                                                min={1}
                                                                max={100}
                                                                onKeyDown={limitOnlyNumber(false)}
                                                                style={{ width: 325 }}
                                                                onChange={updateFormState(
                                                                    generateFields(FORM_FIELDS.DEPOSIT_GAP), true
                                                                )}
                                                            />
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <FormItem label="Deposit amount">
                                                        {getFieldDecorator(generateFields(FORM_FIELDS.DEPOSIT_AMOUNT), {
                                                            initialValue: paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_AMOUNT)]
                                                        })(
                                                            <InputNumber
                                                                min={1}
                                                                style={{ width: 325 }}
                                                                disabled={paymentPlanConfigFormState[generateFields(FORM_FIELDS.DEPOSIT_PERCENTAGE)] ? true : false}
                                                                onChange={updateFormState(
                                                                    generateFields(FORM_FIELDS.DEPOSIT_AMOUNT), true
                                                                )}
                                                            />
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
    
                                        */

                                    }

                                    {!isTemplate ? (
                                        <Row>
                                            <h4>{getTranslatedText("Custom Fields:")}</h4>
                                            <Button type="primary" style={{ marginBottom: 10 }} onClick={addCustomFieldTab}>{getTranslatedText("Add Custom Field")}</Button>
                                            <Tabs
                                                onChange={handleCustomFieldTabChange}
                                                activeKey={paymentPlanConfigFormState[selectedCustomFieldTabName]}
                                                type="editable-card"
                                                onEdit={(targetKey, action) => handleCustomFieldEditTab(targetKey, action)}
                                                destroyInactiveTabPane={false}
                                                hideAdd
                                            >
                                                {map(
                                                    paymentPlanConfigFormState[customFieldsTabName],
                                                    ({
                                                        component: TabItemComponent,
                                                        ...option
                                                    }) => {
                                                        let keyIndex = option.key.toString().split('-')[3];
                                                        return (
                                                            <TabPane
                                                                tab={
                                                                    option.title
                                                                }
                                                                key={option.key}
                                                                closable={option.closable}
                                                            >
                                                                {paymentPlanConfigFormState[selectedCustomFieldTabName] === option.key &&
                                                                    (loading ? (
                                                                        getLoadingSkeletons(
                                                                            3,
                                                                            loading
                                                                        )
                                                                    ) : (
                                                                        <TabItemComponent
                                                                            form={form}
                                                                            setPaymentPlanConfigFormState={setPaymentPlanConfigFormState}
                                                                            paymentPlanConfigFormState={paymentPlanConfigFormState}
                                                                            setFormHasChanges={setFormHasChanges}
                                                                            paymentPlanOptionIndex={paymentPlanOptionIndex}
                                                                            selectedUserCompany={selectedUserCompany}
                                                                            tabIndex={keyIndex}
                                                                        />
                                                                    ))}
                                                            </TabPane>
                                                        )
                                                    }
                                                )}
                                            </Tabs>
                                        </Row>
                                    ) : ''}
                                </Card>
                            </Col>
                            <Col span={12}>
                                {instalmentType && paymentPlanConfigFormState[generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY)] && instalmentType != INSTALMENT_TYPE.CUSTOM && !paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)] ?
                                    (<Row><Col>
                                        <Card style={{ marginTop: 15, marginLeft: 10 }} >
                                            <h4>{getTranslatedText("Payment Schedule Dates:")}</h4>
                                            <ul>
                                                {
                                                    map(
                                                        paymentPlanConfigFormState[generateFields(FORM_DISPLAY.SCHEDULE_DATES_DISPLAY)],
                                                        (value) => (
                                                            <li key={value}>{value}</li>
                                                        )
                                                    )
                                                }
                                            </ul>

                                            {instalmentType == INSTALMENT_TYPE.MONTHLY ? (<i style={{ color: 'red' }}>{` ${getTranslatedText("Note: Schedule date may vary based on the actual")} ${paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_TIMING)]} ${getTranslatedText("of the invoice")}`}</i>) : ''}
                                            {instalmentType == INSTALMENT_TYPE.PAYMENT_AMOUNT ? (<i style={{ color: 'red' }}>{` ${getTranslatedText("Note: Schedule date & sample invoice amount may vary base on the actual")} ${paymentPlanConfigFormState[generateFields(FORM_FIELDS.START_TIMING)]} ${getTranslatedText("date and total amount of the invoice")}`}</i>) : ''}
                                        </Card>
                                    </Col></Row>) : ''
                                }

                                {paymentPlanConfigFormState[generateFields(FORM_DISPLAY.ADD_PAY_PERCENT)] && paymentPlanConfigFormState[generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY)] && !paymentPlanConfigFormState[generateFields(FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW)] ?
                                    (<Row><Col>
                                        <Card style={{ marginTop: 15, marginLeft: 10 }} >
                                            <h4>{getTranslatedText("Payment Schedule dates with payment percentage:")}</h4>
                                            <ul>
                                                {
                                                    map(
                                                        paymentPlanConfigFormState[generateFields(FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY)],
                                                        (value) => (
                                                            <li key={value}>{value}</li>
                                                        )
                                                    )
                                                }
                                            </ul>

                                            <i style={{ color: 'red' }}>{` ${getTranslatedText("Note: Payment percentage will be applied to the total amount of the invoice.")}`}</i>
                                        </Card>
                                    </Col></Row>) : ''
                                }
                            </Col>
                        </Row>
                    ) : ''
                }
            </Form>
        </>
    );
};


export default CreatePaymentPlanConfigTabContent;
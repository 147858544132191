/**
 * File for the `Cashflow Forecasting` widget.
 */

import { Table } from 'antd';
import {
    clone,
    filter,
    find,
    get,
    isEmpty,
    isUndefined,
    map,
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets, widgetDateRangeValues } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardCashflowForecastRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    getTranslatedText,
    replaceInstancesOfCustomerString
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';
import {
    displayColumnOptionsStart,
    displayColumnOptionsEnd,
    displayRowCountOptions,
} from './CashflowForecastingWidgetField';
import { dateFormatDoMMMYYYYSpace, dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import moment from 'moment-timezone';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatCurrency: (
        amount: number,
        cusCurrencyCode?: string,
        cusLocale?: string
    ) => JSX.Element;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}

export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');

const CashflowForecastingWidget: React.FC<IProps> = ({
    widgetDetails,
    formatCurrency,
    formatDateUTCToLocal,
    isOrgView,
    organisationCurrenciesAll,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptionsStart
        : filter(displayColumnOptionsStart, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value, fixed, width }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
        fixed: fixed,
        width: width
    }));

    const dispatch = useDispatch();
    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
    });

    const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
    const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
        currencyCodeUsed = get(currencyValueUsed, 'Value');
        localeUsed = get(currencyValueUsed, 'Locale');
    }

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    /**
     * Common function for formatting currencies
     */
    const handleFormatCurrency = (toFormat: number) => {
        return formatCurrency(toFormat, currencyCodeUsed, localeUsed);
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const cashflowForecastColumns: DynamicObject[] = [];
        let hasDisplayValue = false;

        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        let payload: DynamicObject = {
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            DueDateMin: startDate,
            DueDateMax: endDate,
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed,
            });
        }

        dispatch(
            getDashboardCashflowForecastRequestAction(
                payload,
                isOrgView,
                (cashflowForecast: String[]) => {
                    if (unmountedRef.current) return;

                    let initialColumns: any = [];
                    let customerColumns: any = [];
 
                   const cashflowColumns = cashflowForecast.reduce((accumulator, dataObject) => {
                        Object.keys(dataObject).forEach((key) => {
                          if (key !== "Amount Predicted" && key !== "Date" && key !== "All Others") {
                            accumulator.add(key);
                          }
                        });
                        return accumulator;
                      }, new Set<string>());

                    if (hasDisplayValue) {
                        initialColumns = cashflowForecastColumns;
                    } else {
                        initialColumns = initialTableColumns;
                    }

                    const cashflowColumnsEnd = filter(displayColumnOptionsEnd, [ 'defaultChecked', true ])
                            .map(({ label, value, width }: DynamicObject) => ({
                                title: replaceInstancesOfCustomerString(
                                    label,
                                    customerLabel,
                                    isOrgView
                                ),
                                dataIndex: value,
                                width: width
                    }));

                    cashflowColumns.forEach((cust) => {
                            customerColumns.push({
                                title: cust, 
                                dataIndex: cust,
                                width: 100,
                            });
                    });

                    const usedDataSource = map(
                        cashflowForecast,
                        (csf, csfIdx: number) => {
                        
                        const row: {[key: string]: any } = {
                            key: csfIdx,
                            Date: get(csf, 'Date') == 'Total' ? 'Total' : formatDateUTCToLocal(get(csf, 'Date'), null, dateFormatDoMMMYYYYSpace),
                        };

                        for (const key of Object.keys(csf)) {
                            if (key !== 'Date') {
                              row[key] = !isUndefined(get(csf, key)) ? handleFormatCurrency(get(csf, key)) : '';
                            }
                          }
                          return row;
                        }
                    );

                    updateTableStateObject({
                        columns: [...initialColumns, ...customerColumns ,...cashflowColumnsEnd],
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    };

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        if (isOrgView && isEmpty(organisationCurrenciesAll)) return;

        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails, organisationCurrenciesAll]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        columns: stateColumns,
        loading: stateLoading,
        dataSource: stateDataSource,
    } = tableState;
    
    return (
        <div>
            <Table
                className="table-striped-rows table-ws-nw"
                columns={stateColumns.map(options => ({
                    ...options,
                    title: getTranslatedText(options.title)
                }))}
                dataSource={stateDataSource}
                loading={stateLoading}
                pagination={false}
                size="middle"
                locale={{
                    emptyText: getTranslatedText('No Data'),
                }}
            />
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(CashflowForecastingWidget))
);



import { Button, Col, Row, Tooltip } from 'antd';
import {
    capitalize,
    find,
    get,
    isEmpty,
    isEqual,
    isUndefined,
    lowerCase,
} from 'lodash';
import React, { memo } from 'react';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { ReportHistory } from '../../store/report/types';
import { getSelectedReportId } from '../../store/report/sagas';
import { useSelector } from 'react-redux';
import AvatarNameInitialsComponent from '../common/AvatarNameInitialsComponent';
import { ReportStatus, ReportTypeString } from '../../constants/reportSortAndFilters';
import { dateFormatDoMMMYYYYHHmmSpace } from '../../constants/dateFormats';
import FontAwesome from '../common/FontAwesome';
import { getCurrentUser } from '../../store/users/sagas';
import { IsOrganisationViewAttribute } from '../../constants/authUserAttributes';
import { getTranslatedText } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { CompanyUserRole } from '../../store/companies/types';

interface IProps {
    readonly item: ReportHistory;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly downloadReportHandler: (report: ReportHistory) => void,
    readonly isOrgView?: boolean | undefined;
    readonly validCompanies?: CompanyUserRole[] | undefined;
}

const ReportHistoryItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    formatDateUTCToLocal,
    downloadReportHandler,
    isOrgView,
    validCompanies
}: IProps) => {
    
    const selectedId = useSelector(getSelectedReportId);
    const {
        Id,
        CreatedDateTime,
        Description,
        Type,
        Status,
        User,
        Company
    } = item;
    const isCompanyAvailable = !isUndefined(Company) &&  !isEmpty(Company);
    const areValidCompaniesAvailable = !isUndefined(validCompanies) && !isEmpty(validCompanies);
    let customerLabel = useSelector(getCustomerUILabel);
    if (!isUndefined(isOrgView) 
        && isOrgView
        && isCompanyAvailable
        && areValidCompaniesAvailable) 
    {
        const companyInOrganisation = find(
            validCompanies,
            (company: CompanyUserRole) => company.Company.CompanyId === Company.CompanyId 
        );
        if (!isUndefined(companyInOrganisation)) {
            customerLabel = get(
                companyInOrganisation, 
                'Company.CustomerTitleLabel'
            ) || 'customer';
        }
    }
    const CreatedDateTimeLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(
            CreatedDateTime,
            undefined,
            dateFormatDoMMMYYYYHHmmSpace
        )
        : CreatedDateTime;

    const handleRowClick = () => {
        const itemInfo: DynamicObject = { ...item, key: Id };
        if (onRowClick) onRowClick(itemInfo);
    };

    const handleDownloadReport = (e: any) => {
        e.stopPropagation();
        downloadReportHandler(item);
    }

    const getStatusMessage = (status: string) => {
        return getTranslatedText(`Report ${lowerCase(status)}`);
    };

    const UserName = (() => {
        let name: string = '';
        if (get(User, 'GivenName')) {
            name += get(User, 'GivenName');
        }
        if (get(User, 'FamilyName')) {
            if (!isEmpty(name)) {
                name += ' ';
            }
            name += get(User, 'FamilyName');
        }
        return name;
    })();

    const ReportTitle = (() => {
        const parts = getTranslatedText(Description).split(' ');
        let typeDisplay: string | undefined;
        switch (Type) {
            case ReportTypeString.Page: typeDisplay = getTranslatedText('Page'); break;
            case ReportTypeString.Dashboard: typeDisplay = getTranslatedText('Dashboard'); break;
        }
        if (typeDisplay) {
            parts.push(typeDisplay);
        }
        parts.push(getTranslatedText('Report'));
        return parts.join(' ');
    })();

    return (
        <Row
            className={`list-item-clickable ${selectedId === Id ? 'list-item-clicked' : ''
                }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 12, paddingBottom: 12 }}
            >
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col xl={1} lg={0} md={0} sm={0} xs={0}></Col>
                    <Col xl={4} lg={5} md={5} sm={5} xs={5} className="pl-10-i">
                        {CreatedDateTimeLocal}
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    {getTranslatedText(ReportTitle.includes('Customer') ? ReportTitle.replace('Customer', `${capitalize(customerLabel)}`): ReportTitle)}
                    </Col>
                    <Col xl={5} lg={6} md={6} sm={6} xs={6}>
                        {getStatusMessage(Status)}
                        {Status === ReportStatus.Complete &&
                            <Tooltip title={getTranslatedText("Download report")} placement="topRight">
                                <Button
                                    type="link"
                                    onClick={handleDownloadReport}
                                >
                                    <FontAwesome
                                        icon={['fas', 'cloud-download-alt']}
                                        size='lg'
                                    />
                                </Button>
                            </Tooltip>}
                    </Col>
                    <Col
                        className="ta-right pr-10"
                        xl={4}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                    >
                        {isOrgView && get(Company, 'Name')}
                    </Col>
                    <Col
                        xl={4}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="ta-right pr-10"
                    >
                        <span className='mr-10'>{UserName}</span>
                        <AvatarNameInitialsComponent
                            fullName={UserName}
                            size="large"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(ReportHistoryItemComponent)),
    arePropsEqual
);

/**
* Reusable component for populating the dynamic payment plan option tab section.
*/

import { Form} from 'antd';
import React from 'react';
import { withDateFormatHandler } from './DateFormatHandler';
import { withNumberFormatHandler } from './NumberFormatHandler';
import CreatePaymentPlanConfigTabContent from '../../components/common/CreatePaymentPlanConfigTabContent';
import { Company } from '../../store/companies/types';

interface IProps {
    form?: any;
    newPaymentPlanConfigFormState: any;
    setNewPaymentPlanConfigFormState: any;
    paymentPlanOptionIndex: number;
    selectedUserCompany: Company;
    setFormHasChanges: any;
    isTemplate?: boolean;
}

const PaymentPlanOptionDetailsDynamicTabContent: React.FC<IProps> = ({
    form,
    setNewPaymentPlanConfigFormState,
    newPaymentPlanConfigFormState,
    paymentPlanOptionIndex,
    selectedUserCompany,
    setFormHasChanges,
    isTemplate
}: IProps) => {
    return (
        <CreatePaymentPlanConfigTabContent
            selectedUserCompany={selectedUserCompany}
            form={form}
            setPaymentPlanConfigFormState={setNewPaymentPlanConfigFormState}
            paymentPlanConfigFormState={newPaymentPlanConfigFormState}
            setFormHasChanges={setFormHasChanges}
            paymentPlanOptionIndex={paymentPlanOptionIndex}
            isTemplate={isTemplate}
        />
    );
};

export default withNumberFormatHandler(
    withDateFormatHandler(PaymentPlanOptionDetailsDynamicTabContent)
);

import update from 'immutability-helper';
import { Reducer } from 'redux';
import { SupportTicketActionTypes, SupportTicketsState } from './types';

// Type-safe initialState!
export const initialState: SupportTicketsState = {
    loading: false,
    errorMessages: [],
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SupportTicketsState> = (state = initialState, action) => {
    switch (action.type) {
        case SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_REQUEST: {
            return { ...state, loading: true };
        }
        case SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_RESPONSE: {
            return { ...state, loading: false };
        }
        case SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }
        case SupportTicketActionTypes.SET_SUPPORT_TICKET_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case SupportTicketActionTypes.SET_SUPPORT_TICKET_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }

        case SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case SupportTicketActionTypes.CLEAR_SUPPORT_TICKET_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        default: {
            return state;
        }

    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as supportTicketsReducer };

import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/users/sagas';
import { IsOrganisationViewAttribute, OrganisationRoleIdAttribute } from '../constants/authUserAttributes';
import { find, get } from 'lodash';
import { ApplicationState } from '../store';

export const useUserRole = () => {
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);
    let userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    if (isOrgView) {
        userRole = get(orgUserRole, 'Name');
    }
    return userRole;
};
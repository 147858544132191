/**
 * File responsible for the content when clicking `Cancel plan` in management pages.
 */

import { Button, Checkbox, Col, Form, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { get, isEmpty, map, toLower } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getTranslatedText } from '../../utils/commonFunctions';
import { ContactOptions } from '../../store/common/types';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly filterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
}

const PaymentPlanCancelPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    filterPayload,
    dispatchAction,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
    const customerLabel = useSelector(getCustomerUILabel);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    /**
     * Function called when `Cancel` button is clicked inside cancel payment plan panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                if (dispatchAction) {
                    dispatch(
                        dispatchAction({
                            ...filterPayload,
                            comment: get(values, 'Comment'),
                            selectedContact: get(values, 'NotifyCustomer') && selectedItems.length > 0 ? selectedItems.reduce((acc, curr) => acc + curr, 0) : undefined,
                            notifyCustomerCancellation: get(values, 'NotifyCustomer'),
                            callback: cancelPaymentPlanResponseModal,
                        })
                    );
                } else {
                    if (closePanel) closePanel(true);
                }

                // setTimeout(() => {
                //     addCommentResponseModal({
                //         IsSuccess: false,
                //         Messages: ['UI Simulation only'],
                //     });
                // }, 3000);
            }
        });
    };

    /**
     * Function responsible for showing the response modal after cancelling the payment plan.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const cancelPaymentPlanResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Payment plan(s) cancelled successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText('Failed to cancel payment plan(s)!');
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };

    const handleChange = (values: any) => {
        const numberValues = values.map(Number);
        let updatedValues: number[] = [];
        let unselected: boolean = false;

        // unselected the list item
        if (!numberValues.includes(8)) {
            if (selectedItems.some(item => !numberValues.includes(item))) {
                updatedValues = numberValues;
                unselected = true;
            }
        }

        if (!unselected) {
            numberValues.forEach((value: number) => {
                const intValue = value;

                if (intValue === 8) {
                    updatedValues = selectedItems.includes(8) ? [] : [8];
                } else {
                    updatedValues = selectedItems.includes(intValue)
                        ? selectedItems.filter((item) => item !== intValue)
                        : [...selectedItems.filter((item) => item !== 8), intValue];
                }
            });
        }

        setSelectedItems(updatedValues);
    };

    useEffect(() => {
        if (getFieldValue('NotifyCustomer') && form.getFieldInstance('ContactMethod')) {
            const currentValue = getFieldValue('ContactMethod') || [];
            const newValue = selectedItems.map(String);
    
            if (currentValue.join(',') !== newValue.join(',')) {
                setFieldsValue({
                    ContactMethod: newValue
                });
            }
        }
    }, [form, selectedItems]);
    

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row>
                    <Col span={24} className="mb-10">
                        <div>
                            {getTranslatedText('You are about to cancel the selected payment plans. If there are amounts still owing on the associated invoices, reminders shall begin again.')}
                        </div>
                    </Col>
                    <Col span={24}>
                        {getTranslatedText('Provide a comment on why these plans are being cancelled')}
                    </Col>
                    <Col span={24}>
                        <FormItem>
                            {getFieldDecorator('Comment', {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Comment required!'),
                                    },
                                ],
                            })
                            (<TextArea rows={4} />)}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('NotifyCustomer', {
                                valuePropName: 'checked',
                            })(
                                <Checkbox>
                                    <span className="grey">
                                        {getTranslatedText(`Notify ${toLower(customerLabel)} on payment plan cancelation`)}
                                    </span>
                                </Checkbox>
                            )}
                        </FormItem>
                        {
                            getFieldValue('NotifyCustomer') &&
                           <FormItem  labelAlign='left' label={getTranslatedText("Contact Method")} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                {getFieldDecorator('ContactMethod', {
                                    initialValue: selectedItems.map(String)
                                })(
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder={getTranslatedText('Select communication method')}
                                        onChange={handleChange}
                                    >
                                        {ContactOptions.filter(co => co.label !== 'Postal address').map(contact => (
                                            <Option key={contact.value} value={String(contact.value)}>{getTranslatedText(contact.label)}</Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        }
                        <div style={{ lineHeight: 'initial' }}>
                            {getTranslatedText(`Please note, your comment is included in the cancel notification to the ${toLower(customerLabel)}.`)}
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                        >
                            {getTranslatedText('Save')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Canceling payment plan(s)")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while canceling payment plan(s) . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const PaymentPlanCancelPanelForm = Form.create({
    name: 'payment-plan-cancel-panel-form',
})(PaymentPlanCancelPanel);

export default withRouter(PaymentPlanCancelPanelForm);


export const INSTALMENT_TYPE = {
    DEFAULT: 'Default',
    MONTHLY: 'Monthly',
    CUSTOM: 'Custom',
    DATE_RANGE: 'DateRange',
    PAYMENT_AMOUNT: 'PaymentAmount'
}


export const paymentPlanConfigTypeOptions = [
    {
        label: 'Date Range',
        value: INSTALMENT_TYPE.DATE_RANGE
    },
    {
        label: 'Monthly',
        value: INSTALMENT_TYPE.MONTHLY
    },
    // { //remove
    //     label: 'Payment Amount',
    //     value: INSTALMENT_TYPE.PAYMENT_AMOUNT
    // },
    {
        label: 'Custom',
        value: INSTALMENT_TYPE.CUSTOM
    }
];

export const FORM_FIELDS = {
    CUSTOMER_ID: 'CustomerId',
    INVOICES: 'Invoices',
    PAYMENTPLANINSTALMENTS: 'PaymentPlanInstalments',
    INSTALMENT_AMOUNT: 'InstalmentAmount',
    INSTALMENT_DUE_DATE: 'InstalmentDueDate',
    REQUIRES_DEPOSIT: 'RequiresDeposit',
    DEPOSIT_TYPE: 'DepositType',
    DEPOSIT_PERCENTAGE: 'DepositPercentage',
    DEPOSIT_AMOUNT: 'DepositAmount',
    DEPOSIT_DUE_DATE: 'DepositDueDate',
    DEPOSIT_GAP: 'DepositGap',
    IS_SPLIT_INSTALMENT: 'IsSplitInstalment',
    INSTALMENT_TYPE: 'InstalmentType',
    SPLIT_INSTALMENT_DATE_RANGE: 'SplitInstalmentDateRange',
    SPECIFIC_PAYMENT_DAY: 'SpecificPaymentDate',
    PAYMENT_PLAN_OPTION: 'PaymentPlanOption',
    NUMBER_OF_INSTALMENT: 'NumberOfInstalment',
    INSTALMENTS: 'Instalments',
    FREQUENCY: 'Frequency',
    SCHEDULED_DATE: 'ScheduledDate',
    SCHEDULE_DATES: 'ScheduleDates',
    CUSTOM_FIELD_NAME: 'CustomFieldName',
    CUSTOM_FIELD_VALUE: 'CustomFieldValue',
    CUSTOM_FIELD_COUNT: 'CustomFieldCount',
    SCHEDULE_DATE_KEYS: 'CustomScheduleDatesKeys',
    START_TIMING: 'StartTiming',
    NAME: 'Name',
    START_DELAY: 'StartDelay',
    FREQUENCY_GAP: 'FrequencyGap',
    CUT_OFF_DATE: 'CutoffDate',
    PAY_PERCENT: 'PayPercent'
};

export const PAYMENT_DAY = {
    FIRST_DATE: 'First Day of the Month',
    LAST_DATE: 'Last Day of the Month',
    CUSTOM: 'Custom (Specify Below)',
}

export const TAB_NAME = {
    CUSTOM_FIELDS: 'CustomFieldsTab',
    SELECTED_CUSTOM_FIELD: 'SelectedCustomFieldTab',
    CUSTOM_FIELD_ID: 'CustomFieldTabId',
    CUSTOM_FIELD_KEYS: 'CustomFieldTabKeys',
}

export const FORM_DISPLAY = {
    PAY_PERCENT_TOTAL: 'PayPercentTotal',
    PAY_PERCENT_WARNING: 'PayPercentWarning',
    PAY_PERCENT_SCHEDULE_DATES_DISPLAY: 'PayPercentScheduleDatesDisplay',
    PAY_PERCENT_HIDE_PREVIEW: 'PayPercentHidePreview',
    ADD_PAY_PERCENT: 'AddPayPercent',
    SCHEDULE_DATES_DISPLAY: 'ScheduleDatesDisplay',
    INSTALMENT_MIN: "InstalmentMin",
    INSTALMENT_MAX: 'InstalmentMax'
}

export const paymentPlanConfigStartTimingOptions = [
    {
        label: 'Create Date',
        value: 'CreateDate'
    },
    {
        label: 'Request Date',
        value: 'RequestDate'
    },
    {
        label: 'Overdue date',
        value: 'OverdueDate'
    }
];

import { API, graphqlOperation } from 'aws-amplify';
import { filter, get, map } from 'lodash';
import {
    all,
    call,
    fork,
    put,
    delay,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import { DynamicObject } from '../../utils/commonInterfaces';
import { SupportTicketActionTypes,SupportTicketsState } from './types';
import { API_NAME,maxAPIRefetchCount, refetchAPIDelay } from '../../config/config';
import {
    getSupportTicketListsRequestAction,
    setSelectedSupportTicketIdSuccessAction,
    getSupportTicketDataSuccessAction,
    getSupportTicketDataErrorAction
} from './actions';
import {
    SUPPORT_TICKET_LISTS_PAGE,
} from '../../config/tableAndPageConstants';

export const getSelectedSupportTicketId = (state: ApplicationState) =>
    state.supportTickets.activeData.selectedId;

export const getSupportTicketData = (state: ApplicationState) =>
    state.supportTickets.activeData.record;
 /**
 * Function calling the API for fetching the remittance advice data based on the given id.
 * @param param0
 */
function* handleSubmitSupportTicketRequest({ payload: sagaPayload }: any) {
    const errorMessage =
        'Error submitting the support ticket. Please try again later.';
    const {
        payload,
        callback
    } = sagaPayload;
    try { 
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/support/ticket/submit',
            {
                body: payload,
            }
        );
        if (callback) {
            const responseCallback = {
                IsSuccess: get(response, 'isSuccess'),
                Id: get(response, 'id'),
                FileURLLists: get(response, 'fileLists'),
            };

            callback(responseCallback);
        }
        
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (callback) {
            const errorCallback = {
                IsSuccess: false
            };
            callback(errorCallback);
        }
    }
}   

/**
 * Function calling the API for attaching file for support ticket.
 * @param param0
 */
function* handleAttachFileSupportTicketRequest({ payload: sagaPayload }: any) {
    const errorMessage =
        'Error submitting the support ticket. Please try again later.';
    const {
        payload,
        callback
    } = sagaPayload;

    try {
        

        console.log('Payload in Saga:', payload);   
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/attach-file/support/ticket/submit',
            {
                body: payload,
            }
        );

        if (callback) {
            const responseCallback = {
                IsSuccess: get(response, 'isSuccess'),
                Id: get(response, 'id'),
                FileURLLists: get(response, 'fileLists'),
            };

            callback(responseCallback);
        }
        
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (callback) {
            const errorCallback = {
                IsSuccess: false
            };
            callback(errorCallback);
        }
    }
}   

/**
 * Function called for getting support ticket lists
 */
function* handleGetSupportTicketListsRequest({ payload: sagaPayload }: any) {
    const { payload, callback, refetchCount } = sagaPayload;
    const errorMessage =
        ['Error fetching support ticket list. Please try again later.'];
    try {
        let res: DynamicObject;
 
        res = yield call(
            [API, 'post'],
            API_NAME,
            '/support/ticket/lists',
            { 
                body: payload
            }
        );

        const supportTickets = get(res,'data');
        if (supportTickets) {
            const responsePayload = {
                data: supportTickets,
                IsSuccess: true,
                pageData: {
                    pageSize: get(payload,'pageSize'),
                    currentPage: get(payload,'currentPage'),
                    hasNextPage:
                        !(supportTickets.length < get(payload,'pageSize')) &&
                        !(get(payload,'pageSize') < SUPPORT_TICKET_LISTS_PAGE.pageSize),
                },
            };

            callback(responsePayload);
        } else {
            const responsePayload = {
                IsSuccess: true,
                Messages: get(res,'Messages')
            };

            callback(responsePayload);
        }
    } catch (err) {
        if (callback) {
            const errorCallback = {
                IsSuccess: false,
                Messages: errorMessage
            };
            callback(errorCallback);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function that sets the selected support ticket id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedSupportTicketIdRequest({ payload }: any) {
    const { supportTicketId, callback } = payload;
    yield put(setSelectedSupportTicketIdSuccessAction(supportTicketId));
    callback();
}

/**
 * Function that calls the API for getting the support ticket details based on the given Id.
 * @param param0
 */
function* handleGetSupportTicketDataRequest({
    payload: { supportTicketId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching support ticket details. Please try again later.';

    try {
        let res: DynamicObject;
        console.log("handleGetSupportTicketDataRequest supportTicketId: ",supportTicketId)
        res = yield call(
            [API, 'post'],
            API_NAME,
            '/support/ticket/details',
            { 
                body: {
                    supportTicketId: supportTicketId,
                },
            }
        );

        if (res) {
            const responsePayload = {
                record: res,
            };
            console.log("handleGetSupportTicketDataRequest responsePayload: ",responsePayload)

            yield put(getSupportTicketDataSuccessAction(responsePayload));
        } else {
            yield put(getSupportTicketDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSupportTicketDataErrorAction([errorMessage]));
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.

function* watchSubmitSupportTicketRequest() {
    yield takeLatest(
        SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_REQUEST,
        handleSubmitSupportTicketRequest
    );
}

function* watchAttachFileSupportTicketRequest() {
    yield takeLatest(
        SupportTicketActionTypes.ATTACH_SUPPORT_TICKET_REQUEST,
        handleAttachFileSupportTicketRequest
    );
}

function* watchGetSupportTicketListsRequest() {
    yield takeLatest(
        SupportTicketActionTypes.GET_SUPPORT_TICKET_LISTS_REQUEST,
        handleGetSupportTicketListsRequest
    );
}

function* watchSetSelectedSupportTicketIdRequest() {
    yield takeLatest(
        SupportTicketActionTypes.SET_SUPPORT_TICKET_SELECTED_ID_REQUEST,
        handleSetSelectedSupportTicketIdRequest
    );
}

function* watchGetSupportTicketDataRequest() {
    yield takeLatest(
        SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_REQUEST,
        handleGetSupportTicketDataRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* supportTicketsSaga() {
    yield all([
        fork(watchSubmitSupportTicketRequest),
        fork(watchAttachFileSupportTicketRequest),
        fork(watchGetSupportTicketListsRequest),
        fork(watchSetSelectedSupportTicketIdRequest),
        fork(watchGetSupportTicketDataRequest)
    ]);
}

export default supportTicketsSaga;

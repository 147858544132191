import { Drawer, Skeleton } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import WorkflowDetailsPanelContent from './WorkflowDetailsPanelContent';
import { UpdateWorkflowRequestPayload, WorkflowConfiguration } from '../../store/workflow/types';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkflowConfigurationsData, getWorkflowSelectedId } from '../../store/workflow/sagas';
import { clearWorkflowActiveDataAction, getWorkflowDefinitionRequestAction } from '../../store/workflow/actions';
import { cloneDeep, times } from 'lodash';

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel: (refresh?: boolean) => void;
    readonly workflowConfiguration?: WorkflowConfiguration;
}

const WorkflowDetailsDrawer: React.FC<IProps> = ({ containerRef, visible, closePanel, workflowConfiguration }) => {
    const dispatch = useDispatch();
    const selectedId = useSelector(getWorkflowSelectedId);
    const activeData = useSelector(getWorkflowConfigurationsData);
    const { loading, record } = activeData;

    const fetchWorkflowDefinition = () => {
        dispatch(
            getWorkflowDefinitionRequestAction({
                WorkflowId: selectedId
            })
        );
    };

    const initializeWorkflow = () => {
        if (selectedId) fetchWorkflowDefinition();
    };

    useEffect(initializeWorkflow, [selectedId]);

    const listenerForClearingState = () => {
        if (!visible) dispatch(clearWorkflowActiveDataAction());
    };

    useEffect(listenerForClearingState, [visible]);

    const populateLoading = () => {
        return (
            <>
                {times(2, (key: number) => {
                    return (
                        <div key={key}>
                            <Skeleton
                                active
                                loading
                                paragraph={{ rows: 2 }}
                            />
                        </div>
                    );
                })}
            </>
        );
    }

    const initialPayload: UpdateWorkflowRequestPayload | undefined = useMemo(() => {
        if (!workflowConfiguration || !record) return undefined;
        return {
            CustomField: workflowConfiguration.CustomField,
            WorkflowDefinition: cloneDeep(record)
        };
    }, [workflowConfiguration, record]);

    return (
        <Drawer
            title={getTranslatedText('Workflow details')}
            width="70vw"
            visible={visible}
            className="workflow-details-panel"
            closable={false}
            maskClosable={false}
            getContainer={getPopoverContainer(containerRef)}
        >
            {visible && (loading
                ? populateLoading()
                : <WorkflowDetailsPanelContent
                    workflowConfiguration={workflowConfiguration}
                    isCreateNew={false}
                    closePanel={closePanel}
                    containerRef={containerRef}
                    initialPayload={initialPayload}
                />)}
        </Drawer>
    );
};

export default WorkflowDetailsDrawer;
/**
 * File for the `Change statistics` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isUndefined,
    map,
    split,
    sum,
    isEmpty,
    isNumber
} from 'lodash';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets, widgetDisplayTypeValues, tableNumberFormatter } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardChangeStatisticsRequestAction, getDashboardChangeStatisticsByTypeRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    replaceInstancesOfCustomerString,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    displayColumnIndicator,
    displayColumnOptions,
    displayRowCountOptions,
} from './RecentChangesWidgetFields';

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly isOrgView?: boolean;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly functionRefObj?: any;
}

const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#F44336',
    '#9C27B0',
    '#FFEB3B',
    '#795548',
    '#8BC34A',
    '#263238',
];

const ChangeStatisticsWidget: React.FC<IProps> = ({
    widgetDetails,
    preview,
    isOrgView,
    formatDateUTCToLocal,
    formatNumber,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const dispatch = useDispatch();
    let widgetRef = useRef<any>(null);

    const [widgetState, setWidgetState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        tickWidth: number;
        chartMarginLeft: number;
        groupBySourceType: boolean;
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        tickWidth: 30,
        chartMarginLeft: 20,
        groupBySourceType: false
    });

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateTableStateObject = (widgetStateObject: {}) => {
        setWidgetState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const recentChangesColumns: DynamicObject[] = [];
        let hasDisplayValue = false;
        let ChangeTypes: any = [];
        let ResourceTypes: any = [];
        let SourceTypes: any = [];
        let GroupBySourceType = false

        if (get(widgetDetails, "groupingOptions") === "SourceType") {
            GroupBySourceType = true
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    recentChangesColumns.push({
                        title: getTranslatedText(columnTitle),
                        dataIndex: fieldName,
                    });
                }
            }

        });

        ChangeTypes = map(
            get(widgetDetails, 'changeTypes'),
            (changeType: number) => changeType
        );

        ResourceTypes = map(
            get(widgetDetails, 'resourceTypes'),
            (resourceType: string) => resourceType
        );

        SourceTypes = map(
            get(widgetDetails, 'sourceTypes'),
            (sourceType: number) => sourceType
        );

        let payload: DynamicObject = {
            filters: {
                Type: sum(ChangeTypes),
                ResourceTypes,
                SourceTypes
            },
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }

        if (!GroupBySourceType) {
            dispatch(
                getDashboardChangeStatisticsRequestAction(
                    payload,
                    isOrgView,
                    (changes: any) => {
                        if (unmountedRef.current) return;

                        const dataSource = map(
                            changes,
                            (chg: any, index: number) => {
                                const Source = !isEmpty(get(chg, "User")) ?
                                    `${get(chg, "User.GivenName", "")} ${get(chg, "User.FamilyName", "")}` :
                                    "System"

                                return {
                                    key: index,
                                    Count: get(chg, 'Count', 0),
                                    Source: Source
                                };
                            }
                        );

                        dataSource.sort((a, b) => b["Count"] - a["Count"]);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let source = ds.Source

                            if (ds.Count > 0 && longestWord.length < source.length) {
                                longestWord = source;
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateTableStateObject({
                            dataSource: dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            groupBySourceType: GroupBySourceType
                        });
                    },
                    payloadCallback
                )
            );
        }
        else {
            dispatch(
                getDashboardChangeStatisticsByTypeRequestAction(
                    payload,
                    isOrgView,
                    (changes: any) => {
                        if (unmountedRef.current) return;

                        const dataSource = map(
                            changes,
                            (chg: any, index: number) => {
                                const Source = (get(chg, "SourceType"))

                                return {
                                    key: index,
                                    Count: get(chg, 'Count', 0),
                                    Source: Source
                                };
                            }
                        );

                        dataSource.sort((a, b) => b["Count"] - a["Count"]);

                        let longestWord = '';
                        map(dataSource, (ds) => {
                            let source = ds.Source

                            if (ds.Count > 0 && longestWord.length < source.length) {
                                longestWord = source;
                            }
                        })

                        const longestWordWidth = measureText(longestWord) * 8;
                        const tickWidth = longestWordWidth > 300 ? 300 : longestWordWidth;
                        const chartMarginLeft = tickWidth - 60 > 0 ? tickWidth - 60 : tickWidth;

                        updateTableStateObject({
                            dataSource: dataSource,
                            loading: false,
                            lastWidgetDetails: clone(widgetDetails),
                            tickWidth,
                            chartMarginLeft,
                            groupBySourceType: GroupBySourceType
                        });
                    },
                    payloadCallback
                )
            );
        }
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for getting the length of text.
     * @param text
     */
    const measureText = (text: string) => {
        return text ? text.length : 0;
    };

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value: any, name: string) => {
        const formattedValue = isNumber(value) ? formatNumber(value) : value;
        const translatedName = getTranslatedText(name);
        return [formattedValue, translatedName];
    };

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            Source,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {/*{getTranslatedText(Source)}*/}
                    {Source}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    let blueValueKey = 'Count';

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');
        const {
            loading: stateLoading,
            dataSource: stateDataSource,
            tickWidth: stateTickWidth,
            chartMarginLeft: stateChartMarginLeft,
            groupBySourceType: groupBySourceType
        } = widgetState;

        if (displayView === widgetDisplayTypeValues.TABLE) {
            const columns = [
                {
                    title: getTranslatedText(`${groupBySourceType ? 'Source Type' : 'Source'}`),
                    dataIndex: 'Source',
                },
                {
                    title: getTranslatedText('Count'),
                    dataIndex: 'Count',
                },

            ];

            return (
                <Table
                    className="table-striped-rows table-ws-nw"
                    columns={columns.map(column => ({
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={tableNumberFormatter(stateDataSource, formatNumber)}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    rowKey="key"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART || displayView === undefined) {
            const pieChartData = clone(stateDataSource);

            const filteredData = pieChartData.filter((dataPoint: any) => dataPoint["Count"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Count"
                                nameKey="Source"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
        } else {
            return (
                <Spin wrapperClassName="spinner-wh100" spinning={stateLoading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <BarChart
                            data={stateDataSource}
                            margin={{
                                top: 20,
                                right: 20,
                                left: stateChartMarginLeft,
                                bottom: 15,
                            }}
                            layout="vertical"
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis
                                type="category"
                                dataKey="Source"
                                tick={{
                                    fontSize: 14,
                                    width: stateTickWidth,
                                }}
                            />
                            <XAxis
                                type="number"
                                allowDecimals={false}
                                width={stateTickWidth}
                                orientation="bottom"
                            />
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                            <Bar dataKey={blueValueKey} fill="#0088fe" />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="tickets-sticky-x-axis" />
                </Spin >
            );
        }
    };

    return (
        <div className="tasks-widget-container h-100" ref={widgetRef}>
            {populateWidgetContent()}
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(ChangeStatisticsWidget))
);

// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
    faExclamationTriangle,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faAddressCard,
    faQuestionCircle,
    faSignOutAlt,
    faTachometerAlt,
    faUsers,
    faChartLine,
    faHome,
    faEnvelope,
    faMobileAlt,
    faSortDown,
    faTimesCircle,
    faTasks,
    faSync,
    faHandHoldingUsd,
    faHeadset,
    faWallet,
    faPiggyBank,
    faCogs,
    faRetweet,
    faFileAlt,
    faComments,
    faAddressBook,
    faFileInvoice,
    faList,
    faFilter,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faCloudDownloadAlt,
    faEnvelopeOpen,
    faComment,
    faFilePdf,
    faFileExcel,
    faEllipsisH,
    faSatelliteDish,
    faCheck,
    faCheckCircle,
    faRobot,
    faCheckDouble,
    faUserTag,
    faInfoCircle,
    faUserEdit,
    faUserPlus,
    faUserMinus,
    faEdit,
    faForward,
    faSignal,
    faPhoneAlt,
    faPhoneVolume,
    faUser,
    faSpinner,
    faCrown,
    faMoneyBillAlt,
    faMoneyBillWave,
    faInfo,
    faHandshake,
    faUndo,
    faUserLock,
    faCopy,
    faFile,
    faPaperclip,
    faPlus,
    faFileInvoiceDollar,
    faLayerGroup,
    faFileDownload,
    faMinusSquare,
    faMinusCircle,
    faSave,
    faChartPie,
    faTable,
    faSquare,
    faCog,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faReceipt,
    faExternalLinkAlt,
    faMoneyCheckAlt,
    faCreditCard,
    faDonate,
    faPauseCircle,
    faRandom,
    faCompress,
    faExpand,
    faAngleDown,
    faAngleUp,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPlusSquare,
    faPlusCircle,
    faTh,
    faObjectGroup,
    faBan,
    faCalendarAlt,
    faBuilding,
    faCity,
    faAngleDoubleUp,
    faReply,
    faShare,
    faSearchDollar,
    faTrash,
    faRecycle,
    faProjectDiagram,
    faExclamationCircle,
    faPaperPlane,
    faClock,
    faPercent,
    faPercentage,
    faTicketAlt,
    faSearch
} from '@fortawesome/free-solid-svg-icons';

import {
    faFrownOpen,
    faPlayCircle,
    faQuestionCircle as farFaQuestionCircle,
    faCircle,
    faSmile,
    faFrown,
    faChartBar,
    faTimesCircle as farFaTimesCircle,
    faCommentAlt,
    faCalendar,    
} from '@fortawesome/free-regular-svg-icons';

import {
    faCcMastercard,
    faCcVisa,
    faCcAmex,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faExclamationTriangle,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faAddressCard,
    faQuestionCircle,
    faSignOutAlt,
    faTachometerAlt,
    faUsers,
    faChartLine,
    faHome,
    faEnvelope,
    faMobileAlt,
    faSortDown,
    faTimesCircle,
    faTasks,
    faSync,
    faHandHoldingUsd,
    faHeadset,
    faWallet,
    faPiggyBank,
    faCogs,
    faTrash,
    faRecycle,
    faProjectDiagram,
    faExclamationCircle,
    faPaperPlane,
    faRetweet,
    faFileAlt,
    faComments,
    faAddressBook,
    faFileInvoice,
    faList,
    faFilter,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faCloudDownloadAlt,
    faEnvelopeOpen,
    faComment,
    faFilePdf,
    faFileExcel,
    faEllipsisH,
    faSatelliteDish,
    faCheck,
    faCheckCircle,
    faRobot,
    faCheckDouble,
    faUserTag,
    faInfoCircle,
    faUserEdit,
    faUserPlus,
    faUserMinus,
    faEdit,
    faForward,
    faSignal,
    faPhoneAlt,
    faPhoneVolume,
    faUser,
    faSpinner,
    faCrown,
    faMoneyBillAlt,
    faMoneyBillWave,
    faInfo,
    faHandshake,
    faUndo,
    faUserLock,
    faCopy,
    faFile,
    faPaperclip,
    faPlus,
    faFileInvoiceDollar,
    faLayerGroup,
    faFileDownload,
    faMinusSquare,
    faMinusCircle,
    faSave,
    faChartPie,
    faTable,
    faSquare,
    faCog,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faReceipt,
    faSearchDollar,
    faExternalLinkAlt,
    faMoneyCheckAlt,
    faCreditCard,
    faDonate,
    faPauseCircle,
    faRandom,
    faCompress,
    faExpand,
    faAngleDown,
    faAngleUp,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPlusSquare,
    faPlusCircle,
    faTh,
    faObjectGroup,
    faBan,
    faClock,
    faCalendar,
    faCalendarAlt,
    faBuilding,
    faCity,
    faAngleDoubleUp,
    faReply,
    faShare,
    // regular icons go here
    faFrownOpen,
    faPlayCircle,
    farFaQuestionCircle,
    faCircle,
    faSmile,
    faFrown,
    faChartBar,
    farFaTimesCircle,
    faCommentAlt,
    faRecycle,
    faExclamationCircle,
    faPaperPlane,
    // brand icons go here
    faCcMastercard,
    faCcVisa,
    faCcAmex,
    faTicketAlt,
    faPercent,
    faPercentage,
    faTicketAlt,
    faSearch
);

import { Reducer } from 'redux';
import { ContentsState, ContentsActionTypes, TemplateType } from './types';
import update from 'immutability-helper';
import { find, get, split } from 'lodash';
import { CompaniesActionTypes } from '../companies/types';
import { targetContentMap } from '../../constants/contents';

// Type-safe initialState!
export const initialState: ContentsState = {
    saveLoading: false,
    activeData: {
        errorMessages: [],
        previewErrors: [],
        loading: false,
        rendering: false,
        record: null,
        preview: {
            content: null,
            version: null
        },
        selection: null
    },
    manualContents: {
        errorMessages: [],
        loading: false,
        templates: null
    },
    otherContents: {
        errorMessages: [],
        loading: false,
        contentConfig: null
    },
    workflowContents: {
        errorMessages: [],
        loading: false,
        contentConfigs: null
    }
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ContentsState> = (state = initialState, action) => {
    switch (action.type) {
        case ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION: {
            const newState = update(state.workflowContents, {
                $merge: {
                    loading: true,
                },
            });

            return {
                ...state,
                workflowContents: newState,
            };
        }
        case ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION_SUCCESS: {
            const newDataState = update(state.workflowContents.contentConfigs, {
                $set: action.payload,
            });
            const newState = update(state.workflowContents, {
                $merge: {
                    loading: false,
                    contentConfigs: newDataState,
                    errorMessages: initialState.workflowContents.errorMessages,
                },
            });
            return {
                ...state,
                workflowContents: newState,
            };
        }
        case ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION_ERROR: {
            const newState = update(state.workflowContents, {
                $merge: {
                    loading: false,
                    contentConfigs: initialState.workflowContents.contentConfigs,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                workflowContents: newState,
            };
        }
        case ContentsActionTypes.CLEAR_WORKFLOW_CONTENT_CONFIGURATION: {
            const newState = update(state.workflowContents, {
                $set: initialState.workflowContents,
            });

            return {
                ...state,
                workflowContents: newState,
            };
        }
        
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION: {
            const newState = update(state.otherContents, {
                $merge: {
                    loading: true,
                },
            });

            return {
                ...state,
                otherContents: newState,
            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION_SUCCESS: {
            const newDataState = update(state.otherContents.contentConfig, {
                $set: action.payload,
            });
            const newState = update(state.otherContents, {
                $merge: {
                    loading: false,
                    contentConfig: newDataState,
                    errorMessages: initialState.otherContents.errorMessages,
                },
            });
            return {
                ...state,
                otherContents: newState,
            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION_ERROR: {
            const newState = update(state.otherContents, {
                $merge: {
                    loading: false,
                    contentConfig: initialState.otherContents.contentConfig,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                otherContents: newState,
            };
        }

        case ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS: {
            const newState = update(state.manualContents, {
                $merge: {
                    loading: true,
                },
            });

            return {
                ...state,
                manualContents: newState,
            };
        }
        case ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_SUCCESS: {
            const newDataState = update(state.manualContents.templates, {
                $set: action.payload,
            });
            const newState = update(state.manualContents, {
                $merge: {
                    loading: false,
                    templates: newDataState,
                    errorMessages: initialState.manualContents.errorMessages,
                },
            });
            return {
                ...state,
                manualContents: newState,
            };
        }
        case ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_ERROR: {
            const newState = update(state.manualContents, {
                $merge: {
                    loading: false,
                    templates: initialState.manualContents.templates,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                manualContents: newState,
            };
        }

        case ContentsActionTypes.GET_CONTENT_TEMPLATE: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    loading: true,
                    record: null
                },
            });

            return {
                ...state,
                activeData: newActiveData

            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    loading: false,
                    record: action.payload,
                    errorMessages: initialState.activeData.errorMessages,
                    preview: {
                        ...state.activeData.preview,
                        content: get(action.payload, 'Preview')
                    }
                }
            });
            return {
                ...state,
                activeData: newActiveData
            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_ERROR: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    loading: false,
                    record: null,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    rendering: true
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW_SUCCESS: {
            const { version }: ContentsState['activeData']['preview'] = action.payload;
            const currentVersion = state.activeData.preview.version;
            if (version !== currentVersion) {
                return state;
            }

            const newActiveData = update(state.activeData, {
                $merge: {
                    rendering: false,
                    preview: action.payload,
                    previewErrors: initialState.activeData.previewErrors,
                }
            });
            return {
                ...state,
                activeData: newActiveData
            };
        }
        case ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW_ERROR: {
            const { version, previewErrors }: { version: string, previewErrors: string[] } = action.payload;
            const currentVersion = state.activeData.preview.version;
            if (version !== currentVersion) {
                return state;
            }

            const newActiveData = update(state.activeData, {
                $merge: {
                    rendering: false,
                    previewErrors: previewErrors,
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case ContentsActionTypes.SAVE_CONTENT_TEMPLATE: {
            return {
                ...state,
                saveLoading: true
            };
        }
        case ContentsActionTypes.SAVE_CONTENT_TEMPLATE_SUCCESS: {
            const { WorkflowId, StateName, TemplateContentName, NewTemplateKey, Type, ...props } = action.payload;

            switch (Type) {
                case TemplateType.WorkflowContent: {
                    const workflowStateConfigs = state.workflowContents.contentConfigs;
                    const changedWorkflow = find(workflowStateConfigs, w => w.WorkflowId === WorkflowId);
                    const changedState = find(get(changedWorkflow, 'States'), w => w.StateName === StateName);
                    const targetContentName: string = find(Object.keys(props), key => key in targetContentMap && props[key]) as string;
                    const templateKeyName = get(targetContentMap[targetContentName], 'templateKeyName');
                    (changedState as any)[templateKeyName] = NewTemplateKey;
                    const workflowContents = update(state.workflowContents, {
                        $merge: {
                            contentConfigs: workflowStateConfigs && [...workflowStateConfigs]
                        },
                    });

                    return {
                        ...state,
                        saveLoading: false,
                        workflowContents
                    };
                }
                case TemplateType.ManualContent: {
                    const manualTemplates = state.manualContents.templates;
                    const templateContent = find(manualTemplates, c => c.TemplateName === TemplateContentName);
                    const targetContentName: string = find(Object.keys(props), key => key in targetContentMap && props[key]) as string;
                    const fileType = get(targetContentMap[targetContentName], 'fileType');
                    const file = find(get(templateContent, 'Files'), f => f.Type === fileType);
                    if (file) {
                        const parts = split(NewTemplateKey, '/');
                        file.Name = parts[parts.length - 1];
                    }
                    const manualContents = update(state.manualContents, {
                        $merge: {
                            templates: manualTemplates && [...manualTemplates]
                        },
                    });

                    return {
                        ...state,
                        saveLoading: false,
                        manualContents
                    };
                }
                case TemplateType.Others: {
                    const contentTemplateConfig = state.otherContents.contentConfig;
                    const templateContent = find(get(contentTemplateConfig, 'TemplateContents'), c => c.TemplateName === TemplateContentName);
                    if (templateContent) {
                        templateContent.TemplateContentS3Key = NewTemplateKey;
                    }
                    const otherContents = update(state.otherContents, {
                        $merge: {
                            contentConfig: contentTemplateConfig && { ...contentTemplateConfig }
                        },
                    });

                    return {
                        ...state,
                        saveLoading: false,
                        otherContents
                    };
                }
            }
        }
        case ContentsActionTypes.SAVE_CONTENT_TEMPLATE_ERROR: {
            return {
                ...state,
                saveLoading: false
            };
        }

        case ContentsActionTypes.CHANGE_CONTENT_SELECTION: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selection: { ...action.payload }
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case ContentsActionTypes.CHANGE_PREVIEW_VERSION: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    preview: {
                        ...state.activeData.preview,
                        version: action.payload
                    },
                    previewErrors: initialState.activeData.previewErrors
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS: {
            return { ...initialState };
        }

        case ContentsActionTypes.CLEAR_CONTENT_STATES: {
            return { ...initialState };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as contentsReducer };

import { Col, Form, Row, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { getContentTemplateAction, getContentTemplateConfigurationAction } from '../../store/contents/actions';
import { ContentsState, SaveContentTemplatePayload, TemplateType } from '../../store/contents/types';
import { getContentSelection, getContentTemplateConfig } from '../../store/contents/sagas';
import { find, get, map } from 'lodash';
import FormItem from 'antd/lib/form/FormItem';
import ContentsManagementForm from './ContentsManagementForm';
import { getTranslatedText } from '../../utils/commonFunctions';


const { Option } = Select;

interface IProps {
    form: WrappedFormUtils;
    valueChanges: any;
    setValueChanges: (valueChanges: any) => void;
}

const formFieldNames = {
    TemplateContentName: 'TemplateContentName'
};

const OtherContentsManagementPage: React.FC<IProps> = ({ form, ...props }) => {
    const dispatch = useDispatch();
    
    const { getFieldDecorator } = form;
    const {
        loading: contentLoading
    }: ContentsState['activeData'] = useSelector((state: ApplicationState) => state.contents.activeData);
    const { errorMessages, loading: configLoading }: ContentsState['otherContents'] = useSelector((state: ApplicationState) => state.contents.otherContents);
    const templateConfig: ContentsState['otherContents']['contentConfig'] = useSelector(getContentTemplateConfig);
    const contentSelection: ContentsState['activeData']['selection'] = useSelector(getContentSelection);
    const selectedTemplateContentName = get(contentSelection, 'TemplateContentName');
    const formFulfilled = !!selectedTemplateContentName;
    const contentSelectionFields = [
        formFieldNames.TemplateContentName
    ];

    const generateContentsFilter = (clearData: () => void) => {
        return <Row gutter={[30, 10]} type="flex">
            <Col style={{ minWidth: 250, width: '25%' }}>
                <FormItem label={getTranslatedText("Target content")}>
                    {getFieldDecorator(formFieldNames.TemplateContentName, {
                        initialValue: selectedTemplateContentName,
                        rules: [],
                    })(
                        <Select
                            loading={configLoading}
                            disabled={configLoading || contentLoading}
                            placeholder={getTranslatedText("Choose a template")}
                            onChange={(_) => clearData()}
                        >
                            {map(get(templateConfig, 'TemplateContents'),
                                ({ TemplateName }) => (
                                    <Option key={TemplateName} value={TemplateName}>
                                        {TemplateName}
                                    </Option>
                                )
                            )}
                        </Select>
                    )}
                </FormItem>
            </Col>
        </Row>;
    };

    const generatePayload = (currentTemplate: string) => {
        const payload: SaveContentTemplatePayload = {
            Type: TemplateType.Others,
            TemplateContentName: selectedTemplateContentName,
            TemplateContent: currentTemplate
        };
        return payload;
    }

    const fetchInitialData = () => {
        if (!templateConfig) {
            dispatch(getContentTemplateConfigurationAction())
        }
    }

    useEffect(fetchInitialData, [templateConfig]);

    const fetchOtherContentTemplate = () => {
        if (selectedTemplateContentName) {
            const TemplateContent = find(get(templateConfig, 'TemplateContents'), c => c.TemplateName === selectedTemplateContentName);
            if (TemplateContent) {
                dispatch(getContentTemplateAction({
                    TemplateKey: get(TemplateContent, 'TemplateContentS3Key')
                }));
            }
        }
    };

    useEffect(fetchOtherContentTemplate, [selectedTemplateContentName]);

    return <ContentsManagementForm
        form={form}
        formTitle="Other contents"
        contentSelectionFields={contentSelectionFields}
        formFulfilled={formFulfilled}
        generateContentsFilter={generateContentsFilter}
        generatePayload={generatePayload}
        refreshCurrentContent={fetchOtherContentTemplate}
        dataErrors={errorMessages}
        {...props}
    />
};

const OtherContentsManagementPageForm: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const ContentsManagementPageForm = Form.create({
            name: 'other-contents-management-page-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(OtherContentsManagementPage);

        return withRouter(ContentsManagementPageForm);
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default OtherContentsManagementPageForm;
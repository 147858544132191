/**
 * File responsible for all the UI and actions for Settings>Company page - multi factor authentication tab item - `/app/settings/company`.
 */

import { Card, Col, Row, Switch, Typography } from 'antd';
import { History as IHistory } from 'history';
import { get, isEqual } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { saveUserCompanyRequestAction } from '../../../../store/companies/actions';
import { Company } from '../../../../store/companies/types';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';
import FormItem from 'antd/lib/form/FormItem';

import { getTranslatedText } from '../../../../utils/commonFunctions';

const { Title } = Typography;

interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
}
const MultiFactorAuthenticationPage: React.FC<IProps> = forwardRef(
    (
        {
            disabled,
            selectedUserCompany,
            handleModalSaveResponse,
            history,
            setFormHasChanges,
        }: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();
        
        const [mfaFormState, setmfaFormState] = useState<{}>({});

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            const formState: DynamicObject = { ...mfaFormState };

            const constructedState = {
                ...selectedUserCompany,
                ...formState,
            };

            const isChanged = !isEqual(selectedUserCompany, constructedState);

            if(isChanged) {
                setFormHasChanges();
            }

            return isChanged;
        };

        /**
         * Function responsible for syncing the data used inside this form from the redux state
         * where all the company data are saved.
         */
        const syncStateFromRedux = () => {
            const isEnableMultiFactorAuthentication = get(
                selectedUserCompany,
                'EnableMultiFactorAuthentication',
                false
            );

            setmfaFormState({EnableMultiFactorAuthentication: isEnableMultiFactorAuthentication});
        };

        useEffect(syncStateFromRedux, [selectedUserCompany]);

        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            const formState: DynamicObject = { ...mfaFormState };

            formState.DetailType = 'MultiFactorAuthentication';
            dispatch(
                saveUserCompanyRequestAction(formState, handleModalSaveResponse)
            );
        };

        /**
         * Function called when cancel is clicked.
         * Resets all the changes and set the data based on the one saved in redux (the original saved data).
         */
        const handleCancel = () => {
            syncStateFromRedux();
        };

        /**
         * Function responsible for updating the new changing state.
         * @param checked - Multi Factor Authentication checked or unchecked
         */
        const onChangeMultiFactorAuthentication = (checked: boolean) => {
            setmfaFormState({
                EnableMultiFactorAuthentication: checked
            });
        };

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>{getTranslatedText("Multi Factor Authentication")}</Title>
                        </Col>
                    </Row>
                    <br />
                    <Row key="multi-factor-authentication-container">
                        <Col span={24}>
                            <Card className="card-rounded-border">
                                <Row>
                                    <Col span={24}>
                                        <Row type="flex" align="middle">
                                            <Col span={8}>
                                                <Title level={4}>{getTranslatedText("Enable Multi Factor Authentication")}</Title>
                                            </Col>
                                            <Col span={4}>
                                                <FormItem style={{ marginBottom: 0 }}>
                                                    <Switch
                                                        checked = {get(
                                                            mfaFormState,
                                                            'EnableMultiFactorAuthentication',
                                                            false
                                                        )}
                                                        checkedChildren={getTranslatedText("ON")}
                                                        unCheckedChildren={getTranslatedText("OFF")}
                                                        disabled={disabled}
                                                        onChange={onChangeMultiFactorAuthentication}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        );
    }
);

export default MultiFactorAuthenticationPage;
/**
 * File for the `Notifications` widget form.
 */

import {
    Col,
    DatePicker,
    Divider,
    Form,
    Row,
    Select,
    Switch,
    TreeSelect,
} from 'antd';
import { capitalize, get, isUndefined, map, toLower } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    chartWidgetDisplayTypes,
    salesDateRangeOptions,
    widgetDateRangeValues,
    historicalChartWidgetDisplayTypes,
} from '../../constants/dashboards';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { populatePopoverContainer, getTranslatedText, getSelectedCurrencyRate, getDashboardFilterCompanyIds } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters, { allOptionsSelectedKey, commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';
import { TasksState } from '../../store/tasks/types';
import { ApplicationState } from '../../store';
import { getWorkflowFilterOptions, taskAutomationWidget, taskContactType } from '../../constants/tasksSortAndFilters';
import { CompanyUserRole } from '../../store/companies/types';
import { getTasksActionFilterOptionsRequestAction } from '../../store/tasks/actions';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';
import { Organisation } from '../../store/organisations/types';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    getFieldValue: any;
    setFieldsValue: any;
    isOrgView?: boolean;
}

export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');

export const displayColumnIndicator = 'DisplayColumn---';

export const displayColumnOptions = {
    StateName: {
        label: 'Type',
        value: 'StateName',
        defaultChecked: true,
        type: 'StateName',
        color: '#a6a6a6',
    },
    OpenedCount: {
        label: 'Opened Count',
        value: 'OpenedCount',
        defaultChecked: true,
        type: 'OpenedCount',
        color: '#fd6a02',
    },
    FailedCount: {
        label: 'Failed Count',
        value: 'FailedCount',
        defaultChecked: true,
        type: 'FailedCount',
        color: '#00c49f',
    },
    DeliveredCount: {
        label: 'Delivered Count',
        value: 'DeliveredCount',
        defaultChecked: true,
        type: 'DeliveredCount',
        color: '#0088fe',
    },
    ContactType: {
        label: 'Contact Type',
        value: 'ContactType',
        defaultChecked: true,
        type: 'ContactType',
        color: '#0088fe',
    },
};

const CommunicationDeliveryWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    getFieldValue,
    setFieldsValue,
    isOrgView,
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const currencyCode: string = useSelector(
        (state: ApplicationState) => state.app.currencyCode
    );

    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );

    let usingCustomerWorkflow = false;
    let usingInvoiceWorkflow = false;
    let isPaymentPlanEnabled = false;
    let supportCashAllocation = false;
    let hasTickets = false;

    if (isOrgView) {
        for (const company of organisationCompanies) {
            if (!usingCustomerWorkflow) {
                usingCustomerWorkflow = get(
                    company,
                    'UsingCustomerWorkflow'
                );
            }

            if (!usingInvoiceWorkflow) {
                usingInvoiceWorkflow = !get(
                    company,
                    'UsingCustomerWorkflow'
                );
            }

            if (!isPaymentPlanEnabled) {
                isPaymentPlanEnabled = get(
                    company,
                    'CompanyPaymentPlan.IsEnabled'
                );
            }
        }
    }
    else {
        usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        usingInvoiceWorkflow = !get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        isPaymentPlanEnabled = get(
            selectedUserCompany,
            'Company.CompanyPaymentPlan.IsEnabled'
        );

    }

    const taskWorkflowOptions = getWorkflowFilterOptions(
        tasksState,
        usingCustomerWorkflow,
        usingInvoiceWorkflow,
        isPaymentPlanEnabled,
        supportCashAllocation,
        hasTickets
    ); 

    const getFilterCompanyIds = (isUsingDefaultOptions: boolean = false) => {
        const selectedCompanyIds = getDashboardFilterCompanyIds(
            getFieldValue,
            widgetDetails,
            commonOrgFormFields,
            organisationCompanies,
            allOptionsSelectedKey,
            isUsingDefaultOptions
        );

        return selectedCompanyIds;
    };
    
    /**
     * Function that populates the date range options.
     */
    const populateDateRangeOptions = () =>
        map(salesDateRangeOptions, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {getTranslatedText(label)}
            </Option>
        ));

      /**
     * Function that populates the task automation options
     */
      const populateAutomationOptions = () =>
      map(taskAutomationWidget, ({ label, value }: any) => (
          <Option key={value} value={value}>
              {label}
          </Option>
      ));
    
       /**
     * Function that populates the task automation options
     */
       const populateTypeOptions = () =>
       map(taskContactType, ({ label, value }: any) => (
           <Option key={value} value={value}>
               {label}
           </Option>
       ));

    useEffect(() => {
            getActionFilterOptions();
    }, []);
    
    /**
     * Function for fetching the action filter options.
     */
    const getActionFilterOptions = () => {
        dispatch(getTasksActionFilterOptionsRequestAction(getFilterCompanyIds(true), isOrgView));
    };
        
    /**
     * Function that populates the start and end date form input when custom date range is selected.
     */
    const populateCustomDateRangeFormItem = () => {
        if (
            getFieldValue('dateRangeSelected') ===
            widgetDateRangeValues.CUSTOM_DATE_RANGE
        ) {
            return (
                <Col span={24}>
                    <Row gutter={10} className="mt-10">
                        <Col span={12}>
                            <FormItem label={getTranslatedText("Start date")}>
                                {getFieldDecorator('customDateRangeStart', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeStart'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeStart'
                                              )
                                          )
                                        : moment(defaultStartDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Start date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("Start date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={getTranslatedText("End date")}>
                                {getFieldDecorator('customDateRangeEnd', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeEnd'
                                    )
                                        ? moment(
                                              get(
                                                  widgetDetails,
                                                  'customDateRangeEnd'
                                              )
                                          )
                                        : moment(defaultEndDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('End date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("End date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };


    /**
     * Function that populates the filter for notification grouping switches.
     */
    const populateDisplayColumnOptionsFilterSection = () => {
        return map(
            displayColumnOptions,
            ({ label, value, defaultChecked }: DynamicObject) => {
                const displayColumnKey = `${displayColumnIndicator}${value}`
                const switchValue = !isUndefined(get(widgetDetails, displayColumnKey))
                    ? get(widgetDetails, displayColumnKey)
                    : defaultChecked;

                return (
                    <Col
                        key={value}
                        span={12}
                        className="switch-fields-container"
                    >
                        <FormItem label={getTranslatedText(label)}>
                            {getFieldDecorator(displayColumnKey, {
                                valuePropName: 'checked',
                                initialValue: switchValue,
                            })(<Switch disabled={formDisabled} />)}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});
    
     /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
     const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    const populateCustomerFieldSection = () => {
        const filterNameUsed = !isOrgView
            ? capitalize(customerLabel)
            : 'Customer'

        const stateName = 'Customer';
        return (
            <Col span={24} key={stateName}>
                <FormItem label={getTranslatedText(filterNameUsed)}>
                    <InputAutoCompleteWithButtonDashboard
                        readOnly={formDisabled}
                        hasNoOkButton={true}
                        updateField={(value: string) => {
                            changeSearchFilter(stateName, value);
                        }}
                        stateValue={searchFilters[stateName]}
                        queryName={isOrgView ? 'GET_CUSTOMERS_FOR_ORGANISATION_AUTOCOMPLETE_FILTER' : 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                        filterField={'Customer'}
                        sortField={'Company name'}
                        responseName={'GetCustomersForCompany.Customers'}
                        labelField={'DisplayName'}
                        getFieldDecorator={getFieldDecorator}
                        widgetDetails={widgetDetails}
                        filterFieldKey={'Customer'}
                        extraQueryVariables={isOrgView ?
                            {
                                CompanyIds: getFilterCompanyIds(),
                                ConversionCurrency: getSelectedCurrencyRate(
                                    selectedUserOrganisation,
                                    currencyCode
                                )
                            } : undefined
                        }
                    />
                </FormItem>
            </Col>
        );
    } ;

    /**
     * Function that populates the dropdown based on given list of options.
     */
       const populateWidgetDisplayTypeOptions = () =>
       map(chartWidgetDisplayTypes, ({ label, value }: any) => (
           <Option key={value} value={value}>
               {getTranslatedText(label)}
           </Option>
       ));

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display type")}>
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '2.value'
                                    ),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateWidgetDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>                     
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on date range")}>
                            {getFieldDecorator('dateRangeSelected', {
                                initialValue:
                                    get(widgetDetails, 'dateRangeSelected') ||
                                    widgetDateRangeValues.THIS_CALENDAR_YEAR,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Date range required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateDateRangeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                    {populateCustomDateRangeFormItem()}
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on task automation")}>
                            {getFieldDecorator('taskAutomation', {
                                initialValue: get(widgetDetails, 'taskAutomation'),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    placeholder={getTranslatedText("Select Automation Type")}
                                    mode ='multiple'
                                >
                                    {populateAutomationOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter by task workflow types")}>
                            {getFieldDecorator('tasksWorkflows', {
                                initialValue: !tasksState.actionFilterOptionsLoading ?
                                get(widgetDetails, 'tasksWorkflows') : undefined
                            })(
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    treeData={taskWorkflowOptions}
                                    placeholder={getTranslatedText("Select Workflow Type")}
                                    treeDefaultExpandAll={false}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    treeCheckable={true}
                                    treeCheckStrictly={false}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on contact type")}>
                            {getFieldDecorator('taskType', {
                                initialValue: get(widgetDetails, 'taskType'),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    placeholder={getTranslatedText("Select Contact Type")}
                                    mode ='multiple'
                                >
                                    {populateTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)}`)}
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>{populateCustomerFieldSection()}</Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <h3>{getTranslatedText('Filter on communication delivery type')}</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateDisplayColumnOptionsFilterSection()}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional={true}
                    />
                )}
            </Col>
        </Row>
    );
};

export default CommunicationDeliveryWidgetFields;

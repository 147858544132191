/**
 * File responsible for all the UI and actions for Settings>Access tokens page - `/app/settings/personal-access-tokens`.
 */

import { Col, Modal, Row, Typography } from 'antd';
import {
    difference,
    filter,
    get,
    includes,
    isEmpty,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActionBar from '../../components/common/ActionBar';
import AvatarNameInitialsComponent from '../../components/common/AvatarNameInitialsComponent';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import PersonalAccessTokenItemComponent from '../../components/settings/PersonalAccessTokenItemComponent';
import TokenCreateDrawerContent from '../../components/settings/TokenCreateDrawerContent';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import { ACCESS_TOKENS_PAGE } from '../../config/tableAndPageConstants';
import {
    CompanyIdAttribute,
    SubAttribute,
} from '../../constants/authUserAttributes';
import { ApplicationState } from '../../store';
import {
    getAccessTokensRequestAction,
    revokeAccessTokensRequestAction,
} from '../../store/accessTokens/actions';
import { getCurrentUser } from '../../store/accessTokens/sagas';
import { AccessTokensState, AccessToken } from '../../store/accessTokens/types';
import { CompanyUserRole } from '../../store/companies/types';
import { computeTableScroll, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

const PersonalAccessTokenItemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../components//settings/PersonalAccessTokenItemDetailsDrawerComponent'
        )
);

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Title } = Typography;
const { confirm } = Modal;

interface IProps {
    history: {
        push: (path: string) => void;
    };
    match: {
        path: string;
    };
}

let lastSelectedCompanyId: null | string = null;
let resetTableScroll = false;
let isRefetching = false;
const PersonalAccessTokenManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    
    const currentUser = useSelector(getCurrentUser);
    const currentUserId: string = get(currentUser, SubAttribute);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const accessTokensState: AccessTokensState = useSelector(
        (state: ApplicationState) => state.accessTokens
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(accessTokensState, 'pageData.currentPage', 0)
    );

    const [showConditions, setShowConditions] = useState<{
        tokenRevokeModal: boolean;
        tokenCreateDrawer: boolean;
        allSelected: boolean;
    }>({
        tokenRevokeModal: false,
        tokenCreateDrawer: false,
        allSelected: false,
    });

    const [tokenDetailsDrawerValues, setTokenDetailsDrawerValues] = useState<{
        visible: boolean;
        record: DynamicObject;
    }>({
        visible: false,
        record: {},
    });

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: any[];
        unselectedRowKeys: any[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Fetch the AccessTokens from API.
     * @param currentPage - page where the scroll is at
     * @param pageSize - number of items in a page
     */
    const fetchAccessTokens = () => {
        if (isEmpty(selectedUserCompany)) return;

        if (!isRefetching) resetTableScroll = false;
        dispatch(getAccessTokensRequestAction());
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and company switch.
     */
    const callFetchAccessTokens = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                resetTableScrollAndPageData();
            }
            fetchAccessTokens();
        }
    };

    useEffect(callFetchAccessTokens, [selectedUserCompany]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
        };
    }, []);

    // /**
    //  * Function that changes the user filter (the dropdown menu right next to the sync/refresh button).
    //  * @param filter - dropdown view value
    //  */
    // const changeAccessTokensTableFilter = (
    //     filter: string,
    //     refetch: boolean = true
    // ) => {
    //     if (!initialTableFilter) {
    //         initialTableFilter = filter;
    //     } else {
    //         if (filter !== initialTableFilter) {
    //             updateShowConditionsObject({
    //                 allSelected: false,
    //                 filterBar: true,
    //             });

    //             resetAllSelectedRowKeys(true);
    //         } else {
    //             resetAllSelectedRowKeys();
    //         }
    //     }

    //     if (filter !== accessTokensState.tableFilter) {
    //         skipListenToPreconfiguredFilter = false;
    //     }

    //     setAccessTokensTableFilter(filter);
    //     resetTableScrollAndPageData();
    //     if (refetch && filter === accessTokensState.tableFilter) {
    //         handleUserFilterRefresh();
    //     }
    // };

    /**
     * A common function for updating tableRowSelection state object.
     * @param selectionObject - object to be updated. Key value pair must conform to tableRowSelection
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function triggered when checkbox for the row is selected.
     * @param record - the record/data for the said row - based on API
     * @param selected - if the checkbox is checked or unchecked
     * @param selectedRows - list of keys for the selected row
     * @param nativeEvent - native event for the Checkbox component / boolean
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Checking if the current user role is allowed to change other user's role.
     * Other accessTokens only - cause the checkbox for the current user is disabled.
     */
    const allowedRevokeAccessTokensRole = true;

    /**
     * Function called when the checkbox is clicked.
     * @param record - record for the row - type is `any` because there may be some adjustments and additional keys that will be added
     */
    const onCheckboxClick = (record: any) => {
        if (allowedRevokeAccessTokensRole && record.key !== currentUserId) {
            const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
            const isCurrentlySelected = includes(selectedRowKeys, record.key);
            const newSelectedRowKeys = !isCurrentlySelected
                ? [...selectedRowKeys, record.key]
                : remove(selectedRowKeys, (key: string) => key !== record.key);

            onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
        }
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     */
    const onRowClick = (record: DynamicObject) => {
        setTokenDetailsDrawerValues({
            visible: true,
            record,
        });
    };

    const selectedKeysName = 'Id';

    /**
     * Function that cwill be called when user state data is changed.
     * Basically checks if select all is clicked, and make all the checkboxes checked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(accessTokensState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            const idKeys = filter(
                selectedKeys,
                (key: string) => key !== currentUserId
            );

            updateTableRowSelection({
                selectedRowKeys: idKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [currentUserId, accessTokensState.data]);

    /**
     * Function that is responsible for which modal should be shown after revoking access token/s (Success/Error).
     * Will receive an object that will contain a boolean named IsSuccess.
     * @param param0 - specified as an object because an error message from API may be added someday.
     */
    const revokeAccessTokenResponseModal = ({
        IsSuccess,
    }: {
        IsSuccess: boolean;
    }) => {
        updateShowConditionsObject({
            allSelected: false,
            tokenRevokeModal: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText(`${tableRowSelection.selectedRowKeys.length > 1 ? 'Access tokens' : 'Access token'} revoked successfully`),
                onOk: () => {
                    refetchListAndResetScroll();
                    updateTableRowSelection({
                        selectedRowKeys: [],
                        unselectedRowKeys: [],
                    });
                },
                okText: getTranslatedText("OK")
            });
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText(`Failed to revoke the ${tableRowSelection.selectedRowKeys.length > 1 ? 'access tokens' : 'access token'}`),
                okText: getTranslatedText("OK")
            });
        }
    };

    /**
     * Function that resets the selected and unselected row keys to their initial values and also the allSelected flag
     * for detecting the status of Select All button.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
    };

    /**
     * Function responsible for showing a confirmation and eventually revoking the access tokens.
     */
    const revokeAccessTokensOfSelectedRows = () => {
        updateShowConditionsObject({
            tokenRevokeModal: false,
        });
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }

        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Confirm"),
            content: getTranslatedText(`Are you sure you want to revoke the selected access ${keysToUse.length > 1 ? 'tokens' : 'token'}?`),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            onOk() {
                dispatch(
                    revokeAccessTokensRequestAction(
                        get(keysToUse, 0),
                        revokeAccessTokenResponseModal
                    )
                );
            },
        });
    };

    /**
     * Function that resets the current page and scroll location.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for updating the showConditions state.
     * @param showConditionObject
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function that controls the visibility of popover.
     * @param name
     * @param condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'tokenCreateDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for populating the title section of change role modal.
     */
    const populateChangeRoleRequestModalTitle = () => 'Revoking access token';

    /**
     * Function for populating the body section of change role modal.
     */
    const populateChangeRoleRequestModalDisplayMessage = () =>
        `Please wait while revoking the ${tableRowSelection.selectedRowKeys.length > 1 ? 'access tokens' : 'access token' }`;

    /**
     * Function for populating the loading text for table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } access tokens`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function for setting the proper value / object needed for populating the table.
     */
    const getDataSource = () => {
        const dataSource: unknown[] | undefined = [];
        map(accessTokensState.data, (accessTokenData: AccessToken) => {
            if (isEmpty(get(accessTokenData, 'ParentUser'))) return;

            const role = get(accessTokenData, 'Role.Name');
            const GivenName = get(accessTokenData, 'ParentUser.GivenName');
            const FamilyName = get(accessTokenData, 'ParentUser.FamilyName');
            const fullName =
                GivenName && FamilyName ? `${GivenName} ${FamilyName}` : '';
            dataSource.push({
                Id: get(accessTokenData, 'Key'),
                fullName,
                name: get(accessTokenData, 'Name'),
                role,
                apiKey: get(accessTokenData, 'Key'),
                createdDateTime: get(accessTokenData, 'CreatedDateTime'),
                active: get(accessTokenData, 'Active'),
                avatar: (
                    <AvatarNameInitialsComponent
                        fullName={fullName}
                        size="large"
                    />
                ),
            });
        });

        return dataSource;
    };

    const dataSource = getDataSource();

    /**
     * Function called when sync/refresh button is clicked.
     */
    const handleUserFilterRefresh = () => {
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchAccessTokens();
    };

    /**
     * Function for populating the token create drawer content.
     */
    const populateTokenCreateDrawerContent = () => {
        return (
            <TokenCreateDrawerContent
                onCancelClick={(refetchList?: boolean) => {
                    updateShowConditionsObject({
                        tokenCreateDrawer: false,
                    });

                    if (refetchList) {
                        refetchListAndResetScroll();
                    }
                }}
                visible={showConditions.tokenCreateDrawer}
            />
        );
    };

    const closeTokenDetailsDrawer = () => {
        setTokenDetailsDrawerValues({
            visible: false,
            record: {},
        });
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText("Personal Access Token")}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Action Bar */}
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        loading={accessTokensState.loading}
                        filterBarOpen={false}
                        actionItems={[
                            {
                                actionKey: 'refetch-access-tokens',
                                actionType: 'protected-button',
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Refresh")}</span>
                                    </>
                                ),
                                buttonDisabled: accessTokensState.loading,
                                onButtonClick: handleUserFilterRefresh,
                                style: {
                                    paddingLeft: 0,
                                },
                            },
                            // {
                            //     actionKey: 'user-select-all',
                            //     actionType: 'protected-button',
                            //     allowedRoles: rolePermissions.USER_CHANGE_ROLE,
                            //     buttonDisabled: isEmpty(accessTokensState.data),
                            //     onButtonClick: selectAllRows,
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'check-double']}
                            //             />
                            //             <span>
                            //                 {populateSelectDeselectAllLabel()}
                            //             </span>
                            //         </>
                            //     ),
                            // },
                            {
                                actionKey: 'token-revoke',
                                actionType: 'protected-button',
                                onButtonClick: revokeAccessTokensOfSelectedRows,
                                buttonDisabled:
                                    isEmpty(
                                        tableRowSelection.selectedRowKeys
                                    ) ||
                                    tableRowSelection.selectedRowKeys.length >
                                        1,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'undo']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Revoke")}</span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'token-create',
                                actionType: 'protected-drawer-button',
                                // allowedRoles:
                                //     rolePermissions.TASK_ACTION_SEND_NOTIFICATION,
                                popoverVisible:
                                    showConditions.tokenCreateDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'tokenCreateDrawer'
                                ),
                                popoverTitle: getTranslatedText("Create a personal access token"),
                                popoverContent: populateTokenCreateDrawerContent(),
                                buttonLoading: false,
                                buttonDisabled: false,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'user-lock']}
                                        />
                                        <span>{getTranslatedText("Create token")}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                        ]}
                        actionEllipsis={{}}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={dataSource}
                            // fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                ACCESS_TOKENS_PAGE.pageSize
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            hideCheckbox={!allowedRevokeAccessTokensRole}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={accessTokensState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={"No access tokens found"}
                            hasNextPage={false}
                            itemComponent={PersonalAccessTokenItemComponent}
                            rerenderTrigger={{
                                dataSource,
                                selectedRowKeys:
                                    tableRowSelection.selectedRowKeys,
                            }}
                            itemHeight={ACCESS_TOKENS_PAGE.rowHeight}
                            selectedId={get(
                                tokenDetailsDrawerValues.record,
                                'Id'
                            )}
                        />
                    </Col>
                </Row>
            </QueueAnim>
            <Suspense fallback={null}>
                <PersonalAccessTokenItemDetailsDrawerComponent
                    visible={tokenDetailsDrawerValues.visible}
                    record={tokenDetailsDrawerValues.record}
                    closeDrawer={closeTokenDetailsDrawer}
                />
            </Suspense>
            {accessTokensState.revokeAccessTokensLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(populateChangeRoleRequestModalTitle())}
                        modalVisible={
                            accessTokensState.revokeAccessTokensLoading
                        }
                        displayMessage={getTranslatedText(populateChangeRoleRequestModalDisplayMessage())}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default withRouter(PersonalAccessTokenManagementPage);

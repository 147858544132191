import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
} from '../../config/config';
import queries from '../../graphql/queries.graphql';
import { DynamicObject } from '../../utils/commonInterfaces';
import { WorkflowActionTypes } from './types';
import { getWorkflowConfigurationsErrorAction, getWorkflowConfigurationsSuccessAction, getWorkflowDefinitionErrorAction, getWorkflowDefinitionSuccessAction, setWorkflowSelectedIdSuccessAction } from './actions';
import { CompanyUserRole } from '../companies/types';
import { getCompanyWorkflowOptionsRequestAction } from '../companies/actions';
import { clearWorkflowContentConfiguration } from '../contents/actions';

export const getWorkflowConfigurationsData = (state: ApplicationState) =>
    state.workflows.activeData;

export const getWorkflowSelectedId = (state: ApplicationState) =>
    state.workflows.activeData.selectedId;

export const getWorflowsState = (state: ApplicationState) =>
    state.workflows;

/**
 * Function called for getting the workflow step options for task editing.
 * @param param0
 */
function* handleGetWorkflowDefinitionRequest({ payload: sagaPayload }: any) {
    const errorMessage = 'Error fetching workflow definitions. Please try again later.';
    try {
        const { WorkflowId } = sagaPayload;
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_DEFINITION, {
                WorkflowId
            })
        );

        const workflowDefinition = get(res.data, 'GetWorkflowDefinition');

        if (workflowDefinition) {
            yield put(getWorkflowDefinitionSuccessAction(workflowDefinition));
        } else {
            yield put(getWorkflowDefinitionErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getWorkflowDefinitionErrorAction([errorMessage]));
    }
}

/**
 * Function called for saving the workflow definition - talking to the API.
 */
function* handleSaveWorkflowDefinitionRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/workflow/definition/update', {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleUpdateWorkflowRequest({ payload: sagaPayload }: any) {
    const { payload, isCreateNew, callback } = sagaPayload;
    try {
        const apiPath = isCreateNew ? '/workflow/update' : '/workflow/definition/steps/update'
        const res: DynamicObject = yield call([API, 'post'], API_NAME, apiPath, {
            body: payload,
        });

        if (callback) {
            const response = {
                IsSuccess: true
            };

            callback(response);
            yield put(getCompanyWorkflowOptionsRequestAction());
            yield put(clearWorkflowContentConfiguration());
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting the workflow step options for task editing.
 * @param param0
 */
function* handleWorkflowConfigurationRequest() {
    const errorMessage = 'Error fetching workflow configuration. Please try again later.';
    try {
        const selectedUserCompany: CompanyUserRole | undefined = yield select(
            (state: ApplicationState) => state.companies.selectedUserCompany
        );
        const usingCustomerWorkflow = get(selectedUserCompany, ['Company', 'UsingCustomerWorkflow']);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_CONFIGURATION_FOR_COMPANY)
        );

        const workflowConfiguration = get(res.data, 'GetWorkflowConfigurationForCompany');
        const workflows: DynamicObject[] = [];

        if (workflowConfiguration) {
            if (usingCustomerWorkflow) workflows.push(...(workflowConfiguration.Customers || []));
            else workflows.push(...(workflowConfiguration.Invoices || []));
            yield put(getWorkflowConfigurationsSuccessAction({
                data: workflows
            }));
        } else {
            yield put(getWorkflowConfigurationsErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getWorkflowConfigurationsErrorAction([errorMessage]));
    }
}

/**
 * Function that connects to delete workflows api.
 */
function* handleDeleteWorkflowsRequest({ payload: sagaPayload }: any) {
    const { WorkflowIds, callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.
        const apiResponse: DynamicObject = yield call([API, 'del'], API_NAME, '/workflow/delete', {
            body: {
                WorkflowIds
            },
        });

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: get(apiResponse, 'Messages')
            };

            callback(response);
            yield put(getCompanyWorkflowOptionsRequestAction());
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function that sets the selectedId upon clicking the row item.
 * @param param0
 */
function* handleSetWorkflowSelectedIdRequest({ payload }: any) {
    const { workflowId, callback } = payload;
    yield put(setWorkflowSelectedIdSuccessAction(workflowId));
    callback();
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetWorkflowDefinitionRequest() {
    yield takeLatest(
        WorkflowActionTypes.GET_WORKFLOW_DEFINITION_REQUEST,
        handleGetWorkflowDefinitionRequest
    );
}

function* watchSaveWorkflowDefinitionRequest() {
    yield takeLatest(
        WorkflowActionTypes.SAVE_WORKFLOW_DEFINITION_CONFIGURATION_REQUEST,
        handleSaveWorkflowDefinitionRequest
    );
}

function* watchUpdateWorkflowRequest() {
    yield takeLatest(
        WorkflowActionTypes.UPDATE_WORKFLOW_REQUEST,
        handleUpdateWorkflowRequest
    );
}

function* watchWorkflowConfigurationRequest() {
    yield takeLatest(
        WorkflowActionTypes.GET_WORKFLOW_CONFIGURATIONS_REQUEST,
        handleWorkflowConfigurationRequest
    );
}

function* watchSetWorkflowSelectedIdRequest() {
    yield takeLatest(
        WorkflowActionTypes.SET_WORKFLOW_SELECTED_ID_REQUEST,
        handleSetWorkflowSelectedIdRequest
    );
}

function* watchDeleteWorkflowsRequest() {
    yield takeLatest(
        WorkflowActionTypes.DELETE_WORKFLOWS_REQUEST,
        handleDeleteWorkflowsRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* workflowsSaga() {
    yield all([
        fork(watchGetWorkflowDefinitionRequest),
        fork(watchSaveWorkflowDefinitionRequest),
        fork(watchUpdateWorkflowRequest),
        fork(watchWorkflowConfigurationRequest),
        fork(watchSetWorkflowSelectedIdRequest),
        fork(watchDeleteWorkflowsRequest)
    ]);
}

export default workflowsSaga;

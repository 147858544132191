/**
 * Component for populating the Tasks > Task history page. `/app/tasks/history`
 */

import { 
    Button, 
    Col, 
    Row, 
    Select 
} from 'antd';
import { 
    filter, 
    forEach, 
    get, 
    isEmpty, 
    isEqual, 
    isUndefined, 
    map 
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import ActionBar from '../../../components/common/ActionBar';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';
import VirtualizedList from '../../../components/common/VirtualizedList';
import TaskHistoryItemComponent from '../../../components/tasks/task_history/TaskHistoryItemComponent';
import {
    PAGE_NAMES_FOR_VIEW,
    TASK_HISTORY_PAGE,
} from '../../../config/tableAndPageConstants';
import { OrganisationIdAttribute } from '../../../constants/authUserAttributes';
import { selectActionedByUserIdFilterMapping } from '../../../constants/common';
import {
    defaultPageSizeForReport,
    taskHistoryOrganisationSummaryPageQuery,
    taskHistoryOrganisationSummaryQueryName
} from '../../../constants/downloadToExcel';
import { invoicesSortValues } from '../../../constants/invoicesSortAndFilters';
import {
    paymentAllocationTaskHistoryType,
    paymentPlanApprovalTriggers,
    paymentPlanTaskHistoryType,
    taskHistoryActionedDateOptions,
    taskHistoryBatchTypes,
    taskHistoryDrawerTypes,
    taskHistorySortByOptions,
    taskHistoryTypeOptions,
} from '../../../constants/tasksSortAndFilters';
import { ApplicationState } from '../../../store';
import { downloadToExcelAction } from '../../../store/common/actions';
import { Company } from '../../../store/companies/types';
import { Organisation } from '../../../store/organisations/types';
import {
    getOrganisationTasksHistoryRequestAction,
    setSelectedTaskHistoryIdRequestAction,
    updateTaskHistoryFiltersAction,
    updateTaskHistorySortByAction,
    updateTaskHistoryTableFilterAction,
} from '../../../store/tasks/actions';
import { initialState } from '../../../store/tasks/reducer';
import {
    GetTaskHistoryRequestPayload,
    SetSelectedTaskHistoryRequestPayload,
    TasksState,
} from '../../../store/tasks/types';
import { getCurrentUser } from '../../../store/users/sagas';
import {
    checkIfEmailIsValid,
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getSelectedCurrencyRate,
    getTranslatedText,
    insertAt,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
} from '../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../utils/commonInterfaces';
import CreateScheduledReportDrawer from '../../common/CreateScheduledReportDrawer';

const TaskHistoryItemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../components/tasks/task_history/TaskHistoryItemDetailsDrawerComponent'
        )
);
const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../../components/common/FilterBar'));

const { Option } = Select;

interface IProps {
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
    readonly companies: Company[];
    readonly usingCustomerWorkflow: boolean;
}
let lastSelectedOrgId: string | null = null;
let resetTableScroll = false;
let fetchTaskHistoryRequired = false;
let skipListenToPreconfiguredFilter = false;
let isRefetching = false;
const tablePageSize = TASK_HISTORY_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.TASK_HISTORY_PAGE;
let initialTableFilter: undefined | string = undefined;

const OrganisationTasksHistoryContent: React.FC<IProps> = ({
    handlePageViewSelection,
    companies,
    usingCustomerWorkflow
}) => {
    const dispatch = useDispatch();
    let companyIds: string[] = [];
    if (!isUndefined(companies) &&  !isEmpty(companies)) {
        companyIds = companies.map((company) => company.CompanyId);
    }

    const currencyCode: string = useSelector(
        (state: ApplicationState) => state.app.currencyCode
    );

    const currentUser = useSelector(getCurrentUser); 

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const [taskHistoryDetailsDrawer, setTaskHistoryDetailsDrawer] = useState<{
        visible: boolean;
        drawerType: string;
    }>({
        visible: false,
        drawerType: taskHistoryDrawerTypes.AutomatedCommunication,
    });

    const taskHistoryState: TasksState['taskHistory'] = useSelector(
        (state: ApplicationState) => state.tasks.taskHistory
    );
    const selectedId: string | null = taskHistoryState.activeData.selectedId;
    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();
    const [tasksTableFilter, setTasksTableFilter] = useState<
        string | undefined
    >(
        isEqual(taskHistoryState.filters, initialState.taskHistory.filters)
            ? initialState.taskHistory.taskTableFilter
            : taskHistoryState.taskTableFilter
    );

    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.selectedUserOrganisation
    );

    const organisationLoading: Organisation = useSelector(
        (state: ApplicationState) => state.organisations.loading
    );

    const [tableFilters, setTableFilters] = useState<any>(
        taskHistoryState.filters
    );

    /**
     * Function for checking if there are applied filters currently save on redux.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(taskHistoryState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };
    
     /**
     * Function for checking if payment plans and CAT are supported.
     */
    const isPaymentPlanEnabled = companies.some(
        (company) => get(company, 'Company.CompanyPaymentPlan.IsEnabled') === true
    );

    const supportCashAllocation = companies.some(
        (company) => get(company, 'Company.SupportCashAllocation') === true
    );

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        actionDrawer: boolean;
        editDrawer: boolean;
        addCommentDrawer: boolean;
        certifyNotPaid: boolean;
        downloadToExcel: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        actionDrawer: false,
        editDrawer: false,
        addCommentDrawer: false,
        certifyNotPaid: false,
        downloadToExcel: false,
        createScheduledReport: false
    });

    const [tableSortStatus, setTableSortStatus] = useState<{
        sortBy: string;
        sortAscending: boolean;
    }>({
        sortBy: taskHistoryState.sortBy,
        sortAscending: taskHistoryState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(taskHistoryState, 'pageData.currentPage', 0)
    );

    /**
     * Function for preparing the filters to be used as payload for API.
     */
    const readyAllFiltersSelected = () => {
        let allFilters: DynamicObject = {
            ...taskHistoryState.filters
        };

        if(companies && isEmpty(allFilters.CompanyIds)) {
            allFilters.CompanyIds = companies.map(c => c.CompanyId);
        }

        const actionedByUserValue = allFilters.ActionedByUserId;
        if (actionedByUserValue) {
            if (checkIfEmailIsValid(actionedByUserValue)) {
                delete allFilters.ActionedByUserId;
                allFilters.ActionedByEmailAddress = actionedByUserValue;
            }
        }

        return allFilters;
    };

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetTaskHistoryRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: taskHistoryState.sortBy,
            sortAscending: taskHistoryState.sortAscending,
            pageSize,
            currentPage: currentPage,
            taskTableFilter: tasksTableFilter,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for calling the fetch task history API.
     * @param currentPage
     * @param pageSize
     */
    const fetchTaskHistory = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserOrganisation)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;

        dispatch(getOrganisationTasksHistoryRequestAction(payload));
    };

    /**
     * Function called in preparation to fetchTaskHistory.
     * Manages the page to be used when calling the API.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserOrganisation) ||
            taskHistoryState.loading
        )
            return;

        if (!taskHistoryState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchTaskHistory(nextPage);
    };

    /**
     * Listener function called when changes on selected user company, filter values, and sort values happens.
     */
    const callFetchTaskHistory = () => {
        if (!selectedUserOrganisation) return;
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrgId = get(selectedUserOrganisation, 'OrganisationId');

        if (organisationIdCognito === selectedOrgId) {
            if (lastSelectedOrgId !== selectedOrgId) {
                lastSelectedOrgId = selectedOrgId;
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!organisationLoading) fetchTaskHistory(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = taskHistoryState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortStatus.sortBy ||
            sortAscending !== tableSortStatus.sortAscending
        ) {
            updateTableSortStatusObject({
                sortBy,
                sortAscending,
            });
        }
    };

    useEffect(callFetchTaskHistory, [
        taskHistoryState.sortBy,
        taskHistoryState.sortAscending,
        taskHistoryState.filters,
        selectedUserOrganisation,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedOrgId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when the filter value on upper left dropdown (next to refresh button) has been updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeTaskHistoryTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    filterBar: true,
                });
            }
        }

        if (filter !== taskHistoryState.taskTableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setTasksTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === taskHistoryState.taskTableFilter) {
            handleTaskFilterRefresh();
        } else {
            dispatch(updateTaskHistoryTableFilterAction(filter));
        }
    };

    /**
     * Function called when `Refresh` button is clicked or the upper left dropdown filter has been set to initial state.
     */
    const handleTaskFilterRefresh = () => {
        fetchTaskHistoryRequired = true;
        skipListenToPreconfiguredFilter = false;
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching the data after an update.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchTaskHistory(0);
    };

    /**
     * Function for populating the menu items for the dropdown.
     * @param menu
     */
    const populateTaskFilterSelectDropdownRender = (menu: any) => (
        <div>{menu}</div>
    );

    /**
     * Function called when clicking the `Change sort order` button inside the ellipsis popover.
     * @param sortAscending - boolean indicator if the sort order is ascending or not
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateTaskHistorySortByAction({
                ...tableSortStatus,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        taskHistoryState.loading || isEmpty(taskHistoryState.data);

    /**
     * Function for populating the ellipsis popover upper section.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText("Filter")}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortStatus((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            taskHistoryState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
            <br />
            <Button type="link" 
            onClick={createScheduledReportHandler}
            disabled={downloadDisabled}>
            <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText('Create Scheduled Report')}</span>
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task',
            true,
        );

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
        };

        const payload = {
            Query: taskHistoryOrganisationSummaryPageQuery,
            OperationName: taskHistoryOrganisationSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.TASK_HISTORY_ORGANISATION_PAGE,
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task',
            true,
        );

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
        };

        const payload = {
            Query: taskHistoryOrganisationSummaryPageQuery,
            OperationName: taskHistoryOrganisationSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.TASK_HISTORY_ORGANISATION_PAGE,
            title: 'Task History Organisation',
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
    const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    

    /**
     * Function for populating the ellipsis popover lower section.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>{getTranslatedText("Sort By")}</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStatusObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortStatus.sortBy}
                >
                    {map(
                        taskHistorySortByOptions,
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => (
                            <Option key={value} value={value}>
                                {getTranslatedText(label)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        taskHistoryState.loading ||
                        tableSortStatus.sortBy === taskHistoryState.sortBy
                    }
                    onClick={applySortedByAndState}
                >
                    {getTranslatedText("Apply")}
                </Button>
            </div>
        </div>
    );

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { taskTableFilter } = taskHistoryState;
        if (taskTableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchTaskHistoryRequired) {
                fetchTaskHistory(0);
                fetchTaskHistoryRequired = false;
            }
        } else {
            handlePageViewSelection(
                taskTableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [taskHistoryState.taskTableFilter]);

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (taskHistoryState.taskTableFilter !== initialTableFilter) {
            setTasksTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeTaskHistoryTableFilter(initialTableFilter);
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called for applying the filters selected.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetTaskHistoryRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    tasksTableFilter,
                    fromFilterBar,
                    () => {
                        changeTaskHistoryTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.taskHistory.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortStatus(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }
        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateTaskHistoryFiltersAction(appliedFilters));
    };

    /**
     * Function called for applying the sort values selected.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortStatus;
        await dispatch(updateTaskHistorySortByAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function called for closing the task history details drawer.
     * Resets all the active data for the previously selected item in redux state.
     */
    const closeTaskHistoryDetailsDrawer = () => {
        updateTaskHistoryDetailsDrawerObject({
            visible: false,
        });
    };

    /**
     * Table Functions
     */
    /**
     * Function for updating the `tableSortStatus` state.
     * @param tableSortStatusObject - must conform to `tableSortStatus` state
     */
    const updateTableSortStatusObject = (tableSortStatusObject: {}) => {
        setTableSortStatus({
            ...tableSortStatus,
            ...tableSortStatusObject,
        });
    };

    /**
     * Function for updating the `taskHistoryDetailsDrawer` state.
     * @param taskHistoryDetailsDrawerObject - must conform to `tableSortStatus` state
     */
    const updateTaskHistoryDetailsDrawerObject =
        (taskHistoryDetailsDrawerObject: {}) => {
            setTaskHistoryDetailsDrawer({
                ...taskHistoryDetailsDrawer,
                ...taskHistoryDetailsDrawerObject,
            });
        };

    /**
     * Function called when a row item is clicked.
     * @param param0 - data for the clicked row item
     */
    const onRowClick = ({ BatchId, Type, Action, CompanyId, User }: DynamicObject) => {
        let drawerType: string = taskHistoryDrawerTypes.AutomatedCommunication;
        if (Type === taskHistoryBatchTypes.Changed ) {
            drawerType = taskHistoryDrawerTypes.ChangedTasks;
        } else if (Type === taskHistoryBatchTypes.Ticket) {
            drawerType = taskHistoryDrawerTypes.ResolvedTicket;
        } else if (Type === taskHistoryBatchTypes.Collection) {
            drawerType = taskHistoryDrawerTypes.Collections;
        } else if (Type === taskHistoryBatchTypes.PaymentPlan) {
            if (Object.values(paymentPlanApprovalTriggers).includes(Action)) {
                drawerType = taskHistoryDrawerTypes.PaymentPlanApproval;
            } else {
                drawerType = taskHistoryDrawerTypes.PaymentPlan;
            }
        } else if (Type === taskHistoryBatchTypes.PaymentAllocation) {
            drawerType = taskHistoryDrawerTypes.PaymentAllocation; 
        } else if (Type === taskHistoryBatchTypes.PaymentVerification) {
            drawerType = taskHistoryDrawerTypes.PaymentVerification;
        } else if (Type === taskHistoryBatchTypes.RemittanceAdvice) {
            drawerType = taskHistoryDrawerTypes.RemittanceAdvice; 
        } else if (Type === taskHistoryBatchTypes.PaymentBehaviourInsights) {
            drawerType = taskHistoryDrawerTypes.PaymentBehaviourInsights; 
        } else if (Type === taskHistoryBatchTypes.PhoneCall) {
            drawerType = taskHistoryDrawerTypes.PhoneCall; 
        }else if (Type === taskHistoryBatchTypes.Communication && User === null) {
            drawerType = taskHistoryDrawerTypes.ClosedNewInvoice
        }

        const setSelectedTaskHistoryRequestPayload: SetSelectedTaskHistoryRequestPayload = {
            BatchId,
            CompanyId,
            callback: () => 
                updateTaskHistoryDetailsDrawerObject({
                    visible: true,
                    drawerType,
            })
                
        };
        dispatch(
            setSelectedTaskHistoryIdRequestAction(
                setSelectedTaskHistoryRequestPayload
            )
        );
    };

    /**
     * Function to populate the loading text for the table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'} task history items`;

        isRefetching = false;

        return getTranslatedText(loadingText);
    };

    /**
     * Function for updating the visibility of popovers.
     * @param name - name of popover
     * @param condition - optional condition if the change should be applied
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'actionDrawer' ||
                    name === 'editDrawer' ||
                    name === 'addCommentDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Reset Functions
     */
    /**
     * Function for resetting the scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function called for closing the filter bar section.
     * Resets all the filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const filterItemList = useMemo(
        () => {
            const historyTypeOptionsUsed = filter(
                taskHistoryTypeOptions,
                (thto: DynamicObject) =>
                    (isPaymentPlanEnabled || paymentPlanTaskHistoryType != thto.label)
                    &&
                    (supportCashAllocation || paymentAllocationTaskHistoryType != thto.label) 
            );

            let tasksFilterBarFilters = [
                {
                    filterName: 'Type',
                    filterStateName: 'Type',
                    filterElement: 'checkbox-group',
                    filterType: 'array',
                    filterOptions: historyTypeOptionsUsed,
                    span: 3
                },
                {
                    filterName: 'Company',
                    filterStateName: 'CompanyIds',
                    filterElement: 'checkbox-group',
                    filterType: 'array',
                    filterOptions: !companies ? [] : companies.map(c => {
                        return {
                            label: c.Name,
                            value: c.CompanyId
                        }
                    }),
                    span: 3
                },
                {
                    filterName: 'Status',
                    filterStateName: 'StatusType',
                    filterElement: 'checkbox-group',
                    filterType: 'array',
                    filterOptions: [
                        {
                            label: 'Completed',
                            value: 2,
                        },
                        {
                            label: 'In Progress',
                            value: 1,
                        },
                        {
                            label: 'Failed',
                            value: 4,
                        },
                    ],
                    span: 3
                },
                {
                    filterName: 'Actioned date',
                    filterStateName: 'ActionDate',
                    filterElement: 'select',
                    filterType: 'text',
                    filterOptions: taskHistoryActionedDateOptions,
                    filterOptionsCustomTypes: {
                        'Custom date range': 'date-range',
                        'Custom days range': 'all-days-range-from-to-last',
                    },
                    span: 3
                },
                {
                    filterName: 'Customer',
                    filterStateName: 'Customer',
                    filterElement: 'input-autocomplete',
                    filterQuery: 'GET_CUSTOMERS_FOR_ORGANISATION_AUTOCOMPLETE_FILTER',
                    filterSort: 'Company name',
                    filterResponse: 'GetCustomersForOrganisation.Customers',
                    filterLabelField: 'DisplayName',
                    span: 3,
                    extraQueryVariables: {
                        CompanyIds: [
                            ...companyIds
                        ],
                        ConversionCurrency: getSelectedCurrencyRate(
                            selectedUserOrganisation,
                            currencyCode
                        ),
                    }
                },
                {
                    filterName: 'Actioned by',
                    filterStateName: 'ActionedByUserId',
                    filterNameQuery: 'Name',
                    filterElement: 'select-with-search',
                    filterQuery: 'GET_USERS_FOR_ORGANISATION_AUTOCOMPLETE_FILTER',
                    filterResponse: 'GetUsersForOrganisation.OrganisationUsers',
                    filterLabelField: ['User.GivenName', 'User.FamilyName'],
                    filterSort: 'Name',
                    filterNameQuerySub: 'Email',
                    filterSubChecking: checkIfEmailIsValid,
                    filterJSONValueFieldSub: 'User.Email',
                    span: 3,
                    tagClass: 'wb-bw',
                    filterMappingUsed: selectActionedByUserIdFilterMapping,
                    extraQueryVariables: {
                        CompanyIds: [
                            ...companyIds
                        ]
                    }
                },
            ];

            if (!usingCustomerWorkflow) {
                tasksFilterBarFilters = insertAt(tasksFilterBarFilters, 3, [
                    {
                        filterName: 'Invoice number',
                        filterStateName: 'InvoiceNumber',
                        filterElement: 'input-autocomplete',
                        filterQuery: 'GET_INVOICES_FOR_ORGANISATION_AUTOCOMPLETE_FILTER',
                        filterSort: invoicesSortValues.INVOICE_NUMBER,
                        filterResponse: 'GetInvoicesForOrganisation.Invoices',
                        filterLabelField: 'Number',
                        extraQueryVariables: {
                            CompanyIds: [
                                ...companyIds
                            ],
                            ConversionCurrency: getSelectedCurrencyRate(
                                selectedUserOrganisation,
                                currencyCode
                            ),
                        }
                    },
                ]);
            }

            return tasksFilterBarFilters;
        }, 
        [isPaymentPlanEnabled, supportCashAllocation, usingCustomerWorkflow]
    );

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };
    
    return (
        <>
            {/* Filter Bar */}
            <QueueAnim type="top" leaveReverse duration={300}>
                {showConditions.filterBar && (
                    <div key="filter-bar-container">
                        <Suspense fallback={null}>
                            <FilterBar
                                pageName={pageName}
                                loading={taskHistoryState.loading}
                                applyFilters={applyFilters}
                                filters={filterItemList}
                                filterValues={taskHistoryState.filters}
                                colDivision={5}
                                closeFilterBar={closeFilterBar}
                                appliedView={tasksTableFilter}
                                doesViewNameExist={doesViewNameExist}
                                tableSortState={{
                                    sortBy: taskHistoryState.sortBy,
                                    sortAscending:
                                        taskHistoryState.sortAscending,
                                }}
                            />
                        </Suspense>
                    </div>
                )}
            </QueueAnim>
            <div key="action-bar-container">
                <ActionBar
                    ref={actionBarRef}
                    pageName={pageName}
                    loading={taskHistoryState.loading}
                    filterBarOpen={showConditions.filterBar}
                    actionItems={[
                        {
                            actionKey: 'task-filter',
                            actionType: 'select-with-button',
                            selectValue: tasksTableFilter,
                            selectDropdownRender: populateTaskFilterSelectDropdownRender,
                            onSelectChange: changeTaskHistoryTableFilter,
                            buttonContent: (
                                <>
                                    <FontAwesome
                                        icon={['fa', 'sync']}
                                        className="mr-8"
                                    />
                                    <span>{getTranslatedText('Refresh')}</span>
                                </>
                            ),
                            buttonDisabled: taskHistoryState.loading,
                            onButtonClick: handleTaskFilterRefresh,
                        },
                    ]}
                    actionEllipsis={{
                        popoverVisible: showConditions.filterEllipsis,
                        popoverOnVisibleChange: popoverOnVisibleChange('filterEllipsis'),
                        popoverTitle: populateFilterEllipsisPopoverTitle(),
                        popoverContent:
                            populateFilterEllipsisPopoverContent(),
                        buttonContent: (
                            <FontAwesome icon={['fas', 'ellipsis-h']} />
                        ),
                    }}
                />
            </div>
            <div className="spacer-15" />
            {/* Table Section */}
            <Row key="table-container">
                <Col span={24}>
                    <VirtualizedList
                        idKeyName="BatchId"
                        dataSource={taskHistoryState.data}
                        fetchMore={handleFetch}
                        scroll={computeTableScroll(
                            window.innerHeight - 185,
                            tablePageSize,
                            TASK_HISTORY_PAGE.rowHeight
                        )}
                        resetTableScroll={resetTableScroll}
                        onRowClick={onRowClick}
                        loading={taskHistoryState.loading}
                        loadingText={populateTableLoadingText()}
                        emptyText={
                            !isEmpty(taskHistoryState.errorMessages)
                                ? get(taskHistoryState, 'errorMessages.0')
                                : getTranslatedText('No task history found')
                        }
                        hasNextPage={taskHistoryState.pageData.hasNextPage}
                        itemComponent={TaskHistoryItemComponent}
                        itemHeight={TASK_HISTORY_PAGE.rowHeight}
                        selectedId={selectedId}
                    />
                </Col>
                <Suspense fallback={null}>
                    <TaskHistoryItemDetailsDrawerComponent
                        visible={taskHistoryDetailsDrawer.visible}
                        closeDrawer={closeTaskHistoryDetailsDrawer}
                        drawerType={taskHistoryDetailsDrawer.drawerType}
                    />
                </Suspense>
                {showConditions.downloadToExcel && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={populateDownloadToExcelModalTitle()}
                            modalVisible={showConditions.downloadToExcel}
                            displayMessage={populateDownloadToExcelModalDisplayMessage()}
                        />
                    </Suspense>
                )}
                <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
            </Row>
        </>
    );
};

export default withPageViewHandler(OrganisationTasksHistoryContent);

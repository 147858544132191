/**
 * File responsible for all the UI and actions for Settings>Customization page - `/app/settings/customization`.
 */

import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Typography,
    Tabs
} from 'antd';
import { debounce, get, includes, isEmpty, map } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import ReactQuill from 'react-quill';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import SelectReadonly from '../../components/FormComponents/SelectReadonly';
import {
    confirmModalCancelText,
    confirmModalOkText,
    DEFAULT_REGION_NAME,
    IODM_COMPANY_STORAGE_NAME,
    IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME,
} from '../../config/config';
import { defaultGuidValue } from '../../constants/common';
import { defaultDisplay, locationOptions } from '../../constants/notifications';
import { ApplicationState } from '../../store';
import {
    getUserCompaniesRequestAction,
    saveUserCompanyRequestAction,
} from '../../store/companies/actions';
import { CompaniesState, CompanyNotificationCustomization } from '../../store/companies/types';
import { getRolePermissions } from '../../store/roles/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';
import { customizationPlaceholders } from '../../constants/settings';
import { getTranslatedText } from '../../utils/commonFunctions';


const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}

const formFieldNames = {
    BrandingLocation: 'BrandingLocation',
    OpeningWording: 'OpeningWording',
    IncludeLinkInLetterBlock: 'IncludeLinkInLetterBlock',
    InformUsTextLabel: 'InformUsTextLabel',
    InformUsInstruction: 'InformUsInstruction',
    CreditUsageWording: 'CreditUsageWording',
    CardPaymentHeading: 'CardPaymentHeading',
    CardPaymentDescription: 'CardPaymentDescription',
    MailingChequePaymentHeading: 'MailingChequePaymentHeading',
    MailingChequePaymentDescription: 'MailingChequePaymentDescription',
    BankPaymentHeading: 'BankPaymentHeading',
    BankPaymentDescription: 'BankPaymentDescription',
};

const reactQuillSettings = {
    modules: {
        toolbar: [['bold', 'italic', 'underline']],
    },

    formats: ['bold', 'italic', 'underline'],
};

const CustomizationManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    
    const rolePermissions = useSelector(getRolePermissions);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );
    const { selectedUserCompany, saveLoading } = companiesState;
    const originalCustomization = get(selectedUserCompany, 'Company.NotificationCustomization') || defaultDisplay;
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form;
    const [formHasChanged, setFormHasChanged] = useState(false);
    const [currentPreviewVersion, setCurrentPreviewVersion] = useState<number>();
    const previewLink = selectedUserCompany && `/notification-details/${defaultGuidValue}?region=${selectedUserCompany.Company.Region || DEFAULT_REGION_NAME}&_v=${currentPreviewVersion}`;

    useEffect(() => {
        updateCurrentCustomization(false);
    }, [originalCustomization]);

    useEffect(() => {
        if (selectedUserCompany) {
            localStorage.setItem(
                IODM_COMPANY_STORAGE_NAME,
                JSON.stringify(selectedUserCompany)
            );
        }
        resetFields();
    }, [selectedUserCompany]);

    const getInitialCustomizationValue = (field: string) => get(originalCustomization, field) || get(defaultDisplay, field);

    const updateCurrentCustomization = (formChanged = true) => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const values = getFieldsValue();
                const inputCustomization = notificationCustomizationMappingToModel(values);
                const customizationValue: CompanyNotificationCustomization = {
                    ...originalCustomization,
                    ...inputCustomization
                };

                localStorage.setItem(IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME, JSON.stringify(customizationValue));
                setCurrentPreviewVersion(new Date().getTime());
            }
        });

        if (formChanged) {
            setFormHasChanged(true);
        }
    };

    const updateCurrentCustomizationDebounced = useMemo(() => debounce(updateCurrentCustomization, 1000), []);

    /**
     * Function use to mapping the NotificationCustomization from the data from the input form.
     */
    const notificationCustomizationMappingToModel = (inputValues: DynamicObject) => {
        const notificationCustomization: CompanyNotificationCustomization = {
            BrandingLocation: inputValues[formFieldNames.BrandingLocation],
            OpeningWording: inputValues[formFieldNames.OpeningWording],
            CreditUsageWording: inputValues[formFieldNames.CreditUsageWording],
            IncludeLinkInLetterBlock: inputValues[formFieldNames.IncludeLinkInLetterBlock],
            InformUsInstruction: inputValues[formFieldNames.InformUsInstruction],
            InformUsTextLabel: inputValues[formFieldNames.InformUsTextLabel],
            CardPaymentCustomization: {
                Heading: inputValues[formFieldNames.CardPaymentHeading],
                Description: cleanEditorContent(inputValues[formFieldNames.CardPaymentDescription])
            },
            MailingChequePaymentCustomization: {
                Heading: inputValues[formFieldNames.MailingChequePaymentHeading],
                Description: cleanEditorContent(inputValues[formFieldNames.MailingChequePaymentDescription])
            },
            BankPaymentCustomization: {
                Heading: inputValues[formFieldNames.BankPaymentHeading],
                Description: cleanEditorContent(inputValues[formFieldNames.BankPaymentDescription])
            }
        };

        return notificationCustomization
    };

    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <div>
                    {
                        getTranslatedText(`When you click the ${confirmModalOkText} button, all the data will be reverted to the last saved values`).split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                            .map((part, index) =>
                                part.startsWith('<b>') ? (
                                    <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                                ) : (
                                    part // Render regular text
                                )
                            )
                    }
                </div>
            ),
            onOk() {
                resetFields();
            },
            onCancel() { },
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (IsSuccess: boolean) => {
        if (IsSuccess) {
            setFormHasChanged(false);
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("Customisation settings saved successfully"),
                onOk: () => {
                    dispatch(getUserCompaniesRequestAction());
                },
                okText: getTranslatedText("OK")
            });
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText("Failed to save customisation settings"),
                okText: getTranslatedText("OK")
            });
        }
    };

    const cleanEditorContent = (content: string) => {
        const template = document.createElement('div');
        template.innerHTML = content;
        return template.textContent ? content : '';
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const payload = {
                    NotificationCustomization: notificationCustomizationMappingToModel(values),
                    DetailType: 'NotificationCustomization',
                };

                dispatch(
                    saveUserCompanyRequestAction(
                        payload,
                        ({ IsSuccess }: { IsSuccess: boolean }) =>
                            handleModalSaveResponse(IsSuccess)
                    )
                );
            }
        });
    };

    /**
     * Function that populates the dropdown options for branding location.
     */
    const populateBrandingLocationOptions = () =>
        map(
            locationOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );

    /**
     * Function that populates the payment option customization tab pane based on
     * payment Option and the mandatory of this field.
     */
    const populatePaymentOptionCustomizeTabPane = (paymentOption: string) => {
        type ObjectKey = keyof typeof formFieldNames;
        const formHeading = `${paymentOption}Heading`;
        const formDescription = `${paymentOption}Description`;

        return (
            <Row>
                <Row>
                    <Col span={3}>
                        <div className="mt-9 pr-8">{getTranslatedText("Heading")}</div>
                    </Col>
                    <Col span={21}>
                        <FormItem>
                            {getFieldDecorator(
                                formFieldNames[formHeading as ObjectKey],
                                {
                                    initialValue: getInitialCustomizationValue(`${paymentOption}Customization.Heading`)
                                }
                            )(
                                <Input
                                    onChange={() => updateCurrentCustomizationDebounced()}
                                    placeholder={getTranslatedText("Enter heading")}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>
                        <div className="mt-9 pr-8">{getTranslatedText("Description")}</div>
                    </Col>
                    <Col className="payment-option-description" span={21}>
                        <FormItem>
                            {getFieldDecorator(
                                formFieldNames[formDescription as ObjectKey],
                                {
                                    initialValue: getInitialCustomizationValue(`${paymentOption}Customization.Description`)
                                }
                            )(
                                <ReactQuill
                                    theme="snow"
                                    {...reactQuillSettings}
                                    onChange={() => updateCurrentCustomizationDebounced()}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Row>
        );
    }

    /**
     * Function that populates the payment option customization tab .
     */
    const populatePaymentOptionCustomizeTab = () => {
        let defaultActiveKey = 'Credit card';

        if (get(originalCustomization, 'CardPaymentCustomization.Heading')) {
            defaultActiveKey = 'Credit card';
        }
        else if (get(originalCustomization, 'MailingChequePaymentCustomization.Heading')) {
            defaultActiveKey = 'Cheque';
        } else if (get(originalCustomization, 'BankPaymentCustomization.Heading')) {
            defaultActiveKey = 'Bank deposit';
        }

        return (
            <Tabs defaultActiveKey={defaultActiveKey} type="card">
                <TabPane tab={getTranslatedText("Credit card")} key="Credit card">
                    {populatePaymentOptionCustomizeTabPane("CardPayment")}
                </TabPane>
                <TabPane tab={getTranslatedText("Cheque")} key="Cheque">
                    {populatePaymentOptionCustomizeTabPane("MailingChequePayment")}
                </TabPane>
                <TabPane tab={getTranslatedText("Bank deposit")} key="Bank deposit">
                    {populatePaymentOptionCustomizeTabPane("BankPayment")}
                </TabPane>
            </Tabs>
        );
    }

    const allowedRoles = rolePermissions.CUSTOMIZATION_UPDATE;
    const formDisabled = !isEmpty(allowedRoles) && !includes(allowedRoles, userRole);

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 7 },
                        xl: { span: 7 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 17 },
                        xl: { span: 17 },
                        lg: { span: 18 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container" type="flex" align="middle">
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <Title level={3}>
                                            {getTranslatedText("Customisation")}
                                        </Title>
                                    </Col>
                                    <Col>
                                        {getTranslatedText("These settings are applied to all notification and payment installment reminders. You can see a preview before saving the changes in the Preview section of this page")}
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={6} className="ta-right">
                                <Button
                                    className="mr-10 w-100px"
                                    type="primary"
                                    onClick={onSaveButtonClick}
                                    disabled={formDisabled || !formHasChanged}
                                    loading={saveLoading}
                                >
                                    {getTranslatedText("Save")}
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                    disabled={formDisabled || !formHasChanged}
                                >
                                    {getTranslatedText("Cancel")}
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Branding location")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col span={24}>
                                        {getTranslatedText("The location of the company's branding logo and contact information")} &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.BrandingLocation,
                                                {
                                                    initialValue: getInitialCustomizationValue('BrandingLocation'),
                                                }
                                            )(
                                                <SelectReadonly
                                                    placeholder={getTranslatedText("Select a location")}
                                                    readOnly={formDisabled}
                                                    style={{ width: 180 }}
                                                    onSelect={() => updateCurrentCustomizationDebounced()}
                                                >
                                                    {populateBrandingLocationOptions()}
                                                </SelectReadonly>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Opening wording")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col className="flex-i" span={24}>
                                        {getTranslatedText("Customise the opening wording")} &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.OpeningWording,
                                                {
                                                    initialValue: getInitialCustomizationValue('OpeningWording'),
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Opening wording is required"),
                                                        }
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    onChange={() => updateCurrentCustomizationDebounced()}
                                                    placeholder={getTranslatedText("Enter some wording")}
                                                    readOnly={formDisabled}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Inclusion of the link to the letter block")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col className="center-flex-i" span={24}>
                                        {getTranslatedText("If turned on, a link to the letter block will be included if there's a letter available")}
                                        &nbsp;&nbsp;
                                        <FormItem>
                                            {getFieldDecorator(
                                                formFieldNames.IncludeLinkInLetterBlock,
                                                {
                                                    valuePropName: 'checked',
                                                    initialValue: getInitialCustomizationValue('IncludeLinkInLetterBlock'),
                                                }
                                            )(
                                                <Switch
                                                    checkedChildren={getTranslatedText("ON")}
                                                    unCheckedChildren={getTranslatedText("OFF")}
                                                    onChange={() => updateCurrentCustomizationDebounced()}
                                                    disabled={formDisabled}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Inform Us button text label")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col className="flex-i" span={24}>
                                        {getTranslatedText("Customise the text label of the button Inform Us")} &nbsp;
                                        <FormItem className="inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.InformUsTextLabel,
                                                {
                                                    initialValue: getInitialCustomizationValue('InformUsTextLabel'),
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Inform Us button text label is required"),
                                                        },
                                                        {
                                                            max: 25,
                                                            message: getTranslatedText("Maximum length allowed is 25 characters!"),
                                                        }
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    onChange={() => updateCurrentCustomizationDebounced()}
                                                    placeholder={getTranslatedText("Enter a text label")}
                                                    readOnly={formDisabled}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Instruction of Inform Us button")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col span={24}>
                                        <div>
                                            {getTranslatedText("Customise the instruction of the Inform Us button")}
                                            <br />
                                            <span>{getTranslatedText(`You can use ${customizationPlaceholders.INFORM_US_LABEL} as a placeholder for the configured Inform Us button text label`).split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                                                .map((part, index) =>
                                                    part.startsWith('<b>') ? (
                                                        <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                                                    ) : (
                                                        part // Render regular text
                                                    )
                                                )}</span>
                                            &nbsp;
                                        </div>
                                        <FormItem>
                                            {getFieldDecorator(
                                                formFieldNames.InformUsInstruction,
                                                {
                                                    initialValue: getInitialCustomizationValue('InformUsInstruction'),
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Inform Us instruction is required"),
                                                        }
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    onChange={() => updateCurrentCustomizationDebounced()}
                                                    placeholder={getTranslatedText("Enter your instruction")}
                                                    readOnly={formDisabled}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Credit usage wording")}</h4>
                            </Col>
                            <Col
                                xxl={15}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                <Row>
                                    <Col className="flex-i" span={24}>
                                        {getTranslatedText("Customise the credit usage wording")} &nbsp;
                                        <FormItem className="fx-1 inline-form-item">
                                            {getFieldDecorator(
                                                formFieldNames.CreditUsageWording,
                                                {
                                                    initialValue: getInitialCustomizationValue('CreditUsageWording'),
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Credit usage wording is required"),
                                                        }
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    onChange={() => updateCurrentCustomizationDebounced()}
                                                    placeholder={getTranslatedText("Enter some wording")}
                                                    readOnly={formDisabled}
                                                />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Payment options customisation")}</h4>
                            </Col>
                            <Col
                                xxl={16}
                                xl={16}
                                lg={16}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                {populatePaymentOptionCustomizeTab()}
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[10, 10]}>
                            <Col span={24}>
                                <h4>{getTranslatedText("Preview")}</h4>
                            </Col>
                            <Col
                                xxl={16}
                                xl={16}
                                lg={16}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                {getTranslatedText("Desktop view")}<br />
                                {selectedUserCompany && currentPreviewVersion && (
                                    <iframe title={getTranslatedText("Preview")}
                                        className="mt-10"
                                        style={{
                                            width: '100%', height: '80vh'
                                        }}
                                        src={previewLink}>
                                    </iframe>
                                )}
                            </Col>
                            <Col
                                xxl={8}
                                xl={8}
                                lg={8}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                {getTranslatedText("Mobile phone view")}<br />
                                {selectedUserCompany && currentPreviewVersion && (
                                    <iframe title={getTranslatedText("Mobile phone view")}
                                        className="mt-10"
                                        style={{ width: 360, height: '80vh' }}
                                        src={previewLink}>
                                    </iframe>
                                )}
                            </Col>
                        </Row>
                        <Divider />
                    </QueueAnim>
                </Form>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving customisation data")}
                        modalVisible={saveLoading}
                        displayMessage={getTranslatedText("Please wait while saving customisation data")}
                    />
                )}
            </Col>
        </div>
    );
};

const CustomizationManagementPageForm = Form.create({
    name: 'customization-management-page-form',
})(CustomizationManagementPage);
export default withRouter(CustomizationManagementPageForm);

import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { AuthActionTypes, VerifyMfaSoftwareTokenPayload } from './types';

export const loginUserAction = (data: DynamicObject) =>
    action(AuthActionTypes.LOGIN_USER, data);
export const logoutUserAction = () => action(AuthActionTypes.LOGOUT_USER);
export const resetReduxStateAction = () =>
    action(AuthActionTypes.RESET_REDUX_STATE);

export const changeEndpointsAction = (companyUserRoleData: DynamicObject) =>
    action(AuthActionTypes.CHANGE_ENDPOINTS, companyUserRoleData);

export const getRegionSettingsConfigAction = () =>
    action(AuthActionTypes.GET_REGION_SETTINGS_CONFIG);

export const updateRegionSettingsConfigAction = (config: DynamicObject[]) =>
    action(AuthActionTypes.UPDATE_REGION_SETTINGS_CONFIG, config);

export const getRegionKeyConfigAction = () =>
    action(AuthActionTypes.GET_REGION_KEY_CONFIG);

export const updateRegionKeyConfigAction = (config: DynamicObject[]) =>
    action(AuthActionTypes.UPDATE_REGION_KEY_CONFIG, config);

export const generateMfaSoftwareTokenAction = (callback: (response: DynamicObject) => void) =>
    action(AuthActionTypes.GENERATE_MFA_SOFTWARE_TOKEN, {
        callback
    });

export const verifyMfaSoftwareTokenAction = (payload: VerifyMfaSoftwareTokenPayload, callback: (response: DynamicObject) => void) =>
    action(AuthActionTypes.VERIFY_MFA_SOFTWARE_TOKEN, {
        payload,
        callback
    });

export const useSmsMfaAction = (callback: (response: DynamicObject) => void) =>
    action(AuthActionTypes.USE_SMS_MFA, {
        callback
    });

export const signInWithOtherProvidersAction = (provider: string) =>
    action(AuthActionTypes.SIGN_IN_WITH_OTHER_PROVIDERS, { provider });

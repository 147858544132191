import API, { graphqlOperation } from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import { clone, filter, findIndex, get, isEmpty } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    DEFAULT_REGION_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
    dashboardMaxAPIRefetchCount,
} from '../../config/config';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    getCompanyFlagValue,
    getGraphqlQueryString,
    getRegionallyGraphqlResponse,
    getRegionConfigFromList,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';
import { DynamicObject } from './../../utils/commonInterfaces';
import {
    getDashboardCurrentATBRequestAction,
    getDashboardDataErrorAction,
    getDashboardDataRequestAction,
    getDashboardDataSuccessAction,
    getDashboardInvoicingAndSettlementsRequestAction,
    getDashboardNotificationsRequestAction,
    getDashboardNotificationSuccessRequestAction,
    getDashboardOpenInvoicesRequestAction,
    getDashboardOpenSupportTicketsRequestAction,
    getDashboardSalesRequestAction,
    getDashboardTasksCountRequestAction,
    getDashboardTasksCountForUsersRequestAction,
    getDashboardTasksCountForUserTypesRequestAction,
    getDashboardTicketsRequestAction,
    getDashboardTicketsForUsersRequestAction,
    getDashboardTicketsForUserTypesRequestAction,
    getDashboardTopCreditsRequestAction,
    getDashboardTopCustomersRequestAction,
    getDashboardTopInvoicesRequestAction,
    getDashboardTotalOwedRequestAction,
    setDashboardSelectedIdRequestAction,
    setDashboardSelectedIdSuccessAction,
    getDashboardPaymentsRequestAction,
    getDashboardPaymentsAllocationRequestAction,
    getDashboardRecentChangesRequestAction,
    getDashboardChangeStatisticsRequestAction,
    getDashboardChangeStatisticsByTypeRequestAction,
    getDashboardCustomersSettlementRequestAction,
    getDashboardCashflowForecastRequestAction,
    getDashboardDailySalesOutRequestAction,
    getDashboardTasksCountActionedByUserRequestAction,
    getDashboardTasksCountActionedByUserTypeRequestAction,
    getDashboardCommunicationDeliveryRequestAction,
    getDashboardCustomerCountPBIRequestAction
} from './actions';
import { DashboardsActionTypes } from './types';
import { WIDGET_NAMES } from '../../config/tableAndPageConstants';
import { ReportType } from '../../constants/reportSortAndFilters';
import conversationLineSaga from '../conversationLine/sagas';
import { AtbViewType } from '../../constants/settings';
import { getOrganisationActionUrl } from '../organisations/sagas';

export const getDashboardList = (state: ApplicationState) =>
    state.dashboards.data;

export const getIsEditingDashboard = (state: ApplicationState) =>
    state.dashboards.isEditing;

export const getDashboardSelectedId = (state: ApplicationState) =>
    state.dashboards.activeData.selectedId;

export const getDashboardActiveErrorMessages = (state: ApplicationState) =>
    state.dashboards.activeData.errorMessages;

/**
 * Function that calls the API for fetching the dashboard list.
 * @param param0
 */
function* handleGetDashboardsSuccess({ payload }: any) {
    const { data, notSameCompany } = payload;

    const dataUsed = clone(data);
    if (isEmpty(dataUsed)) {
        yield put(setDashboardSelectedIdRequestAction(null));
    } else {
        let dashboardId = get(dataUsed, '0.Name');

        const dashboardSelectedId: string | null = yield select(
            getDashboardSelectedId
        );

        if (!dashboardSelectedId || notSameCompany) {
            yield put(setDashboardSelectedIdRequestAction(dashboardId));
        } else {
            const dashboardName = clone(dashboardSelectedId);
            const dashboardIdx = findIndex(dataUsed, ['Name', dashboardName]);
            if (dashboardIdx === -1) {
                yield put(setDashboardSelectedIdRequestAction(dashboardId));
            } else {
                yield put(setDashboardSelectedIdRequestAction(dashboardName));
            }

            const dashboardErrorMessages: string[] = yield select(
                getDashboardActiveErrorMessages
            );

            if (!isEmpty(dashboardErrorMessages)) {
                yield put(setDashboardSelectedIdRequestAction(dashboardName));
            }

            return;
        }
    }
}

/**
 * Function calling the API for fetching the dashboard data based on the given id.
 * @param param0
 */
function* handleGetDashboardDataRequest({ payload: dashboardId }: any) {
    const errorMessage =
        'Error fetching dashboard details. Please try again later.';
    try {
        const dashboardList: any[] = yield select(getDashboardList);
        const dashboardFiltered = filter(dashboardList, ['Name', dashboardId]);
        const Dashboard = get(dashboardFiltered, 0);

        if (Dashboard) {
            const responsePayload = {
                record: Dashboard,
            };

            yield put(getDashboardDataSuccessAction(responsePayload));
        } else {
            yield put(getDashboardDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getDashboardDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected dashboard id for reference.
 * @param param0
 */
function* handleSetDashboardSelectedIdRequest({ payload }: any) {
    const { dashboardId, callback } = payload;
    yield put(setDashboardSelectedIdSuccessAction(dashboardId));
    yield put(getDashboardDataRequestAction(dashboardId));
    if (callback) callback();
}

// TOP INVOICES
/**
 * Function that fetches the invoice list - api connection.
 * @param param0
 */
function* handleGetDashboardTopInvoicesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true,
        );

        let queryName = 'GET_INVOICES_FOR_COMPANY'
        let usedQueryResponseName = 'GetInvoicesForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_INVOICES_FOR_ORGANISATION';
            usedQueryResponseName = 'GetInvoicesForOrganisation';
        }
        const usedQuery = queries[queryName];

        // To call async functions, use redux-saga's `call()`.
        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            InvoiceState: invoiceState,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TOP_INVOICES,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Invoices } = get(res.data, usedQueryResponseName);

            refetchCount = 0;
            if (callback) callback(Invoices);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTopInvoicesRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TOP CREDITS
/**
 * Function that fetches the credits list - api connection.
 * @param param0
 */
function* handleGetDashboardTopCreditsRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            creditState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'credit',
            true,
        );

        let queryName = 'GET_CREDITS_FOR_COMPANY';
        let usedQueryResponseName = 'GetCreditsForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CREDITS_FOR_ORGANISATION';
            usedQueryResponseName = 'GetCreditsForOrganisation';
        }
        const usedQuery = queries[queryName];

        // To call async functions, use redux-saga's `call()`.
        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            CreditState: creditState,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TOP_CREDITS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Credits } = get(res.data, usedQueryResponseName);

            refetchCount = 0;
            if (callback) callback(Credits);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTopCreditsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TOP CUSTOMERS
/**
 * Function that fetches the customer list - api connection.
 * @param param0
 */
function* handleGetDashboardTopCustomersRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true,
        );

        let queryName = 'GET_CUSTOMERS_FOR_COMPANY';
        let usedQueryResponseName = 'GetCustomersForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CUSTOMERS_FOR_ORGANISATION';
            usedQueryResponseName = 'GetCustomersForOrganisation';
        }
        const usedQuery = queries[queryName];
        const variables = {
            ...cleanFilters,
            ATBType: atbType,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TOP_CUSTOMERS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Customers } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(Customers);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTopCustomersRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// RECENT CHANGES
/**
 * Function that fetches the change lines list - api connection.
 * @param param0
 */
function* handleGetDashboardRecentChangesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;

        let queryName = 'GET_CHANGE_LINES_FOR_COMPANY';
        let usedQueryResponseName = 'GetChangeLinesForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CHANGE_LINES_FOR_ORGANISATION';
            usedQueryResponseName = 'GetChangeLinesForOrganisation';
        }
        let usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...filters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.RECENT_CHANGES,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { ChangeLines } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(ChangeLines);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardRecentChangesRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// CHANGE STATISTICS
/**
 * Function that fetches the change lines statistics - api connection.
 * @param param0
 */
function* handleGetDashboardChangeStatisticsRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;

        let queryName = 'GET_CHANGE_COUNT_BY_SOURCE_FOR_COMPANY';
        let usedQueryResponseName = 'GetChangeCountBySourceForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CHANGE_COUNT_BY_SOURCE_FOR_ORGANISATION';
            usedQueryResponseName = 'GetChangeCountBySourceForOrganisation';
        }
        const usedQuery = queries[queryName];
        const variables = {
            ...filters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.STATISTIC_CHANGES,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { ChangeSourceCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(ChangeSourceCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardChangeStatisticsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}


// CHANGE STATISTICS - BY TYPE
/**
 * Function that fetches the change lines statistics - api connection.
 * @param param0
 */
function* handleGetDashboardChangeStatisticsByTypeRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;

        let queryName = 'GET_CHANGE_COUNT_BY_SOURCE_TYPE_FOR_COMPANY';
        let usedQueryResponseName = 'GetChangeCountBySourceTypeForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CHANGE_COUNT_BY_SOURCE_TYPE_FOR_ORGANISATION';
            usedQueryResponseName = 'GetChangeCountBySourceTypeForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...filters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.STATISTIC_CHANGES_PER_TYPE,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { ChangeSourceTypeCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(ChangeSourceTypeCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardChangeStatisticsByTypeRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// CUSTOMERS SETTLEMENT
/**
 * Function that fetches the customers settlement list - api connection.
 * @param param0
 */
function* handleGetDashboardCustomersSettlementRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true,
        );

        let queryName = 'GET_CUSTOMER_SETTLEMENT_AVERAGE_DAYS_FOR_COMPANY';
        let usedQueryResponseName = 'GetCustomerSettlementAverageDaysForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CUSTOMER_SETTLEMENT_AVERAGE_DAYS_FOR_ORGANISATION';
            usedQueryResponseName = 'GetCustomerSettlementAverageDaysForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.CUSTOMERS_SETTLEMENT,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { CustomerSettlementAverageDaysList } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(CustomerSettlementAverageDaysList);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardCustomersSettlementRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TASKS
/**
 * Function that fetches the tasks count - api connection.
 * @param param0
 */
function* handleGetDashboardTasksCountRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'tasks'
        );

        let queryName = 'GET_TASK_COUNT_BY_STATE_NAME'
        let usedQueryResponseName = 'GetTaskCountByStateName';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TASK_COUNT_BY_STATE_NAME_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTaskCountByStateNameForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TASKS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TaskCountStateNames } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TaskCountStateNames);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTasksCountRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TASKS FOR USERS
/**
 * Function that fetches the tasks count - api connection.
 * @param param0
 */
function* handleGetDashboardTasksCountForUsersRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'tasks'
        );

        let queryName = 'GET_TASK_COUNT_BY_USER';
        let usedQueryResponseName = 'GetTaskCountByUserForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TASK_COUNT_BY_USER_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTaskCountByUserForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.ACTIVE_TASKS_PER_USER,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TaskUserCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TaskUserCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTasksCountForUsersRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TASKS FOR USERS - USER TYPE
/**
 * Function that fetches the tasks count - api connection.
 * @param param0
 */
function* handleGetDashboardTasksCountForUserTypesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'tasks'
        );

        let queryName = 'GET_TASK_COUNT_BY_USER_TYPE';
        let usedQueryResponseName = 'GetTaskCountByUserTypeForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TASK_COUNT_BY_USER_TYPE_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTaskCountByUserTypeForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.ACTIVE_TASKS_PER_USER_TYPE,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TaskUserTypeCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TaskUserTypeCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTasksCountForUserTypesRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// OPEN SUPPORT TICKETS
/**
 * Function that fetches the open support tickets - api connection.
 * @param param0
 */
function* handleGetDashboardOpenSupportTicketsRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_TICKET_COUNT_BY_STATUS';
        let usedQueryResponseName = 'GetTicketCountByStatus';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_TICKET_COUNT_BY_STATUS_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTicketCountByStatusForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.OPEN_SUPPORT_TICKETS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { OpenSum } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(OpenSum);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardOpenSupportTicketsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback(0);
        }
    }
}

// SALES
/**
 * Function that fetches the sales report - api connection.
 * @param param0
 */
function* handleGetDashboardSalesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'sales',
            true
        );

        let queryName = 'GET_SALES_REPORT_FOR_COMPANY';
        let usedQueryResponseName = 'GetSalesReportForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_COMPANY_SALES_REPORT_FOR_ORGANISATION';
            usedQueryResponseName = 'GetCompanySalesReportForOrganisation';
        }
        const usedQuery = queries[queryName];
        const IsCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );

        const variables = {
            ...cleanFilters,
            ...restProps,
            IsCalendarView
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.SALES,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Sales } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(Sales);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardSalesRequestAction(payload, isOrgView, callback)
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// NOTIFICATION SUCCESS
/**
 * Function that fetches the notification success report - api connection.
 * @param param0
 */
function* handleGetDashboardNotificationSuccessRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_NOTIFICATION_SUCCESS_REPORT';
        let usedQueryResponseName = 'GetNotificationSuccessReport';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_NOTIFICATION_SUCCESS_REPORT_FOR_ORGANISATION';
            usedQueryResponseName =
                'GetNotificationSuccessReportForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.NOTIFICATION_SUCCESS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const data = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(data);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardNotificationSuccessRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// INVOICING AND SETTLEMENTS
/**
 * Function that fetches the invoicing and settlements report - api connection.
 * @param param0
 */
function* handleGetDashboardInvoicingAndSettlementsRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        let queryName = 'GET_INVOICING_SETTLEMENT_REPORT';
        let usedQueryResponseName = 'GetInvoicingSettlementReport';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_COMPANY_INVOICING_SETTLEMENT_REPORT_FOR_ORGANISATION';
            usedQueryResponseName =
                'GetCompanyInvoicingSettlementReportForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.INVOICING_SETTLEMENTS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { InvoicingSettlements } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(InvoicingSettlements);
        }

    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardInvoicingAndSettlementsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TICKETS
/**
 * Function that fetches the tickets report - api connection.
 * @param param0
 */
function* handleGetDashboardTicketsRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_TICKET_COUNT_BY_GROUPING';
        let usedQueryResponseName = 'GetTicketCountByGrouping';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_TICKET_COUNT_BY_GROUPING_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTicketCountByGroupingForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TICKETS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TicketCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TicketCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTicketsRequestAction(payload, isOrgView, callback)
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}


// TICKETS FOR USERS
/**
 * Function that fetches the tickets for users report - api connection.
 * @param param0
 */
function* handleGetDashboardTicketsForUsersRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_TICKET_COUNT_BY_USER_FOR_COMPANY';
        let usedQueryResponseName = 'GetTicketCountByUserForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_TICKET_COUNT_BY_USER_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTicketCountByUserForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TICKETS_FOR_USER,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TicketUserCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TicketUserCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTicketsForUsersRequestAction(payload, isOrgView, callback)
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TICKETS FOR USERS - USER TYPES
/**
 * Function that fetches the tickets for users report - api connection.
 * @param param0
 */
function* handleGetDashboardTicketsForUserTypesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_TICKET_COUNT_BY_USER_TYPE_FOR_COMPANY';
        let usedQueryResponseName = 'GetTicketCountByUserTypeForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_TICKET_COUNT_BY_USER_TYPE_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTicketCountByUserTypeForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TICKETS_FOR_USER_TYPE,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TicketUserTypeCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TicketUserTypeCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTicketsForUserTypesRequestAction(payload, isOrgView, callback)
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TOTAL OWED
/**
 * Function that fetches the total owed report- api connection.
 * @param param0
 */
function* handleGetDashboardTotalOwedRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, ...restProps } = payload;

        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        let queryName = 'GET_TOTAL_OWED_REPORT';
        let usedQueryResponseName = 'GetTotalOwedReport';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TOTAL_OWED_REPORT_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTotalOwedReportForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...cleanFilters,
            ...restProps,
            IsCalendarView: isCalendarView
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TOTAL_OWNED,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TotalAmountOwing } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TotalAmountOwing);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTotalOwedRequestAction(payload, isOrgView, callback)
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback(0);
        }
    }
}

// NOTIFICATIONS
/**
 * Function that fetches the notifications report - api connection.
 * @param param0
 */
function* handleGetDashboardNotificationsRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_NOTIFICATIONS_REPORT';
        let usedQueryResponseName = 'GetNotificationReport';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_NOTIFICATIONS_REPORT_FOR_ORGANISATION';
            usedQueryResponseName = 'GetNotificationReportForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.NOTIFICATIONS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Notifications } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(Notifications);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardNotificationsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// CURRENT ATB
/**
 * Function that fetches the current atb report - api connection.
 * @param param0
 */
function* handleGetDashboardCurrentATBRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'sales',
            true
        );

        let queryName = 'GET_CUSTOMER_CURRENT_ATB_REPORT';
        let usedQueryResponseName = 'GetCustomerCurrentATBReport';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_CUSTOMER_CURRENT_ATB_REPORT_FOR_ORGANISATION';
            usedQueryResponseName =
                'GetCustomerCurrentATBReportForOrganisation';
        }
        const IsCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        const usedQuery = queries[queryName];
        const variables = {
            ...cleanFilters,
            ...restProps,
            IsCalendarView
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.CURRENT_ATB,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const data = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(data);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardCurrentATBRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// OPEN INVOICES
/**
 * Function that fetches the open invoices - api connection.
 * @param param0
 */
function* handleGetDashboardOpenInvoicesRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, invoiceState, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );

        let queryName = 'GET_INVOICE_COUNT_FOR_COMPANY';
        let usedQueryResponseName = 'GetInvoiceCountForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_INVOICE_COUNT_FOR_ORGANISATION';
            usedQueryResponseName = 'GetInvoiceCountForOrganisation';
        }
        const usedQuery = queries[queryName];

        const variables = {
            ...cleanFilters,
            InvoiceState: invoiceState,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.OPEN_INVOICES,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { InvoiceCount } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(InvoiceCount);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardOpenInvoicesRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback(0);
        }
    }
}

// COMMUNICATION DELIVERY
/**
 * Function that fetches the communication delivery report - api connection.
 * @param param0
 */
function* handleGetDashboardCommunicationDeliveryRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_COMMUNICATION_DELIVERY_FOR_COMPANY';
        let usedQueryResponseName = 'GetDeliveryCountsForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_COMMUNICATION_DELIVERY_FOR_ORGANISATION';
            usedQueryResponseName = 'GetDeliveryCountsForOrganisation';
        }
        const usedQuery = queries[queryName];
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.COMMUNICATION_DELIVERY,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { DeliveryCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(DeliveryCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardCommunicationDeliveryRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}
// PAYMENTS
/**
 * Function that fetches the payments list - api connection.
 * @param param0
 */
function* handleGetDashboardPaymentsRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        let queryName = 'GET_PAYMENTS_FOR_COMPANY';
        let usedQueryResponseName = 'GetPaymentsForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_PAYMENTS_FOR_ORGANISATION';
            usedQueryResponseName = 'GetPaymentsForOrganisation';
        }
        const usedQuery = queries[queryName];

        // To call async functions, use redux-saga's `call()`.
        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.PAYMENTS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { Payments } = get(res.data, usedQueryResponseName);

            refetchCount = 0;
            if (callback) callback(Payments);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardPaymentsRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// PAYMENTS - ALLOCATION
/**
 * Function that fetches the payments allocation list - api connection.
 * @param param0
 */
function* handleGetDashboardPaymentsAllocationRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        let queryName = 'GET_PAYMENTS_ALLOCATION_FOR_COMPANY';
        let usedQueryResponseName = 'GetPaymentAllocationTotalForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_PAYMENTS_ALLOCATION_FOR_ORGANISATION';
            usedQueryResponseName = 'GetPaymentAllocationTotalForOrganisation';
        }
        const usedQuery = queries[queryName];

        // To call async functions, use redux-saga's `call()`.
        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.PAYMENTS_ALLOCATION,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const data = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(data);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardPaymentsAllocationRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// CASHFLOW FORECAST
/**
 * Function that fetches the cashflow forecasting
 * @param param0
 */
function* handleGetDashboardCashflowForecastRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            ...restProps
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );

        let queryName = 'GET_CASFHLOW_FORECAST_FOR_COMPANY';
        let usedQueryResponseName = 'GetCashflowForecastForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_CASFHLOW_FORECAST_FOR_ORGANISATION';
            usedQueryResponseName = 'GetCashflowForecastForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ATBType: atbType,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.CASHFLOW_FORECASTING,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { CashflowForecastList } = get(res.data, usedQueryResponseName);
            refetchCount = 0;

            const FormattedCashflowForecastList = CashflowForecastList.map((i: { CashflowForecastData: string }) => {
                const cashflowForecast = i.CashflowForecastData;
                const formattedCashflowForecast = JSON.parse(cashflowForecast);
                return formattedCashflowForecast;
            });
            if (callback) callback(FormattedCashflowForecastList);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardCashflowForecastRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// DAILY SALES OUT
/**
 * Function that fetches the DAILY SALES OUT- api connection.
 * @param param0
 */
function* handleGetDashboardDailySalesOutRequest({
    payload: sagaPayload,
}: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            undefined,
            true
        );

        let queryName = 'GET_DAILY_SALES_OUT_FOR_COMPANY';
        let usedQueryResponseName = 'GetDailySalesOutReportForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );

            queryName = 'GET_DAILY_SALES_OUT_FOR_ORGANISATION';
            usedQueryResponseName = 'GetDailySalesOutReportForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.DAILY_SALES_OUT,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { DailySalesOutReport } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(DailySalesOutReport);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardDailySalesOutRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback(0);
        }
    }
}

// TASK COUNT ACTIONED BY USER
/**
 * Function that fetches the tasks count actioned by user - api connection.
 * @param param0
 */
function* handleGetDashboardTasksCountActionedByUserRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'tasks'
        );

        let queryName = 'GET_TASK_COUNT_ACTIONED_BY_USER_FOR_COMPANY'
        let usedQueryResponseName = 'GetTaskCountActionedByUserForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TASK_COUNT_ACTIONED_BY_USER_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTaskCountActionedByUserForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TASK_COUNT_ACTIONED_BY_USER,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TaskUserActionedCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TaskUserActionedCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTasksCountActionedByUserRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// TASK COUNT ACTIONED BY USER
/**
 * Function that fetches the tasks count actioned by user - api connection.
 * @param param0
 */
function* handleGetDashboardTasksCountActionedByUserTypeRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        // To call async functions, use redux-saga's `call()`.

        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'tasks'
        );

        let queryName = 'GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_FOR_COMPANY'
        let usedQueryResponseName = 'GetTaskCountActionedByUserTypeForCompany';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_FOR_ORGANISATION';
            usedQueryResponseName = 'GetTaskCountActionedByUserTypeForOrganisation';
        }
        const usedQuery = queries[queryName];

        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.TASK_COUNT_ACTIONED_BY_USER_TYPE,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            const { TaskUserTypeCounts } = get(res.data, usedQueryResponseName);
            refetchCount = 0;
            if (callback) callback(TaskUserTypeCounts);
        }
    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardTasksCountActionedByUserTypeRequestAction(
                    payload,
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// payment behaviour insights breakdown
/**
 * Function that fetches the change lines statistics - api connection.
 * @param param0
 */
function* handleGetDashboardCustomerCountPBIRequest({ payload: sagaPayload }: any) {
    const { payload, isOrgView, callback, payloadCallback } = sagaPayload;
    let refetchCount = 0;
    try {
        const { filters, ...restProps } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customers'
        );

        let queryName = 'GET_CUSTOMER_COUNT_PBI_FOR_COMPANY';
        let configUsed = {};
        if (isOrgView) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            configUsed = getRegionConfigFromList(
                get(payload, 'Regions.0', DEFAULT_REGION_NAME),
                regionKeyConfig,
                regionSettingsConfig
            );
            queryName = 'GET_CUSTOMER_COUNT_PBI_FOR_ORGANISATION';
        }
        const usedQuery = queries[queryName];
        let res: DynamicObject;
        const variables = {
            ...cleanFilters,
            ...restProps,
        };

        if (payloadCallback) {
            payloadCallback({
                Query: getGraphqlQueryString(usedQuery),
                OperationName: queryName,
                Variables: JSON.stringify(variables),
                WidgetName: WIDGET_NAMES.CUSTOMER_PAYMENT_BEHAVIOUR_INSIGHTS,
                Type: ReportType.Dashboard,
                ApiUrl: get(configUsed, 'Url')
            });
        } else {
            const res: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                get(configUsed, 'Url'),
                usedQuery,
                variables
            );

            refetchCount = 0;
            if (callback) {
                if (isOrgView)
                    callback(res.data.GetCustomerCountPBIForOrganisation.CustomerCountPaymentBehaviourInsights);
                else
                    callback(res.data.GetCustomerCountPBIForCompany.CustomerCountPaymentBehaviourInsights);
            }
        }


    } catch (err) {
        if (
            refetchCount <= dashboardMaxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getDashboardCustomerCountPBIRequestAction(
                    isOrgView,
                    callback
                )
            );
        } else {
            if (err instanceof Error) {
                console.log('Error', err);
            } else {
                console.error('An unknown error occured.', err);
            }

            if (callback) callback([]);
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.

function* watchGetDashboardsSuccess() {
    yield takeLatest(
        DashboardsActionTypes.GET_DASHBOARDS_SUCCESS,
        handleGetDashboardsSuccess
    );
}

function* watchGetDashboardDataRequest() {
    yield takeLatest(
        DashboardsActionTypes.GET_DASHBOARD_DATA_REQUEST,
        handleGetDashboardDataRequest
    );
}

function* watchSetDashboardSelectedIdRequest() {
    yield takeLatest(
        DashboardsActionTypes.SET_DASHBOARD_SELECTED_ID_REQUEST,
        handleSetDashboardSelectedIdRequest
    );
}

function* watchGetDashboardTopInvoicesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_INVOICES_REQUEST,
        handleGetDashboardTopInvoicesRequest
    );
}

function* watchGetDashboardTopCreditsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CREDITS_REQUEST,
        handleGetDashboardTopCreditsRequest
    );
}

function* watchGetDashboardTopCustomersRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CUSTOMERS_REQUEST,
        handleGetDashboardTopCustomersRequest
    );
}

function* watchGetDashboardRecentChangesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_RECENT_CHANGES_REQUEST,
        handleGetDashboardRecentChangesRequest
    );
}

function* watchGetDashboardChangeStatisticsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CHANGE_STATISTICS_REQUEST,
        handleGetDashboardChangeStatisticsRequest
    );
}

function* watchGetDashboardChangeStatisticsByTypeRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CHANGE_STATISTICS_BY_TYPE_REQUEST,
        handleGetDashboardChangeStatisticsByTypeRequest
    );
}

function* watchGetDashboardCustomersSettlementRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CUSTOMERS_SETTLEMENT_REQUEST,
        handleGetDashboardCustomersSettlementRequest
    );
}

function* watchGetDashboardTasksCountRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_REQUEST,
        handleGetDashboardTasksCountRequest
    );
}

function* watchGetDashboardTasksCountForUsersRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_FOR_USERS_REQUEST,
        handleGetDashboardTasksCountForUsersRequest
    );
}

function* watchGetDashboardTasksCountForUserTypesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TASKS_COUNT_FOR_USER_TYPES_REQUEST,
        handleGetDashboardTasksCountForUserTypesRequest
    );
}

function* watchGetDashboardOpenSupportTicketsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_OPEN_SUPPORT_TICKETS_REQUEST,
        handleGetDashboardOpenSupportTicketsRequest
    );
}

function* watchGetDashboardSalesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_SALES_REQUEST,
        handleGetDashboardSalesRequest
    );
}

function* watchGetDashboardNotificationSuccessRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_NOTIFICATION_SUCCESS_REQUEST,
        handleGetDashboardNotificationSuccessRequest
    );
}

function* watchGetDashboardInvoicingAndSettlementsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_INVOICING_AND_SETTLEMENTS_REQUEST,
        handleGetDashboardInvoicingAndSettlementsRequest
    );
}

function* watchGetDashboardTicketsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TICKETS_REQUEST,
        handleGetDashboardTicketsRequest
    );
}

function* watchGetDashboardTicketsForUsersRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TICKETS_FOR_USERS_REQUEST,
        handleGetDashboardTicketsForUsersRequest
    );
}

function* watchGetDashboardTicketsForUserTypesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TICKETS_FOR_USER_TYPES_REQUEST,
        handleGetDashboardTicketsForUserTypesRequest
    );
}

function* watchGetDashboardTotalOwedRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_TOTAL_OWED_REQUEST,
        handleGetDashboardTotalOwedRequest
    );
}

function* watchGetDashboardNotificationsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_NOTIFICATIONS_REQUEST,
        handleGetDashboardNotificationsRequest
    );
}

function* watchGetDashboardCurrentATBRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_CURRENT_ATB_REQUEST,
        handleGetDashboardCurrentATBRequest
    );
}

function* watchGetDashboardOpenInvoicesRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_OPEN_INVOICES_REQUEST,
        handleGetDashboardOpenInvoicesRequest
    );
}

function* watchGetDashboardCommunicationDeliveryRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_COMMUNICATION_DELIVERY_REQUEST,
        handleGetDashboardCommunicationDeliveryRequest
    );
}

function* watchGetDashboardPaymentsRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_PAYMENTS_REQUEST,
        handleGetDashboardPaymentsRequest
    );
}

function* watchGetDashboardPaymentsAllocationRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DASHBOARD_PAYMENTS_ALLOCATION_REQUEST,
        handleGetDashboardPaymentsAllocationRequest
    );
}

function* watchGetDashboardCashflowForecastRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_CASFHLOW_FORECAST_REQUEST,
        handleGetDashboardCashflowForecastRequest
    );
}

function* watchGetDashboardDailySalesOutRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_DAILY_SALES_OUT_FOR_COMPANY_REQUEST,
        handleGetDashboardDailySalesOutRequest
    );
}

function* watchGetDashboardTasksCountActionedByUserRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_TASK_COUNT_ACTIONED_BY_USER_REQUEST,
        handleGetDashboardTasksCountActionedByUserRequest
    );
}

function* watchGetDashboardTasksCountActionedByUserTypeRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_TASK_COUNT_ACTIONED_BY_USER_TYPE_REQUEST,
        handleGetDashboardTasksCountActionedByUserTypeRequest
    );
}

function* watchGetDashboardCustomerCountPBIRequest() {
    yield takeEvery(
        DashboardsActionTypes.GET_CUSTOMER_COUNT_PBI_FOR_COMPANY_REQUEST,
        handleGetDashboardCustomerCountPBIRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* dashboardsSaga() {
    yield all([
        fork(watchGetDashboardsSuccess),
        fork(watchGetDashboardDataRequest),
        fork(watchSetDashboardSelectedIdRequest),
        fork(watchGetDashboardTopInvoicesRequest),
        fork(watchGetDashboardTopCreditsRequest),
        fork(watchGetDashboardTopCustomersRequest),
        fork(watchGetDashboardRecentChangesRequest),
        fork(watchGetDashboardChangeStatisticsRequest),
        fork(watchGetDashboardChangeStatisticsByTypeRequest),
        fork(watchGetDashboardCustomersSettlementRequest),
        fork(watchGetDashboardTasksCountRequest),
        fork(watchGetDashboardTasksCountForUsersRequest),
        fork(watchGetDashboardTasksCountForUserTypesRequest),
        fork(watchGetDashboardOpenSupportTicketsRequest),
        fork(watchGetDashboardSalesRequest),
        fork(watchGetDashboardNotificationSuccessRequest),
        fork(watchGetDashboardInvoicingAndSettlementsRequest),
        fork(watchGetDashboardTicketsRequest),
        fork(watchGetDashboardTicketsForUsersRequest),
        fork(watchGetDashboardTicketsForUserTypesRequest),
        fork(watchGetDashboardTotalOwedRequest),
        fork(watchGetDashboardNotificationsRequest),
        fork(watchGetDashboardCurrentATBRequest),
        fork(watchGetDashboardOpenInvoicesRequest),
        fork(watchGetDashboardCommunicationDeliveryRequest),
        fork(watchGetDashboardPaymentsRequest),
        fork(watchGetDashboardPaymentsAllocationRequest),
        fork(watchGetDashboardCashflowForecastRequest),
        fork(watchGetDashboardDailySalesOutRequest),
        fork(watchGetDashboardTasksCountActionedByUserRequest),
        fork(watchGetDashboardTasksCountActionedByUserTypeRequest),
        fork(watchGetDashboardCustomerCountPBIRequest)
    ]);
}

export default dashboardsSaga;

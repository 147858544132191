/**
 * File for the `Recent changes` widget.
 */

import { Table } from 'antd';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isUndefined,
    map,
    split,
    sum,
    isEmpty
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getDashboardRecentChangesRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    getTranslatedText,
    replaceInstancesOfCustomerString,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    displayColumnIndicator,
    displayColumnOptions,
    displayRowCountOptions,
} from './RecentChangesWidgetFields';
import {
    resourceTypesFilterOptions,
    changeTypesFilter,
    changeTypesFilterOptions,
    sourceTypesFilterOptions
} from '../../constants/changesSortAndFilters';
import {
    dateFormatDoMMMYYYYhmmASpace,
} from '../../constants/dateFormats';

interface IProps {
    widgetDetails: DynamicObject;
    readonly isOrgView?: boolean;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly functionRefObj?: any;
}

const RecentChangesWidget: React.FC<IProps> = ({
    widgetDetails,
    isOrgView,
    formatDateUTCToLocal,
    functionRefObj
}: IProps) => {
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptions
        : filter(displayColumnOptions, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
    }));

    const dispatch = useDispatch();
    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
    });

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const recentChangesColumns: DynamicObject[] = [];
        let hasDisplayValue = false;
        let ChangeTypes: any = [];
        let ResourceTypes: any = [];
        let SourceTypes: any = [];

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    recentChangesColumns.push({
                        title: columnTitle,
                        dataIndex: fieldName,
                    });
                }
            }

        });

        ChangeTypes = map(
            get(widgetDetails, 'changeTypes'),
            (changeType: number) => changeType
        );

        ResourceTypes = map(
            get(widgetDetails, 'resourceTypes'),
            (resourceType: string) => resourceType
        );

        SourceTypes = map(
            get(widgetDetails, 'sourceTypes'),
            (sourceType: number) => sourceType
        );

        let payload: DynamicObject = {
            filters: {
                Type: sum(ChangeTypes),
                ResourceTypes,
                SourceTypes
            },
            pageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
            });
        }

        dispatch(
            getDashboardRecentChangesRequestAction(
                payload,
                isOrgView,
                (changes: any) => {
                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = recentChangesColumns;
                    } else {
                        usedColumns = tableState.columns;
                    }

                    const usedDataSource = map(
                        changes,
                        (chg: any, index: number) => {
                            const resourceTypeLabel = resourceTypesFilterOptions(true,true).find((item) => item.value === split(get(chg, "Resource.Uri", ""), ":")[0])
                            const associatedResourceTypeLabel = resourceTypesFilterOptions(true,true).find((item) => item.value === (!isEmpty(get(chg, "AssociatedResource.Uri", "")) ?
                                split(get(chg, "AssociatedResource.Uri", ""), ":")[0] :
                                split(get(chg, "Allocation.ToResource.Uri", ""), ":")[0]))
                            const changeLabel = changeTypesFilterOptions(true).find((item) => item.value === get(changeTypesFilter, get(chg, "Type", "")))
                            const sourceType = sourceTypesFilterOptions(true).find((item) => item.dbValue === get(chg, "Source", ""))

                            return {
                                key: index,
                                ResourceType: resourceTypeLabel ? resourceTypeLabel.label : "",
                                AssociatedResourceType: associatedResourceTypeLabel ? associatedResourceTypeLabel.label : "",
                                ChangeType: changeLabel ? changeLabel.label : "",
                                Source: sourceType ? sourceType.label : "",
                                Date: formatDateUTCToLocal(
                                    get(chg, 'CreatedDateTime'),
                                    null,
                                    dateFormatDoMMMYYYYhmmASpace
                                ),
                                User: `${get(chg, "User.GivenName", "")} ${get(chg, "User.FamilyName", "")}`
                            };
                        }
                    );

                    updateTableStateObject({
                        columns: usedColumns,
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        columns: stateColumns,
        loading: stateLoading,
        dataSource: stateDataSource,
    } = tableState;
    return (
        <div>
            <Table
                className="table-striped-rows table-ws-nw"
                columns={stateColumns.map(column => ({
                    ...column,
                    title: getTranslatedText(column.title)
                }))}
                dataSource={stateDataSource}
                loading={stateLoading}
                pagination={false}
                size="middle"
                rowKey="key"
                locale={{
                    emptyText: getTranslatedText('No Data'),
                }}
            />
        </div>
    );
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(RecentChangesWidget))
);

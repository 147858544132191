import { checkIfEmailIsValid, getTranslatedText } from '../utils/commonFunctions';
import { dateSelectOptions } from './invoicesSortAndFilters';
import { selectUserFilterMapping } from '../constants/common';
import moment from 'moment-timezone';

export const raiseTicketFieldVariables = {
    SELECT_ACTION: 'TicketOption',
    PAYMENT_DATE: 'PaymentDate',
    AMOUNT_PAID: 'AmountPaid',
    OTHER_INFORMATION: 'Information',
    SLIDER_MONTH: 'MonthSlider'
};

export const ticketOptionTypes = {
    GENERAL: 'General',
    INVOICE_PAID: 'InvoicePaid',
    REQUEST_PAYMENT_PLAN: 'RequestForPaymentPlan',
    INFO_ONLY: 'InfoOnly',
    INFO_ESCALATE: 'InfoEscalate',
};

export const ticketOptionReasons = {
    PROMISE_TO_PAY: 'Promise to pay'
}

export const preConfiguredFilters = {
    ALL_ITEMS: 'All items',
};

// export const ticketsTableFilterOptions = [
//     {
//         label: 'All items',
//         value: preConfiguredFilters.ALL_ITEMS,
//     },
// ];

export const ticketsSortByOptions = [
    // { label: 'Priority', value: 'Priority' },
    { label: 'Type', value: 'Type' },
    { label: 'State', value: 'State' },
    { label: 'Company name', value: 'DisplayName' },
    { label: 'Customer code', value: 'CustomerCode' },
    { label: 'Opened date', value: 'OpenDate' },
    { label: 'Closed date', value: 'ClosedDate' },
    { label: 'Action with', value: 'ActionWith' },
];

export const ticketsStatusFilterOptions = [
    { label: 'All', value: -1 },
    { label: 'Open', value: 1 },
    { label: 'Closed', value: 0 },
];

export const ticketStateMap = {
    OPEN: 'Open',
    CLOSED: 'Closed',
};

export const ticketsTypeOptions = [
    {
        label: 'Invoice already paid',
        value: ticketOptionTypes.INVOICE_PAID,
    },
    {
        label: 'General',
        value: ticketOptionTypes.GENERAL,
    },
    /* Commenting out these extra options as they are currently out of scope
    {
        label: 'Request for payment plan',
        value: ticketOptionTypes.REQUEST_PAYMENT_PLAN,
    },
    {
        label: 'Promise to pay',
        value: ticketOptionTypes.PROMISE_TO_PAY,
    }, */
];

const {
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    UNDEFINED
} = dateSelectOptions;

export const ticketRaisedOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const ticketResolvedOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const extraInformationLabels: any = {
    Information: 'Other information'
};

export const ticketActionsOptions = [
    {
        label: 'With customer',
        value: 1,
    },
    {
        label: 'With staff',
        value: 2,
    },
    {
        label: 'Not yet actioned',
        value: 4,
    },
];

export const getTicketsWidgetFilters = () => {
    return [
        // {
        //     filterName: 'Type',
        //     filterStateName: 'ReasonType',
        //     filterElement: 'select-checkbox-group',
        //     filterType: 'array',
        //     filterOptions: ticketsTypeOptions,
        //     filterPlaceholder: 'Select Ticket Type',
        // },
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Assigned user',
            filterStateName: 'AssignedUserId',
            filterNameQuery: 'Name',
            //filterElement: 'select-with-search',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterResponse: 'GetUsersForCompany.CompanyUserRoles',
            filterLabelField: ['User.GivenName', 'User.FamilyName'],
            filterSort: 'Name',
            filterNameQuerySub: 'Email',
            filterSubChecking: checkIfEmailIsValid,
            filterJSONValueFieldSub: 'User.Email',
            filterHiddenName: 'UserId',
            filterHiddenValue: 'User.UserId',
            tagClass: 'wb-bw',
            filterMappingUsed: selectUserFilterMapping,
            filterExclude: true,
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerCountry',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerState',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
        },
        {
            filterName: 'Raised',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: ticketRaisedOptions,
            filterMaxDate: moment(),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
            filterPlaceholder: getTranslatedText('Select Date Range'),
        },
        {
            filterName: 'Resolved',
            filterStateName: 'ClosedDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: ticketResolvedOptions,
            filterMaxDate: moment(),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
            filterPlaceholder: getTranslatedText('Select Date Range'),
            filterExclude: true,
        },
    ];
}
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
} from 'antd';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatYYYYMMDDDash,
} from '../../../constants/dateFormats';
import { getPopoverContainer, getTranslatedText } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { Task } from '../../../store/tasks/types';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';
import { taskActionOrganisationPhoneCallTaskRequest, taskActionPhoneCallTaskRequest } from '../../../store/tasks/actions';
import { get, isEmpty, map, some } from 'lodash';
import ModalWithSpinner from '../../common/ModalWithSpinner';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { getCurrentUser } from '../../../store/users/sagas';
import { IsOrganisationViewAttribute } from '../../../constants/authUserAttributes';
const { confirm } = Modal;

const FormItem = Form.Item;
const { TextArea } = Input;
interface IProps {
    readonly taskRecord?: Task;
    readonly getSelectedTasksValues?: any;
    readonly cancelVisible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly containerRef?: any;
}
const formFieldsMap = {
    PromiseToPayDate: 'PromiseToPayDate',
    Comment: 'Comment'
};

const TaskPhoneCallDrawerContent: React.FC<IProps> = ({
    taskRecord,
    getSelectedTasksValues,
    cancelVisible,
    closePanel,
    form,
    containerRef
}) => {
    const dispatch = useDispatch();
    
    const customerLabel = useSelector(getCustomerUILabel);
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [hasPromiseToPay, setHasPromiseToPay] = useState(false);
    const {
        getFieldDecorator,
        getFieldValue,
        validateFields,
        isFieldsTouched,
        getFieldsError
    } = form;
    const formInvalid = Object.values(getFieldsError()).some(error => !!error);
    const hasMobilePhoneOrLandline = taskRecord
        ? some(get(taskRecord, ['Customer', 'Contacts']),
            contact => !!get(contact, 'MobileNumber') || !!get(contact, 'Landline'))
        : true;

    const handleFormSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        validateFields((err: any, values: DynamicObject) => {
            if (!err) {
                if (values.PromiseToPayDate) {
                    values.PromiseToPayDate = moment(values.PromiseToPayDate).format(
                        dateFormatYYYYMMDDDash
                    );
                }
                const taskAction = isOrgView ? taskActionOrganisationPhoneCallTaskRequest : taskActionPhoneCallTaskRequest;

                confirm({
                    className: 'modal-swapped-buttons',
                    title: getTranslatedText('Confirm'),
                    content: getTranslatedText(`Are you sure you want to save this information?`),
                    okText: getTranslatedText(confirmModalOkText),
                    cancelText: getTranslatedText(confirmModalCancelText),
                    onOk() {
                        setSubmitLoading(true);
                        const { allExcept, keysToUse, filterObject, recordLimit } = (getSelectedTasksValues && getSelectedTasksValues())
                            || { allExcept: false, keysToUse: [get(taskRecord, 'Id')], filterObject: {CompanyIds: [get(taskRecord, 'Customer.CompanyId')]} } as any;
                        dispatch(taskAction({
                            filter: filterObject,
                            taskIds: keysToUse,
                            excludeTasks: allExcept,
                            recordLimit,
                            Comment: get(values, formFieldsMap.Comment),
                            PromiseToPayDate: get(values, formFieldsMap.PromiseToPayDate)
                        }, phoneCallTaskResponseModal));
                    },
                });
            }
        });
    };

    const phoneCallTaskResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Actioned phone call task successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                okText: getTranslatedText('OK'),
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to action task!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK'),
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const commentFormItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const disableWeekends = (date: Moment) => {
        const day = date.day();
        return day === 0 || day === 6; // 0: Sunday, 6: Saturday
    };

    return <>
        <Form className="form-inline-mb-8" {...formItemLayout}>
            {hasMobilePhoneOrLandline && <>
                <Row className="mb-10" type="flex" align="middle">
                    <Col md={8}></Col>
                    <Col>
                        <Checkbox checked={hasPromiseToPay} onChange={(e) => setHasPromiseToPay(e.target.checked)}>
                            {getTranslatedText('Specify promise to pay date')}
                        </Checkbox>
                    </Col>
                </Row>
                <FormItem label={getTranslatedText("Payment date")}>
                    {getFieldDecorator(formFieldsMap.PromiseToPayDate, {
                        rules: [
                            {
                                validator: async (_rule: any, value: any) => {
                                    const promiseToPayDate = value || getFieldValue(formFieldsMap.PromiseToPayDate);
                                    if (hasPromiseToPay && !promiseToPayDate) {
                                        throw new Error(getTranslatedText(`Payment date is required`));
                                    }
                                }
                            }
                        ],
                    })(
                        <DatePicker
                            disabled={!hasPromiseToPay}
                            format={dateFormatDDMMYYYYSlash}
                            placeholder={getTranslatedText("Select a date")}
                            style={{ width: '100%' }}
                            disabledDate={(date) => {
                                return date ? date.isBefore(moment().startOf('day')) || disableWeekends(date) : false;
                            }}
                            getCalendarContainer={
                                containerRef
                                    ? () =>
                                        getPopoverContainer(
                                            containerRef
                                        )
                                    : undefined
                            }
                        />
                    )}
                </FormItem>
            </>}
            <Row className="mb-10" type="flex" align="middle">
                <Col className="ta-right pr-8" md={8}>
                    {getTranslatedText('Comment')}:
                </Col>
            </Row>
            <FormItem {...commentFormItemLayout}>
                {getFieldDecorator(formFieldsMap.Comment, {
                    rules: [
                        {
                            max: 1024,
                            message: getTranslatedText('Up to 1024 characters only can be saved!'),
                        },
                    ],
                })(
                    <TextArea
                        autoSize={{
                            minRows: 4,
                        }}
                        placeholder={getTranslatedText("Please provide some information here")}
                        allowClear
                    />
                )}
            </FormItem>
            {!hasMobilePhoneOrLandline && <div className="orange">
                {getTranslatedText('This phone call cannot be completed as the {customerLabel} does not have a mobile number or landline!')}
            </div>}
            <div className="mt-10 ta-right">
                <Button
                    onClick={handleFormSave}
                    disabled={formInvalid || !isFieldsTouched()}
                    type="primary"
                >
                    {getTranslatedText('Action')}
                </Button>
                {cancelVisible && (
                    <Button onClick={() => closePanel && closePanel()}
                        className="ml-8">
                        {getTranslatedText('Cancel')}
                    </Button>
                )}
            </div>
        </Form>
        {submitLoading && (
            <ModalWithSpinner
                modalTitle={getTranslatedText("Actioning phone call")}
                modalVisible={submitLoading}
                displayMessage={getTranslatedText("Please wait while actioning the phone call . . .")}
            />
        )}
    </>;
};

const TaskPhoneCallDrawerContentForm = Form.create({
    name: 'task-phone-call-form',
})(TaskPhoneCallDrawerContent);

export default withRouter(TaskPhoneCallDrawerContentForm);

import React, { RefObject, useEffect, useRef, useState, Suspense, lazy } from 'react';
import { Col, Modal, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import QueueAnim from 'rc-queue-anim';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import {
    DeleteWorkflowsRequestPayload,
    GetWorkflowConfigurationsRequestPayload,
    WorkflowConfiguration,
    WorkflowsState,
} from '../../store/workflow/types';
import { getCurrentUser } from '../../store/users/sagas';
import { DynamicObject, ResponseModalObject } from '../../utils/commonInterfaces';
import {
    PAGE_NAMES_FOR_VIEW,
    WORKFLOW_CONFIGURATIONS_PAGE
} from '../../config/tableAndPageConstants';
import { ApplicationState } from '../../store';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import { initialState } from '../../store/workflow/reducer';
import { filter, get, includes, isEmpty, isEqual, map, remove } from 'lodash';
import { CompanyUserRole } from '../../store/companies/types';
import { deleteWorkflowsRequestAction, getWorkflowConfigurationsRequestAction, setWorkflowSelectedIdRequestAction } from '../../store/workflow/actions';
import { getRolePermissions } from '../../store/roles/sagas';
import VirtualizedList from '../../components/common/VirtualizedList';
import { computeTableScroll, getTranslatedText } from '../../utils/commonFunctions';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import WorkflowItemComponent from '../../components/workflows/WorkflowItemComponent';
import WorkflowDetailsPanelContent from '../../components/workflows/WorkflowDetailsPanelContent';
import WorkflowDetailsDrawer from '../../components/workflows/WorkflowDetailsDrawer';
import { getWorkflowSelectedId } from '../../store/workflow/sagas';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import './workflows.less';
import { getCompanyWorkflowOptionsRequestAction } from '../../store/companies/actions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const { Title } = Typography;
const { confirm } = Modal;
interface IProps {
    readonly handlePageViewSelection: (
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}

let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
const tablePageSize = WORKFLOW_CONFIGURATIONS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.WORKFLOW_CONFIGURATIONS_PAGE;

const WorkflowManagementPage: React.FC<IProps> = ({
    handlePageViewSelection
}) => {
    const dispatch = useDispatch();

    const selectedId = useSelector(getWorkflowSelectedId);
    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const [workflowDetailsDrawer, setWorkflowDetailsDrawer] = useState<{
        visible: boolean,
        workflowConfiguration?: WorkflowConfiguration
    }>({ visible: false });

    const workflowsState: WorkflowsState = useSelector(
        (state: ApplicationState) => state.workflows
    );

    const [workflowTableFilter, setWorkflowTableFilter] = useState<
        string | undefined
    >(
        isEqual(workflowsState.filters, initialState.filters)
            ? initialState.tableFilter
            : workflowsState.tableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(workflowsState, 'pageData.currentPage', 0)
    );

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        createButtonLoading: boolean;
    }>({
        createButtonLoading: false,
    });

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const [showConditions, setShowConditions] = useState<{
        allSelected: boolean;
        createWorkflowDrawer: boolean;
        deleteWorkflows: boolean;
    }>({
        allSelected: false,
        createWorkflowDrawer: false,
        deleteWorkflows: false
    });

    /**
     * Function that populates the workflow table filter (upper left).
     * @param menu
     */
    const populateWorkflowFilterSelectDropdownRender = (
        menu: React.Component
    ) => (
        <div>
            {menu}
        </div>
    );

    /**
     * Function that prepares the payload for the fetch request in workflow table.
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetWorkflowConfigurationsRequestPayload = {
            filters: {},
            sortAscending: workflowsState.sortAscending,
            pageSize,
            currentPage: currentPage
        };

        return payload;
    };

    /**
     * Function that handles fetching of workflows (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchWorkflows = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getWorkflowConfigurationsRequestAction(payload));
    };

    /**
     * Common function that updates the tableRowSelection state.
     * @param selectionObject - must conform to tableRowSelection object
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Common function for updating the action bar items state.
     * @param itemStateObject - must conform to actionBarItemsState object
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Function for resetting all selected and unselected row keys in the state.
     * Also resets some of the action bar items state
     * and the allselected indicator for `Select all` button.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            editButtonDisabled: true,
        });
        updateShowConditionsObject({
            allSelected: false,
        });
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchWorkflows(0);
    };

    /**
     * Function called when refresh button is clicked (upper left near table filter dropdown).
     */
    const handleWorkflowRefresh = () => {
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Action Bar Functions
     */
    /**
     * Function that updates the redux table filters and state table filters.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeWorkflowTableFilter = (
        refetch: boolean = true
    ) => {
        resetTableScrollAndPageData();
        if (refetch) {
            handleWorkflowRefresh();
        }
    };

    /**
     * Common function that manipulates the state object for showConditions state.
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Reset Functions
     */
    /**
     * Function for resetting the table scroll and sets current page to initial state.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function triggered when action bar items with drawer/popover is clicked.
     * @param name - name of popover item
     * @param condition - optional condition whether to show the popover/drawer
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'createWorkflowDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and company switch.
     */
    const callFetchWorkflows = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                resetTableScrollAndPageData();
            }
            fetchWorkflows(0);
        }
    };

    useEffect(callFetchWorkflows, [selectedUserCompany]);


    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleFetch = () => {
        if (
            isEmpty(selectedUserCompany) ||
            workflowsState.loading
        )
            return;

        if (!workflowsState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchWorkflows(nextPage);
    };

    function populateTableLoadingText(): string | undefined {
        const loadingText = `${getTranslatedText('Fetching workflows')}`;

        isRefetching = false;

        return loadingText;
    }


    /**
     * Function called when checkbox is clicked (either checked/unchecked) - will call onRowSelect.
     * @param record - the data for each row (with additional `key` property to identify the row)
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked. Responsible for showing the drawer.
     * @param record - data for each row
     * @param activeTab - the tab key to be shown upon showing the drawer
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setWorkflowSelectedIdRequestAction(get(record, 'WorkflowId'), () => {
                setWorkflowDetailsDrawer({
                    visible: true,
                    workflowConfiguration: record as any
                });
            })
        );
    };

    /**
     * Function triggered when after checkbox is clicked. Responsible for setting the state of selected
     * or unselected rows.
     * @param record - table row data record
     * @param selected - boolean if checked/unchecked
     * @param selectedRows - list of keys selected previously
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };


    /**
     * Function for populating the response modal after delete workflows action API call has finished.
     * @param param0
     */
    const deleteWorkflowsResponseModal = ({
        IsSuccess,
        Messages
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            deleteWorkflows: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Selected workflows deleted successfully!'),
                onOk: handleWorkflowRefresh,
            });
        } else {
            let errorMessageContent: any = `Failed to delete workflows!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }
            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent
            });
        }
    };

    /**
     * Function called when delete workflows is confirmed.
     */
    const deleteWorkflows = () => {
        updateShowConditionsObject({
            deleteWorkflows: true,
        });

        const payload: DeleteWorkflowsRequestPayload = {
            WorkflowIds: tableRowSelection.selectedRowKeys
        };

        dispatch(
            deleteWorkflowsRequestAction({
                ...payload,
                callback: deleteWorkflowsResponseModal,
            })
        );
    };

    /**
     * Function called when delete workflows button is clicked which shows a confirmation modal for the action.
     */
    const confirmDeleteWorkflows = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Delete workflows'),
            content: (
                <div>
                    {getTranslatedText('Are you sure you want to remove the selected workflows')}
                </div>
            ),
            onOk: () => deleteWorkflows(),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    const closePanel =
        (conditionBool: string) => (refreshList?: boolean) => {
            updateShowConditionsObject({
                [conditionBool]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
                dispatch(getCompanyWorkflowOptionsRequestAction());
            }
        };

    const closeWorkflowDetailsDrawer = (refreshList?: boolean) => {
        setWorkflowDetailsDrawer({ visible: false });
        if (refreshList) {
            refetchListAndResetScroll();
        }
    }

    /**
     * Function for populating the title and message for the loading popover when delete workflows action is executed.
     */
    const populateTitleAndMessageForDeleteWorkflows = () => {
        let deleteWorkflowTitle = '';
        let deleteWorkflowMessage = '';
        if (showConditions.deleteWorkflows) {
            deleteWorkflowTitle = getTranslatedText('Deleting workflows');
            deleteWorkflowMessage = getTranslatedText('Please wait while we remove the selected workflows');
        }

        return {
            deleteWorkflowTitle,
            deleteWorkflowMessage
        };
    };

    const { deleteWorkflowTitle, deleteWorkflowMessage } =
        populateTitleAndMessageForDeleteWorkflows();

    return (<>
        <Row>
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container">
                        <Col span={24}>
                            <Title level={3}>{getTranslatedText('Workflow Configurations')}</Title>
                        </Col>
                    </Row>
                </QueueAnim>
                <div className="spacer-15" />
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={workflowsState.loading}
                        actionItems={[
                            {
                                actionKey: 'task-filter',
                                actionType: 'select-with-button',
                                selectValue: workflowTableFilter,
                                selectDropdownRender:
                                    populateWorkflowFilterSelectDropdownRender,
                                onSelectChange: changeWorkflowTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: workflowsState.loading,
                                onButtonClick: handleWorkflowRefresh
                            },
                            {
                                actionKey: 'create-workflow',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.WORKFLOW_MANAGEMENT,
                                popoverVisible:
                                    showConditions.createWorkflowDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createWorkflowDrawer'
                                ),
                                popoverTitle: getTranslatedText('Create new workflow'),
                                popoverContent: <WorkflowDetailsPanelContent
                                    closePanel={closePanel('createWorkflowDrawer')}
                                    isCreateNew={true}
                                />,
                                buttonDisabled: workflowsState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create workflow')}</span>
                                    </>
                                ),
                                drawerWidth: '70vw',
                            },
                            {
                                actionKey: 'delete-workflow',
                                actionType: 'protected-button',
                                allowedRoles: rolePermissions.WORKFLOW_MANAGEMENT,
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                onButtonClick: confirmDeleteWorkflows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'trash']}
                                        />
                                        <span>{getTranslatedText('Delete workflows')}</span>
                                    </>
                                ),
                            }
                        ]}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={workflowsState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                WORKFLOW_CONFIGURATIONS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={workflowsState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(workflowsState.errorMessages)
                                    ? get(workflowsState, 'errorMessages.0')
                                    : getTranslatedText('No workflow configurations found')
                            }
                            hasNextPage={false}
                            itemComponent={WorkflowItemComponent}
                            itemHeight={WORKFLOW_CONFIGURATIONS_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Suspense fallback={null}>
            <WorkflowDetailsDrawer
                visible={workflowDetailsDrawer.visible}
                closePanel={closeWorkflowDetailsDrawer}
                workflowConfiguration={workflowDetailsDrawer.workflowConfiguration}
            />
        </Suspense>
        {showConditions.deleteWorkflows && (
            <Suspense fallback={null}>
                <ModalWithSpinner
                    modalTitle={deleteWorkflowTitle}
                    modalVisible={showConditions.deleteWorkflows}
                    displayMessage={deleteWorkflowMessage}
                />
            </Suspense>
        )}
    </>);
};

export default withPageViewHandler(WorkflowManagementPage);
/**
 * Component for populating the Invoice details drawer.
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getSelectedReportId,
    getReportData,
} from "../../store/report/sagas";
import {
    clearReportDataAction,
} from "../../store/report/actions";
import { get } from "lodash";
import { Drawer } from "antd";
import ReportDetailsContent from "./ReportDetailsContent";
import { getLoadingSkeletons } from "../../utils/commonFunctions";
import { DynamicObject } from "../../utils/commonInterfaces";

interface IProps {
    readonly visible: boolean;
    readonly region?: string;
    readonly closeDrawer: () => void;
    readonly refetchList?: () => void;
    readonly activeTab?: string;
    readonly getReportDataRequestAction: (reportJobId: string) => DynamicObject
}

export const itemDrawerTabItemContainerMinusHeight = 108;

const ReportDetailsDrawerComponent: React.FC<IProps> = ({
    visible,
    region,
    closeDrawer,
    refetchList,
    getReportDataRequestAction
}: IProps) => {
    const dispatch = useDispatch();
    const selectedId = useSelector(getSelectedReportId);
    const { loading, record } = useSelector(getReportData);
    const user = get(record, "User");

    const fetchReportDetails = () => {
        dispatch(getReportDataRequestAction(selectedId));
    };

    const initializeDataByReportId = () => {
        if (selectedId) fetchReportDetails();
    };

    useEffect(initializeDataByReportId, [selectedId]);

    const listenerForClearingState = () => {
        if (!visible) dispatch(clearReportDataAction());
    };

    useEffect(listenerForClearingState, [visible]);

    return (
        <>
            <div>
                <Drawer
                    className="protected-drawer"
                    visible={visible}
                    onClose={closeDrawer}
                    closable={true}
                    width={1050}
                >
                    {(loading ? (
                        getLoadingSkeletons(
                            3,
                            loading
                        )
                    ) : (
                        <ReportDetailsContent
                            region={region}
                            record={record}
                            user={user}
                        />
                    ))}
                </Drawer>
            </div>
        </>
    );
};

export default ReportDetailsDrawerComponent;

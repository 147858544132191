/**
 * Component for showing an alert message with icon.
 * Used in showing error and success banners.
 */

import React from 'react';
import { Alert, Row, Col } from 'antd';
import FontAwesome from './FontAwesome';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    readonly faIcon: string[];
    readonly title: string;
    readonly displayMessageComp: any;
    readonly alertType: 'success' | 'info' | 'warning' | 'error' | undefined;
}
const AlertMessageWithIcon: React.FC<IProps> = ({
    faIcon,
    title,
    displayMessageComp,
    alertType,
}: IProps) => {

    return (
        <Alert
            className="h-100"
            message={
                <div className="alert-content-100">
                    <Col md={24}>
                        <Row className="alert-message-content-container">
                            <Col md={10} sm={24} xs={24} className="ta-center">
                                <FontAwesome
                                    icon={faIcon}
                                    style={{ fontSize: 100 }}
                                />
                            </Col>
                            <Col
                                md={14}
                                sm={24}
                                xs={24}
                                style={{ padding: '30px 0px' }}
                                className="alert-message-error-section"
                            >
                                <h2 style={{ marginBottom: 10 }}>{getTranslatedText(title)}</h2>
                                <div>{getTranslatedText(displayMessageComp)}</div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            }
            type={alertType}
        />
    );
};

export default AlertMessageWithIcon;

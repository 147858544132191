/**
 * Reusable component for populating the dynamic custom field tabs in payment plan selfserve.
 */

import { Form, Row, Col, Select, Input } from 'antd';
import React from 'react';

import {
    FORM_FIELDS
} from '../../constants/paymentPlanConfigs';
import { getTranslatedText } from '../../utils/commonFunctions';

const { Item: FormItem } = Form;

interface IProps {
    form?: any;
    updateFormState: any;
    paymentPlanOptionIndex: number;
    paymentPlanOptionKeyIndex: number;
    customFieldIndex: number;
    dynamicCustomField:any;
    populateSelectOptions:any;
}

const PaymentPlanDynamicCustomFieldTabContent: React.FC<IProps> = ({
    form,
    updateFormState,
    paymentPlanOptionIndex,
    customFieldIndex,
    dynamicCustomField,
    populateSelectOptions
}: IProps) => {
    const { getFieldDecorator } = form;

    return (
        <>
            <Row>
                <Col className='mt-7' span={3}>{getTranslatedText("Name:")} </Col>
                <Col span={5}>
                    <FormItem>
                            {getFieldDecorator(FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.CUSTOM_FIELD_NAME + customFieldIndex, {
                                initialValue: dynamicCustomField ? dynamicCustomField.name : '',
                            })(
                                <Select
                                    className={`action-bar-select cursor-p`}
                                    onChange={updateFormState(
                                        FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.CUSTOM_FIELD_NAME + customFieldIndex,
                                        paymentPlanOptionIndex,
                                        null,
                                        customFieldIndex
                                    )}
                                >
                                    {populateSelectOptions()}
                                </Select>
                            )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col className='mt-7' span={3}>{getTranslatedText("Value:")} </Col>
                <Col span={5}>
                        <FormItem>
                            {getFieldDecorator(FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.CUSTOM_FIELD_VALUE + customFieldIndex, {
                                initialValue: dynamicCustomField ? dynamicCustomField.value : '',
                            })(
                                <Input 
                                    onChange={updateFormState(
                                        FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.CUSTOM_FIELD_VALUE + customFieldIndex,
                                        paymentPlanOptionIndex,
                                        null,
                                        customFieldIndex
                                    )}
                                />
                            )}
                        </FormItem>
                </Col>
            </Row>
        </>
        
    );
};

export default PaymentPlanDynamicCustomFieldTabContent;

/**
 * Reusable component for populating the custom field tabs section in payment plan selfserve.
 */

import { Typography, Form,Row,Col,Select, Input } from 'antd';
import React from 'react';
import {
    FORM_FIELDS
} from '../../constants/paymentPlanConfigs';
import { getTranslatedText } from '../../utils/commonFunctions';
const { Item: FormItem } = Form;
interface IProps {
    form?: any;
    updateFormState: any;
    paymentPlanOptionIndex: number;
    paymentPlanOptionKeyIndex: number;
    customFieldIndex: number;
    customField:any;
    populateSelectOptions:any;
}

const PaymentPlanCustomFieldTabContent: React.FC<IProps> = ({
    form,
    updateFormState,
    paymentPlanOptionIndex,
    paymentPlanOptionKeyIndex,
    customFieldIndex,
    customField,
    populateSelectOptions
}: IProps) => {
    const { getFieldDecorator } = form;

    return (
        <>
            <Row>
                <Col className='mt-7' span={3}>{getTranslatedText("Name:")} </Col>
                <Col span={5}>
                    <FormItem>
                            {getFieldDecorator(FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.CUSTOM_FIELD_NAME + customFieldIndex, {
                                initialValue: customField ? customField.Name : '',
                            })(
                                <Select
                                    className={`action-bar-select cursor-p`}
                                    onChange={updateFormState(
                                        FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.CUSTOM_FIELD_NAME + customFieldIndex,
                                        paymentPlanOptionIndex,
                                        null,
                                        customFieldIndex,
                                        paymentPlanOptionKeyIndex
                                    )}
                                >
                                    {populateSelectOptions()}
                                </Select>
                            )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col className='mt-7' span={3}>{getTranslatedText("Value:")} </Col>
                <Col span={5}>
                        <FormItem>
                            {getFieldDecorator(FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.CUSTOM_FIELD_VALUE + customFieldIndex, {
                                initialValue: customField ? customField.Value : '',
                            })(
                                <Input 
                                    onChange={updateFormState(
                                        FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.CUSTOM_FIELD_VALUE + customFieldIndex,
                                        paymentPlanOptionIndex,
                                        null,
                                        customFieldIndex,
                                        paymentPlanOptionKeyIndex
                                    )}
                                />
                            )}
                        </FormItem>
                </Col>
            </Row>
        </>
        
    );
};

export default PaymentPlanCustomFieldTabContent;

/**
 * File responsible for all the UI and actions for ImportHistory page - `/app/import/history`.
 */

import { Col, Row, Typography } from 'antd';
import { get, isEmpty } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { lazy, RefObject, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VirtualizedList from '../../components/common/VirtualizedList';
import { IMPORT_HISTORY_PAGE, PAGE_NAMES_FOR_VIEW } from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import { ApplicationState } from '../../store';
import { CompanyUserRole } from '../../store/companies/types';
import FontAwesome from '../../components/common/FontAwesome';
import {
    clearImportHistoryDataAction,
    getImportHistoryRequestAction,
    setImportHistorySelectedIdRequestAction,
} from '../../store/import/actions';
import { getCurrentEntityType, getSelectedImportHistoryId } from '../../store/import/sagas';
import { ImportHistoryState } from '../../store/import/types';
import { getCurrentUser } from '../../store/users/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import ImportHistoryItemComponent from './ImportHistoryItemComponent';
import ActionBar from '../../components/common/ActionBar';
import {
    computeTableScroll,
    getTranslatedText
} from '../../utils/commonFunctions';


const ImportHistoryItemDetailsDrawerComponent = lazy(
    () => import('./ImportHistoryItemDetailsDrawerComponent')
);

const { Title } = Typography;

let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;

const pageName = PAGE_NAMES_FOR_VIEW.IMPORT_HISTORY_PAGE;

const ImportHistoryPage: React.FC = () => {
    const dispatch = useDispatch();
    
    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const currentUser = useSelector(getCurrentUser);
    const selectedId = useSelector(getSelectedImportHistoryId);
    const entityType = useSelector(getCurrentEntityType);

    const importHistoryState: ImportHistoryState = useSelector(
        (state: ApplicationState) => state.import.history
    );
    const importHistoryData = get(importHistoryState, 'data');

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const [
        importHistoryDetailsDrawerVisible,
        setImportHistoryDetailsDrawerVisible,
    ] = useState<boolean>(false);

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(importHistoryState, 'pageData.currentPage', 0)
    );

    /**
     * Function for getting the Import history data from API.
     */
    const fetchImportHistory = () => {
        if (isEmpty(selectedUserCompany) || !entityType) return;

        resetTableScroll = false;
        dispatch(getImportHistoryRequestAction(entityType));
    };

    /**
     * Function called when company is switched. Refetch the import history data.
     */
    const callFetchImportHistory = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetTableScrollVariable();
            }
            fetchImportHistory();
        }
    };

    /**
     * Function called when row is clicked. This shows the import history details drawer.
     * @param record
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setImportHistorySelectedIdRequestAction(get(record, 'Id'), () => {
                setImportHistoryDetailsDrawerVisible(true);
            })
        );
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchImportHistory();
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
         * Function called when the refresh button is clicked.
         */
    const handleImportHistoryRefresh = () => {
        refetchListAndResetScroll();
    };
    /**
     * Function called when closing the Import history details drawer.
     */
    const closeImportHistoryDetailsDrawer = () => {
        setImportHistoryDetailsDrawerVisible(false);
        dispatch(clearImportHistoryDataAction());
        callFetchImportHistory();
    };

    // on Unmount
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (get(importHistoryData[0],'Status') === IMPORT_HISTORY_PAGE.IMPORT_HISTORY_DATA.status.inProgress) {
            timer = setTimeout(function () {
                callFetchImportHistory();
            }, 60000);
        }

        return () => {
            clearTimeout(timer)
            lastSelectedCompanyId = null;
        };
    }, [importHistoryData]);

    useEffect(() => {
        fetchImportHistory();
    }, [entityType]);

    useEffect(callFetchImportHistory, [selectedUserCompany]);



    const populateTableLoadingText = () => `Fetching list of import history`;

    /**
     * Function for resetting the table scroll.
     */
    const resetTableScrollVariable = async () => {
        resetTableScroll = true;
    };

    return (
        <Col md={24} sm={24} xs={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText("History")}</Title>
                    </Col>
                </Row>
                <div className="spacer-5" />
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={importHistoryState.loading}
                        actionItems={[
                            {
                                actionKey: 'import-refresh',
                                actionType: 'refresh-button',
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Refresh")}</span>
                                    </>
                                ),
                                buttonDisabled: importHistoryState.loading,
                                onButtonClick: handleImportHistoryRefresh,
                            }
                        ]}
                    />
                </div>
                <div className="spacer-5" />
                <Row key="table-container" className="border-top-first">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={importHistoryState.data}
                            scroll={computeTableScroll(
                                window.innerHeight - 240,
                                IMPORT_HISTORY_PAGE.pageSize,
                                IMPORT_HISTORY_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            onRowClick={onRowClick}
                            loading={importHistoryState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(importHistoryState.errorMessages)
                                    ? get(importHistoryState, 'errorMessages.0')
                                    : "No import history found"
                            }
                            hasNextPage={false}
                            itemComponent={ImportHistoryItemComponent}
                            itemHeight={IMPORT_HISTORY_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <ImportHistoryItemDetailsDrawerComponent
                            visible={importHistoryDetailsDrawerVisible}
                            closeDrawer={closeImportHistoryDetailsDrawer}
                            entityType={entityType}
                        />
                    </Suspense>
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default ImportHistoryPage;
import { action } from "typesafe-actions";
import { ContentSelection, ContentTemplate, ContentTemplateConfiguration, ContentsActionTypes, ContentsState, GetContentTemplatePayload, GetContentTemplatePreviewPayload, ManualCommunicationTemplate, SaveContentTemplatePayload, WorkflowContentConfiguration } from "./types";
import { DynamicObject } from "../../utils/commonInterfaces";

export const getWorkflowContentConfigurationAction = () =>
    action(ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION);
export const getWorkflowContentConfigurationSuccess = (payload: WorkflowContentConfiguration[]) =>
    action(ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION_SUCCESS, payload);
export const getWorkflowContentConfigurationError = (payload: string[]) =>
    action(ContentsActionTypes.GET_WORKFLOW_CONTENT_CONFIGURATION_ERROR, payload);
export const clearWorkflowContentConfiguration = () =>
    action(ContentsActionTypes.CLEAR_WORKFLOW_CONTENT_CONFIGURATION);

export const getContentTemplateConfigurationAction = () =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION);
export const getContentTemplateConfigurationSuccess = (payload: ContentTemplateConfiguration) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION_SUCCESS, payload);
export const getContentTemplateConfigurationError = (payload: string[]) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_CONFIGURATION_ERROR, payload);

export const changeContentSelectionAction = (payload: ContentSelection) =>
    action(ContentsActionTypes.CHANGE_CONTENT_SELECTION, payload);

export const getContentTemplateAction = (payload: GetContentTemplatePayload) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE, payload);
export const getContentTemplateSuccess = (payload: ContentTemplate) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_SUCCESS, payload);
export const getContentTemplateError = (payload: string[]) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_ERROR, payload);

export const getContentTemplatePreviewAction = (payload: GetContentTemplatePreviewPayload, version: string) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW, {
        payload, version
    });
export const getContentTemplatePreviewSuccess = (payload: ContentsState['activeData']['preview']) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW_SUCCESS, payload);
export const getContentTemplatePreviewError = (payload: string[], version: string | null) =>
    action(ContentsActionTypes.GET_CONTENT_TEMPLATE_PREVIEW_ERROR, {
        previewErrors: payload,
        version
    });

export const getManualCommunicationTemplatesAction = (
    callback?: (response: ManualCommunicationTemplate[]) => void
) => action(ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS, { callback });
export const getManualCommunicationTemplatesActionSuccess = (payload: ManualCommunicationTemplate[]) =>
    action(ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_SUCCESS, payload);
export const getManualCommunicationTemplatesActionError = (payload: string[]) =>
    action(ContentsActionTypes.GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_ERROR, payload);

export const saveContentTemplateAction = (
    payload: SaveContentTemplatePayload,
    callback: (response: DynamicObject) => void) =>
    action(ContentsActionTypes.SAVE_CONTENT_TEMPLATE, {
        payload, callback
    });
export const saveContentTemplateSuccess = (payload: SaveContentTemplatePayload & { NewTemplateKey: string }) =>
    action(ContentsActionTypes.SAVE_CONTENT_TEMPLATE_SUCCESS, payload);
export const saveContentTemplateError = () =>
    action(ContentsActionTypes.SAVE_CONTENT_TEMPLATE_ERROR);

export const clearContentStateAction = () =>
    action(ContentsActionTypes.CLEAR_CONTENT_STATES);

export const changePreviewVersionAction = (payload: string) =>
    action(ContentsActionTypes.CHANGE_PREVIEW_VERSION, payload);
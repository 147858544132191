/**
 * File responsible for all the UI and actions for Organisation Settings>Users page - `/app/settings/users`.
 */

import { Button, Col, Modal, Row, Typography } from 'antd';
import {
    capitalize,
    difference,
    filter,
    find,
    findIndex,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../../components/common/ActionBar';
import AvatarNameInitialsComponent from '../../../components/common/AvatarNameInitialsComponent';
import FilterBar from '../../../components/common/FilterBar';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';
import VirtualizedList from '../../../components/common/VirtualizedList';
import OrganisationUserItemComponent from '../../../components/organisation/OrganisationUserItemComponent';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../../config/config';
import {
    PAGE_NAMES_FOR_VIEW,
    USERS_PAGE,
} from '../../../config/tableAndPageConstants';
import {
    OrganisationIdAttribute,
    OrganisationRoleIdAttribute,
    SubAttribute,
} from '../../../constants/authUserAttributes';
import { selectCompanyIdFilterMapping } from '../../../constants/common';
import {
    organisationUsersRoleAllowed,
    organistaionUsersRoleMapping,
} from '../../../constants/organisationsSortAndFilters';
import { ApplicationState } from '../../../store';
import { CompanyUserRole } from '../../../store/companies/types';
import {
    getOrganisationUsersRequestAction,
    removeOrganisationUsersRequestAction,
    setSelectedOrganisationUserIdRequestAction,
    updateOrganisationUsersFiltersAction,
    updateOrganisationUsersTableFilterAction,
} from '../../../store/organisations/actions';
import {
    GetOrganisationUsersRequestPayload,
    Organisation,
    OrganisationsState,
    OrganisationUser,
} from '../../../store/organisations/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { Role } from '../../../store/roles/types';
import { initialState } from '../../../store/users/reducer';
import { getCurrentUser } from '../../../store/users/sagas';
import {
    computeTableScroll,
    emptyPredefinedFilterOnAppliedFilters,
    getTranslatedText
} from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';


const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

const OrganisationUsertemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../components/organisation/OrganisationUsertemDetailsDrawerComponent'
        )
);

const { Title } = Typography;
const { confirm } = Modal;

interface IProps {
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}

let lastSelectedOrganisationId: null | string = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchUsersRequired = false;
let skipListenToPreconfiguredFilter = false;
const pageName = PAGE_NAMES_FOR_VIEW.ORG_USERS_PAGE;
let initialTableFilter: undefined | string = undefined;

const OrganisationUsersManagementPage: React.FC<IProps> = ({
    handlePageViewSelection,
}: IProps) => {
    
    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);
    const roleList = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );
    const orgUserRoleId = get(currentUser, OrganisationRoleIdAttribute);
    const orgUserRole = find(roleList, ['RoleId', orgUserRoleId]);
    const userRole = get(orgUserRole, 'Name');

    const currentUserId: string = get(currentUser, SubAttribute);
    const rolePermissions = useSelector(getRolePermissions);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const usersState: OrganisationsState['users'] = useSelector(
        (state: ApplicationState) => state.organisations.users
    );

    const selectedUserOrganisation: Organisation = useSelector(
        (state: ApplicationState) =>
            state.organisations.selectedUserOrganisation
    );

    const roles: Role[] = useSelector(
        (state: ApplicationState) => state.roles.allRoles
    );

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(usersState, 'pageData.currentPage', 0)
    );
    const [tableSortState, setTableSortState] = useState<{
        sortBy: GetOrganisationUsersRequestPayload['sortBy'];
        sortAscending: boolean;
    }>({
        sortBy: usersState.sortBy,
        sortAscending: usersState.sortAscending,
    });
    const [tableFilters, setTableFilters] = useState<DynamicObject>(
        usersState.filters
    );

    const [organisationUserDetailsDrawer, setOrganisationUserDetailsDrawer] =
        useState<{
            visible: boolean;
        }>({
            visible: false,
        });

    const [usersTableFilter, setUsersTableFilter] = useState<
        string | undefined
    >(
        isEqual(usersState.filters, initialState.filters)
            ? initialState.tableFilter
            : usersState.tableFilter
    );

    /**
     * This function checks if there are applied filters stored in redux.
     * If true, the FilterBar component will be shown.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(usersState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        // editUserPopover: boolean;
        allSelected: boolean;
        removeUsers: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        // editUserPopover: false,
        allSelected: false,
        removeUsers: false,
    });

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: any[];
        unselectedRowKeys: any[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        let filters: any = {
            ...usersState.filters,
            CompanyIds: []
        };
        if (!isUndefined(filters.CompanyId) && !isEmpty(filters.CompanyId)) {
            filters.CompanyIds.push(filters.CompanyId);
            delete filters.CompanyId;
        }

        const payload: GetOrganisationUsersRequestPayload = {
            filters: {
                ...filters
            },
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize,
            currentPage,
        };

        return payload;
    };

    /**
     * Fetch the Users from API.
     * @param currentPage - page where the scroll is at
     * @param pageSize - number of items in a page
     */
    const fetchUsers = (
        currentPage = tableCurrentPage,
        pageSize = USERS_PAGE.pageSize
    ) => {
        if (isEmpty(selectedUserOrganisation)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getOrganisationUsersRequestAction(payload));
    };

    /**
     * Function that controls the page before fetch user is called.
     * This also sets the state table page that is used.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserOrganisation) ||
            usersState.loading
        )
            return;

        if (!usersState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchUsers(nextPage);
    };

    /**
     * Function that will be called upon initial loading of page, filter and sort changes, and organisation switch.
     */
    const callFetchUsers = () => {
        if (!selectedUserOrganisation) return;
        const organisationIdCognito = get(currentUser, OrganisationIdAttribute);
        const selectedOrganisationId = get(
            selectedUserOrganisation,
            'OrganisationId'
        );

        if (organisationIdCognito === selectedOrganisationId) {
            if (lastSelectedOrganisationId !== selectedOrganisationId) {
                lastSelectedOrganisationId = selectedOrganisationId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnOrganisationSwitch();
                resetTableScrollAndPageData();
            }
            fetchUsers(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnOrganisationSwitch = () => {
        const { filters, sortBy, sortAscending } = usersState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
            });
        }
    };

    useEffect(callFetchUsers, [
        usersState.sortBy,
        usersState.sortAscending,
        usersState.filters,
        selectedUserOrganisation,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;

        if (usersState.tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchUsersRequired) {
                fetchUsers(0);
                fetchUsersRequired = false;
            }
        } else {
            handlePageViewSelection(
                usersState.tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [usersState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedOrganisationId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    /**
     * Change the address/path to a specific page in the application.
     * @param route - path where the user will go
     */
    // const changeRoute = (route: string) => {
    //     return () => props.history.push(`${props.match.path}/${route}`);
    // };

    /**
     * Function that changes the user filter (the dropdown menu right next to the sync/refresh button).
     * @param filter - dropdown view value
     */
    const changeUsersTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });

                resetAllSelectedRowKeys(true);
            } else {
                resetAllSelectedRowKeys();
            }
        }

        if (filter !== usersState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setUsersTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === usersState.tableFilter) {
            handleUserFilterRefresh();
        } else {
            dispatch(updateOrganisationUsersTableFilterAction(filter));
        }
    };

    /**
     * A common function for updating tableRowSelection state object.
     * @param selectionObject - object to be updated. Key value pair must conform to tableRowSelection
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Role options that will be used for filter 'Role'
     * object based on API response.
     */
    const getRoleOptions = () => {
        const roleOpts: { label: string; value: string }[] = [];
        forEach(roles, (role: { RoleId: string; Name: string }) => {
            if (includes(organisationUsersRoleAllowed, role.Name)) {
                roleOpts.push({
                    label: organistaionUsersRoleMapping[role.Name],
                    value: role.RoleId,
                });
            }
        });

        return roleOpts;
    };

    const roleOptions = getRoleOptions();

    /**
     * Function triggered when checkbox for the row is selected.
     * @param record - the record/data for the said row - based on API
     * @param selected - if the checkbox is checked or unchecked
     * @param selectedRows - list of keys for the selected row
     * @param nativeEvent - native event for the Checkbox component / boolean
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Checking if the current user role is allowed to change other user's role.
     * Other users only - cause the checkbox for the current user is disabled.
     */
    const allowedRemoveUserRole =
        isEmpty(rolePermissions.ORGANISATION_REMOVE_USERS) ||
        includes(rolePermissions.ORGANISATION_REMOVE_USERS, userRole)
            ? true
            : false;

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    const selectedKeysName = 'User.UserId';
    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setSelectedOrganisationUserIdRequestAction(
                get(record, selectedKeysName),
                () => {
                    setOrganisationUserDetailsDrawer({
                        visible: true,
                    });
                }
            )
        );
    };

    /**
     * Function called to close the organisation user details drawer.
     * Clears the data saved during drawer close.
     */
    const closeOrganisationUseretailsDrawer = () => {
        setOrganisationUserDetailsDrawer({
            visible: false,
        });
    };

    /**
     * Get count of total users except self
     */
    const getTotalUsersCountExceptSelf = () => {
        const currentUserIndex = findIndex(usersState.data, [
            selectedKeysName,
            currentUserId,
        ]);

        return currentUserIndex > -1
            ? usersState.data.length - 1
            : usersState.data.length;
    };

    const selectAllRows = () => {
        const lengthComparator = getTotalUsersCountExceptSelf();

        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length < lengthComparator;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(
                filter(usersState.data, (user: CompanyUserRole) => {
                    if (currentUserId !== get(user, selectedKeysName))
                        return get(user, selectedKeysName);

                    return;
                }),
                selectedKeysName
            );

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }
    };

    /**
     * Function that cwill be called when user state data is changed.
     * Basically checks if select all is clicked, and make all the checkboxes checked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(usersState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            const idKeys = filter(
                selectedKeys,
                (key: string) => key !== currentUserId
            );

            updateTableRowSelection({
                selectedRowKeys: idKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [currentUserId, usersState.data]);

    /**
     * Function that updates the visibility of showConditionsObject.
     * @param visible
     * @param filterName
     */
    const changeConditionVisibility = (
        visible: boolean,
        filterName: string
    ) => {
        updateShowConditionsObject({
            [filterName]: visible,
        });
    };

    /**
     * Function that is responsible for which modal should be shown after changing roles (Success/Error).
     * Will receive an object that will contain a boolean named IsSuccess.
     * @param param0 - specified as an object because an error message from API may be added someday.
     */
    // const editUsersResponseModal = ({ IsSuccess }: { IsSuccess: boolean }) => {
    //     if (IsSuccess) {
    //         Modal.success({
    //             title: 'Success',
    //             content: `${
    //                 tableRowSelection.selectedRowKeys.length > 1
    //                     ? 'Users'
    //                     : 'User'
    //             } role updated successfully!`,
    //             onOk: () => {
    //                 updateShowConditionsObject({
    //                     allSelected: false,
    //                     editUserPopover: false,
    //                 });
    //                 refetchListAndResetScroll();
    //                 updateTableRowSelection({
    //                     selectedRowKeys: [],
    //                     unselectedRowKeys: [],
    //                 });
    //             },
    //         });
    //     } else {
    //         Modal.error({
    //             title: 'Error',
    //             content: `Failed to update ${
    //                 tableRowSelection.selectedRowKeys.length > 1
    //                     ? 'users'
    //                     : 'user'
    //             } role!`,
    //         });
    //     }
    // };

    /**
     * Function that resets the selected and unselected row keys to their initial values and also the allSelected flag
     * for detecting the status of Select All button.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
    };

    // /**
    //  * Function responsible for showing a confirmation and eventually changing the roles of the selected users.
    //  * @param roleId - role Id to change to - based on API
    //  */
    // const editUsersOfSelectedRows = (roleId: string) => {
    //     updateShowConditionsObject({
    //         editUserPopover: false,
    //     });

    //     const payload = getUsersFilterPayload();

    //     confirm({
    //         className: 'modal-swapped-buttons',
    //         title: 'Confirm',
    //         content: `Are you sure you want to update the data for ${
    //             tableRowSelection.selectedRowKeys.length > 1
    //                 ? 'these users'
    //                 : 'this user'
    //         }?`,
    //         okText: confirmModalOkText,
    //         cancelText: confirmModalCancelText,
    //         onOk() {
    //             dispatch(
    //                 changeUsersRoleRequestAction({
    //                     roleId,
    //                     ...payload,
    //                     callback: editUsersResponseModal,
    //                 })
    //             );
    //         },
    //     });
    // };

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (usersState.tableFilter !== initialTableFilter) {
            setUsersTableFilter(initialTableFilter);
            if (!isUndefined(initialTableFilter))
                changeUsersTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Function called when Apply filter is clicked inside the filter bar.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetOrganisationUsersRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    usersTableFilter,
                    fromFilterBar,
                    () => {
                        changeUsersTableFilter('');
                    }
                );
            }
        }

        const appliedFilters = filters || initialState.filters;

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateOrganisationUsersFiltersAction(appliedFilters));
        resetAllSelectedRowKeys();
    };

    /**
     * Function called when Apply button is clicked after changing some values in the Sort by section found
     * upon clicking the 3 dots which shows a popover.
     */
    // const applySortedBy = async () => {
    //     await resetTableScrollAndPageData();
    //     await dispatch(updateOrganisationUsersSortByAction(tableSortState));
    //     await updateShowConditionsObject({
    //         filterEllipsis: false,
    //     });
    // };

    /**
     * Function responsible for updating the sort order and sortby - call to API.
     * @param sortAscending - boolean (true if ascending and false if descending)
     */
    // const changeSortOrder = async (sortAscending: boolean) => {
    //     await resetTableScrollAndPageData();
    //     dispatch(
    //         updateOrganisationUsersSortByAction({
    //             sortBy: tableSortState.sortBy,
    //             sortAscending,
    //         })
    //     );
    // };

    /**
     * Function that resets the current page and scroll location.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for updating the sort state.
     * @param tableSortStateObject
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function for updating the showConditions state.
     * @param showConditionObject
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    // UI Condition Updates on Render
    /**
     * Dropdown renderer UI near the sync button.
     * @param menu
     */
    const populateUserFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
             <div
                 className="pa-12 cursor-p"
                 onMouseDown={() => console.log('Add Custom View Clicked')}
             >
                 Add custom view
             </div> */}
        </div>
    );

    /**
     * Function for the UI `Select/Deselect all`.
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = usersState.pageData.hasNextPage === false;
        const lengthComparator = getTotalUsersCountExceptSelf();

        let selectDeselectLabel = '';
        const usedLengthCondition =
            lengthComparator === tableRowSelection.selectedRowKeys.length;

        if (
            (!isEmpty(usersState.data) &&
                tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
            (allDataLoaded && usedLengthCondition)
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function responsible for the Change role popover content UI.
     */
    // const populateChangeRolePopoverContent = () => (
    //     <div className="pop-action-content-no-padding">
    //         <List
    //             className="list-hoverable"
    //             dataSource={roles}
    //             loading={rolesFetching}
    //             renderItem={({
    //                 RoleId,
    //                 Name,
    //             }: {
    //                 RoleId: string;
    //                 Name: string;
    //             }) => (
    //                 <ListItem onClick={() => editUsersOfSelectedRows(RoleId)}>
    //                     {Name}
    //                 </ListItem>
    //             )}
    //         />
    //     </div>
    // );

    /**
     * Function responsible for the Change role popover button content UI.
     */
    // const populateChangeRoleProtectedPopoverButtonContent = () => (
    //     <>
    //         <FontAwesome icon={['fas', 'user-edit']} />
    //         <span>Change role</span>
    //     </>
    // );

    const downloadDisabled = usersState.loading || isEmpty(usersState.data);

    /**
     * Function responsible for the upper section on the ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                // disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            {/* <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...tableSortState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            usersState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button> */}
        </div>
    );

    /**
     * Function for displaying the lower section for ellipsis popover.
     */
    // const populateFilterEllipsisPopoverContent = () => (
    //     <div className="pop-action-content">
    //         <div className="mb-10">
    //             <span>Sort by</span>
    //         </div>
    //         <div>
    //             <Select
    //                 onChange={(sortBySelected: string) =>
    //                     updateTableSortStateObject({
    //                         sortBy: sortBySelected,
    //                     })
    //                 }
    //                 value={tableSortState.sortBy}
    //             >
    //                 {map(usersSortByOptions, (sort: string) => (
    //                     <Option key={sort} value={sort}>
    //                         {sort}
    //                     </Option>
    //                 ))}
    //             </Select>
    //         </div>
    //         <br />
    //         <div className="ta-right">
    //             <Button
    //                 type="primary"
    //                 disabled={
    //                     usersState.loading ||
    //                     tableSortState.sortBy === usersState.sortBy
    //                 }
    //                 onClick={applySortedBy}
    //             >
    //                 Apply
    //             </Button>
    //         </div>
    //     </div>
    // );

    /**
     * Function that controls the visibility of popover.
     * @param name
     * @param condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                changeConditionVisibility(visible, name);
            }
        };
    };

    /**
     * Function for populating the title section of change role modal.
     */
    // const populateChangeRoleRequestModalTitle = () =>
    //     `Changing ${
    //         tableRowSelection.selectedRowKeys.length > 1 ? 'Users' : 'User'
    //     } role`;

    /**
     * Function for populating the body section of change role modal.
     */
    // const populateChangeRoleRequestModalDisplayMessage = () =>
    //     `Please wait while changing the ${
    //         tableRowSelection.selectedRowKeys.length > 1 ? 'users' : 'user'
    //     } role. . .`;

    /**
     * Function for populating the loading text for table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } users`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function for setting the proper value / object needed for populating the table.
     */
    const getDataSource = () => {
        const dataSource: unknown[] | undefined = [];

        map(usersState.data, (userData: OrganisationUser) => {
            if (isEmpty(userData.User)) return;
            const { User } = userData;
            const { GivenName, FamilyName } = User;
            const fullName = `${GivenName} ${FamilyName}`;
            dataSource.push({
                key: get(userData, selectedKeysName),
                fullName,
                // role: Role.Name,
                avatar: (
                    <AvatarNameInitialsComponent
                        fullName={fullName}
                        size="large"
                    />
                ),
                ...userData,
            });
        });

        return dataSource;
    };

    const dataSource = getDataSource();

    /**
     * Function that handles the closing of filter bar.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function called when sync/refresh button is clicked.
     */
    const handleUserFilterRefresh = () => {
        fetchUsersRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchUsers(0);
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function for getting the selected users values.
     */
    const getSelectedUsersValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }

        const filterObject = {
            RoleIds: tableFilters.Role as string[],
            Name: tableFilters.Name,
            Email: tableFilters.Email,
            CompanyId: tableFilters.CompanyId,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    const getSelectedUsersLabel = () => {
        const selUsersValue = getSelectedUsersValues();
        const { keysToUse, allExcept } = selUsersValue;
        const lengthComparator = getTotalUsersCountExceptSelf();
        const count =
            allExcept === false
                ? keysToUse.length
                : lengthComparator - keysToUse.length;
        const userText =
            keysToUse.length > 1 ||
            (keysToUse.length === 0 && dataSource.length > 1)
                ? 'users'
                : 'user';

        return { label: userText, count };
    };

    /**
     * Function called when remove users button is clicked which shows a confirmation modal for the action.
     */
    const confirmRemoveUsers = () => {
        const { label: userLabel, count: usersCount } = getSelectedUsersLabel();
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText(`Remove ${userLabel}`),
            content: (
                <div>
                    {getTranslatedText(`Are you sure you want to delete ${usersCount} ${userLabel}?}`)}
                    <div className="mt-8">{getTranslatedText(`<b>Note:</b> When you remove someone, it immediately stops any new sign-ins to your organisation, and if they’re currently signed in, they’ll be automatically signed out of the organisation within 60 minutes`).split(/(<b>[^<]+<\/b>)/g)
                        .map((part, index) =>
                            part.startsWith('<b>') ? (
                                <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                            ) : (
                                part // Render regular text
                            )
                        )}
                    </div>
                </div>
            ),
            onOk: () => removeUsers(),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function for populating the payload needed for user actions.
     */
    const getUsersFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } = getSelectedUsersValues();

        return {
            filter: filterObject,
            userIds: keysToUse,
            excludeUsers: allExcept,
        };
    };

    /**
     * Function called when remove users is confirmed.
     */
    const removeUsers = () => {
        updateShowConditionsObject({
            removeUsers: true,
        });

        const payload = getUsersFilterPayload();
        dispatch(
            removeOrganisationUsersRequestAction({
                ...payload,
                callback: removeUsersResponseModal,
            })
        );
    };

    /**
     * Function for populating the response modal after Remove users action API call has finished.
     * @param param0
     */
    const removeUsersResponseModal = ({
        IsSuccess,
    }: {
        IsSuccess: boolean;
    }) => {
        const { label: userLabel } = getSelectedUsersLabel();
        updateShowConditionsObject({
            removeUsers: false,
        });
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText(`${capitalize(userLabel)} removed successfully!`),
                onOk: handleUserFilterRefresh,
                okText: getTranslatedText('OK'),
            });
        } else {
            Modal.error({
                title: getTranslatedText('Error'),
                content: (
                    <div>
                        <div>{getTranslatedText(`Failed to remove ${userLabel}!`)}</div>
                    </div>
                ),
                okText: getTranslatedText('OK'),
            });
        }
    };

    /**
     * Function for populating the title and message for the loading popover when remove users action is executed.
     */
    const populateTitleAndMessageForRemoveUser = () => {
        let removeUserTitle = '';
        let removeUserMessage = '';
        if (showConditions.removeUsers) {
            const { label: userLabel } = getSelectedUsersLabel();
            removeUserTitle = `Removing ${userLabel}`;
            removeUserMessage = `Please wait while we remove the selected ${userLabel}. . .`;
        }

        return {
            removeUserTitle,
            removeUserMessage,
        };
    };

    const { removeUserTitle, removeUserMessage } =
        populateTitleAndMessageForRemoveUser();

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText('Users')}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <FilterBar
                                pageName={pageName}
                                loading={usersState.loading}
                                applyFilters={applyFilters}
                                filters={[
                                    {
                                        filterName: 'Name',
                                        filterElement: 'input',
                                        filterType: 'text',
                                    },
                                    {
                                        filterName: 'Email',
                                        filterElement: 'input',
                                        filterType: 'email',
                                    },
                                    {
                                        filterName: 'Role',
                                        filterElement: 'checkbox-group',
                                        filterType: 'array',
                                        filterOptions: roleOptions,
                                    },
                                    {
                                        filterName: 'Company',
                                        filterStateName: 'CompanyId',
                                        filterNameQuery: 'CompanyName',
                                        filterElement: 'select-with-search',
                                        filterQuery:
                                            'GET_ORGANISATION_COMPANIES_AUTOCOMPLETE_FILTER',
                                        filterResponse:
                                            'GetOrganisationCompanies.Companies',
                                        filterLabelField: ['Name'],
                                        tagClass: 'wb-bw',
                                        filterMappingUsed:
                                            selectCompanyIdFilterMapping,
                                        filterCustomError:
                                            'Please select a company from the list!',
                                    },
                                ]}
                                filterValues={usersState.filters}
                                colDivision={6}
                                closeFilterBar={closeFilterBar}
                                appliedView={usersTableFilter}
                                doesViewNameExist={doesViewNameExist}
                                closeAllPopovers={
                                    organisationUserDetailsDrawer.visible
                                }
                            />
                        </div>
                    )}
                </QueueAnim>
                {/* Action Bar */}
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={usersState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'user-filter',
                                actionType: 'select-with-button',
                                selectValue: usersTableFilter,
                                selectDropdownRender:
                                    populateUserFilterSelectDropdownRender,
                                onSelectChange: changeUsersTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: usersState.loading,
                                onButtonClick: handleUserFilterRefresh,
                            },
                            {
                                actionKey: 'user-select-all',
                                actionType: 'protected-button',
                                allowedRoles:
                                    rolePermissions.ORGANISATION_REMOVE_USERS,
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {getTranslatedText(populateSelectDeselectAllLabel())}
                                        </span>
                                    </>
                                ),
                            },
                            // {
                            //     actionKey: 'user-invite-create',
                            //     actionType: 'protected-button',
                            //     allowedRoles:
                            //         rolePermissions.USER_INVITE_CREATE,
                            //     onButtonClick: changeRoute('invite-users'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'user-plus']}
                            //             />
                            //             <span>Invite</span>
                            //         </>
                            //     ),
                            // },
                            // {
                            //     actionKey: 'user-edit',
                            //     actionType: 'protected-popover-button',
                            //     allowedRoles:
                            //         rolePermissions.ORGANISATION_REMOVE_USERS,
                            //     popoverVisible: showConditions.editUserPopover,
                            //     popoverOnVisibleChange: popoverOnVisibleChange(
                            //         'editUserPopover',
                            //         !isEmpty(tableRowSelection.selectedRowKeys)
                            //     ),
                            //     popoverContent:
                            //         populateChangeRolePopoverContent(),

                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     buttonContent:
                            //         populateChangeRoleProtectedPopoverButtonContent(),
                            // },
                            {
                                actionKey: 'user-remove users',
                                actionType: 'protected-button',
                                allowedRoles:
                                    rolePermissions.ORGANISATION_REMOVE_USERS,
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                onButtonClick: confirmRemoveUsers,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'user-minus']}
                                        />
                                        <span>{getTranslatedText('Delete')}</span>
                                    </>
                                ),
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            // popoverContent:
                            //     populateFilterEllipsisPopoverTitle(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={dataSource}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                USERS_PAGE.pageSize
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            hideCheckbox={!allowedRemoveUserRole}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={usersState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText="No users found"
                            hasNextPage={usersState.pageData.hasNextPage}
                            itemComponent={OrganisationUserItemComponent}
                            rerenderTrigger={{
                                dataSource,
                                selectedRowKeys:
                                    tableRowSelection.selectedRowKeys,
                            }}
                            itemHeight={USERS_PAGE.rowHeight}
                        />
                    </Col>
                </Row>
            </QueueAnim>
            {/* {usersState.editUsersLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={populateChangeRoleRequestModalTitle()}
                        modalVisible={usersState.editUsersLoading}
                        displayMessage={populateChangeRoleRequestModalDisplayMessage()}
                    />
                </Suspense>
            )} */}
            <Suspense fallback={null}>
                <OrganisationUsertemDetailsDrawerComponent
                    visible={organisationUserDetailsDrawer.visible}
                    closeDrawer={closeOrganisationUseretailsDrawer}
                    refetchList={refetchListAndResetScroll}
                />
            </Suspense>
            {showConditions.removeUsers && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText(removeUserTitle)}
                        modalVisible={showConditions.removeUsers}
                        displayMessage={getTranslatedText(removeUserMessage)}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default withPageViewHandler(OrganisationUsersManagementPage);

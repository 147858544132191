import { action } from "typesafe-actions";
import { GetReportsRequestPayload, ReportActionTypes } from "./types";
import { DynamicObject, ResponseModalObject } from "../../utils/commonInterfaces";
import { PageData } from "../common/types";

export const getReportsRequestAction = (payload: GetReportsRequestPayload) =>
    action(ReportActionTypes.GET_REPORTS_REQUEST, payload);
export const getReportsForOrganisationRequestAction = (payload: GetReportsRequestPayload) =>
    action(ReportActionTypes.GET_REPORTS_FOR_ORGANISATION_REQUEST, payload);
export const getReportsSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(ReportActionTypes.GET_REPORTS_SUCCESS, payload);
export const getReportsErrorAction = (payload: string[]) =>
    action(ReportActionTypes.GET_REPORTS_ERROR, payload);

export const updateReportsTableFilterAction = (tableFilter: string) =>
    action(ReportActionTypes.UPDATE_REPORTS_TABLE_FILTER_STATE, tableFilter);

export const updateReportsFiltersAction = (
    filters: GetReportsRequestPayload['filters']
) => action(ReportActionTypes.UPDATE_REPORTS_FILTERS, filters);

export const updateReportsSortByAndStateAction = (tableSortAndState: {
    sortBy: GetReportsRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        ReportActionTypes.UPDATE_REPORTS_SORT_BY_AND_STATE,
        tableSortAndState
    );

export const downloadCompleteReportAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(ReportActionTypes.DOWNLOAD_COMPLETE_REPORTS, {
        payload,
        callback,
    });

// Single Record
export const setSelectedReportIdRequestAction = (
  reportId: string | null
) =>
  action(ReportActionTypes.SET_REPORT_SELECTED_ID, {
    reportId,

  });
export const getReportDataRequestAction = (
  ReportJobId: string
) =>
  action(ReportActionTypes.GET_REPORT_DATA_REQUEST, {
    ReportJobId
  });
export const getReportDataForOrganisationRequestAction = (
  ReportJobId: string,
  Region: string
) =>
  action(ReportActionTypes.GET_REPORT_DATA_FOR_ORGANISATION_REQUEST, {
    ReportJobId,
    Region
  });

export const getReportDataSuccessAction = (payload: { record: {} }) =>
  action(ReportActionTypes.GET_REPORT_DATA_SUCCESS, payload);

export const getReportDataErrorAction = (payload: string[]) =>
  action(ReportActionTypes.GET_REPORT_DATA_ERROR, payload);

export const clearReportDataAction = () =>
  action(ReportActionTypes.CLEAR_REPORT_DATA);

export const clearReportFiltersAction = () => 
  action(ReportActionTypes.CLEAR_REPORT_FILTERS);


/**
 * File for the `Change statistics` widget form.
 */

import { Col, Divider, Form, Row, Select, Switch, Radio } from 'antd';
import { get, includes, isUndefined, map } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    populatePopoverContainer,
    replaceInstancesOfCustomerString,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import { ApplicationState } from '../../store';
import {
    resourceTypesFilterOptions,
    changeTypesFilterOptions,
    sourceTypesFilterOptions
} from '../../constants/changesSortAndFilters';
import { chartWidgetDisplayTypes, groupBySourceTypesOptions } from '../../constants/dashboards';
import { CompanyUserRole } from '../../store/companies/types';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayRowCountOptions = [5, 10, 15, 20];

const ChangeStatisticsWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    isOrgView,
    getFieldValue,
    setFieldsValue,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    let isPaymentPlanEnabled = false;
    let supportCashAllocation = false;

    const displayColumnOptions = {
        ResourceType: {
            label: getTranslatedText('Resource type'),
            value: 'ResourceType',
            defaultChecked: true,
            OrgViewOnly: false,
        },
        AssociatedResourceType: {
            label: getTranslatedText('Associated resource'),
            value: 'AssociatedResourceType',
            defaultChecked: true,
            OrgViewOnly: false,
        },
        ChangeType: {
            label: getTranslatedText('Change type'),
            value: 'ChangeType',
            defaultChecked: true,
            OrgViewOnly: false,
        },
        Source: {
            label: getTranslatedText('Source'),
            value: 'Source',
            defaultChecked: true,
            OrgViewOnly: false,
        },
        Date: {
            label: getTranslatedText('Date'),
            value: 'Date',
            defaultChecked: true,
            OrgViewOnly: false,
        },
        User: {
            label: getTranslatedText('User'),
            value: 'User',
            defaultChecked: true,
            OrgViewOnly: false,
        },
    };

    if (isOrgView) {
        for (const company of organisationCompanies) {
            if (!isPaymentPlanEnabled) {
                isPaymentPlanEnabled = get(
                    company,
                    'CompanyPaymentPlan.IsEnabled'
                );
            }

            if (!supportCashAllocation) {
                supportCashAllocation = get(
                    company,
                    'SupportCashAllocation'
                );
            }
        }
    }
    else {
        isPaymentPlanEnabled = get(
            selectedUserCompany,
            'Company.CompanyPaymentPlan.IsEnabled'
        );

        supportCashAllocation = get(
            selectedUserCompany,
            'Company.SupportCashAllocation'
        );
    }

    /**
     * Function that populates the columns to display switches.
     */
    const populateColumnsToDisplayOptions = () => {
        return map(
            displayColumnOptions,
            ({ label, value, defaultChecked, OrgViewOnly }: DynamicObject) => {
                if (
                    label !== "Customer custom fields"
                ) {
                    if (!isOrgView && OrgViewOnly) {
                        return null;
                    }
                    else {

                        const displayColumnKey = `${displayColumnIndicator}${value}`;
                        const switchValue = !isUndefined(
                            get(widgetDetails, displayColumnKey)
                        )
                            ? get(widgetDetails, displayColumnKey)
                            : defaultChecked;

                        const labelUsed = replaceInstancesOfCustomerString(
                            label,
                            customerLabel,
                            isOrgView
                        );
                        return (
                            <Col
                                key={value}
                                span={12}
                                className="switch-fields-container"
                            >
                                <FormItem label={getTranslatedText(labelUsed)}>
                                    {getFieldDecorator(displayColumnKey, {
                                        valuePropName: 'checked',
                                        initialValue: switchValue,
                                    })(<Switch disabled={formDisabled} />)}
                                </FormItem>
                            </Col>
                        );
                    }
                }
            }
        );
    };

    /**
    * Function that populates the dropdown based on given list of options.
    */
    const populateDisplayTypeOptions = () =>
        map(chartWidgetDisplayTypes, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {getTranslatedText(label)}
            </Option>
        ));

    /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
        ));

    /**
    * Function for populating the resource type options.
    */
    const populateResourceTypesSelectOptions = () =>
        map(resourceTypesFilterOptions(isPaymentPlanEnabled, supportCashAllocation), ({ label, value }: any) => {
            return (
                <Option key={value} value={value}>
                    {label}
                </Option>
            );
        });

    /**
    * Function for populating the change type options.
    */
    const populateChangeTypesSelectOptions = () =>
        map(changeTypesFilterOptions(supportCashAllocation), ({ label, value }: any) => {
            return (
                <Option key={value} value={value}>
                    {label}
                </Option>
            );
        });

    /**
    * Function for populating the source type options.
    */
    const populateSourceTypesSelectOptions = () =>
        map(sourceTypesFilterOptions(supportCashAllocation), ({ label, value }: any) => {
            return (
                <Option key={value} value={value}>
                    {label}
                </Option>
            );
        });

    /**
     * Function that populates the radio group buttons based on given list of options.
     * @param radioOptions
     */
    const populateRadioOptions = (radioOptions: DynamicObject[]) => {
        return map(radioOptions, (opt) => (
            <Radio.Button value={get(opt, 'value')} key={get(opt, 'value')}>
                {getTranslatedText(get(opt, 'label'))}
            </Radio.Button>
        ));
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display type")}>
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '1.value'
                                    ),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row gutter={10}>
                            <FormItem label={getTranslatedText("Filter on group options")}>
                                {getFieldDecorator('groupingOptions', {
                                    initialValue: get(widgetDetails, 'groupingOptions') ||
                                        get(groupBySourceTypesOptions, '0.value'),
                                })(
                                    <Radio.Group className="btn-radio-group">
                                        {populateRadioOptions(
                                            groupBySourceTypesOptions
                                        )}
                                    </Radio.Group>
                                )}
                            </FormItem>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter by change type")}>
                            {getFieldDecorator('changeTypes', {
                                initialValue:
                                    get(widgetDetails, 'changeTypes')
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    placeholder={getTranslatedText("Select Change Type")}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    mode="multiple"
                                >
                                    {populateChangeTypesSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter by resource type")}>
                            {getFieldDecorator('resourceTypes', {
                                initialValue:
                                    get(widgetDetails, 'resourceTypes')
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    placeholder={getTranslatedText("Select Resource Type")}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    mode="multiple"
                                >
                                    {populateResourceTypesSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                {/* <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label="Filter by source type">
                            {getFieldDecorator('sourceTypes', {
                                initialValue:
                                    get(widgetDetails, 'sourceTypes')
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    placeholder="Select Source Type"
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    mode="multiple"
                                >
                                    {populateSourceTypesSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row> */}
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                    />
                )}
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(ChangeStatisticsWidgetFields);

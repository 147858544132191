/**
 * File responsible for all the UI and actions for Settings>Accounting System page - `/app/settings/accounting-system`.
 */

import {
    Button,
    Card,
    Col,
    Divider,
    Modal,
    Row,
    Select,
    Typography,
} from 'antd';
import { get, includes, isEmpty, isUndefined, map, toLower } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import connectXeroButton from '../../assets/connectXeroButton.png';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import ProtectedButton from '../../components/common/ProtectedButton';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import {
    accountingSystemOptions,
    accountingSystemSelectOptions,
    companyApiImportTypes,
    companyCloudImportTypes,
    companyImportTypes,
} from '../../constants/settings';
import { ApplicationState } from '../../store';
import {
    getUserCompaniesRequestAction,
    saveUserCompanyRequestAction,
} from '../../store/companies/actions';
import { CompaniesState } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    getXeroConfigurationRequestAction,
    xeroConnectRequestAction,
    xeroDisconnectRequestAction,
    xeroManualSyncRequestAction,
} from '../../store/import/actions';
import { getRolePermissions } from '../../store/roles/sagas';
import {
    getLoadingSkeletons,
    getSelectedAccountingSystemBasedOnCompanyDetails,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';


const { Title } = Typography;
const { confirm } = Modal;
const { Option } = Select;
interface IProps {
    history: {
        push: (path: string) => void;
    };
}

const AccountingSystemManagementPage: React.FC<IProps> = (props: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    
    const dispatch = useDispatch();
    const rolePermissions = useSelector(getRolePermissions);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const { selectedUserCompany, saveLoading } = companiesState;

    const [selectedAccountingSystem, setSelectedAccountingSystem] =
        useState<string>(accountingSystemOptions.IODM_CONNECT_WEB_API);

    const [xeroState, setXeroState] = useState<{
        getConfigLoading: boolean;
        connectLoading: boolean;
        disconnectLoading: boolean;
        manualSyncLoading: boolean;
        data: DynamicObject | null;
    }>({
        getConfigLoading: false,
        connectLoading: false,
        disconnectLoading: false,
        manualSyncLoading: false,
        data: null,
    });

    /**
     * Function called when `Cancel` button is clicked inside Accounting system management page.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Continue'),
            content: (
                <div>
                    {getTranslatedText(`When you click the ${confirmModalOkText} button, the data will be reverted to the last saved value`).split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                        .map((part, index) =>
                            part.startsWith('<b>') ? (
                                <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                            ) : (
                                part // Render regular text
                            )
                        )}
                </div>
            ),
            onOk() {
                setSelectedAccountingSystemBasedOnCompanyDetails();
            },
            onCancel() {},
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (
        IsSuccess: boolean,
        showResponse: boolean,
        callback?: () => void
    ) => {
        const actionAfterSuccess = !isUndefined(callback)
            ? callback
            : () => dispatch(getUserCompaniesRequestAction());
        if (IsSuccess) {
            if (showResponse) {
                Modal.success({
                    title: getTranslatedText("Success"),
                    content: getTranslatedText("Company data saved successfully"),
                    onOk: () => {
                        actionAfterSuccess();
                    },
                    okText: getTranslatedText("OK")
                });
            } else {
                actionAfterSuccess();
            }
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText("Failed to save company data"),
                okText: getTranslatedText("OK")
            });
        }
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = (
        showResponse: boolean = true,
        defaultPayload?: DynamicObject,
        callback?: () => void
    ) => {
        const { importType, cloudImportType, apiImportType } =
            getCloudImportTypeAndImportType();
        const payload = defaultPayload
            ? defaultPayload
            : {
                  DetailType: 'AccountingSystems',
                  ImportType: importType,
                  CloudImportType: cloudImportType,
                  ApiImportType: apiImportType,
              };
        dispatch(
            saveUserCompanyRequestAction(
                payload,
                ({ IsSuccess }: { IsSuccess: boolean }) =>
                    handleModalSaveResponse(IsSuccess, showResponse, callback)
            )
        );
    };

    /**
     * Function for fetching import type values
     */
    const getCloudImportTypeAndImportType = () => {
        let importType: string | null = null;
        let cloudImportType: string | null = null;
        let apiImportType: string | null = null;
        if (selectedAccountingSystem === accountingSystemOptions.EXCEL) {
            importType = companyImportTypes.EXCEL;
            cloudImportType = null;
            apiImportType = null;
        } else if (selectedAccountingSystem === accountingSystemOptions.XERO) {
            importType = companyImportTypes.CLOUD;
            cloudImportType = companyCloudImportTypes.XERO;
            apiImportType = null;
        } else if (
            selectedAccountingSystem ===
            accountingSystemOptions.IODM_CONNECT_WEB_API
        ) {
            importType = companyImportTypes.API;
            cloudImportType = null;
            apiImportType = companyApiImportTypes.IODM_CONNECT_WEB;
        } else if (
            selectedAccountingSystem === accountingSystemOptions.LEGACY_API
        ) {
            importType = companyImportTypes.API;
            cloudImportType = null;
            apiImportType = companyApiImportTypes.LEGACY;
        }

        return {
            importType,
            cloudImportType,
            apiImportType,
        };
    };

    /**
     * Function called when selecting from the accounting system dropdown.
     * @param selectedValue
     */
    const onAccountingSystemSelect = (selectedValue: string) => {
        setSelectedAccountingSystem(selectedValue);
    };

    /**
     * Function that populates the accounting system dropdown options.
     */
    const populateAccountingSystemOptions = () => {
        return map(
            accountingSystemSelectOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
    };

    /**
     * Common function for updating xero state.
     * @param xeroStateObject
     */
    const updateXeroStateObject = (xeroStateObject: DynamicObject) => {
        setXeroState({
            ...xeroState,
            ...xeroStateObject,
        });
    };

    /**
     * Common function for populating and showing the error message.
     * @param errorMessages
     */
    const populateErrorMessage = (errorMessages: any) => {
        const errorMessageContent: string | JSX.Element[] = map(
            errorMessages,
            (error: string, index: number) => <div key={index}>{error}</div>
        );

        return Modal.error({
            title: getTranslatedText("Error"),
            content: errorMessageContent,
            okText: getTranslatedText("OK")
        });
    };

    /**
     * Function for getting the xero configuration.
     */
    const getXeroConfiguration = (
        successCallback?: (xeroConfig: DynamicObject) => void
    ) => {
        updateXeroStateObject({
            getConfigLoading: true,
        });
        dispatch(
            getXeroConfigurationRequestAction((response: DynamicObject) => {
                if (response.IsSuccess) {
                    const xeroConfig = get(response, 'xeroConfiguration');
                    updateXeroStateObject({
                        getConfigLoading: false,
                        data: xeroConfig,
                    });
                    if (successCallback) {
                        successCallback(xeroConfig);
                    }
                } else {
                    updateXeroStateObject({
                        getConfigLoading: false,
                    });

                    populateErrorMessage(response.Messages);
                }
            })
        );
    };

    const onAccountingSystemSelected = () => {
        if (selectedAccountingSystem === accountingSystemOptions.XERO) {
            getXeroConfiguration();
        } else {
            updateXeroStateObject({
                getConfigLoading: false,
                connectLoading: false,
                disconnectLoading: false,
                data: null,
            });
        }
    };

    useEffect(onAccountingSystemSelected, [selectedAccountingSystem]);

    /**
     * Function for handling the effect when a user company is selected.
     */
    const setSelectedAccountingSystemBasedOnCompanyDetails = () => {
        const selectedAccountingSystemFromCompany =
            getSelectedAccountingSystemBasedOnCompanyDetails(
                selectedUserCompany
            );

        if (selectedAccountingSystem !== selectedAccountingSystemFromCompany) {
            setSelectedAccountingSystem(selectedAccountingSystemFromCompany);
        } else {
            onAccountingSystemSelected();
        }
    };

    /**
     * Listens for company switch.
     */
    useEffect(setSelectedAccountingSystemBasedOnCompanyDetails, [
        selectedUserCompany,
    ]);

    /**
     * Function that calls the action to initiate a connection to xero api.
     */
    const connectToXero = () => {
        updateXeroStateObject({
            connectLoading: true,
        });
        dispatch(
            xeroConnectRequestAction((response: DynamicObject) => {
                if (response.IsSuccess) {
                    onSaveButtonClick(false);
                    window.location.href = response.RedirectUrl;
                } else {
                    populateErrorMessage(response.Messages);
                }
            })
        );
    };

    /**
     * Function that calls the action to disconnect from xero api.
     */
    const disconnectFromXero = () => {
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Confirm"),
            content: getTranslatedText("Are you sure you want to disconnect from Xero?"),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            onOk: () => {
                updateXeroStateObject({
                    disconnectLoading: true,
                });
                dispatch(
                    xeroDisconnectRequestAction(
                        get(xeroState, 'data.ConnectionId', ''),
                        (response: DynamicObject) => {
                            updateXeroStateObject({
                                disconnectLoading: false,
                            });
                            if (response.IsSuccess) {
                                Modal.success({
                                    title: getTranslatedText("Success"),
                                    content:
                                        getTranslatedText("Successfully disconnected from Xero"),
                                    onOk: () => {
                                        getXeroConfiguration(
                                            (xeroConfig: DynamicObject) => {
                                                if (isEmpty(xeroConfig)) {
                                                    onSaveButtonClick(false, {
                                                        DetailType:
                                                            'AccountingSystems',
                                                        ImportType:
                                                            companyImportTypes.API,
                                                        CloudImportType: null,
                                                        ApiImportType:
                                                            companyApiImportTypes.IODM_CONNECT_WEB,
                                                    });
                                                }
                                            }
                                        );
                                    },
                                    keyboard: false,
                                    okText: getTranslatedText("OK")
                                });
                            } else {
                                Modal.error({
                                    title: getTranslatedText("Error"),
                                    content: getTranslatedText("Failed to disconnect from Xero"),
                                    okText: getTranslatedText("OK")
                                });
                            }
                        }
                    )
                );
            },
        });
    };

    /**
     * Function called on xero manual sync button click
     */
    const onXeroManualSync = () => {
        updateXeroStateObject({
            manualSyncLoading: true,
        });
        dispatch(
            xeroManualSyncRequestAction((response: DynamicObject) => {
                updateXeroStateObject({
                    manualSyncLoading: false,
                });
                if (response.IsSuccess) {
                    Modal.success({
                        title: getTranslatedText("Success"),
                        content: getTranslatedText("Successfully synced Xero account"),
                        onOk: () => {
                            getXeroConfiguration();
                        },
                        okText: getTranslatedText("OK")
                    });
                } else {
                    let errorMessageContent:
                        | string
                        | JSX.Element[] = getTranslatedText("Failed to sync Xero account");
                    if (!isEmpty(response.Messages)) {
                        errorMessageContent = map(
                            response.Messages,
                            (error: string, index: number) => (
                                <div key={index}>{error}</div>
                            )
                        );
                    }
                    Modal.error({
                        title: getTranslatedText("Error"),
                        content: errorMessageContent,
                        okText: getTranslatedText("OK")
                    });
                }
            })
        );
    };

    /**
     * Function that populates the xero display.
     */
    const populateXeroDisplay = () => {
        if (xeroState.getConfigLoading) {
            return getLoadingSkeletons(1, true);
        } else {
            if (isEmpty(xeroState.data)) {
                return (
                    <Col span={24}>
                        <Button
                            className="image-button"
                            type="link"
                            loading={xeroState.connectLoading}
                            onClick={connectToXero}
                            disabled={formDisabled}
                        >
                            {/* Connect to Xero */}
                            <img src={connectXeroButton} title="" alt="" />
                        </Button>
                    </Col>
                );
            } else {
                return (
                    <Col span={24}>
                        <div>
                            {getTranslatedText("Connected to Xero Company")}:{' '}
                            {get(xeroState.data, 'TenantName')}
                        </div>
                        <div>
                            {getTranslatedText("Connected using Xero user")}:{' '}
                            {get(xeroState.data, 'XeroUser.UserName')}
                        </div>
                        <div className="mt-10">
                            <Button
                                type="danger"
                                loading={xeroState.disconnectLoading}
                                onClick={disconnectFromXero}
                                disabled={formDisabled}
                            >
                                {getTranslatedText("Disconnect from Xero")}
                            </Button>
                        </div>
                    </Col>
                );
            }
        }
    };

    let formDisabled = true;
    const allowedRoles = rolePermissions.UPDATE_ACCCOUNTING_SYSTEM;
    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        formDisabled = false;
    }

    const formHasChanges =
        selectedAccountingSystem !==
        getSelectedAccountingSystemBasedOnCompanyDetails(selectedUserCompany);

    const disabledXeroConnected = !isEmpty(xeroState.data);

    const allowedRoleXeroManualSync =
        rolePermissions.MANUAL_SYNC_XERO_ACCCOUNTING_SYSTEM;

    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={12}>
                            <Row>
                                <Col>
                                    <Title level={3}>{getTranslatedText("Accounting System")}</Title>
                                </Col>
                                <Col>
                                    {getTranslatedText("Connect to your accounting system here")}
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12} className="ta-right">
                            {!disabledXeroConnected && (
                                <>
                                    <Button
                                        className="mr-10 w-100px"
                                        type="primary"
                                        onClick={() => onSaveButtonClick()}
                                        disabled={
                                            formDisabled || !formHasChanges
                                        }
                                        loading={saveLoading}
                                    >
                                        {getTranslatedText("Save")}
                                    </Button>
                                    <Button
                                        className="buttonGrey w-100px"
                                        onClick={onCancelButtonClick}
                                        disabled={
                                            formDisabled || !formHasChanges
                                        }
                                    >
                                        {getTranslatedText("Cancel")}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className="flex-i">
                            <div className="mt-5 pr-20">
                                {getTranslatedText("Select accounting system")} &emsp;
                            </div>
                            <div className="fx-1">
                                <Row>
                                    <Col className="mb-20">
                                        <Select
                                            className="accounting-system-select"
                                            value={selectedAccountingSystem}
                                            onSelect={onAccountingSystemSelect}
                                            placeholder="Select accounting system"
                                            disabled={
                                                disabledXeroConnected ||
                                                formDisabled ||
                                                xeroState.connectLoading
                                            }
                                        >
                                            {populateAccountingSystemOptions()}
                                        </Select>
                                    </Col>
                                    <Col span={12}>
                                        {selectedAccountingSystem ===
                                            accountingSystemOptions.XERO &&
                                            populateXeroDisplay()}
                                    </Col>
                                </Row>
                                {selectedAccountingSystem ===
                                    accountingSystemOptions.XERO &&
                                    !xeroState.getConfigLoading &&
                                    !isEmpty(xeroState.data) && (
                                        <Row className="mt-20">
                                            <Col span={12}>
                                                <Card
                                                    style={{ marginLeft: -28 }}
                                                >
                                                    <div>
                                                        {getTranslatedText(`IODM automatically synchronises its data with your Xero account. If you find that an invoice is missing in your IODM account, or some ${toLower(customerLabel)} or invoice information is incorrect, click the Manual Sync button. The system then performs a full download of your invoice and ${toLower(customerLabel)} data to ensure it is aligned to your Xero account`)}
                                                    </div>
                                                    <div className="mt-10">
                                                        <ProtectedButton
                                                            allowedRoles={
                                                                allowedRoleXeroManualSync
                                                            }
                                                            buttonProps={{
                                                                className:
                                                                    'action-bar-button',
                                                                type: 'primary',
                                                                onClick:
                                                                    onXeroManualSync,
                                                                disabled:
                                                                    xeroState.disconnectLoading,
                                                                loading:
                                                                    xeroState.disconnectLoading,
                                                            }}
                                                        >
                                                        {getTranslatedText("Manual Sync")}
                                                        </ProtectedButton>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                            </div>
                        </Col>
                    </Row>
                </QueueAnim>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving company data")}
                        modalVisible={saveLoading}
                        displayMessage={getTranslatedText("Please wait while saving company data")}
                    />
                )}
                {xeroState.disconnectLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Disconnecting from Xero")}
                        modalVisible={xeroState.disconnectLoading}
                        displayMessage={getTranslatedText("Please wait while we disconnect your company from Xero")}
                    />
                )}
                {xeroState.manualSyncLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Syncing Xero account")}
                        modalVisible={xeroState.manualSyncLoading}
                        displayMessage={getTranslatedText("Please wait while we sync your Xero account")}
                    />
                )}
            </Col>
        </div>
    );
};

export default withRouter(AccountingSystemManagementPage);

/**
 * File responsible for all the UI and actions for Settings>Company page container - `/app/settings/company`.
 */

import { Button, Col, Modal, Row, Tabs, Typography } from 'antd';
import { History as IHistory } from 'history';
import { debounce, get, includes, isEmpty, map } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../../config/config';
import { ApplicationState } from '../../../store';
import { getUserCompaniesRequestAction } from '../../../store/companies/actions';
import {
    CompaniesState,
    CompanyUserRole,
} from '../../../store/companies/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { getTranslatedText, updateLessVariables } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import '../settings.less';
import CompanyBrandingPage from './branding/CompanyBrandingPage';
import CompanyContactPage from './contact/CompanyContactPage';
// import { getRolePermissions } from '../../../store/roles/sagas';
import CompanyGeneralPage from './general/CompanyGeneralPage';
import MultiFactorAuthenticationPage from './multi-factor-authentication/MultiFactorAuthentication';
import CommunicationMethodPage from './communication/CommunicationMethodPage';




const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;
let fromBranding: boolean = false;
interface IProps {
    location: Location;
    history: typeof IHistory;
    match: {
        path: string;
    };
}

const routeTabs = [
    { name: "General", routeName: "General", component: CompanyGeneralPage },
    { name: "Contact", routeName: "Contact", component: CompanyContactPage },
    { name: "Branding", routeName: "Branding", component: CompanyBrandingPage },
    { name: 'Communication Methods', routeName: "Communication Methods", component: CommunicationMethodPage },
    { name: "Multi Factor Authentication", routeName: "Multi Factor Authentication", component: MultiFactorAuthenticationPage },
];

const companyRoute = '/app/settings/company';

const CompanyPageContainer: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const rolePermissions = useSelector(getRolePermissions);
    
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;

    const childRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [currentTab, setCurrentTab] = useState<string>('');

    // const rolePermissions = useSelector(getRolePermissions);

    /**
     * Responsible for setting the selected tab on page load. Based on the route path.
     */
    useEffect(() => {
        // Translate the tabs name
        routeTabs.map((routeTab: any) => {
            routeTab.name = routeTab.name || routeTab.name;

            return routeTab;
        });

        const currentTabPath = props.location.pathname
            .replace(companyRoute, '')
            .replace('/', '');

        const selectedTab = currentTabPath || routeTabs[0].routeName.toLowerCase();
        
        setCurrentTab(selectedTab);
    }, [props.location.pathname]);

    /**
     * Function that is executed when a tab is selected (change - different from the previously selected tab).
     * @param tabKey - key for the tab (required to set the tab identity)
     */
    const onTabChange = (tabKey: string) => {
        const tabPath: string = tabKey === routeTabs[0].name.toLowerCase() ? '' : `/${tabKey}`;

        if (tabKey === 'branding') {
            fromBranding = true;
        } else {
            fromBranding = false;
        }

        props.history.push(`${companyRoute}${tabPath}`);
    };

    const [modalTitle, setModalTitle] = useState<string>('');
    const [displayMessage, setDisplayMessage] = useState<string>('');
    
    /**
     * Function called when common `Save` button is clicked
     * calling the save function for the child (General, Contact, Branding).
     */
    const onSaveButtonClick = async () => {
        if (childRef.current) {
            try {
                // Translate modalTitle
                setModalTitle(getTranslatedText('Saving company data'));

                // Translate displayMessage
                setDisplayMessage(getTranslatedText('Please wait while saving company data'));

                // Perform save operation
                childRef.current.save();
            } catch (error) {
                console.error("Error occurred:", error);
            }
        }
    };

    /**
     * Function called when common `Cancel` button is clicked
     * calling the cancel function for the child tab items.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <span>
                    {getTranslatedText(`When you click the ${confirmModalOkText} button, all the data will be reverted to the last saved values`).split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                        .map((part, index) =>
                            part.startsWith('<b>') ? (
                                <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                            ) : (
                                part // Render regular text
                            )
                        )}
                </span>
            ),
            onOk() {
                if (childRef.current) childRef.current.cancel();
            },
            onCancel() {},
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving company
     * data in either of the 3 child tab items.
     * @param param0 - includes a boolean and may have an error message response from API
     */
    const handleModalSaveResponse = ({ IsSuccess }: { IsSuccess: boolean }) => {
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("Company data saved successfully"),
                onOk: () => {
                    dispatch(getUserCompaniesRequestAction());
                },
                okText: getTranslatedText("OK")
            });
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText("Failed to save company data"),
                okText: getTranslatedText("OK")
            });
        }
    };

    /**
     * A wrapper function (using debounce) for setting if form has changes.
     * Wrapped in debounce to prevent being called a couple of times in a short span of time.
     */
    const setHasChangesDebounced = debounce(() => {
        if (childRef.current) {
            const hasChanges = childRef.current.checkForChanges();
            setFormHasChanges(hasChanges);
        }
    }, 500);

    /**
     * Function called on mount and unmount.
     * This one specifically handles the unmounting to listen for changes
     * and reset colors if needed.
     */
    const mountUnmountFunction = () => {
        return () => {
            if (childRef.current) {
                const hasChanges = childRef.current.checkForChanges();

                if (hasChanges === true && fromBranding) {
                    if (selectedUserCompany) {
                        const { PrimaryColorTheme, SecondaryColorTheme } =
                            selectedUserCompany.Company;

                        if (PrimaryColorTheme && SecondaryColorTheme) {
                            updateLessVariables({
                                '@custom-primary-color': PrimaryColorTheme,
                                '@custom-secondary-color': SecondaryColorTheme,
                            });
                        }
                    }
                }
            }
        };
    };

    useEffect(mountUnmountFunction, []);

    let formsDisabled = true;
    const allowedRoles = rolePermissions.COMPANY_UPDATE_INFORMATION;
    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        formsDisabled = false;
    }

    const { saveLoading } = companiesState;

    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={12}>
                            <Title level={3}>{getTranslatedText("Company")}</Title>
                        </Col>
                        <Col span={12} className="ta-right">
                            <Button
                                className="mr-10 w-100px"
                                type="primary"
                                onClick={onSaveButtonClick}
                                disabled={formsDisabled || !formHasChanges}
                                loading={saveLoading}
                            >
                                {getTranslatedText("Save")}
                            </Button>
                            <Button
                                className="buttonGrey w-100px"
                                onClick={onCancelButtonClick}
                                disabled={formsDisabled || !formHasChanges}
                            >
                                {getTranslatedText("Cancel")}
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row key="tabs-container">
                        <Col span={24}>
                            <Tabs
                                className="left-aligned-tab h-100"
                                tabPosition="left"
                                activeKey={currentTab}
                                onChange={onTabChange}
                            >
                                {map(
                                    routeTabs,
                                    ({
                                        name: tabName,
                                        routeName: routePath,
                                        component: TabComponent,
                                    }: {
                                        name: string;
                                        routeName: string;
                                        component: any;
                                    }) => {
                                        let lowerCaseTabName = routePath.toLocaleLowerCase();

                                        return (
                                            <TabPane
                                                tab={getTranslatedText(tabName)}
                                                key={lowerCaseTabName}>
                                                {lowerCaseTabName ===
                                                    currentTab ? (
                                                    <div
                                                        className="custom-tab-container"
                                                        style={{height:window.innerHeight - 150,}}
                                                    >
                                                        <TabComponent
                                                            ref={childRef}
                                                            history={props.history}
                                                            disabled={formsDisabled}
                                                            selectedUserCompany={get(selectedUserCompany,'Company',{})}
                                                            handleModalSaveResponse={handleModalSaveResponse}
                                                            setFormHasChanges={setHasChangesDebounced}
                                                        />
                                                    </div>
                                                ) : null}
                                            </TabPane>
                                        );
                                    }
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </QueueAnim>
                {saveLoading && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={modalTitle}
                            modalVisible={saveLoading}
                            displayMessage={displayMessage}
                        />
                    </Suspense>
                )}
            </Col>
        </div>
    );
};

export default withRouter(CompanyPageContainer);

/**
 * Function for displaying an empty component (with Image and description)  - from Ant design.
 */

import React from 'react';
import { Empty } from 'antd';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    emptyMessage: string;
    componentRef?: any;
}
const EmptyList: React.FC<IProps> = ({
    emptyMessage,
    componentRef,
}: IProps) => {
    return (
        <div className="empty-list-container" ref={componentRef}>
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={getTranslatedText(emptyMessage)}
            />
        </div>
    );
};

export default EmptyList;

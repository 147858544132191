/**
 * File responsible for all the UI and actions for Settings>Payment Plan Configuration - Create Payment Plan tab item - `/app/settings/payment-plan-config`.
 */

import { Col, Row, Tabs, Spin, Modal } from 'antd';
import { History as IHistory } from 'history';
import { get, isEmpty, map, unset } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { Company } from '../../../../store/companies/types';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../../utils/commonInterfaces';
import {
    INSTALMENT_TYPE,
    FORM_FIELDS,
    TAB_NAME,
    FORM_DISPLAY
} from '../../../../constants/paymentPlanConfigs';
import CreatePaymentPlanConfigTabContent from '../../../../components/common/CreatePaymentPlanConfigTabContent';

import { PaymentPlansState, PaymentPlanConfig } from '../../..//../store/paymentPlans/types';
import { ApplicationState } from '../../../../store';
import { generateGUID, getLoadingSkeletons, getPopoverContainer, getTranslatedText } from '../../../../utils/commonFunctions';
import { getPaymentPlanConfigRequestAction, createPaymentPlanConfigRequestAction } from '../../../../store/paymentPlans/actions';
import { dateFormatDDMMYYYYSlash } from '../../../../constants/dateFormats';
import moment from 'moment-timezone';

const { TabPane } = Tabs;
interface IProps {
    history: typeof IHistory;
    disabled: boolean;
    selectedUserCompany: Company;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
    setFormHasChanges: () => void;
    form?: any;
    setSaveLoading: (
        saveLoading: boolean
    ) => void;
    isTemplate: boolean;
}
const CreatePaymentPlanConfigPage: React.FC<IProps> = forwardRef(
    (
        props: IProps,
        ref: Ref<any>
    ) => {
        const drawerRef: any = useRef(null);
        const dispatch = useDispatch();
        const { validateFields, resetFields } = props.form;
        const [paymentPlanConfigFormState, setPaymentPlanConfigFormState] = useState<PaymentPlanConfig[]>([]);
        const paymentPlansState: PaymentPlansState = useSelector(
            (state: ApplicationState) => state.paymentPlans
        );
        const { loading } = paymentPlansState;
        const paymentPlanConfigs: PaymentPlanConfig[] | undefined = paymentPlansState.paymentPlanConfigs;

        ///tab state
        const [paymentPlanOptions, setPaymentPlanOptions] = useState<any[]>([]);
        const [paymentPlanOptionTabId, setPaymentPlanOptionTabId] = useState(1);
        const [paymentPlanOptionKeys, setPaymentPlanOptionKeys] = useState<number[]>([]);
        const [selectedPaymentPlanOptionTab, setSelectedPaymentPlanOptionTab] = useState<string>("payment-plan-dynamic-0");

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const fetchInitialData = () => {
            dispatch(getPaymentPlanConfigRequestAction())
        }

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            let enabledSubmit: boolean = false;
            if (paymentPlanConfigFormState && paymentPlanOptions.length > 0) {
                map(paymentPlanOptions, (value) => {
                    let tabIndex: string = value.key.split('-')[3];

                    let installmentTypeHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + tabIndex);
                    let dateRangeHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + tabIndex);
                    let specificPaymentDayHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + tabIndex);
                    let customFieldsHasChange: any[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);
                    let depositPercentageHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + tabIndex);
                    let depositGabHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + tabIndex);
                    let depositAmountHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + tabIndex);
                    let startTimingHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.START_TIMING + tabIndex);
                    let startDelayHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.START_DELAY + tabIndex);
                    let cutOffHasChange: any = get(paymentPlanConfigFormState, FORM_FIELDS.CUT_OFF_DATE + tabIndex);
                    let instalmentAmountHasChanges: any = get(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_AMOUNT + tabIndex);
                    let addPaymentPercentHasChanges: any = get(paymentPlanConfigFormState, FORM_DISPLAY.ADD_PAY_PERCENT + tabIndex);
                    let frequencyGapHasChanges: any = get(paymentPlanConfigFormState, FORM_FIELDS.FREQUENCY_GAP + tabIndex);

                    if (installmentTypeHasChange
                        || frequencyGapHasChanges
                        || addPaymentPercentHasChanges
                        || instalmentAmountHasChanges
                        || cutOffHasChange
                        || startDelayHasChange
                        || startTimingHasChange
                        || depositAmountHasChange
                        || depositGabHasChange
                        || depositPercentageHasChange
                        || (customFieldsHasChange && customFieldsHasChange.length > 0)
                        || specificPaymentDayHasChange
                        || dateRangeHasChange) {
                        enabledSubmit = true;
                    }
                });
            }

            return enabledSubmit;
        };
        /**
         * update form state
         */
        const updateFormStateObject = (name: string, val: any) => {
            setPaymentPlanConfigFormState((prev: any) => ({
                ...prev,
                [name]: val
            }));
        };

        const hasNoNullOrEmptyProperty = (list: any, key: any) => {
            let res: boolean = list.every((obj: any) => obj[key] !== null && obj[key] !== "" && obj[key] !== undefined);
            return res;
        };


        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            validateFields((err: DynamicObject, values: DynamicObject) => {
                let paymentPlanConfigOptions: PaymentPlanConfig[] = [];
                if (!err) {
                    let invalidFields: string[] = [];
                    paymentPlanOptions.map((obj) => {

                        let keyIndex = obj.key.split('-')[3];
                        let installmentType = get(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + keyIndex);
                        let numberOfInstallment = get(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + keyIndex);
                        let paymentPlanName = get(paymentPlanConfigFormState, FORM_FIELDS.NAME + keyIndex);
                        let customFields: { Name: string; Value: string }[] = [];
                        let scheduleDates: string[] = [];
                        let customDatesWithPercent: { date: string, payPercent: number }[] = [];
                        let payPercents: number[] = [];
                        //add schedule dates
                        if (numberOfInstallment != null && numberOfInstallment > 0) {
                            switch (installmentType) {
                                case INSTALMENT_TYPE.DATE_RANGE:
                                    let dates = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + keyIndex);
                                    if (dates) scheduleDates = dates;
                                    break;
                                case INSTALMENT_TYPE.CUSTOM:
                                    let key: number[] = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + keyIndex);
                                    for (var i = 0; i < numberOfInstallment; i++) {
                                        let scheduleDate = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULED_DATE + keyIndex + key[i]);

                                        if (!isEmpty(scheduleDate))
                                            scheduleDates.push(scheduleDate.format(dateFormatDDMMYYYYSlash));

                                    }
                                    break;
                            }
                        }

                        if (scheduleDates.length == 0 && installmentType == INSTALMENT_TYPE.DATE_RANGE) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("Schedule dates are not yet generated.")}`);

                        //schedule dates validation
                        const uniqueScheduleDates = new Set(scheduleDates);
                        if (uniqueScheduleDates.size !== scheduleDates.length) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("Schedule dates has duplicate.")}`);

                        //add tab custom fields
                        let customFieldTabKeys: any[] | null = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + keyIndex);
                        if (customFieldTabKeys && customFieldTabKeys.length > 0) {
                            map(customFieldTabKeys, (val) => (
                                customFields.push({
                                    Name: get(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + keyIndex + val),
                                    Value: get(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + keyIndex + val)
                                })
                            ));
                        }

                        //check if customer fields has duplicate
                        const uniqueCustomeFields = new Set(customFields.map(obj => JSON.stringify(obj)));
                        if (uniqueCustomeFields.size !== customFields.length) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("Custom fields has duplicate.")}`);

                        //add pay percent
                        let addPayPercent: boolean = get(paymentPlanConfigFormState, FORM_DISPLAY.ADD_PAY_PERCENT + keyIndex);
                        let totalPayPercent: number = 0;
                        if (addPayPercent && numberOfInstallment > 0) {
                            for (var i = 0; i < numberOfInstallment; i++) {
                                let payPercent: number = get(paymentPlanConfigFormState, FORM_FIELDS.PAY_PERCENT + keyIndex + i);
                                if (payPercent) {
                                    payPercents.push(payPercent);
                                    customDatesWithPercent.push({ date: scheduleDates[i], payPercent: payPercent });
                                    totalPayPercent = totalPayPercent + payPercent;
                                }
                            }
                        }

                        //pay percent validations
                        if (addPayPercent && totalPayPercent != 100) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("Payment percentage is not equal to 100%.")}`);
                        if (addPayPercent && payPercents.length !== numberOfInstallment) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("The payment percentage is not completed.")}`);

                        //maximum number of instalment in daterange
                        let instalmentMax = get(paymentPlanConfigFormState, FORM_DISPLAY.INSTALMENT_MAX + keyIndex);
                        if (installmentType == INSTALMENT_TYPE.DATE_RANGE && numberOfInstallment > instalmentMax) invalidFields.push(`${paymentPlanName} - ${getTranslatedText("There are")} ${numberOfInstallment} ${getTranslatedText("instalments but we only have")} ${instalmentMax} ${getTranslatedText("days.")}`);

                        if (installmentType == INSTALMENT_TYPE.CUSTOM) {
                            let sortedDatesAsc = scheduleDates.sort((a, b) =>
                                a.split('/').reverse().join().localeCompare(b.split('/').reverse().join()));
                            scheduleDates = sortedDatesAsc;
                        }

                        if (addPayPercent && installmentType == INSTALMENT_TYPE.CUSTOM) {
                            let sortedAsc = customDatesWithPercent.sort((a, b) =>
                                a.date.split('/').reverse().join().localeCompare(b.date.split('/').reverse().join()));

                            let sortPayPercents: number[] = [];
                            for (var i = 0; i < sortedAsc.length; i++)
                                sortPayPercents.push(sortedAsc[i].payPercent);

                            payPercents = sortPayPercents;
                        }

                        let payload: PaymentPlanConfig = {
                            ConfigId: generateGUID(),
                            Instalments: numberOfInstallment,
                            IsTemplate: props.isTemplate,
                            NewVersion: true,
                            Name: get(paymentPlanConfigFormState, FORM_FIELDS.NAME + keyIndex),
                            InstalmentType: installmentType
                        };

                        //assign payload field values
                        if (customFields.length > 0) payload.CustomFields = customFields;
                        if (scheduleDates != null && scheduleDates.length > 0) payload.ScheduleDates = scheduleDates;
                        if (installmentType == INSTALMENT_TYPE.MONTHLY) payload.Frequency = INSTALMENT_TYPE.MONTHLY;
                        if (addPayPercent) payload.PayPercent = payPercents;

                        let depositPercentag = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + keyIndex);
                        if (depositPercentag && depositPercentag > 0) payload.DepositPercentage = depositPercentag

                        let depositGap = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + keyIndex);
                        if (depositGap && depositGap > 0) payload.DepositGap = depositGap;

                        let depositAmount = get(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + keyIndex);
                        if (depositAmount && depositAmount > 0) payload.DepositAmount = depositAmount;

                        let startTiming = get(paymentPlanConfigFormState, FORM_FIELDS.START_TIMING + keyIndex);
                        if (startTiming) payload.StartTiming = startTiming;

                        let startDelay = get(paymentPlanConfigFormState, FORM_FIELDS.START_DELAY + keyIndex);
                        if (startDelay && startDelay > 0) payload.StartDelay = startDelay;

                        let cutoff = get(paymentPlanConfigFormState, FORM_FIELDS.CUT_OFF_DATE + keyIndex);
                        if (cutoff) payload.CutoffDate = cutoff.format(dateFormatDDMMYYYYSlash);

                        let frequencyGap = get(paymentPlanConfigFormState, FORM_FIELDS.FREQUENCY_GAP + keyIndex);
                        if (frequencyGap && frequencyGap > 0) payload.FrequencyGap = frequencyGap;

                        let specificPaymentDate = get(paymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + keyIndex);
                        if (specificPaymentDate && specificPaymentDate > 0) payload.SpecificPaymentDate = specificPaymentDate;

                        let splitInstalmentDateRange = get(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + keyIndex);
                        if (splitInstalmentDateRange) payload.SplitInstalmentDateRange = splitInstalmentDateRange;

                        let instalmentAmount = get(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_AMOUNT + keyIndex);
                        if (instalmentAmount) payload.InstalmentAmount = instalmentAmount;

                        //validate required fields on the other tabs
                        let invalidCustomFields: boolean = (customFields.length > 0 && (!hasNoNullOrEmptyProperty(customFields, 'Value') || !hasNoNullOrEmptyProperty(customFields, 'Name')));
                        let invalidRequiredFields: boolean = false;
                        switch (installmentType) {
                            case INSTALMENT_TYPE.DATE_RANGE:
                                if (!splitInstalmentDateRange || !numberOfInstallment || invalidCustomFields)
                                    invalidRequiredFields = true;
                                break;
                            case INSTALMENT_TYPE.MONTHLY:
                                if (!numberOfInstallment || !startDelay || !startTiming || !frequencyGap || !scheduleDates || invalidCustomFields)
                                    invalidRequiredFields = true;
                                break;
                            case INSTALMENT_TYPE.CUSTOM:
                                if (numberOfInstallment !== scheduleDates.length || invalidCustomFields) invalidRequiredFields = true;
                                break
                        }

                        let errorMessage = invalidCustomFields ? getTranslatedText("Required fields need to be populated in custom fields") : getTranslatedText("Required fields not found");
                        if (invalidRequiredFields) invalidFields.push(`${paymentPlanName} - ${errorMessage}.`);

                        paymentPlanConfigOptions.push(payload);

                    });

                    if (invalidFields.length > 0) {
                        Modal.error({
                            title: getTranslatedText("Invalid values"),
                            content: (<ul>
                                {map(
                                    invalidFields,
                                    (error: string) => (
                                        <li key={error}>{error}</li>
                                    )
                                )}</ul>
                            ),
                            getContainer: () => getPopoverContainer(drawerRef),
                        });
                    } else if (paymentPlanConfigOptions.length > 0) {
                        props.setSaveLoading(true);

                        //get latest payment plan config
                        fetchInitialData();

                        //include config in other page
                        let otherConfig = paymentPlanConfigs.filter(w => w.IsTemplate !== props.isTemplate && !w.IsDefault);
                        if (otherConfig && otherConfig.length > 0)
                            otherConfig.map(config => {
                                paymentPlanConfigOptions.push(config);
                            });

                        const paymentPlanConfigPayload: {
                            PaymentPlanConfigurations: {},
                        } = {
                            PaymentPlanConfigurations: paymentPlanConfigOptions
                        }

                        submitPaymentPlanConfig(paymentPlanConfigPayload);
                    }
                }
            });
        };

        const resetAllFields = (tabIndex: number) => {

            //unset fields states
            unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + tabIndex);
            unset(paymentPlanConfigFormState, FORM_DISPLAY.SCHEDULE_DATES_DISPLAY + tabIndex);

            unset(paymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + tabIndex);

            unset(paymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + tabIndex);



            if (tabIndex != 0) unset(paymentPlanConfigFormState, FORM_FIELDS.NAME + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.START_DELAY + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.START_TIMING + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.FREQUENCY_GAP + tabIndex);
            unset(paymentPlanConfigFormState, FORM_FIELDS.CUT_OFF_DATE + tabIndex);

            unset(paymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_AMOUNT + tabIndex);


            //remove custom fields
            let keys: number[] = get(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + tabIndex);
            if (keys && keys.length > 0) {
                map(keys, (key) => {
                    unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + tabIndex + key);
                    unset(paymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + tabIndex + key);
                });
            }

            //remove customer schedule date
            let scheduleDatesKey: number[] = get(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + tabIndex);
            if (scheduleDatesKey && scheduleDatesKey.length > 0) {
                map(scheduleDatesKey, (key) => {
                    unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULED_DATE + tabIndex + key);

                });
                unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + tabIndex);
            }

            //remove pay percent
            let numberOfInstalment: number = get(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + tabIndex);
            if (numberOfInstalment) {
                for (var i = 0; i < numberOfInstalment; i++)
                    unset(paymentPlanConfigFormState, FORM_FIELDS.PAY_PERCENT + tabIndex + i);

                unset(paymentPlanConfigFormState, FORM_DISPLAY.PAY_PERCENT_TOTAL + tabIndex);
                unset(paymentPlanConfigFormState, FORM_DISPLAY.PAY_PERCENT_WARNING + tabIndex);
                unset(paymentPlanConfigFormState, FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY + tabIndex);
                unset(paymentPlanConfigFormState, FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW + tabIndex);
                unset(paymentPlanConfigFormState, FORM_DISPLAY.ADD_PAY_PERCENT + tabIndex);
            }
            unset(paymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + tabIndex);


            if (tabIndex == 0) {

                //set initial custom field tab state
                updateFormStateObject(TAB_NAME.CUSTOM_FIELD_ID + tabIndex, 0);
                updateFormStateObject(TAB_NAME.CUSTOM_FIELDS + tabIndex, []);
                updateFormStateObject(TAB_NAME.CUSTOM_FIELD_KEYS + tabIndex, []);
                updateFormStateObject(TAB_NAME.SELECTED_CUSTOM_FIELD + tabIndex, '');
            } else {

                //remove state
                unset(paymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + tabIndex);
                unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELDS + tabIndex);
                unset(paymentPlanConfigFormState, TAB_NAME.SELECTED_CUSTOM_FIELD + tabIndex);
                unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + tabIndex);
                unset(paymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_ID + tabIndex);
            }

        }

        /**
         * Function called when cancel is clicked.
         * Resets all the changes and set the data based on the one saved in redux (the original saved data).
         */
        const handleCancel = () => {
            if (paymentPlanOptions && paymentPlanOptions.length > 0) {
                map(
                    paymentPlanOptions, (option) => {
                        let keyIndex = option.key.split('-')[3];
                        resetAllFields(keyIndex);
                    });

                //set init tab option
                setSelectedPaymentPlanOptionTab('payment-plan-dynamic-0');
                let saveFirstOption: any[] = [paymentPlanOptions[0]];
                setPaymentPlanOptions(saveFirstOption);
            }

            resetFields();
            props.setFormHasChanges();

        };

        const submitPaymentPlanConfig = (payload: any) => {
            dispatch(
                createPaymentPlanConfigRequestAction(payload, props.handleModalSaveResponse)
            );
        }

        const updatePaymentPlanOptionPanes = () => {

            if (paymentPlanConfigFormState.length == 0) {
                let tabPane: any[] = [];
                const tabOption = {
                    key: `payment-plan-dynamic-0`,
                    component: CreatePaymentPlanConfigTabContent,
                    closable: false
                }

                tabPane.push(tabOption);
                setPaymentPlanOptions(tabPane);

                setPaymentPlanConfigFormState((prev: any) => ({
                    ...prev,
                    [FORM_FIELDS.NAME + '0']: getTranslatedText("Payment plan")
                }));
            }

            if (paymentPlanConfigFormState.length != 0 && paymentPlanOptions) {

                const newOptions: any[] = [];
                map(
                    paymentPlanOptions,
                    (option) => {
                        let key = option.key.toString().split('-')[3];
                        let tabName = get(paymentPlanConfigFormState, FORM_FIELDS.NAME + key);
                        if (tabName && tabName != '') option.title = tabName;
                        newOptions.push(option);
                    }
                );

                setPaymentPlanOptions(newOptions);
            }


        }
        useEffect(updatePaymentPlanOptionPanes, [paymentPlanConfigFormState]);

        const addPaymentPlanOption = () => {
            const tabOption = {
                key: `payment-plan-dynamic-${paymentPlanOptionTabId}`,
                component: CreatePaymentPlanConfigTabContent,
                closable: true
            }

            setPaymentPlanOptions([...paymentPlanOptions, tabOption]);

            setPaymentPlanConfigFormState((prev: any) => ({
                ...prev,
                [FORM_FIELDS.NAME + paymentPlanOptionTabId]: getTranslatedText("Payment plan") + " " + (paymentPlanOptionTabId + 1)
            }));

            setPaymentPlanOptionKeys([...paymentPlanOptionKeys, paymentPlanOptionTabId]);
            setPaymentPlanOptionTabId(paymentPlanOptionTabId + 1);
            setSelectedPaymentPlanOptionTab("payment-plan-dynamic-" + paymentPlanOptionTabId);
        };

        const removePaymentPlanOptions = (targetKey: any) => {

            // Filter out the tab with the targetKey
            const newTabs = paymentPlanOptions.filter((pane) => pane.key !== targetKey);
            const tabIndex = targetKey.split('-')[3];

            resetAllFields(tabIndex);

            // If the active tab is being removed, switch to the next tab or the previous one
            if (selectedPaymentPlanOptionTab === targetKey && newTabs.length > 0) {
                // If the tab to be removed is active, set the new active key
                setSelectedPaymentPlanOptionTab(newTabs[0].key); // Set the active key to the first remaining tab's key
            }

            setPaymentPlanOptions(newTabs);
        };

        const handlePaymentPlanOptionEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove") => {
            if (action === 'add') {
                addPaymentPlanOption();  // Call the add function when action is 'add'
            } else if (action === 'remove') {
                removePaymentPlanOptions(targetKey);
            }
        };

        /**
        * Function called when the active payment plan option tab (clicking on tab item - title)
        * @param value
        */
        const handlePaymentPlanOptionTabChange = (value: string) => {
            setSelectedPaymentPlanOptionTab(value);
        };

        return (
            <>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => props.history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <h4 className="mb-9">{getTranslatedText("Create Payment Plan Configuration")}</h4>
                    <Spin
                        spinning={loading}
                        tip={getTranslatedText("Loading payment plan configuration details")}
                        style={{
                            height: '80%',
                        }}
                    >
                        <Row>
                            <Col
                                xxl={24}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={18}
                                xs={18}
                            >
                                <Tabs
                                    onChange={handlePaymentPlanOptionTabChange}
                                    activeKey={selectedPaymentPlanOptionTab}
                                    type="editable-card"
                                    onEdit={(targetKey, action) => handlePaymentPlanOptionEditTab(targetKey, action)}
                                    destroyInactiveTabPane={false}
                                >
                                    {map(
                                        paymentPlanOptions,
                                        ({
                                            component: TabItemComponent,
                                            paymentPlanConfig,
                                            ...option
                                        }) => {

                                            let keyIndex = option.key.toString().split('-')[3];

                                            return (
                                                <TabPane
                                                    tab={
                                                        option.title
                                                    }
                                                    key={option.key}
                                                    closable={option.closable}
                                                >
                                                    {selectedPaymentPlanOptionTab === option.key &&
                                                        (loading ? (
                                                            getLoadingSkeletons(
                                                                3,
                                                                loading
                                                            )
                                                        ) : (
                                                            <TabItemComponent
                                                                form={props.form}
                                                                setPaymentPlanConfigFormState={setPaymentPlanConfigFormState}
                                                                paymentPlanConfigFormState={paymentPlanConfigFormState}
                                                                setFormHasChanges={props.setFormHasChanges}
                                                                paymentPlanOptionIndex={keyIndex}
                                                                selectedUserCompany={props.selectedUserCompany}
                                                                isTemplate={props.isTemplate}
                                                            />
                                                        ))}
                                                </TabPane>
                                            )
                                        }
                                    )}

                                </Tabs>
                            </Col>
                        </Row>
                    </Spin>
                </QueueAnim>
            </>
        );
    }
);

export default CreatePaymentPlanConfigPage;


/**
 * File responsible for the credit table row item component.
 */

import { Checkbox, Col, Row } from 'antd';
import {
    get,
    includes,
    isUndefined,
    isEqual,
} from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import '../../pages/sales/sales.less';
import { getBankFileDescriptorSelectedId } from '../../store/bankFileDescriptors/sagas';
import { BankFileDescriptor } from '../../store/bankFileDescriptors/types';
import { getCompanyName, getIfIsLg, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { Customer } from '../../store/customers/types';

interface IProps {
    readonly item: BankFileDescriptor;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
}

const BankFileDescriptorItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const bankFileDescriptorIconContainerRef = useRef<any>(null);
    const {
        Id,
        Customer,
        Descriptors
    } = item;

    const selectedId = useSelector(getBankFileDescriptorSelectedId);

    /**
     * Function called for adding the key prop - identifier for the row.
     */
    const getItemInfo = () => {
        const itemInfo: DynamicObject = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function triggered when row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    let itemColor = 'green';

    const isLg = getIfIsLg();

    let firstRowContainerHeight: any = 'auto';

    if (isLg) {
        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    let iconCircleColor = 'green';
    let iconColor = 'green';

    return (
        <Row
            className={`list-item-clickable ${selectedId === Id ? 'list-item-clicked' : ''
                }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }}
            >
                <Row
                    className="pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                    type="flex"
                    align="middle"
                >
                    <Col xxl={2} xl={3} lg={3} md={2} sm={2} xs={2}>
                        <Row className="fx-center-xl">
                            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                <div
                                    className="ta-center center-flex-jc-i"
                                    style={{
                                        height: firstRowContainerHeight,
                                    }}
                                >
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={16}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-left"
                            >
                                <div
                                    className="descriptor-icon-container"
                                    ref={bankFileDescriptorIconContainerRef}
                                >
                                    <div className="fa-layers fa-fw fa-2x w-100">
                                        <FontAwesome
                                            icon={['far', 'circle']}
                                            className={
                                                iconCircleColor || iconColor
                                            }
                                            size="2x"
                                            transform="shrink-2"
                                        />
                                        <FontAwesome
                                            icon={['fas', 'user']}
                                            className={iconColor}
                                            size="2x"
                                            transform="shrink-8"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={22} xl={21} lg={21} md={22} sm={22} xs={22}>
                        <Row className="first-row-container" type="flex">
                            <Col span={24}>
                                <div ref={firstRowContainerRef}>
                                    <Row gutter={20}>
                                        <Col
                                            xxl={17}
                                            xl={15}
                                            lg={12}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                        >
                                            <Row type="flex" gutter={60}>
                                                {!isUndefined(
                                                    get(
                                                        Customer,
                                                        'CustomerCode'
                                                    )
                                                ) && (
                                                        <Col
                                                            // xxl={4}
                                                            className={`${isLg
                                                                    ? 'flx-5'
                                                                    : 'flx-2'
                                                                } mxw-100`}
                                                        >
                                                            <b className="ws-nw">
                                                                {get(
                                                                    Customer,
                                                                    'CustomerCode'
                                                                )}
                                                            </b>
                                                        </Col>
                                                    )}

                                                <Col
                                                    className="flx-6 mxw-100"
                                                    span={isLg ? 24 : undefined}
                                                >
                                                    <b className="ws-nw">
                                                        {getCompanyName(
                                                            Customer as Customer
                                                        )}
                                                    </b>
                                                </Col>
                                            </Row>
                                            {Descriptors && (<Row type="flex" gutter={60}>
                                                <Col
                                                    className={`mxw-100`}
                                                >
                                                    {`${Descriptors.length > 1 ? getTranslatedText('Descriptors') : getTranslatedText('Descriptor') }: ${Descriptors.join(" | ")}`}
                                                </Col>
                                            </Row>)}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(BankFileDescriptorItemComponent)),
    arePropsEqual
);
/**
 * File responsible for the credit table row item component.
 */

import { Button, Checkbox, Col, Row, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import {
    get,
    includes,
    map,
    compact,
    concat,
    isEmpty,
    isUndefined,
    isEqual,
} from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import '../../pages/sales/sales.less';
import { CustomField } from '../../store/common/types';
import { getCreditSelectedId } from '../../store/credits/sagas';
import { Credit } from '../../store/credits/types';
import { getCompanyName, getIfIsLg, getTranslatedText, roundNumberToDecimalDigits } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';


interface IProps {
    readonly item: Credit;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const CreditItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    formatDateLocal,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const creditIconContainerRef = useRef<any>(null);
    const {
        Id,
        Number: CreditNumber,
        LocalCreatedDate,
        LocalAvailableDate,
        OriginalAmount,
        AmountAvailable,
        Type,
        CreatedType,
        Percentage,
        IsRecurring,
        State,
        // ClosedDate,
        Customer,
        CustomFields,
        Attachments,
        Company
    } = item;

    const selectedId = useSelector(getCreditSelectedId);
    
    const CompanyName = get(Company, 'Name');

    /**
     * Function called for adding the key prop - identifier for the row.
     */
    const getItemInfo = () => {
        const itemInfo: DynamicObject = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function triggered when row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    // const name = `${get(item, 'User.GivenName', 'Jonathan')} ${get(
    //     item,
    //     'User.FamilyName',
    //     'Smith'
    // )}`;

    /**
     * Function that formats the currency if the format currency function from
     * NumberFormatHandler HOC exists.
     * @param amount
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    let itemColor = 'green';

    /**
     * Function that gets that is triggered when clicking on the comment icon from table row.
     * @param e
     */
    // const viewCommentsSectionDrawer = (
    //     e: React.MouseEvent<HTMLElement, MouseEvent>
    // ) => {
    //     e.stopPropagation();
    //     const itemInfo = getItemInfo();
    //     if (onRowClick) onRowClick(itemInfo, 'conversation');
    // };

    const isLg = getIfIsLg();

    let firstRowContainerHeight: any = 'auto';

    if (isLg) {
        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    const AllCustomFields = concat(
        compact(CustomFields),
        compact(get(Customer, 'CustomFields'))
    );

    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            AllCustomFields,
            ({ Name, Value }: CustomField) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    let iconCircleColor = 'green';
    let iconColor = 'green';

    if (!(AmountAvailable > 0 || (Percentage > 0 && State === "Open"))) {
        iconCircleColor = 'settled';
        iconColor = 'settled';

        itemColor = 'settled';
    }

    /**
     * Function to populate `Created on` and `Available on` components.
     * Defined to easily be used in different screen size layouts.
     */
    const createdAndAvailableDatesComponent = (
        <Row type="flex" gutter={60}>
            <Col>{getTranslatedText(`Created ${CreatedType}`)}</Col>
            <Col>{getTranslatedText("Created on date").replace('[date]', formatDateLocal(LocalCreatedDate))}</Col>
            <Col>{getTranslatedText("Available on date").replace('[date]', formatDateLocal(LocalAvailableDate))}</Col>
        </Row>
    );

    const translateAttachmentTooltip = (totalAttachment: number, text: string) => {
        let translatedText = getTranslatedText(text).replace('[totalAttachment]', totalAttachment.toString());

        return translatedText;
    }

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }}
            >
                <Row
                    className="pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                    type="flex"
                    align="middle"
                >
                    <Col xxl={2} xl={3} lg={3} md={2} sm={2} xs={2}>
                        <Row className="fx-center-xl">
                            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                                <div
                                    className="ta-center center-flex-jc-i"
                                    style={{
                                        height: firstRowContainerHeight,
                                    }}
                                >
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={16}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-left"
                            >
                                <div
                                    className="credit-icon-container"
                                    ref={creditIconContainerRef}
                                >
                                    <div className="fa-layers fa-fw fa-2x w-100">
                                        <Tooltip
                                            title={getTranslatedText(Type)}
                                            placement="topRight">
                                            <FontAwesome
                                                icon={['far', 'circle']}
                                                className={
                                                    iconCircleColor || iconColor
                                                }
                                                size="2x"
                                                transform="shrink-2"
                                            />
                                            <FontAwesome
                                                icon={['fas', Type === 'Credit' ? 'file-invoice-dollar' : 'hand-holding-usd']}
                                                className={iconColor}
                                                size="2x"
                                                transform="shrink-8"
                                                />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={22} xl={21} lg={21} md={22} sm={22} xs={22}>
                        <Row className="first-row-container" type="flex">
                            <Col span={24}>
                                <div ref={firstRowContainerRef}>
                                    <Row gutter={20}>
                                        <Col
                                            xxl={17}
                                            xl={15}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Row type="flex" gutter={60}>
                                                <Col
                                                    // xxl={4}
                                                    className={`${
                                                        isLg ? 'flx-5' : 'flx-2'
                                                    } mxw-100`}
                                                >
                                                    <b className="ws-nw">
                                                        {CreditNumber}
                                                    </b>
                                                </Col>
                                                {!isUndefined(
                                                    get(
                                                        Customer,
                                                        'CustomerCode'
                                                    )
                                                ) && (
                                                    <Col
                                                        // xxl={4}
                                                        className={`${
                                                            isLg
                                                                ? 'flx-5'
                                                                : 'flx-2'
                                                        } mxw-100`}
                                                    >
                                                        <b className="ws-nw">
                                                            {get(
                                                                Customer,
                                                                'CustomerCode'
                                                            )}
                                                        </b>
                                                    </Col>
                                                )}

                                                <Col
                                                    className="flx-6 mxw-100"
                                                    span={isLg ? 24 : undefined}
                                                >
                                                    <b className="ws-nw">
                                                        {getCompanyName(
                                                            Customer
                                                        )}
                                                    </b>
                                                </Col>
                                                {CompanyName && (<Col
                                                    className="flx-6 mxw-100"
                                                    span={isLg ? 24 : undefined}
                                                >
                                                    <b className="ws-nw">
                                                        {CompanyName}
                                                    </b>
                                                </Col>)}
                                            </Row>
                                            <Row>
                                                <Col
                                                    xxl={24}
                                                    xl={24}
                                                    lg={0}
                                                    md={0}
                                                    sm={0}
                                                    xs={0}
                                                >
                                                    {
                                                        createdAndAvailableDatesComponent
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/* Fixed Total overdue and contact icons container */}
                                        <Col
                                            xxl={7}
                                            xl={9}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Row type="flex">
                                                <Col className="ta-right unallocated-container fx-1">
                                                    <Row>
                                                        <Col>
                                                            <b
                                                                className={
                                                                    itemColor
                                                                }
                                                            >
                                                                {
                                                                    Percentage && Percentage > 0 ?
                                                                    <>{getTranslatedText("Amount matched from Remittance Advice")}</>
                                                                    // <>{getTranslatedText("Percentage")}{' '}{roundNumberToDecimalDigits(Percentage)}</>
                                                                    :
                                                                    <>{getTranslatedText("Unallocated amount")}{' '}{handleFormatCurrency(AmountAvailable)}</>
                                                                }
                                                                
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xxl={24}
                                                            xl={24}
                                                            lg={24}
                                                            md={24}
                                                            sm={24}
                                                            xs={24}
                                                        >
                                                            <Row gutter={30}>
                                                                <Col span={10}>
                                                                    <ButtonGroup className="button-link-icons">
                                                                        {!isEmpty(
                                                                            Attachments
                                                                        ) && (
                                                                            <Tooltip
                                                                                title={translateAttachmentTooltip(Attachments.length, `attachment${Attachments.length > 1 ? 's' : ''} available`)}
                                                                                placement="topRight"
                                                                            >
                                                                                <Button
                                                                                    type="link"
                                                                                    className="row-icons-btn"
                                                                                >
                                                                                    <FontAwesome
                                                                                        icon={[
                                                                                            'fas',
                                                                                            'file-alt',
                                                                                        ]}
                                                                                        className="blue"
                                                                                    />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        {
                                                                            IsRecurring && IsRecurring === true &&
                                                                            <Tooltip
                                                                                title={getTranslatedText("Recurring")}
                                                                                placement="topRight">
                                                                                <Button
                                                                                    type="link"
                                                                                    className="row-icons-btn"
                                                                                >
                                                                                    <FontAwesome
                                                                                        icon={[
                                                                                            'fas',
                                                                                            'sync',
                                                                                        ]}
                                                                                        className="blue"
                                                                                    />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        }
                                                                        {/* <Button type="link">
                                                                            <FontAwesome
                                                                                icon={[
                                                                                    'fas',
                                                                                    'file-alt',
                                                                                ]}
                                                                                className="blue"
                                                                            />
                                                                        </Button> */}
                                                                        {/* {LatestComment && (
                                                                            <Tooltip
                                                                                title={
                                                                                    LatestComment
                                                                                }
                                                                                placement="topRight"
                                                                            >
                                                                                <Button
                                                                                    type="link"
                                                                                    onClick={
                                                                                        viewCommentsSectionDrawer
                                                                                    }
                                                                                >
                                                                                    <FontAwesome
                                                                                        icon={[
                                                                                            'fas',
                                                                                            'comments',
                                                                                        ]}
                                                                                        className="blue"
                                                                                    />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )} */}
                                                                    </ButtonGroup>
                                                                </Col>
                                                                <Col span={14}>
                                                                    {
                                                                        Percentage && Percentage > 0 ?
                                                                        ''
                                                                        :
                                                                        <>
                                                                            {getTranslatedText("Original total")}{' '}{handleFormatCurrency(OriginalAmount)}
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={0} xl={0} lg={24} md={24} sm={24} xs={24}>
                                {createdAndAvailableDatesComponent}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                {populateCustomFields()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

// export default withDateFormatHandler(
//     withNumberFormatHandler(CreditItemComponent)
// );

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(CreditItemComponent)),
    arePropsEqual
);
